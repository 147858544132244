import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import FooterButtonCircle from '@/bingo/components/FormSection/FooterButtonCircle.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx'
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import SubTotal from '@/bingo/components/FormSection/RandomTicket/SubTotal.tsx'
import TicketSlider from '@/bingo/components/FormSection/RandomTicket/TicketSlider.tsx'
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import { defaultMultiplier } from '@/bingo/const/bingoConfigs.ts'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
import {
  bingoActions,
  selectBingoCanBack,
  selectBingoNumbersOfTickets,
  selectBingoTicketUnitPrice,
} from '@/bingo/redux/bingo.slice.ts'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { genTicketId } from '@/bingo/utils/BingoEngine'
import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Center, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React from 'react'
import { PiClipboardTextFill } from 'react-icons/pi'
import MyBetsBingo from '../../MyBets'
import FormGoBack from '../FormGoBack'
import { selectWallet } from '@store/modules/wallet.slice.ts'

const RandomTicket = () => {
  const walletSelected = useAppSelector(selectWallet)
  const numberOfTickets = useAppSelector(selectBingoNumbersOfTickets)
  const ticketUnitPrice = useAppSelector(selectBingoTicketUnitPrice)
  const dispatch = useAppDispatch()
  const { isOpen: isOpenMyBets, onOpen: onOpenMyBets, onClose: onCloseMyBet } = useDisclosure()

  const handleGenerateTicket = () => {
    const generatedTickets: LocalBingoTicket[] = []

    for (let i = 0; i < numberOfTickets; i++) {
      const numbers = generateBingoCard()
      const bingoTicket: LocalBingoTicket = {
        id: genTicketId(numbers),
        star: defaultMultiplier,
        numbers,
      }
      generatedTickets.push(bingoTicket)
    }

    dispatch(bingoActions.updateTicketsGenerated(generatedTickets))
    dispatch(bingoActions.naviate({ step: 'orderConfirmation' }))
  }

  const canBack = useAppSelector(selectBingoCanBack)

  const handleGoBack = () => {
    dispatch(bingoActions.goBack())
  }

  const { t } = useCustomTranslation()

  return (
    <FormContainer title={t('bingo.randomTicket')}>
      <Stack padding="15px" position="relative" flex="1" gap="0">
        <SubTitle marginBottom="10px">
          <Text>{t('bingo.ticketUnitPrice')}</Text>
          <BingoCurrencyIcon currency={walletSelected?.currency} />
          <Text>{ticketUnitPrice}</Text>
        </SubTitle>
        <TicketSlider />
        <SubTotal />
      </Stack>
      <FormFooter>
        {canBack ? (
          <FormGoBack onClick={handleGoBack} />
        ) : (
          <FooterButtonCircle
            position="absolute"
            top="50%"
            left="20px"
            transform="translateY(-50%)"
            onClick={onOpenMyBets}
          >
            <PiClipboardTextFill />
          </FooterButtonCircle>
        )}
        <Center flex={1}>
          <FormButtonBig onClick={handleGenerateTicket}>{t('bingo.generateTicket')}</FormButtonBig>
        </Center>
      </FormFooter>
      {isOpenMyBets && (
        <AppDrawer title={'Game records'} isOpen={isOpenMyBets} onClose={onCloseMyBet} overlay={true}>
          <MyBetsBingo />
        </AppDrawer>
      )}
    </FormContainer>
  )
}

export default RandomTicket
