import { useAppSelector } from '@/redux/store'
import { selectDiffTime } from '@/redux/store/modules/game.slice'
import { useEffect, useRef, useState } from 'react'
import { bingoConfig } from '../const/bingoConfigs'
import { selectBingoGameRoundStartTime } from '../redux/bingo.slice'

const { bingoWarningDuration } = bingoConfig.round

export function useBingo() {
  const start_time = useAppSelector(selectBingoGameRoundStartTime)
  const [hasBingo, setBingo] = useState(false)
  const diffTime = useAppSelector(selectDiffTime)

  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    diffTimeRef.current = diffTime
  }, [diffTime])

  useEffect(() => {
    if (!start_time) return
    const countdown = new Date(start_time).getTime() - Date.now() - diffTimeRef.current
    if (countdown < 0) return

    const timeout = setTimeout(() => {
      setBingo(true)
    }, countdown)
    return () => clearTimeout(timeout)
  }, [start_time])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBingo(false)
    }, bingoWarningDuration)
    return () => clearTimeout(timeout)
  }, [hasBingo])
  return hasBingo
}
