import { isMobile } from 'react-device-detect'

type zoomUIParams = {
  desiredWidth?: number
}

export const zoomUI = ({ desiredWidth = 515 }: zoomUIParams) => {
  const root: HTMLElement | null = document.querySelector(':root')

  const viewportWidth = window.innerWidth
  if (viewportWidth > desiredWidth) {
    if (root) {
      if (!isMobile) {
        root.style.setProperty('--zoom-level', 'unset')
      }
      root.style.setProperty('--zoom-level-element', 'unset')
    }

    return 100
  }

  const _zoomLevel = (viewportWidth / desiredWidth) * 100
  const zoomLevel = _zoomLevel + '%'

  if (root) {
    if (!isMobile) {
      root.style.setProperty('--zoom-level', zoomLevel)
    }
    root.style.setProperty('--zoom-level-element', _zoomLevel * window.innerWidth / window.screen.width + '%')
  }

  return _zoomLevel
}