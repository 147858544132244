import React, { useRef, useState } from 'react'
import { Box, BoxProps, Flex, Spinner } from '@chakra-ui/react'

export type RocketButtonBetProps = BoxProps & {
  onClickFn?: (params?: any) => any,
  isDisabled?: boolean,
  isLoading?: boolean,
}

const RocketButtonBet = ({onClickFn, isDisabled, isLoading, children, ...rest}: RocketButtonBetProps) => {
  const [clicked, setClicked] = useState<boolean>(false)
  const setClickedFalseTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

  const handleMouseDown = () => {
    if (clicked || isDisabled || isLoading) return

    setClicked(true)
  }

  const handleMouseUp = () => {
    if (isDisabled || isLoading) return

    if (clicked) {
      setClicked(false)
    }

    if (!onClickFn) return

    onClickFn()
  }

  const handleMouseLeave = () => {
    if (!clicked || isDisabled || isLoading) return

    setClicked(false)
  }

  const handleClick = () => {
    if (isDisabled || isLoading) return

    clearTimeout(setClickedFalseTimerRef.current)
    setClicked(true)
    setClickedFalseTimerRef.current = setTimeout(() => {
      setClicked(false)
    }, 50)
  }

  return (
    <Box
      // width="106px"
      minWidth="146px"
      minHeight="54px"
      aspectRatio="320/153"
      position="relative"
      userSelect="none"
      lineHeight="1"
      cursor={(isDisabled || isLoading) ? 'not-allowed' : 'pointer'}
      opacity={(isDisabled || isLoading) ? 0.4 : 1}
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      _after={{
        // dark shadow
        content: '""',
        background: '#1a212a',
        borderRadius: '10px',
        boxShadow: 'inset 0 -1px 0px 0px rgba(61, 77, 100, 0.74)',
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '0',
      }}
      _before={{
        // elevation
        content: '""',
        background: '#7ea800',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        bottom: '3px',
        zIndex: '1',
      }}
      {...rest}
    >
      <Flex
        // button background
        alignItems="center"
        justifyContent="center"
        backgroundColor="#bdf303"
        backgroundImage="url('/images/rockets/btn-inset.webp')"
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
        borderRadius="10px"
        transition=".1s"
        position="absolute"
        zIndex="2"
        left="0"
        right="0"
        top={clicked ? '4px' : '0'}
        bottom={clicked ? '3px' : '11px'}
      >
        {isLoading && (
          <Spinner
            position="absolute"
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

export default RocketButtonBet;
