import LotteryPatternsArea from '@/bingo/components/GameplayStage/LotteryPatternsArea'
import MiningArea from '@/bingo/components/GameplayStage/MiningArea'
import StageBg from '@/bingo/components/GameplayStage/StageBg.tsx'
import StageUIContainer from '@/bingo/components/GameplayStage/StageUIContainer.tsx'
// import { useScale } from '@/bingo/hooks/useScale'
import { Box } from '@chakra-ui/react'
import React from 'react'

const GameplayStage = () => {
  // const scale = useScale()
  // const transform = `scale(${scale})`
  // const height = 230 * scale
  // const width = 500 * scale

  return (
    <Box
      // height={height}
      // width={width}
      overflow="hidden"
    >
      <Box
        position="relative"
        // width={500}
        // transform={transform}
        // transformOrigin="top left"
      >
        <StageBg />
        <StageUIContainer>
          <MiningArea />
          <LotteryPatternsArea />
        </StageUIContainer>
      </Box>
    </Box>
  )
}

export default GameplayStage
