import { Center, Flex, Stack, Text, Image, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import { HexagonHeading, NormalParagraph, UnorderedTextList } from './RuleGameContainer.tsx'
import { useAppSelector } from '@/redux/store/index.ts'
import { selectConfigRound, selectTabIndex } from '@/redux/store/modules/rocketTshow.ts'
import { f } from 'fintech-number'

export default function RuleHRK() {
  const { t } = useCustomTranslation()
  const tabIndex = useAppSelector(selectTabIndex) //tabIndex = 0 => Classic, tabIndex = 1 => TrenBall
  const config = useAppSelector(selectConfigRound)

  return (
    <Stack height="80vh" overflow="hidden" borderRadius="12px 12px 0 0" className="newgame-container">
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={2}
        px={5}
        pb={7}
        borderRadius="12px 12px 0 0"
      >
        <Flex color="white" pr={4} justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} mt={2} lineHeight={1.2} className="!text-[24px]">
              {t('ruleGame.bankerPlayer.title')}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" className="w-[74px] h-[68px] mt-[2px]" />
        </Flex>
      </Stack>
      <Stack></Stack>
      <Tabs defaultIndex={tabIndex}>
        <Center mt={-10}>
          <TabList
            backgroundColor="#4a4a4e"
            border="none"
            borderRadius="30px"
            className="w-full ml-5 mr-4 p-1 pb-[4px] h-[42px]"
          >
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0 !py-0"
            >
              <Text marginTop="4px">{t('rocket.tabs.classic')}</Text>
            </Tab>
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0"
            >
              <Text marginTop="4px">{t('rocket.tabs.trenball')}</Text>
            </Tab>
          </TabList>
        </Center>
        <TabPanels height="calc(80vh - 132px)" overflowY="auto">
          <TabPanel>
            <HexagonHeading heading={t('ruleGame.rocket.title1')} />
            <NormalParagraph>{t('ruleGame.rocket.paragraph1')}</NormalParagraph>
            <NormalParagraph>
              {t('rulegame.rocket.paragraph2')}{' '}
              {f(+config.minBetAmount, {
                decimal: 2,
              })}
            </NormalParagraph>
            <NormalParagraph>
              {t('ruleGame.rocket.paragraph3')}{' '}
              {f(+config.maxBetAmount, {
                decimal: 2,
              })}
            </NormalParagraph>
            <>{getContentClassic(t)}</>
          </TabPanel>
          <TabPanel>
            <HexagonHeading heading={t('ruleGame.rocket.title1')} />
            <NormalParagraph>{t('ruleGame.rocket.paragraph1')}</NormalParagraph>
            <NormalParagraph>
              {t('rulegame.rocket.paragraph2')}{' '}
              {f(+config.minBetAmount, {
                decimal: 2,
              })}
            </NormalParagraph>
            <NormalParagraph>
              {t('ruleGame.rocket.paragraph3')}{' '}
              {f(+config.maxBetAmount, {
                decimal: 2,
              })}
            </NormalParagraph>
            <>{getContentTrenBall(t)}</>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

const getContentTrenBall = (t: any) => {
  return (
    <>
      <Stack h="4px"></Stack>
      <HexagonHeading heading={t('ruleGame.rocket.title2')} />
      <Image
        src="/images/rockets/img-rocket-9.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <NormalParagraph>{t('ruleGame.rocket.trenlball.paragraph1')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.trenlball.paragraph2')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph3')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph4')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph5')}</>,
        ]}
      />
      <NormalParagraph mb={2}>{t('ruleGame.rocket.trenlball.paragraph6')}</NormalParagraph>
      <HexagonHeading heading={t('ruleGame.rocket.trenlball.paragraph7')} />
      <NormalParagraph>{t('ruleGame.rocket.trenlball.paragraph8')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.trenlball.paragraph9')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph10')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph11')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph12')}</>,
        ]}
      />
      <Stack h="4px"></Stack>
      <HexagonHeading heading={t('ruleGame.rocket.trenlball.paragraph13')} />
      <NormalParagraph>{t('ruleGame.rocket.trenlball.paragraph14')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.trenlball.paragraph15')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph16')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph17')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph18')}</>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.rocket.trenlball.paragraph19')}</NormalParagraph>
      <Image
        src="/images/rockets/img-rocket-10.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <HexagonHeading heading={t('ruleGame.rocket.trenlball.paragraph20')} />
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.trenlball.paragraph21')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph22')}</>,
          <>{t('ruleGame.rocket.trenlball.paragraph23')}</>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.rocket.trenlball.paragraph24')}</NormalParagraph>
    </>
  )
}

const getContentClassic = (t: any) => {
  return (
    <>
      <Stack h="4px"></Stack>
      <HexagonHeading heading={t('ruleGame.rocket.title2')} />
      <Image
        src="/images/rockets/img-rocket-1.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <NormalParagraph>{t('ruleGame.rocket.paragraph2.1')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.rocket.paragraph2.2')}</NormalParagraph>
      <NormalParagraph mb={2}>{t('ruleGame.rocket.paragraph2.3')}</NormalParagraph>
      <Image
        src="/images/rockets/img-rocket-2.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <NormalParagraph>{t('ruleGame.rocket.paragraph2.4')}</NormalParagraph>
      <NormalParagraph mb={2}>{t('ruleGame.rocket.paragraph4')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.paragraph5')}</>,
          <>{t('ruleGame.rocket.paragraph6')}</>,
          <>{t('ruleGame.rocket.paragraph7')}</>,
        ]}
      />
      <HexagonHeading heading={t('ruleGame.rocket.title3')} />
      <Image
        src="/images/rockets/img-rocket-4.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <NormalParagraph mb={2}>{t('ruleGame.rocket.paragraph8')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.paragraph9')}</>,
          <>{t('ruleGame.rocket.paragraph10')}</>,
          <>{t('ruleGame.rocket.paragraph11')}</>,
        ]}
      />
      <HexagonHeading heading={t('ruleGame.rocket.title4')} />
      <Image
        src="/images/rockets/img-rocket-5.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <NormalParagraph mb={2}>{t('ruleGame.rocket.paragraph12')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.paragraph13')}</>,
          <>{t('ruleGame.rocket.paragraph14')}</>,
          <>{t('ruleGame.rocket.paragraph15')}</>,
        ]}
      />
      <HexagonHeading heading={t('ruleGame.rocket.title5')} />
      <NormalParagraph mb={2}>{t('ruleGame.rocket.paragraph16')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>{t('ruleGame.rocket.paragraph17')}</>,
          <>{t('ruleGame.rocket.paragraph18')}</>,
          <>{t('ruleGame.rocket.paragraph19')}</>,
        ]}
      />
      <Image
        src="/images/rockets/img-rocket-6.png"
        mr="auto"
        mt={4}
        mb={6}
        ml="auto"
        userSelect="none"
        width="300px"
        height="auto"
      />
      <HexagonHeading heading={t('ruleGame.rocket.title6')} />
      <NormalParagraph>{t('ruleGame.rocket.paragraph20')}</NormalParagraph>
      <UnorderedTextList
        items={[
          <>
            <Text mb={2}>{t('ruleGame.rocket.paragraph21')}</Text>
            <UnorderedTextList
              items={[
                <Text>{t('ruleGame.rocket.paragraph22')}</Text>,
                <Text>{t('ruleGame.rocket.paragraph23')}</Text>,
                <Text>{t('ruleGame.rocket.paragraph24')}</Text>,
              ]}
            ></UnorderedTextList>
            <Image
              src="/images/rockets/img-rocket-7.png"
              mr="auto"
              mt={4}
              mb={6}
              ml="auto"
              userSelect="none"
              width="300px"
              height="auto"
            />
          </>,
          <>
            <Text mb={2}>{t('ruleGame.rocket.paragraph25')}</Text>
            <UnorderedTextList
              items={[<Text>{t('ruleGame.rocket.paragraph26')}</Text>, <Text>{t('ruleGame.rocket.paragraph27')}</Text>]}
            ></UnorderedTextList>
            <Image
              src="/images/rockets/img-rocket-8.png"
              mr="auto"
              mt={4}
              mb={6}
              ml="auto"
              userSelect="none"
              width="300px"
              height="auto"
            />
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.rocket.paragraph28')}</NormalParagraph>
    </>
  )
}
