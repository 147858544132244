import { Box, Stack, Image, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getSymbolFromCurrency } from '../../const/currency.tsx'
import { useAppDispatch, useAppSelector } from '@/redux/store/index.ts'
import {
  rocketTshowActions,
  cashOutOrder,
  ROUND_STATE,
  selectGameRound,
  selectListActiveOrders,
  selectMultiplier,
} from '@/redux/store/modules/rocketTshow.ts'
import { CrashOrder, CrashOrderCashOutInput } from '@/@generated/gql/graphql-hash.ts'
import { f } from 'fintech-number'
import { ImCheckmark } from 'react-icons/im'
import useCustomToast from '@/hooks/useCustomToast.ts'
import useGameId from '@/hooks/useGameId.ts'
import useCustomTranslation from '@/hooks/useCustomTranslation.tsx'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

function ListCurrentBet() {
  const dispatch = useAppDispatch()
  const { showToastThrottle } = useCustomToast()
  const myOrders = useAppSelector(selectListActiveOrders)
  const multiplier = useAppSelector(selectMultiplier)
  const { t } = useCustomTranslation()

  useEffect(() => {
    if (!multiplier) return
    for (const order of myOrders) {
      if (order?.auto_cash_out !== '0' && +order?.auto_cash_out < +multiplier?.m) {
        // handleCashOutOrders(order)
        dispatch(rocketTshowActions.updateActiveOrders(myOrders.filter((item) => item.id !== order.id)))
        showToastThrottle({
          title: t('cashOutSuccessful'),
          status: 'success',
          isClosable: false,
          duration: 2000,
          icon: <ImCheckmark fontSize={32} />,
          containerStyle: {
            width: '60px',
            paddingBlock: '0px',
            pointerEvents: 'none',
          },
        })
        // break
      }
    }
  }, [multiplier])

  return (
    <Stack
      position="absolute"
      bottom="88px"
      right={1}
      zIndex="5"
      alignItems="end"
      maxH="50%"
      overflow="hidden"
      overflowY="scroll"
      className="zoom-container"
    >
      {myOrders?.length > 0 && (
        <>
          {myOrders?.map((item: CrashOrder, index: number) => {
            return <ItemBetting item={item} key={`${item.id}-${index}`} />
          })}
        </>
      )}
    </Stack>
  )
}

export default ListCurrentBet

const ItemBetting = ({ item }: { item: CrashOrder }) => {
  const gameRound = useAppSelector(selectGameRound)
  const game_id = useGameId()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { showToastThrottle } = useCustomToast()
  const { t, i18n } = useCustomTranslation()
  const currentLang = i18n.language

  const handleCashOutOrders = (order: CrashOrder) => {
    const params: CrashOrderCashOutInput = {
      bet_ids: [order.id],
      game_id: game_id,
      round_id: order.round_id,
    }
    setIsLoading(true)
    dispatch(cashOutOrder(params))
      .then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: t('cashOutSuccessful'),
            status: 'success',
            isClosable: false,
            duration: 2000,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      w="fit-content"
      backgroundColor="rgba(34, 42, 54, 0.7)"
      borderRadius={8}
      px={2}
      py={1}
    >
      <Box flexDirection="column" gap={0} fontSize={14}>
        <Stack flexDirection="row" alignItems="center" gap={0}>
          <Image w="14px" src={getSymbolFromCurrency(item?.currency)} alt="" />
          <RocketText mt="1px" ml="4px" {...NotoSansFontFamily.medium}>
            {f(item?.amount)}
          </RocketText>
        </Stack>
        {item?.auto_cash_out !== '0' && (
          <RocketText transform="none" marginTop="3px" {...NotoSansFontFamily.normal} fontSize={13}>
            {t('auto')}: {item?.auto_cash_out}
          </RocketText>
        )}
      </Box>
      <Button
        minWidth="54px"
        width="auto"
        h="20px"
        fontSize={14}
        background="#bdf303"
        _hover={{
          background: '#c5ff00',
        }}
        isLoading={isLoading}
        isDisabled={gameRound?.state !== ROUND_STATE.RUNNING}
        _disabled={{
          cursor: 'not-allowed',
          background: '#EDF2F7',
        }}
        onClick={() => handleCashOutOrders(item)}
        letterSpacing={currentLang === 'en' ? '-0.5px' : ''}
        padding="0 5px"
      >
        {gameRound?.state === ROUND_STATE.RUNNING ? t('cash') : t('waiting')}
      </Button>
    </Stack>
  )
}
