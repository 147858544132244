import { BingoOrder, BingoOrderHistory } from '@/@generated/gql/graphql-hash'
import { gql } from '@apollo/client'

export interface BingoNextRoundOrderResp {
  bingoNextRoundOrder: BingoOrder
}
export const getBingoNextRoundOrderQuery = gql`
  query GetBingoNextRoundOrder {
    bingoNextRoundOrder {
      id
      round_id
      player_id
      agency_id
      wallet_id
      player_name
      currency
      amount
      ticket_unit_price
      reward
      created_at
      bingo_bet_tickets {
        id
        bingo_bet_id
        numbers
        multiplier
        pattern
        star
      }
      bingo_bet_extra_balls {
        id
        bingo_bet_id
        index
        number
        amount
      }
      bingo_round {
        id
        game_id
        server_seed
        client_seed
        random_value
        result
        proof
        start_time
      }
    }
  }
`
export interface BingoActiveOrderResp {
  bingoActiveOrder: BingoOrder
}
export const getBingoActiveOrderQuery = gql`
  query {
    bingoActiveOrder {
      id
      round_id
      player_id
      agency_id
      wallet_id
      player_name
      currency
      amount
      reward
      created_at
      ticket_unit_price
      bingo_bet_extra_balls {
        id
        bingo_bet_id
        index
        number
        amount
      }
      bingo_bet_tickets {
        bingo_bet_id
        numbers
        multiplier
        star
      }
      bingo_round {
        id
        game_id
        server_seed
        client_seed
        random_value
        result
        proof
        start_time
      }
    }
  }
`

export interface GetBingoOrderHistoryResp {
  bingoOrderHistory: BingoOrderHistory
}

export const getBingoOrderHistoryQuery = gql`
  query BingoOrderHistory($input: FilterInput!) {
    bingoOrderHistory(input: $input) {
      page
      limit
      total
      data {
        id
        round_id
        player_id
        agency_id
        wallet_id
        player_name
        currency
        amount
        reward
        created_at
        ticket_unit_price
        bingo_bet_extra_balls {
          id
          bingo_bet_id
          index
          number
          amount
        }
        bingo_bet_tickets {
          bingo_bet_id
          numbers
          multiplier
          star
        }
        bingo_round {
          id
          game_id
          server_seed
          client_seed
          random_value
          result
          proof
          start_time
        }
      }
    }
  }
`

export interface CreateBingoOrderResp {
  createBingoOrder: BingoOrder
}

export const createBingoOrderMutate = gql`
  mutation CreateBingoOrder($input: BingoOrderInput!) {
    createBingoOrder(input: $input) {
      id
      round_id
      player_id
      wallet_id
      amount
      currency
      ticket_unit_price
      bingo_bet_extra_balls {
        id
        bingo_bet_id
        index
        number
        amount
      }
      bingo_bet_tickets {
        bingo_bet_id
        numbers
        multiplier
        star
      }
      bingo_round {
        result
      }
      reward
      created_at
    }
  }
`
export interface CreateBingoNextRoundOrderResp {
  createBingoNextRoundOrder: BingoOrder
}

export const createBingoNextRoundOrderMutate = gql`
  mutation CreateBingoNextRoundOrder($input: BingoNextRoundOrderInput!) {
    createBingoNextRoundOrder(input: $input) {
      id
      round_id
      player_id
      agency_id
      wallet_id
      player_name
      currency
      amount
      ticket_unit_price
      reward
      created_at
      bingo_bet_tickets {
        id
        bingo_bet_id
        numbers
        multiplier
        pattern
        star
      }
      bingo_bet_extra_balls {
        id
        bingo_bet_id
        index
        number
        amount
      }
      bingo_round {
        id
        game_id
        server_seed
        client_seed
        random_value
        result
        proof
        start_time
      }
    }
  }
`

export interface BuyExtraBallResp {
  bingoBuyExtraBall: BingoOrder
}

export const buyExtraBallMutate = gql`
  mutation BingoBuyExtraBall($input: BingoExtraBallInput!) {
    bingoBuyExtraBall(input: $input) {
      id
      round_id
      player_id
      agency_id
      wallet_id
      player_name
      currency
      amount
      reward
      created_at
      ticket_unit_price
      bingo_bet_extra_balls {
        id
        bingo_bet_id
        index
        number
        amount
      }
      bingo_bet_tickets {
        bingo_bet_id
        numbers
        multiplier
        star
      }
      bingo_round {
        id
        game_id
        server_seed
        client_seed
        random_value
        result
        proof
        start_time
      }
    }
  }
`
