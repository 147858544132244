import GroupColumn from '@/bingo/components/FormSection/EditTicket/CurrentTicket/GroupColumn.tsx'
import { bingoGroups } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { getTicketCol } from '@/bingo/const/bingoHelpers'
import { BingoGroup } from '@/bingo/types/bingo.ts'
import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

type CurrentTicketProps = FlexProps & {
  currentGroup: BingoGroup
  numbers: number[]
}

const CurrentTicket = ({ currentGroup, numbers }: CurrentTicketProps) => {
  return (
    <Flex borderRadius="12px" overflow="hidden" flex="1">
      {bingoGroups.map((group, i) => (
        <GroupColumn group={group} key={group} isActive={currentGroup === group} data={getTicketCol(numbers, i)} />
      ))}
    </Flex>
  )
}

export default CurrentTicket
