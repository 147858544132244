import { useAppDispatch, useAppSelector } from '@/redux/store'
import soundSlice, { selectIsMuted } from '@/redux/store/modules/audio.slice'
import { Button, ButtonProps, Icon } from '@chakra-ui/react'
import React from 'react'
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs'

type SoundControllerProps = ButtonProps

const SoundController = ({ ...rest }: SoundControllerProps) => {
  const isMuted = useAppSelector(selectIsMuted)
  const dispatch = useAppDispatch()

  function handleClick() {
    dispatch(soundSlice.actions.toggleSound())
  }

  return (
    <Button variant="link" position="absolute" top="8px" right="5px" fontSize="25px" onClick={handleClick} {...rest}>
      <Icon as={isMuted ? BsFillVolumeMuteFill : BsFillVolumeUpFill} color="#f97768" />
    </Button>
  )
}

export default SoundController
