import { Center, Flex, Image, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import Fairness from './Fairness.tsx'

const GamePlayAndVerification = () => {
  const { t, i18n } = useCustomTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  return (
    <Stack height="80vh" overflow="hidden" borderRadius="12px 12px 0 0">
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={2}
        px={5}
        borderRadius="12px 12px 0 0"
      >
        <Flex color="white" pr={4} justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} fontSize={21} mt={2} lineHeight={1.2}>
              {t('hashLottery')}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" className="w-[74px] h-[68px] mt-[2px]" />
        </Flex>
      </Stack>
      <Fairness isMenu={true} randomValue={""}/>
    </Stack>
  )
}
export default GamePlayAndVerification
