export class Topics {
  static lastGame(gameId: string) {
    return `public/games/${gameId}/last_game`
  }

  static get topReward() {
    return `public/games/top_reward`
  }

  static configGame(gameId: string) {
    return `public/games/${gameId}/config`
  }
  static walletBalance(playerId: string) {
    return `wallet/players/${playerId}/balance`
  }

  static gameRound(gameId: string) {
    return `public/${gameId}/round`
  }
  static bingoBalls(gameId: string) {
    return `public/${gameId}/balls`
  }

  static configRound(gameId: string) {
    return `public/${gameId}/config`
  }

  static getMultiplier(gameId: string) {
    return `public/${gameId}/multiplier`
  }

  static getPlayerBettingInRound(gameId: string) {
    return `public/${gameId}/current_bet`
  }

  static lastRound(gameId: string) {
    return `public/${gameId}/last_round`
  }

  static rewardOrder(gameId: string, player_id: string) {
    return `public/${gameId}/${player_id}/reward`
  }

  static treasure(gameId: string, roundID: string) {
    return `public/${gameId}/treasure/${roundID}`
  }

  static autoCashOutHilo(gameId: string, roundID: string) {
    return `public/${gameId}/auto_cash_out/${roundID}`
  }
}
