import React from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

type BingoConfirmProps = {
  title?: string | React.ReactNode,
  content?: string | React.ReactNode,
  cancelText?: string | React.ReactNode,
  okText?: string | React.ReactNode,
  isOpen: boolean,
  onClose: (params?: any) => any,
  onOk?: (params?: any) => any,
}

const BingoConfirm = ({title, content, cancelText = 'Cancel', okText = 'Yes', isOpen, onClose, onOk}: BingoConfirmProps) => {
  const handleOk = () => {
    if (onOk) {
      onOk()
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        background="linear-gradient(180deg, #fff 0%, #ffdede 100%)"
        borderRadius="20px"
      >
        <ModalHeader
          color="#560f40"
          fontSize="26px"
          textAlign="center"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          background="none"
          textAlign="center"
          color="#74275c"
          fontSize="24px"
        >
          {content}
        </ModalBody>
        <ModalFooter
          alignItems="center"
          justifyContent="center"
          gap="10px"
        >
          <Button
            background="linear-gradient(180deg, #fec6c0 0%, #feb6ad 100%)"
            color="#d92727"
            minWidth="100px"
            _hover={{}}
            _active={{}}
            onClick={onClose}
          >
            {cancelText}
          </Button>
          <Button
            background="linear-gradient(180deg, #fd563e 0%, #ed4b34 100%)"
            color="#fff"
            minWidth="100px"
            _hover={{}}
            _active={{}}
            onClick={handleOk}
          >
            {okText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default BingoConfirm