import LotteryPattern from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern'
import { selectBingoPatterns } from '@/bingo/redux/bingo.slice'
import { useAppSelector } from '@/redux/store'
import { Flex } from '@chakra-ui/react'
import React from 'react'

const BottomRow = () => {
  const patterns = useAppSelector(selectBingoPatterns)

  const [_1, _2, _3, _4, ...others] = patterns
  return (
    <Flex flex="1" justifyContent="space-between" alignItems="center">
      {others.map((pattern, index) => (
        <LotteryPattern pattern={pattern} key={index} />
      ))}
    </Flex>
  )
}

export default BottomRow
