import React from 'react'
import { Button, ButtonProps, Flex, Image, useDisclosure } from '@chakra-ui/react'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { AppDrawer } from '@/components/common/AppDrawer'
import RuleGame from '@/components/hash-game/RuleGame'
import FooterButtonSound from '@components/rocket/NewUI/RocketTabs/FooterButtonSound.tsx'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'

type FooterButonProps = ButtonProps

export const FooterButton = ({ children, ...rest }: FooterButonProps) => {
  return (
    <Button
      background="none"
      border="none"
      outline="none"
      width="auto"
      height="auto"
      display="inline-flex"
      alignItems="center"
      gap="6px"
      color="#4d5c72"
      {...NotoSansFontFamily.normal}
      fontSize="14px"
      lineHeight="1"
      padding="0"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

const RocketTabFooter = () => {
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderBottomRadius="13.3px"
        padding="14.3px 21.3px"
        gap="33.3px"
      >
        <FooterButton
          gap="10px"
          isDisabled
        >
          <Image src="/images/rockets/icon-star.webp" width="19.3px" height="19.3px" />
          {t('rocket.tabs.favorites')}
        </FooterButton>
        <FooterButton
          gap="8px"
          isDisabled
        >
          <Image src="/images/rockets/icon-share.webp" width="19.3px" height="19.3px" />
          {t('rocket.tabs.share')}
        </FooterButton>
        <FooterButtonSound />
        <FooterButton marginLeft="auto" marginRight="13px" marginTop="-4px" onClick={() => onOpen()}>
          <Image src="/images/rockets/icon-help.webp" width="20px" height="20px" />
          {t('rocket.tabs.gameplay')}
        </FooterButton>
      </Flex>
      {isOpen && (
        <AppDrawer title={""} isOpen={isOpen} onClose={onClose} overlay={true}>
          {/* <Fairness isMenu={true} randomValue={''} /> */}
          <RuleGame />
        </AppDrawer>
      )}
    </>
  )
}

export default RocketTabFooter;
