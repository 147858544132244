import React from 'react'
import { Box, ButtonProps } from '@chakra-ui/react'
import { FooterButton } from '@components/rocket/NewUI/RocketTabs/RocketTabFooter.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { toggleSound } from '@store/modules/audio.slice.ts'

type FooterButtonSoundProps = ButtonProps

const FooterButtonSound = ({...rest}: FooterButtonSoundProps) => {
  const { t } = useCustomTranslation()
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const dispatch = useAppDispatch()

  const handleToggleSound = () => {
    dispatch(toggleSound())
  }

  return (
    <FooterButton
      gap="11px"
      marginLeft="2px"
      onClick={handleToggleSound}
      {...rest}
    >
      <Box
        height="20px"
        width="26.15px"
        backgroundImage={!isMuted ? 'url(/images/rockets/icon-sound-off.webp)' : 'url(/images/rockets/icon-sound-on.webp)'}
        backgroundRepeat="no-repeat"
        backgroundSize="contain"
      />
      {t('rocket.tabs.sound')}
    </FooterButton>
  )
}

export default FooterButtonSound