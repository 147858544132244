import { ServiceConfig } from '@/redux/store/services/service-config'
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  createHttpLink,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GqlError } from './error'

const clientCache = new InMemoryCache()

class CgApolloClient<T> extends ApolloClient<T> {
  constructor(options: ApolloClientOptions<T>) {
    super(options)
  }
  query<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>,
  ): Promise<ApolloQueryResult<T>> {
    return super.query(options).catch((err) => {
      const errors = new GqlError(err).errors
      console.log('errors', errors)
      // eventBus.dispatch(EVENT_MESSAGE_ERROR, {
      //   data: {
      //     message: errors?.[0]?.message,
      //     code: errors?.[0]?.code,
      //   },
      // })
      throw new GqlError(err).errors
    })
  }

  mutate<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext
  >(options: MutationOptions<TData, TVariables, TContext>): Promise<FetchResult<TData>> {
    return super.mutate(options).catch((err) => {
      const errors = new GqlError(err).errors
      console.log('errors', errors)
      // eventBus.dispatch(EVENT_MESSAGE_ERROR, {
      //   data: {
      //     message: errors?.[0]?.message,
      //     code: errors?.[0]?.code,
      //   },
      // })
      throw new GqlError(err).errors
    })
  }
}

function createApolloClient(uri: string, cache: InMemoryCache) {
  const httpLink = createHttpLink({ uri })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${ServiceConfig.token}`,
      },
    }
  })

  return new CgApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}

export const gqlClient = createApolloClient(import.meta.env.VITE_GRAPHQL_HTTP_URL, clientCache)
