import React from 'react'
import {
  Image,
  Table,
  TableBodyProps,
  TableCaption,
  TableCaptionProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Text,
  TextProps,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import MoneyCell from '@components/rocket/NewUI/RocketTables/MoneyCell.tsx'
import { f } from 'fintech-number'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

export type RocketTableColumns = string[]
export type RocketTableCell = TableCellProps & {
  value: string | React.ReactNode
}
export type RocketTableRow = {
  rowProps?: RocketTbodyTrProps
  cells: RocketTableCell[]
}
export type RocketTableData = {
  columns?: RocketTableColumns
  rows?: RocketTableRow[]
}

type RocketThProps = TableColumnHeaderProps
type RocketTbodyTrProps = TableRowProps
type RocketTdProps = TableCellProps
type RocketTableProps = {
  tableContainerProps?: TableContainerProps
  tableCaptionProps?: TableCaptionProps
  tableProps?: TableProps
  theadProps?: TableHeadProps
  headTrProps?: TableRowProps
  thProps?: TableColumnHeaderProps
  tbodyProps?: TableBodyProps
  bodyTrProps?: TableRowProps
  tdProps?: TableCellProps
  columns?: RocketTableColumns
  rows?: RocketTableRow[]
  tableCaption?: string | React.ReactNode
}
type RocketTableContentWrapperProps = RocketTableProps & {
  children?: React.ReactNode
}
type CellTextProps = TextProps

export const renderBetAmountCell = (value?: string | number, currency?: string): RocketTableCell => {
  const defaultCell = {
    value: <CellNull />,
  }

  if (!value) return defaultCell

  const valueNumbered = Number(value)

  return {
    value: <MoneyCell value={f(valueNumbered, { decimal: 2 })} currency={currency} />,
  }
}

export const renderRewardCell = (value?: string | number, currency?: string, isNull?: boolean): RocketTableCell => {
  const defaultCell = {
    value: <CellNull />,
  }

  if (!value || isNull) return defaultCell

  const valueNumbered = Number(value)

  if (valueNumbered > 0) {
    return {
      value: <MoneyCell value={f(valueNumbered, { decimal: 2 })} currency={currency} />,
      className: 'isGreen',
    }
  }

  return {
    value: <MoneyCell value="0.00" currency={currency} />,
  }
}

export const renderPlayerCell = (value?: string): RocketTableCell => {
  if (!value || value?.length === 0)
    return {
      value: <CellNull />,
    }

  return {
    value: (
      <CellText lineHeight="1" margin="0">
        {value}
      </CellText>
    ),
  }
}

export const CellText = ({ children, ...rest }: CellTextProps) => {
  return (
    <RocketText lineHeight="1" margin="0" {...rest}>
      {children}
    </RocketText>
  )
}

export const CellNull = ({ ...rest }: CellTextProps) => {
  return <CellText {...rest}>--</CellText>
}

const RocketTh = ({ children, ...rest }: RocketThProps) => {
  return (
    <Th
      background="#212834"
      color="#50637f"
      lineHeight="1"
      padding="6.6px"
      textAlign="center"
      height="36px"
      fontSize="16px"
      fontWeight="unset"
      textTransform="unset"
      {...rest}
    >
      {children}
    </Th>
  )
}

const RocketTbodyTr = ({ children, ...rest }: RocketTbodyTrProps) => {
  return (
    <Tr
      cursor={rest?.onClick ? 'pointer' : 'default'}
      _even={{
        background: '#1b222c',
      }}
      _hover={{
        background: 'rgba(67, 87, 117, 0.4)',
        '> td p': {
          filter: 'brightness(1.3)',
        },
      }}
      {...rest}
    >
      {children}
    </Tr>
  )
}

const RocketTd = ({ children, ...rest }: RocketTdProps) => {
  return (
    <Td
      padding="12.6px 16px"
      textAlign="center"
      color="#647c9f"
      lineHeight="1"
      fontSize="16px"
      fontWeight="unset"
      sx={{
        '&.isGreen': {
          color: '#0df323',
        },
        '&.isRed': {
          color: '#b0414e',
        },
        '&.Red': {
          color: '#d52b4f',
        },
        '&.Green': {
          color: '#27a052',
        },
        '&.Blue': {
          color: '#0a79df',
        },
        '&.Yellow': {
          color: '#ff9600',
        },
      }}
      {...rest}
    >
      {children}
    </Td>
  )
}

const TableContentWrapper = ({
  tableContainerProps,
  tableCaptionProps,
  tableProps,
  theadProps,
  headTrProps,
  thProps,
  tbodyProps,
  columns,
  tableCaption,
  children,
}: RocketTableContentWrapperProps) => {
  const { t } = useCustomTranslation()

  return (
    <TableContainer {...tableContainerProps}>
      <Table variant="unstyled" {...NotoSansFontFamily.normal} {...tableProps}>
        {tableCaption && (
          <TableCaption color="#45556d" margin="0" padding="21.3px" {...tableCaptionProps}>
            {tableCaption}
          </TableCaption>
        )}
        {columns && columns.length > 0 && (
          <Thead {...theadProps}>
            <Tr {...headTrProps}>
              {columns?.length &&
                columns?.map((column) => (
                  <RocketTh {...thProps} key={column}>
                    {t(column)}
                  </RocketTh>
                ))}
            </Tr>
          </Thead>
        )}
        <Tbody {...tbodyProps}>{children}</Tbody>
      </Table>
    </TableContainer>
  )
}

const RocketTable = ({ bodyTrProps, tdProps, rows, ...rest }: RocketTableProps) => {
  const { t } = useCustomTranslation()

  if (!rows || rows?.length === 0) {
    return (
      <TableContentWrapper {...rest}>
        <RocketTbodyTr _hover={{}}>
          <RocketTd colSpan={4} textAlign="center" padding="15px" height="371.9px">
            <Image width="40px" src="/images/norecord.webp" display="inline-block" marginBottom="10px" />
            <Text>{t('notification.youHavenorecordsYet')}</Text>
          </RocketTd>
        </RocketTbodyTr>
      </TableContentWrapper>
    )
  }

  return (
    <TableContentWrapper {...rest}>
      {rows?.map((row, rowIndex) => {
        return (
          <RocketTbodyTr {...bodyTrProps} {...row?.rowProps} key={rowIndex}>
            {row?.cells?.map((cell, cellIndex) => {
              const cellClassNames = `${tdProps?.className ?? ''} ${cell?.className ?? ''}`
              return (
                <RocketTd
                  {...tdProps}
                  className={cellClassNames}
                  {...{
                    ...cell,
                    value: undefined,
                  }}
                  key={cellIndex}
                >
                  {cell.value}
                </RocketTd>
              )
            })}
          </RocketTbodyTr>
        )
      })}
    </TableContentWrapper>
  )
}

export default RocketTable
