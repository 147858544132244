import { Box, Flex, InputProps, Stack } from '@chakra-ui/react'
import React, { HTMLProps } from 'react'
import RocketInputTitle from '@components/rocket/NewUI/RocketTabs/RocketInputTitle.tsx'
import RocketIconCurrency from '@components/rocket/NewUI/Common/RocketIconCurrency.tsx'
import RocketInput from '@components/rocket/NewUI/Common/RocketInput.tsx'
import BtnQuickChange from '@components/UI/quick-form/BtnQuickChange.tsx'
import RocketButtonBet, { RocketButtonBetProps } from '@components/rocket/NewUI/Common/RocketButtonBet.tsx'
import RocketChip from '@components/rocket/NewUI/RocketTabs/RocketChip.tsx'
import RocketButtonCashOutAll from '@components/rocket/NewUI/Common/RocketButtonCashOutAll.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type BetAmountFormProps = {
  isLoading?: boolean
  isFormDisabled?: boolean
  isBetButtonDisabled?: boolean
  canCashOutAll?: boolean
  afterCashOutAll?: boolean
  inputProps?: HTMLProps<HTMLInputElement> & InputProps
  buttonBetProps?: RocketButtonBetProps
  buttonCashOutAllProps?: RocketButtonBetProps
  multiplyCallback?: (params?: any) => any
  divideCallback?: (params?: any) => any
  quickBetAmountCallback?: (params?: any) => any
  currentBetAmount?: number
}

const quickBetAmounts: number[] = [5, 10, 20, 50, 100, 200, 300]

const BetAmountForm = ({
  isLoading,
  afterCashOutAll,
  isFormDisabled,
  isBetButtonDisabled,
  canCashOutAll,
  inputProps,
  buttonBetProps,
  buttonCashOutAllProps,
  multiplyCallback,
  divideCallback,
  quickBetAmountCallback,
  currentBetAmount,
}: BetAmountFormProps) => {
  const { t } = useCustomTranslation()

  const handleRocketChipClick = (amount: number) => {
    if (isFormDisabled) return

    if (quickBetAmountCallback) {
      quickBetAmountCallback(amount)
    }
  }

  return (
    <Box padding="0 16px 5.6px">
      <Flex gap="13.3px" height="72px">
        <Flex background="#1a212a" padding="3px" border="1px solid #2a3444" borderRadius="8px" flex="1">
          <Stack padding="7.6px 0 5.6px 11.6px" flex="1" gap="3px" height="65px">
            <RocketInputTitle flex="1">{t('rocket.tabs.betAmount')}</RocketInputTitle>
            <Flex flex="1">
              <RocketIconCurrency width="25.5px" opacity={isFormDisabled ? 0.4 : 1} />
              <RocketInput height="auto" ref={inputProps?.ref} disabled={isFormDisabled} {...inputProps} />
            </Flex>
          </Stack>
          <Stack gap="1.3px">
            <BtnQuickChange
              changeType="multiply"
              display="block"
              flex="1"
              minWidth="81.5px"
              height="20px"
              fontSize="18px"
              onClick={multiplyCallback}
              isDisabled={isFormDisabled}
            />
            <BtnQuickChange
              changeType="divide"
              display="block"
              flex="1"
              minWidth="81.5px"
              height="20px"
              fontSize="18px"
              onClick={divideCallback}
              isDisabled={isFormDisabled}
            />
          </Stack>
        </Flex>
        {canCashOutAll ? (
          <RocketButtonCashOutAll aspectRatio="unset" isLoading={isLoading} {...buttonCashOutAllProps} />
        ) : (
          <RocketButtonBet
            aspectRatio="unset"
            isLoading={isLoading}
            isDisabled={isBetButtonDisabled || isFormDisabled}
            {...buttonBetProps}
          >
            <Stack alignItems="center" justifyContent="center">
              {afterCashOutAll ? (
                <>
                  <RocketText fontSize="18px" {...NotoSansFontFamily.semiBold} color="#010101">
                    {t('rocket.tabs.nextRound')}
                  </RocketText>
                  <RocketText fontSize="18px" {...NotoSansFontFamily.semiBold} color="#010101">
                    {t('rocket.tabs.nextRound2')}
                  </RocketText>
                </>
              ) : (
                <RocketText fontSize="18px" {...NotoSansFontFamily.semiBold} color="#010101">
                  {t('rocket.tabs.betButton')}
                </RocketText>
              )}
              {/* <Text fontSize="18px" {...NotoSansFontFamily.semiBold} color="#010101">
                {afterCashOutAll ? t('rocket.tabs.nextRound') : t('rocket.tabs.betButton')}
              </Text> */}
              {/*<Text*/}
              {/*  fontSize="14px"*/}
              {/*  {...NotoSansFontFamily.medium}*/}
              {/*  color="#000000"*/}
              {/*>*/}
              {/*  （下一回合）*/}
              {/*</Text>*/}
            </Stack>
          </RocketButtonBet>
        )}
      </Flex>
      <Box
        marginLeft="-16px"
        marginRight="-16px"
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          width: '20px',
          background: 'linear-gradient(to right, #26303e, transparent)',
          zIndex: '1',
        }}
        _after={{
          content: '""',
          position: 'absolute',
          top: '0',
          bottom: '0',
          right: '0',
          width: '20px',
          background: 'linear-gradient(to left, #26303e, transparent)',
          zIndex: '1',
        }}
      >
        <Flex
          overflowX="auto"
          gap="17.5px"
          marginTop="15px"
          padding="5.5px 21px"
          height="81px"
          sx={{
            '::webkit-scrollbar': {
              display: 'none',
            },
            'scrollbar-width': 'none',
          }}
        >
          {quickBetAmounts.map((amount) => (
            <RocketChip
              betAmount={amount}
              key={amount}
              isSelected={currentBetAmount === amount}
              onClick={() => handleRocketChipClick(amount)}
              isDisabled={isFormDisabled}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default BetAmountForm
