const isDev = import.meta.env.DEV

export class Configs {
  static pageSize = 10
  static get commitSHA() {
    return import.meta.env.VITE_COMMIT_SHA
  }
  static get isDev() {
    return isDev
  }

  static get socketUrl() {
    return replaceDomain(import.meta.env.VITE_MQTT_URL)
  }
  static get walletApiUrl() {
    return replaceDomain(import.meta.env.VITE_API_HUB_URL)
  }
  static get baseApiUrl() {
    return replaceDomain(import.meta.env.VITE_API_URL)
  }
  static get trackingId(): string {
    return import.meta.env.VITE_GA_TRACKING_ID || ''
  }
  static get graphApiUrl(): string {
    return import.meta.env.VITE_GRAPHQL_HTTP_URL || ''
  }
  static get plinkoGameId(): string {
    return import.meta.env.VITE_GAME_ID_PLINKO || ''
  }
  static get bingoGameId(): string {
    return import.meta.env.VITE_GAME_ID_BINGO || ''
  }
}

export const replaceDomain = (url: string) => {
  //Domain origin
  //   const urlOrigin = new URL('https://staging-cryptoft.abc.ed.cryptogaming.top1/future/BTC_USDT');

  //   const hostname = urlOrigin.hostname; // e.g., "subdomain.example.com"
  //   const parts = hostname.split('.');
  //   const isSubdomain = parts.length > 2;
  if (isDev) return url
  const hostname = window.location.hostname // e.g., "subdomain.example.com"
  const parts = hostname.split('.')
  const isSubdomain = parts.length > 2

  // const subdomain = isSubdomain ? parts.slice(0, -2).join('.') : null;
  const domain = isSubdomain ? parts.slice(-2).join('.') : hostname

  //Domain url
  const parsedUrl = new URL(url)
  // Split the hostname into parts
  const partsUrl = parsedUrl.hostname.split('.')
  const isSubdomainUrl = partsUrl.length > 2

  // Extract the subdomain if it exists
  const subdomainUrl = isSubdomainUrl ? partsUrl.slice(0, -2).join('.') : null
  // Construct the new hostname
  const newHostname = subdomainUrl ? `${subdomainUrl}.${domain}` : domain

  // Replace the hostname in the URL
  parsedUrl.hostname = newHostname
  // Return the updated URL as a string
  return parsedUrl.toString()
}
