import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'

const soundSlice = createSlice({
  name: 'sound',
  initialState: {
    isMuted: true,
  },
  reducers: {
    toggleSound: (state) => {
      state.isMuted = !state.isMuted
    },
  },
})

export const selectIsMuted = (state: RootState) => !state.sound.isMuted

export const { toggleSound } = soundSlice.actions
export default soundSlice
