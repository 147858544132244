import { useIsDisplayMinerCountdown } from '@/bingo/hooks/useIsDisplayCountdown'
import { useRoundCountdown } from '@/bingo/hooks/useRoundCountdown'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

type BingoCountDownProps = TextProps

export function BingoCountDown({ ...rest }: BingoCountDownProps) {
  const isDisplay = !useIsDisplayMinerCountdown()
  const { seconds } = useRoundCountdown()
  const { t } = useCustomTranslation()

  return (
    <Text textAlign="center" color="#570f3f" className="text-sm h-6" {...rest}>
      {seconds < 0 || !isDisplay ? '' : t('bingo.drawTheLotteryInSeconds', { seconds })}
    </Text>
  )
}
