import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createThunk } from './common'
import {
  CrashOrder,
  CrashOrderCashOutInput,
  FilterInput,
  CreateCrashOrderInput,
  CrashCurrentRoundOrder,
  CrashRound,
  CrashResultHistoryInput,
  CrashResultHistory,
  TrenBallType,
} from '@/@generated/gql/graphql-hash'
import { gqlClient } from '@/utils/apollo-client'
import { gql } from '@apollo/client'
import { RootState } from '..'
import { ConfigRound, GameRound } from '../entities/game.entity'

export const enum ROUND_STATE {
  WAITING = 'Waiting',
  END = 'End',
  RUNNING = 'Running',
}
export interface BettingState {
  gameRound: GameRound
  configRound: ConfigRound
  createOrderStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  createOrderError: string | null
  multiplier: any
  myActiveOrders: any[]
  myActiveTrenBallOrders: CrashOrder[]
  temporaryOrders: any[]
  cashoutOrders: CrashCurrentRoundOrder[]
  myOrders: CrashOrder[]
  myRoundOrders: CrashOrder[]
  listPasts: CrashRound[]
  crashResultHistory: CrashResultHistory
  listCurrentLives: any[]
  totals: number
  amount: string
  tabIndex: number
}

const initialState: BettingState = {
  gameRound: {} as GameRound,
  configRound: {} as ConfigRound,
  createOrderStatus: 'idle',
  createOrderError: null,
  multiplier: {},
  myActiveOrders: [] as any[],
  temporaryOrders: [] as any[],
  cashoutOrders: [],
  listPasts: [] as CrashRound[],
  crashResultHistory: {
    result: [],
  },
  myOrders: [] as CrashOrder[],
  myRoundOrders: [],
  listCurrentLives: [],
  totals: 0,
  amount: '1',
  tabIndex: 0,
  myActiveTrenBallOrders: [],
}

export const createOrderMutate = gql`
  mutation CrashOrder($input: CreateCrashOrderInput!) {
    createCrashOrder(input: $input) {
      id
      amount
      auto_cash_out
      round_id
      currency
      created_at
      tren_ball_type
    }
  }
`

export const cashOutOrderMutate = gql`
  mutation cashOutOrder($input: CrashOrderCashOutInput!) {
    cashOutOrder(input: $input) {
      id
      round_id
      amount
      auto_cash_out
      cash_out
      currency
      game_id
      player_id
      round_id
      wallet_id
      tren_ball_type
      reward
    }
  }
`

export const getMyOrdersQuery = gql`
  query CrashOrderHistory($input: FilterInput!) {
    crashOrdersHistory(input: $input) {
      data {
        id
        round_id
        currency
        amount
        wallet_id
        auto_cash_out
        cash_out
        reward
        multiplier
        random_value
        created_at
        client_seed
        server_seed
        tren_ball_type
        proof
      }
      limit
      page
      total
    }
  }
`

export const getCurrentLiveQuery = gql`
  query CrashCurrentRoundOrder($input: FilterInput!) {
    crashCurrentRoundOrder(input: $input) {
      id
      player_name
      amount
      esc_multiplier
      reward
      timestamp
      currency
      player_id
      tren_ball_type
    }
  }
`

export const getListPastsQuery = gql`
  query CrashRoundHistory($input: FilterInput!) {
    crashRoundHistory(input: $input) {
      data {
        round_id
        random_value
        extreme_multiplier
        ultimate_player
        multiplier
      }
    }
  }
`

export const getCrashResultHistoryQuery = gql`
  query CrashResultHistory($input: CrashResultHistoryInput!) {
    crashResultHistory(input: $input) {
      result
      last10
      last20
      last30
      last50
      last100
    }
  }
`

export const createNewOrder = createThunk('orders/createNewOrder', async (input: CreateCrashOrderInput) => {
  const resp = await gqlClient?.mutate<CrashOrder>({
    mutation: createOrderMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const cashOutOrder = createThunk('orders/cashOutOrder', async (input: CrashOrderCashOutInput) => {
  const resp = await gqlClient?.mutate<any>({
    mutation: cashOutOrderMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export type SearchOrderQuery = {
  gameId: string
  page?: number
  limit?: number
}

export const getMyOrders = createThunk('betting/getMyOrders', async (input: FilterInput) => {
  const resp = await gqlClient?.query<any>({
    query: getMyOrdersQuery,
    variables: {
      input: input,
    },
    // onError: handleApolloError(dispatch),
  })

  return {
    ...resp?.data?.crashOrdersHistory,
    data: resp?.data?.crashOrdersHistory?.data?.map((item: any) => ({ ...item, game_id: input.game_id })),
  }
})

export const getListPasts = createThunk('betting/getListPasts', async (input: FilterInput) => {
  const resp = await gqlClient?.query<any>({
    query: getListPastsQuery,
    variables: {
      input: input,
    },
  })
  return resp?.data?.crashRoundHistory?.data
})

export const getCrashResultHistory = createThunk(
  'betting/crashResultHistory',
  async (input: CrashResultHistoryInput) => {
    const resp = await gqlClient?.query<any>({
      query: getCrashResultHistoryQuery,
      variables: {
        input: input,
      },
    })

    return resp?.data?.crashResultHistory
  },
)

export const getListCurrentLives = createThunk('betting/getListCurrentLives', async (input: FilterInput) => {
  const resp = await gqlClient?.query<any>({
    query: getCurrentLiveQuery,
    variables: {
      input: input,
    },
  })
  return resp?.data?.crashCurrentRoundOrder
})

export const rocketTshowSlice = createSlice({
  name: 'rocketTshow',
  initialState,
  reducers: {
    updateAmount: (state, action: PayloadAction<any>) => {
      state.amount = action.payload
    },
    updateGameRound: (state, action: PayloadAction<GameRound>) => {
      state.gameRound = action.payload
    },
    updateConfigRound: (state, action: PayloadAction<ConfigRound>) => {
      state.configRound = action.payload
    },
    updateMultiplier: (state, action: PayloadAction<any>) => {
      state.multiplier = action.payload
    },
    updateActiveOrders: (state, action: PayloadAction<any>) => {
      state.myActiveOrders = action.payload
    },
    updateTemporaryOrders: (state, action: PayloadAction<any>) => {
      state.temporaryOrders = action.payload
    },
    updateMyOrders: (state, action: PayloadAction<any>) => {
      state.myOrders = state.myOrders.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      )
      // state.myOrders = [action.payload, ...state.myOrders]
    },
    updateMyRoundOrders: (state, action: PayloadAction<any>) => {
      state.myRoundOrders = [action.payload, ...state.myRoundOrders]
    },
    updateCashoutOrders: (state, action: PayloadAction<CrashCurrentRoundOrder[]>) => {
      state.cashoutOrders = action.payload
    },
    addCashoutOrders: (state, action: PayloadAction<CrashCurrentRoundOrder[]>) => {
      state.cashoutOrders = [...state.cashoutOrders, ...action.payload]
    },
    updateListPasts: (state, action: PayloadAction<any>) => {
      state.listPasts = [action.payload, ...state.listPasts]
    },
    updateListCurrentLives: (state, action: PayloadAction<any>) => {
      state.listCurrentLives = action.payload
    },
    updateTabIndex: (state, action: PayloadAction<any>) => {
      state.tabIndex = action.payload
    },
    updateCurrentLives: (state, action: PayloadAction<any>) => {
      state.listCurrentLives = state.listCurrentLives.map((item) =>
        item.id === action.payload.id ? action.payload : item,
      )
    },
    clearActiveOrders: (state) => {
      state.myActiveOrders = []
    },
    clearActiveTrenBallOrders: (state) => {
      state.myActiveTrenBallOrders = []
    },
    clearMyRoundOrders: (state) => {
      state.myRoundOrders = []
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyOrders.fulfilled, (state, action) => {
        state.myOrders = action.payload?.data
        state.totals = action.payload?.total
        state.myActiveOrders = action.payload?.data.filter(
          (item: any) =>
            item?.cash_out === '0' &&
            item?.multiplier === '0' &&
            item?.reward === '0' &&
            item?.tren_ball_type === TrenBallType.None,
        )
        const listTrendBall = action.payload?.data.filter(
          (item: any) => item?.server_seed === '' && item?.tren_ball_type !== TrenBallType.None,
        )

        state.myActiveTrenBallOrders = listTrendBall.reduce((acc, currentValue) => {
          const existingItem = acc.find((item) => item.tren_ball_type === currentValue.tren_ball_type)

          if (existingItem) {
            existingItem.amount = +existingItem.amount + +currentValue.amount
          } else {
            acc.push({ ...currentValue })
          }

          return acc
        }, [])
      })
      .addCase(getListPasts.fulfilled, (state, action) => {
        state.listPasts = action.payload
      })
      .addCase(getCrashResultHistory.fulfilled, (state, action) => {
        state.crashResultHistory = action.payload
      })
      .addCase(getListCurrentLives.fulfilled, (state, action) => {
        state.listCurrentLives = action.payload
      })
      .addCase(createNewOrder.fulfilled, (state, action) => {
        state.createOrderStatus = 'succeeded'
        state.createOrderError = null
        const order = action?.payload?.createCrashOrder
        if (order?.tren_ball_type === TrenBallType.None) {
          state.myActiveOrders = [...state.myActiveOrders, order]
        } else {
          const orderTotalAmount = state.myActiveTrenBallOrders.find(
            (item) => item.tren_ball_type === order.tren_ball_type,
          )
          if (orderTotalAmount) {
            state.myActiveTrenBallOrders = state.myActiveTrenBallOrders.map((item) =>
              item.tren_ball_type === order.tren_ball_type ? { ...item, amount: +item.amount + +order.amount } : item,
            )
          } else {
            state.myActiveTrenBallOrders = [...state.myActiveTrenBallOrders, order]
          }
        }
        state.myOrders = [order, ...state.myOrders]
      })
      .addCase(createNewOrder.pending, (state) => {
        state.createOrderStatus = 'loading'
        state.createOrderError = null
      })
      .addCase(createNewOrder.rejected, (state, action) => {
        state.createOrderStatus = 'failed'
        state.createOrderError = action.payload as string
      })
      .addCase(cashOutOrder.fulfilled, (state, action) => {
        const bet_ids = action.meta.arg.bet_ids
        const filterList = state.myActiveOrders.filter((item) => !bet_ids?.includes(item.id))
        state.myActiveOrders = [...filterList]
        // state.myOrders = [action.payload.cashOutOrder, ...state.myOrders]
        // console.log("state.myOrders", state.myOrders);
      })
  },
})

export const selectMyOrders = (state: RootState) => state.rocketTshow.myOrders
export const selectMyRoundOrders = (state: RootState) => state.rocketTshow.myRoundOrders
export const selectTotalOrdersRK = (state: RootState) => state.rocketTshow.totals
export const selectCreateOrderLoading = (state: RootState) => state.rocketTshow.createOrderStatus
export const selectListPasts = (state: RootState) => state.rocketTshow.listPasts
export const selectCrashResultHistory = (state: RootState) => state.rocketTshow.crashResultHistory
export const selectListCurrentLives = (state: RootState) => state.rocketTshow.listCurrentLives
export const selectListActiveOrders = (state: RootState) => state.rocketTshow.myActiveOrders
export const selectListActiveTrenBallOrders = (state: RootState) => state.rocketTshow.myActiveTrenBallOrders
export const selectTemporaryOrders = (state: RootState) => state.rocketTshow.temporaryOrders
export const selectGameRound = (state: RootState) => state.rocketTshow.gameRound
export const selectConfigRound = (state: RootState) => state.rocketTshow.configRound
export const selectMultiplier = (state: RootState) => state.rocketTshow.multiplier
export const selectCashoutOrders = (state: RootState) => state.rocketTshow.cashoutOrders
export const selectAmountRocketTShow = (state: RootState) => state.rocketTshow.amount
export const selectTabIndex= (state: RootState) => state.rocketTshow.tabIndex

export const rocketTshowActions = {
  ...rocketTshowSlice.actions,
  createNewOrder,
  cashOutOrder,
}

export default rocketTshowSlice
