import { useEffect } from 'react'
import useSound from 'use-sound'
import { HookOptions } from 'use-sound/dist/types'
import usePageVisibility from './usePageVisibility'

declare global {
  interface Window {
    sound: any
  }
}

const minVolume = 0.0000000001
const maxVolume = 1
export function useCustomSound(soundFile: string, options?: HookOptions) {
  const result = useSound(soundFile, options)

  const [play, { sound }] = result

  const isPageVisible = usePageVisibility()

  useEffect(() => {
    const fromVolume = isPageVisible ? minVolume : maxVolume
    const toVolume = isPageVisible ? maxVolume : minVolume
    const diff = (toVolume - fromVolume) / 10
    let volume = fromVolume
    const interval = setInterval(() => {
      try {
        volume += diff
        if (volume < minVolume || volume > maxVolume) {
          sound.volume(toVolume)
          clearInterval(interval)
        }
        sound.volume(volume)
      } catch (err) {}
    }, 100)
    return () => clearInterval(interval)
  }, [isPageVisible])

  return result
}
