import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx'
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import TicketList from '@/bingo/components/FormSection/OrderConfirmation/TicketList.tsx'
import { bingoConfig } from '@/bingo/const/bingoConfigs'
import {
  bingoActions,
  selectBingoBoughtExtraBall,
  selectBingoCanBuyMoreTicket,
  selectBingoExtraBallAmount,
  selectBingoGameRoundId,
  selectBingoIsMinningExtraBall,
  selectBingoIsRunning,
  selectBingoPlayingOrder,
  selectBingoPlayingOrderRoundId,
  selectBingoPlayingTickets,
  selectBingoPossibles,
  selectBingoRoundBallByIndex,
  selectBingoRoundBalls,
  selectBingoRoundBallsCount,
  selectBingoRoundConfig,
  selectBingoRoundOrderBalls,
  selectBingoTotalBonus,
  selectBingoWinningTicket,
} from '@/bingo/redux/bingo.slice.ts'
import { WinningTicketItem } from '@/bingo/types/bingo'
import { formatNumber } from '@/components/banker-player/Betting'
import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomToast from '@/hooks/useCustomToast'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { selectDiffTime } from '@/redux/store/modules/game.slice'
import { Center, CircularProgress, Container, Flex, SimpleGrid, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { sum } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { ImCheckmark } from 'react-icons/im'
import { PiClipboardTextFill } from 'react-icons/pi'
import { SmallBall } from '../../GameplayStage/MiningArea/Ball'
import MyBetsBingo from '../../MyBets'
import FooterButtonCircle from '../FooterButtonCircle'
import FormButtonBig from '../FormButtonBig'
import WinningTicket from '../Mining/WinningTicket'
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'

function BuyExtraBall() {
  const balls = useAppSelector(selectBingoRoundBalls)
  const totalBonus = useAppSelector(selectBingoTotalBonus)
  const extraBallAmount = useAppSelector(selectBingoExtraBallAmount)
  const { balls: ballCount, extraBalls: extraBallCount } = bingoConfig.mining
  const extraBalls = balls.slice(ballCount, ballCount + extraBallCount)
  const ballsPerRow = Math.round(extraBallCount / 2)
  const isBought = useAppSelector(selectBingoBoughtExtraBall(balls.length))
  const { t } = useCustomTranslation()

  const { showToastThrottle } = useCustomToast()
  const dispatch = useAppDispatch()
  function handleBuy() {
    dispatch(bingoActions.buyExtraBall()).then((res) => {
      if (res.meta.requestStatus == 'fulfilled') {
        showToastThrottle({
          title: t('notification.successfully'),
          status: 'success',
          isClosable: false,
          duration: 1500,
          icon: <ImCheckmark fontSize={32} />,
          containerStyle: {
            width: '60px',
            paddingBlock: '0px',
            pointerEvents: 'none',
          },
        })
      }
    })
  }

  return (
    <Stack width="100%" color="#5e1046" fontWeight="500" fontSize="xl">
      <Flex flex={1} flexDirection="row">
        <Center>
          {<Text color="#ff4218">{extraBalls.length}</Text>}
          {<Text>/{extraBallCount}</Text>}
        </Center>
        <Center flex={1}>
          <Text>{t('bingo.totalBonus')}:</Text>
          <Text color="#ff4218" marginLeft={1}>
            {formatNumber(totalBonus)}
          </Text>
        </Center>
        {/* <Center width="32px" height="32px">
          <MdClose />
        </Center> */}
      </Flex>
      <Flex alignItems="center">
        <Stack flex={1}>
          <Flex width="100%" gap={2} justifyContent="space-between">
            {Array(ballsPerRow)
              .fill(0)
              .map((_, i) => {
                return <ExtraBall index={i} key={i} />
              })}
          </Flex>
          <Flex width="100%" gap={2} justifyContent="space-between">
            {Array(ballsPerRow)
              .fill(0)
              .map((_, i) => {
                return <ExtraBall index={ballsPerRow + i} key={ballsPerRow + i} />
              })}
          </Flex>
        </Stack>
        {extraBallAmount.greaterThan(0) ? (
          <FormButtonBig
            width="120px"
            height="80px"
            padding="0"
            marginLeft={5}
            onClick={handleBuy}
            isDisabled={isBought}
          >
            <Stack>
              {isBought ? (
                <Text>{t('bingo.bet')}</Text>
              ) : (
                <>
                  <Text>{t('bingo.extraBalls')}</Text>
                  <Text>{formatNumber(extraBallAmount + '')}</Text>
                </>
              )}
            </Stack>
          </FormButtonBig>
        ) : (
          <Center width="120px" marginLeft={5}>
            <Text color="#ff6533" fontWeight="400" fontSize="md">
              Unfortunately! You are not eligible to purchase additional balls
            </Text>
          </Center>
        )}
      </Flex>
    </Stack>
  )
}

interface ExtraBallProps {
  index: number
}

function ExtraBall({ index }: ExtraBallProps) {
  const i = index + bingoConfig.mining.balls
  const isBought = useAppSelector(selectBingoBoughtExtraBall(i))
  const ball = useAppSelector(selectBingoRoundBallByIndex(i))
  const ballCount = useAppSelector(selectBingoRoundBallsCount)
  const isCountdown = i == ballCount
  const bg = isCountdown || isBought ? 'transparent' : '#d9d9d9' // ball ? '#d9d9d9' : '#c1c1c1'

  return (
    <Center flex={1} maxWidth="50px">
      <Center position="relative" width="100%" paddingBottom="100%" borderRadius="50%" backgroundColor={bg}>
        {isCountdown ? (
          <Center position="absolute" top={0} left={0} right={0} bottom={0} zIndex={1}>
            <Countdown />
          </Center>
        ) : (
          <Center
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            color={isBought ? '#e25565' : '#fff'}
            borderRadius="50%"
            border={isBought ? '3px solid #e25565' : undefined}
          >
            {ball || <img src="/bingo/images/extra-default.0358f2b.webp" />}
          </Center>
        )}
      </Center>
    </Center>
  )
}

const { animationDuration, ballFlyDuration } = bingoConfig.mining
const { ballExtraLockBefore } = bingoConfig.round

function Countdown() {
  const { bingoExtraBallTime } = useAppSelector(selectBingoRoundConfig) || {}
  const countdown = bingoExtraBallTime * 1000 - animationDuration * 3 - ballFlyDuration - ballExtraLockBefore
  const [time, setTime] = useState(countdown)

  const diffTime = useAppSelector(selectDiffTime)
  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    diffTimeRef.current = diffTime
  }, [diffTime])
  const target = useRef(Date.now() + countdown + diffTimeRef.current)

  useEffect(() => {
    const interval = setInterval(() => {
      const endTime = target.current
      const _diff = endTime - Date.now() - diffTimeRef.current
      const diff = _diff < 0 ? 0 : _diff

      setTime(diff)
      if (diff < 0) {
        clearInterval(interval)
      }
    }, 300)
    return () => clearInterval(interval)
  }, [target, countdown])
  const percent = (time / countdown) * 100
  const seconds = Math.round(time / 1000)

  return (
    <CircularProgress
      size="100%"
      value={percent}
      valueText={seconds + ''}
      thickness="6px"
      color="#00c504"
      trackColor="gray.200"
      position="relative"
    >
      <Center
        position="absolute"
        top="18%"
        bottom="18%"
        left="18%"
        right="18%"
        fontSize="lg"
        fontWeight="bold"
        borderRadius="50%"
        backgroundColor="#d9d9d9"
        color="#00c504"
      >
        {seconds}
      </Center>
    </CircularProgress>
  )
}

const YourBet = () => {
  const tickets = useAppSelector(selectBingoPlayingTickets) || []
  const dispatch = useAppDispatch()
  const canBuyMore = useAppSelector(selectBingoCanBuyMoreTicket)
  const playingOrderRoundId = useAppSelector(selectBingoPlayingOrderRoundId)
  const roundId = useAppSelector(selectBingoGameRoundId)
  const isRunning = useAppSelector(selectBingoIsRunning)
  const isExtraBall = useAppSelector(selectBingoIsMinningExtraBall)
  const { isOpen: isOpenMyBets, onOpen: onOpenMyBets, onClose: onCloseMyBet } = useDisclosure()

  const handleBuyMore = () => {
    dispatch(bingoActions.naviate({ step: 'randomTicket' }))
  }
  const balls = useAppSelector(selectBingoRoundOrderBalls)
  const showHighlight =
    playingOrderRoundId && ((playingOrderRoundId == roundId && isRunning) || playingOrderRoundId != roundId)

  const { t } = useCustomTranslation()

  return (
    <FormContainer title="Your Bet">
      <CongratsWinningTicket />

      <Stack padding="15px" position="relative" flex="1" gap="0" overflow="hidden">
        <Possible />
        <TicketList data={tickets} resultBalls={showHighlight ? balls : []} expectedBalls={!!showHighlight} />
      </Stack>
      {canBuyMore && (
        <FormFooter>
          <FooterButtonCircle
            position="absolute"
            top="50%"
            left="20px"
            transform="translateY(-50%)"
            onClick={onOpenMyBets}
          >
            <PiClipboardTextFill />
          </FooterButtonCircle>
          <FormButtonBig onClick={handleBuyMore}>{t('bingo.buyMore')}</FormButtonBig>
        </FormFooter>
      )}
      {isExtraBall && playingOrderRoundId == roundId && (
        <FormFooter zIndex={10}>
          <BuyExtraBall />
        </FormFooter>
      )}
      {isOpenMyBets && (
        <AppDrawer title={'Game records'} isOpen={isOpenMyBets} onClose={onCloseMyBet} overlay={true}>
          <MyBetsBingo />
        </AppDrawer>
      )}
    </FormContainer>
  )
}

export default YourBet

function CongratsWinningTicket() {
  const _ticket = useAppSelector(selectBingoWinningTicket)
  const [ticket, setTicket] = useState<WinningTicketItem | null>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!_ticket) return

    setTicket(_ticket)
    setTimeout(() => {
      setTicket(null)
    }, bingoConfig.round.winningTicketDuration)
  }, [_ticket])

  useEffect(() => {
    if (!ticket) {
      dispatch(bingoActions.congratsTicketSuccess())
    }
  }, [ticket])

  if (!ticket) return null

  return (
    <Center position="fixed" top={186} left={0} right={0} bottom={0} zIndex={4}>
      <Container position="relative" top={0} left={0} width="100%" height="100%">
        <WinningTicket key={ticket.index} ticket={ticket} ticketUnitPrice={2} />
      </Container>
    </Center>
  )
}

interface PossibleItemProps {
  amount: number
  balls?: number[]
}
function PossibleItem({ amount, balls }: PossibleItemProps) {
  const playingOrder = useAppSelector(selectBingoPlayingOrder)
  const { t } = useCustomTranslation()

  return (
    <Flex
      color="#ff4218"
      alignItems="center"
      justifyContent="center"
      bg="white"
      minHeight="36px"
      borderRadius="18px"
      px="12px"
    >
      <BingoCurrencyIcon currency={playingOrder?.currency} />
      <Text flex={1} marginLeft={2} fontWeight="500">
        {formatNumber(amount)}
      </Text>
      {balls ? (
        <>
          <Center flex={1}>
            {balls.slice(0, 3).map((value) => {
              return <SmallBall value={value} marginLeft={-1.5} />
            })}
            {balls.length > 3 && (
              <Text color="black" fontWeight="600" marginLeft="1px" fontSize="18px">
                +{balls.length - 3}
              </Text>
            )}
          </Center>
        </>
      ) : (
        <Text textTransform="uppercase" fontWeight="500" fontSize={16} color="#AFAFAF">
          {t('bingo.possible')}
        </Text>
      )}
    </Flex>
  )
}

function Possible() {
  const possibles = useAppSelector(selectBingoPossibles) || []

  const total = sum(possibles.map(({ amount, balls }) => amount * balls.length))
  if (!total) return null

  return (
    <SimpleGrid columns={2} marginBottom={4} spacingX={6} spacingY={2}>
      <PossibleItem amount={total} />
      {possibles.map(({ amount, balls }) => {
        return <PossibleItem amount={amount} balls={balls} />
      })}
    </SimpleGrid>
  )
}
