import { BingoTicketInput } from '@/@generated/gql/graphql-hash'
import ButtonChangeGroup from '@/bingo/components/FormSection/EditTicket/ButtonChangeGroup.tsx'
// import ButtonEP from '@/bingo/components/FormSection/EditTicket/ButtonEP.tsx'
// import ButtonPer from '@/bingo/components/FormSection/EditTicket/ButtonPer.tsx'
import CurrentTicket from '@/bingo/components/FormSection/EditTicket/CurrentTicket'
// import NumbersRating from '@/bingo/components/FormSection/EditTicket/NumbersRating'
import NumbersSelect from '@/bingo/components/FormSection/EditTicket/NumbersSelect'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import { bingoConfig, defaultMultiplier } from '@/bingo/const/bingoConfigs'
import { bingoGroups } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { useRoundCountdown } from '@/bingo/hooks/useRoundCountdown'
import { bingoActions } from '@/bingo/redux/bingo.slice'
import { genTicketId } from '@/bingo/utils/BingoEngine'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppDispatch } from '@/redux/store'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'

function checkValidNumbers(numbers: number[]) {
  if (numbers.length != 15) return false
  return numbers.findIndex((x) => !x) == -1
}

const buttonConfigs = {
  active: {
    background: 'linear-gradient(180deg, rgba(255,145,130,0.38) 0%, rgba(255,145,130,0.6) 100%)',
    color: '#d92727',
  },
  inactive: {
    background: 'linear-gradient(180deg, #efefef 0%, #cecece 100%)',
    color: '#888',
  },
}

interface EditTicketProps {
  ticket?: BingoTicketInput
  onClose: () => void
}

const emptyNumbers = Array(bingoConfig.ticket.rows * bingoConfig.ticket.cols).fill(0)

const EditTicket = ({ ticket, onClose }: EditTicketProps) => {
  const { t } = useCustomTranslation()
  const [groupIndex, setGroupIndex] = useState<number>(0)
  const [currentPer, setCurrentPer] = useState<number>(10)

  const [numbers, setNumbers] = useState(ticket?.numbers || emptyNumbers)
  const canSave = checkValidNumbers(numbers)
  const { seconds } = useRoundCountdown()

  const handlePrevGroup = () => {
    if (groupIndex === 0) return

    setGroupIndex((prevGroupIndex) => prevGroupIndex - 1)
  }
  const handleNextGroup = () => {
    if (groupIndex >= bingoGroups.length - 1) return

    setGroupIndex((prevGroupIndex) => prevGroupIndex + 1)
  }

  const saveButtonStyle = canSave ? buttonConfigs.active : buttonConfigs.inactive
  const dispatch = useAppDispatch()
  function saveTicket() {
    if (ticket) {
      dispatch(
        bingoActions.ticketDetailUpdated({
          ...ticket,
          id: genTicketId(ticket?.numbers),
          numbers,
        }),
      )
    } else {
      dispatch(
        bingoActions.addTicketCollection({
          id: genTicketId(numbers),
          numbers,
          star: defaultMultiplier,
        }),
      )
    }
    onClose()
  }

  return (
    <Box paddingBottom="25px">
      <Text textAlign="center" lineHeight="1" margin="0 0 10px">
        {seconds < 0 ? '' : t('bingo.drawTheLotteryInSeconds', { seconds })}
      </Text>
      <Box
        background="linear-gradient(180deg, #fff 0%, rgba(255,255,255,0.5) 100%)"
        boxShadow="0px 0.313rem 0.313rem 0px rgba(255,205,205,0.5)"
        borderRadius="15px"
        padding="20px 30px"
      >
        <Flex alignItems="center" gap="20px" marginBottom="30px">
          <CurrentTicket currentGroup={bingoGroups[groupIndex]} numbers={numbers} />
          {/*<Stack gap="15px">*/}
          {/*  <ButtonEP epType="hot" />*/}
          {/*  <ButtonEP epType="cold" />*/}
          {/*</Stack>*/}
        </Flex>

        {/*<Flex alignItems="center" justifyContent="center" gap="10px" marginBottom="20px">*/}
        {/*  <ButtonPer per={10} onClick={() => setCurrentPer(10)} isSelected={currentPer === 10} />*/}
        {/*  <ButtonPer per={20} onClick={() => setCurrentPer(20)} isSelected={currentPer === 20} />*/}
        {/*  <ButtonPer per={50} onClick={() => setCurrentPer(30)} isSelected={currentPer === 30} />*/}
        {/*</Flex>*/}

        {/*<NumbersRating currentGroup={bingoGroups[groupIndex]} />*/}

        <Flex alignItems="center" justifyContent="center" gap="20px" marginBottom="30px">
          <ButtonChangeGroup variant="prev" onClick={handlePrevGroup} />
          <NumbersSelect onNumberChange={setNumbers} numbers={numbers} currentGroup={bingoGroups[groupIndex]} />
          <ButtonChangeGroup variant="next" onClick={handleNextGroup} />
        </Flex>

        <Flex alignItems="center" justifyContent="center" gap="30px">
          <FormButtonBig {...buttonConfigs.active} onClick={onClose}>
            Close
          </FormButtonBig>
          <FormButtonBig {...saveButtonStyle} isDisabled={!canSave} onClick={saveTicket}>
            Save
          </FormButtonBig>
        </Flex>
      </Box>
    </Box>
  )
}

export default EditTicket
