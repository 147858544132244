import { BingoBetExtraBall, BingoBetTickets, BingoOrder } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import { CopyBtn } from '@/components/common/CopyBtn'
import { textGrayDrawer } from '@/const/color'
import { currency, currencyMap } from '@/const/currency'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import {} from '@/redux/store'
import { Box, Button, Flex, Link, Stack, Text, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { f } from 'fintech-number'
import _ from 'lodash'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import Ticket from '../Common/Ticket'
import { MenuBingo } from '../Menu'
import { convertWinningPattern } from './TabTickets'

export const TicketDetail = React.memo(
  ({
    order,
    ticket,
    extraBallIdx,
  }: {
    order: BingoOrder
    ticket: BingoBetTickets
    extraBallIdx?: BingoBetExtraBall
  }) => {
    const { t } = useCustomTranslation()
    const isWin = +ticket?.multiplier > 0
    const gameId = useGameId()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const currencySupported = currencyMap[order.currency]
    const isExtraBallDetail = !_.isNil(extraBallIdx)
    const reward = !isExtraBallDetail ? +ticket?.star * +order?.ticket_unit_price * +ticket.multiplier : 0
    const formatOrderId = (orderId?: string) => {
      if (!orderId) return ''
      const formatOrderIdRegex = /^(.{8}).*(.{8})$/
      return orderId.replace(formatOrderIdRegex, '$1...$2')
      // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
    }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const resultBalls = [...order?.bingo_round?.result.slice(0, 30), ...order?.bingo_bet_extra_balls?.map(item => item?.number)]
  
    return (
      <>
        <Box backgroundColor="#2f2f31" className="newgame-container">
          {/* <Stack p={4} backgroundColor="#2f2f31" color={'#fff'} gap={0}>
            <Text fontWeight={600} fontSize="22px" mt={2}>
              {t(gameId)}
            </Text>
            <Flex gap={2} alignItems="center" lineHeight={1.2}>
              <Text fontSize={32} fontWeight={600} color={isWin ? '#B0FC00' : '#ff5959'}>
                {isWin ? (
                  <>{`+${f(+order?.reward, {
                    decimal: currencySupported?.decimal,
                  })}`}</>
                ) : (
                  <>
                    {f(+order?.amount, {
                      decimal: currencySupported?.decimal,
                    })}
                  </>
                )}
              </Text>
              <Image src={getSymbolFromCurrency(order?.currency)} className="w-6 h-6 rounded-lg" />
            </Flex>
            <Stack flexDirection="row" justifyContent="space-between" color={isWin ? '#B0FC00' : '#ff5959'}>
              <Text>{isWin ? t('win') : t('lose')}</Text>
              <Text>{t('statusPayment')}</Text>
            </Stack>
          </Stack> */}
          <Stack p={4} color={'#fff'} borderRadius="14px 14px 0 0" backgroundColor="#363639" gap="10px">
            <Stack>
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('game')}</Text>
                <Text fontWeight={400}>{t(gameId)}</Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betAmount')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>
                    {f(+order?.amount, {
                      decimal: currencySupported?.decimal,
                    })}{' '}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>Valid Bet</Text>
                {isExtraBallDetail ? (
                  <Flex gap={1} alignItems="center">
                    <Text fontWeight={400} color="#B0FC00">
                      {f(extraBallIdx?.amount, {
                        decimal: 2,
                      })}
                    </Text>
                    {currency.find((i) => i.name === order?.currency)?.icon}
                  </Flex>
                ) : (
                  <Flex gap={1} alignItems="center">
                    <Text fontWeight={400} color={isWin ? '#B0FC00' : '#ff5959'}>
                      {isWin ? (
                        <>{`+${f(reward, {
                          decimal: currencySupported?.decimal,
                        })}`}</>
                      ) : (
                        <>
                          {f(+order?.amount, {
                            decimal: currencySupported?.decimal,
                          })}
                        </>
                      )}
                    </Text>
                    {currency.find((i) => i.name === order?.currency)?.icon}
                  </Flex>
                )}
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>Bet Method</Text>
                <Text fontWeight={400}>{isExtraBallDetail ? t('bingo.extraBall') : t('bingo.randomTicket')}</Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betTime')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{dayjs(order.created_at).format('YYYY-MM-DD hh:mm:ss')}</Text>
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('orderId')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{formatOrderId(order?.id)}</Text>
                  <CopyBtn text={order?.id} />
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Stack gap="0">
                <Flex alignItems="center" justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{t('result')}</Text>
                  <CopyBtn text={order?.bingo_round?.random_value} />
                </Flex>
                <Text fontWeight={400} display="inline-block">
                  {order?.bingo_round?.random_value.split(',').splice(0, 30).toString()}
                  {','}
                  <Text color="#f59a23" display="contents">
                    {' '}
                    {order?.bingo_round?.random_value.split(',').splice(30, 40).toString()}
                  </Text>
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Stack gap="0">
                <Flex alignItems="center" justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{t('serverSeed')}</Text>
                  <CopyBtn text={order?.bingo_round?.server_seed} />
                </Flex>
                <Text fontWeight={400}>{order?.bingo_round?.server_seed}</Text>
              </Stack>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Stack gap="0">
                <Flex alignItems="center" justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{t('clientSeed')}</Text>
                  <CopyBtn text={order?.bingo_round?.client_seed} />
                </Flex>
                <Text fontWeight={400}>{order?.bingo_round?.client_seed}</Text>
              </Stack>
            </Stack>
            {/* <CopyAll
              text={t('copyTextSHA256')}
              cpyTxt={`${order?.bingo_round?.random_value}${order?.bingo_round?.server_seed}${order?.bingo_round?.client_seed}`}
              handeClick={() => {
                window.open(
                  `https://emn178.github.io/online-tools/sha256.html?input=${order?.bingo_round?.random_value}${order?.bingo_round?.server_seed}${order?.bingo_round?.client_seed}`,
                  '_blank',
                )
              }}
            /> */}
            <Button
              aria-label="buttonVerifyWalletBingo"
              aria-labelledby="buttonVerifyWalletBingo"
              w="100%"
              backgroundColor="#c7fb14"
              borderRadius="8px"
              height="28px"
            >
              <Stack direction="row">
                <Link
                  href={`https://emn178.github.io/online-tools/sha256.html?input=${order?.bingo_round?.random_value}${order?.bingo_round?.server_seed}${order?.bingo_round?.client_seed}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
                  isExternal
                >
                  {t('buttonVerifyWallet')} <FiExternalLink style={{ display: 'inline', marginBottom: '2px' }} />
                </Link>
              </Stack>
            </Button>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Stack gap="0">
                <Flex alignItems="center" justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{t('proof')}</Text>
                  <CopyBtn text={order?.bingo_round?.proof} />
                </Flex>
                <Text fontWeight={400}>{order?.bingo_round?.proof}</Text>
              </Stack>
            </Stack>
            <Button
              aria-label="explosivePointVerification"
              aria-labelledby="explosivePointVerification"
              w="100%"
              backgroundColor="#c7fb14"
              borderRadius="8px"
              mt="8px"
              onClick={() => onOpen()}
              height="32px"
            >
              {t('explosivePointVerification')}
            </Button>
            {!isExtraBallDetail && (
              <Stack>
                <hr style={{ borderColor: '#434343' }} />
                <Box py={1}>
                  <Text color={textGrayDrawer}>Tickets</Text>
                  <Flex justifyContent="center" color="#2f2f31" mt={4} p={4} backgroundColor="#2f2f31">
                    <Ticket
                      data={ticket}
                      ticketUnitPrice={order?.ticket_unit_price}
                      resultBalls={resultBalls}
                      // expectedBalls={false}
                      winningPattern={ticket?.pattern ? convertWinningPattern(ticket?.pattern) : ''}
                      isBetSuccess
                      maxWidth="200px"
                      patternBoardProps={{
                        fontSize: '14px'
                      }}
                    />
                  </Flex>
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>

        {isOpen && (
          <AppDrawer isOpen={isOpen} onClose={onClose} overlay>
            <MenuBingo popup={'Verification'} bingo_round={order?.bingo_round} />
          </AppDrawer>
        )}
      </>
    )
  },
)
