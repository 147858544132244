import { getSymbolFromCurrency } from '@/const/currency'
import { useAppSelector } from '@/redux/store'
import { ROUND_STATE, selectGameRound, selectListCurrentLives } from '@/redux/store/modules/rocketTshow'
import { getAvatar } from '@/utils/helper'
import { Flex, Stack, Image, BoxProps } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React from 'react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

const ListPlayerBettingInRound = ({ sx }: { sx?: BoxProps }) => {
  const listPlayers = useAppSelector(selectListCurrentLives)
  const gameRound = useAppSelector(selectGameRound)

  return (
    <Stack
      width="25%"
      position="absolute"
      top="100px"
      zIndex="2"
      left="5px"
      gap={1}
      minW="32%"
      maxW="32%"
      className="zoom-container"
      {...sx}
    >
      {listPlayers.slice(0, 10).map((item: any, index: number) => (
        <Flex
          key={index}
          gap={1}
          background="#10151e"
          px={2}
          pr={3}
          py="5px"
          borderRadius={10}
          // justifyContent="space-between"
        >
          <Image src={getAvatar(item.player_id.toString())} alt="" w="14px" h="14px" borderRadius="50%" />
          <RocketText fontSize={14} color="#9297a0" {...NotoSansFontFamily.medium} lineHeight={1} className="text-one-line">
            {item.player_name}
          </RocketText>
          <RocketText fontSize={12} {...NotoSansFontFamily.medium} color="#9297a0" lineHeight={1} mt="1px" ml="auto">
            {+item?.esc_multiplier > 0
              ? `${f(item?.esc_multiplier, {
                  decimal: 2,
                })}x`
              : ''}
          </RocketText>
          <Flex gap={1} alignItems="center">
            <RocketText
              fontSize={14}
              {...NotoSansFontFamily.medium}
              color={item?.reward > 0 ? '#01ffa2' : gameRound?.state === ROUND_STATE.END ? '#ff335f' : '#9297a0'}
              lineHeight={1}
            >
              {f(item.amount, {
                decimal: 2,
              })}
            </RocketText>
            <Image src={getSymbolFromCurrency(item.currency)} className={`w-[14px] h-[14px] rounded-lg`} />
          </Flex>
        </Flex>
      ))}
    </Stack>
  )
}

export default React.memo(ListPlayerBettingInRound)
