import React from 'react'
import TShowMainGame from '@components/tshow/GamePlay/TShowMainGame.tsx'
import RKQuickForm from '@/components/rocket/QuickForm/index'
import TabSection from '@/components/tshow/GamePlay/TabSection'
import MyBets from '@/components/rocket/MyBets'
import AuthGame from '@/components/rocket/AuthGame'
import GameRoundSubscription from '@/components/rocket/Subcription/GameRoundSubscription'
import ConfigRoundSubscription from '@/components/rocket/Subcription/ConfigRoundSubscription'
import MultiplierSubscription from '@/components/rocket/Subcription/MultiplierSubscription'
import OrdersSubscription from '@/components/rocket/Subcription/OrdersSubscription'
import useZoomUI from '@/hooks/useZoomUI'
import TShowGameBackground from '@/components/tshow/GamePlay/TShowGameBackground'
import CurrentBetSubcription from '@/components/rocket/Subcription/CurrentBetSubcription'
import RoundConfig from '@/components/tshow/GamePlay/RoundConfig'

export default function TShowPage() {
  useZoomUI({
    containersClassNames: ['zoom-container'],
  })

  return (
    <AuthGame>
      <TShowGameBackground>
        <GameRoundSubscription />
        <ConfigRoundSubscription />
        <MultiplierSubscription />
        <OrdersSubscription />
        <CurrentBetSubcription />
        <TShowMainGame />
        <RoundConfig />
        <RKQuickForm />
        <TabSection />
        <MyBets />
      </TShowGameBackground>
    </AuthGame>
  )
}
