import { Wallet } from '@/redux/store/entities/wallet.entity'
import preferenceSlice from '@/redux/store/modules/preference.slice'
import { Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react'
import {
  getListWalletEnabled,
  getListWallets,
  selectListWallets,
  selectSupportedCurrency,
  selectWallet,
} from '@store/modules/wallet.slice'
import { forEach, keyBy } from 'lodash'
import { default as React, useEffect, useMemo } from 'react'
import { TiArrowSortedDown } from 'react-icons/ti'
import { useSearchParams } from 'react-router-dom'
import { getSymbolFromCurrency } from '../../const/currency'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { money } from '../../utils/number'
import useGameId from '@/hooks/useGameId'
import { GameID } from '@/const/game-type'

export default function SelectWallet() {
  const walletSelected = useAppSelector(selectWallet)
  const [params] = useSearchParams()
  const switch_wallet = params.get('switch_wallet')
  const gameId = useGameId()
  const canSwitchWallet = switch_wallet == '1'

  return (
    <Menu matchWidth={false} direction="ltr" isLazy lazyBehavior="unmount">
      {({ isOpen }) => (
        <>
          <MenuButton
            className="menu-btn"
            role="button"
            data-id="menu-item-change-wallet"
            aria-expanded="false"
            aria-haspopup="menu"
            aria-controls="menu-list-:rc:"
            // backgroundColor="#18181b"
            backgroundColor={gameId === GameID.H_ROCKET ? '#323b45' : '#18181b'}
            paddingInline={0}
            m={0}
            width={canSwitchWallet ? gameId === GameID.H_ROCKET ? '46px' : '36px' : gameId === GameID.H_ROCKET ? '26px' : '18px'}
            minWidth={canSwitchWallet ? gameId === GameID.H_ROCKET ? '46px' : '36px' : gameId === GameID.H_ROCKET ? '26px' : '18px'}
            height={canSwitchWallet ? '20px' : '18px'}
            _active={{ background: 'none' }}
            _hover={{ background: 'none' }}
            as={Button}
            defaultValue={walletSelected.id}
            alignItems="center"
            cursor={canSwitchWallet ? 'pointer' : 'auto'}
            pointerEvents={!canSwitchWallet ? 'none' : 'auto'}
            rightIcon={
              canSwitchWallet ? (
                <TiArrowSortedDown color="#4b4b51" style={{ width: 14, height: 14 }} className="ml-[2px]" />
              ) : (
                <></>
              )
            }
          >
            <Image
              src={getSymbolFromCurrency(walletSelected?.currency)}
              w={gameId === GameID.H_ROCKET ? 26 : 22}
              h={gameId === GameID.H_ROCKET ? 26 : 22}
              alt=""
            />
          </MenuButton>
          {canSwitchWallet && <ListMenu isOpen={isOpen} />}
        </>
      )}
    </Menu>
  )
}

type ListMenuProps = {
  isOpen?: boolean
}

const ListMenu = ({ isOpen }: ListMenuProps) => {
  const dispatch = useAppDispatch()
  const dataList = useAppSelector(selectListWallets)
  const walletSelected = useAppSelector(selectWallet)
  const activeCurrency = walletSelected.currency
  const supportedCurrency = useAppSelector(selectSupportedCurrency)

  const fetchWallet = () => {
    dispatch(getListWallets())
    dispatch(getListWalletEnabled())
  }

  useEffect(() => {
    if (!isOpen) return

    fetchWallet()
  }, [isOpen])

  const handleSelect = (wallet: Wallet) => {
    if (!wallet.is_enable) return
    dispatch(preferenceSlice.actions.activeWalletUpdated(wallet))
  }

  const listSorted = useMemo(() => {
    const result = [...dataList]
    const check = keyBy(dataList, 'currency')

    forEach(supportedCurrency, (currency) => {
      if (check[currency]) return
      const fakeWallet: Wallet = {
        currency,
        amount: 0,
        id: '',
        is_enable: true,
      }
      check[currency] = fakeWallet
      result.push(fakeWallet)
    })
    result.sort((a, b) => {
      if (a.currency == activeCurrency) return -1
      if (b.currency == activeCurrency) return 1
      if (!a.is_enable) return 1
      if (!b.is_enable) return -1
      return 0
    })
    return result
  }, [dataList, activeCurrency, supportedCurrency])

  return (
    <MenuList width="unset" minW="unset" border="none">
      {listSorted.length > 0 ? (
        listSorted.map((item: Wallet) => {
          return (
            <MenuItem
              data-id={`item-wallet-${item.currency}`}
              bg={item.currency === walletSelected.currency ? '#FFFFD4' : 'transparent'}
              w="100%"
              color="white"
              key={item.currency}
              cursor={item.is_enable ? 'hover' : 'not-allowed'}
              opacity={item.is_enable ? 1 : 0.6}
              onClick={() => handleSelect(item)}
              px={2}
            >
              <Flex alignItems="center" gap={2} color="#333">
                <Image src={getSymbolFromCurrency(item.currency)} className={`w-6 h-6 rounded-lg`} />
                <Stack gap="2px">
                  <Text fontSize={15} lineHeight={1}>
                    {item.currency}
                  </Text>
                  <Text fontSize={14} lineHeight={1}>
                    {money(item.amount, 2, 2)}{' '}
                  </Text>
                </Stack>
              </Flex>
            </MenuItem>
          )
        })
      ) : (
        <></>
      )}
    </MenuList>
  )
}
