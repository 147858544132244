import ButtonSquare from '@/bingo/components/Common/ButtonSquare.tsx'
import { Flex, Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { FiMinus } from 'react-icons/fi'
import { GoPlus } from 'react-icons/go'

type BingoInputProps = {
  inputProps?: Partial<InputProps>
  handlePlus?: () => void
  handleMinus?: () => void
  isMinusDisabled?: boolean
  isPlusDisabled?: boolean
}

const BingoInput = ({ inputProps, handlePlus, handleMinus, isMinusDisabled, isPlusDisabled }: BingoInputProps) => {
  return (
    <Flex alignItems="center" justifyContent="center" gap="10px">
      <ButtonSquare onClick={handleMinus} isDisabled={isMinusDisabled}>
        <FiMinus />
      </ButtonSquare>
      <Input
        type="number"
        border="1px solid #ff9d75"
        boxShadow="0px 0.078rem 0px 0px rgba(255,255,255,0.5) inset"
        background="linear-gradient(180deg, #fde8e8 0%, #fff 100%)"
        borderRadius="8px"
        color="#ef5f24"
        lineHeight="1"
        fontSize="24px"
        fontWeight="400"
        width="80px"
        textAlign="center"
        cursor="auto"
        _focus={{}}
        _focusVisible={{}}
        _hover={{}}
        {...inputProps}
      />
      <ButtonSquare onClick={handlePlus} isDisabled={isPlusDisabled}>
        <GoPlus />
      </ButtonSquare>
    </Flex>
  )
}

export default BingoInput
