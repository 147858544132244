import React from 'react'
import { Flex, FlexProps, Image, ImageProps } from '@chakra-ui/react'
import { getSymbolFromCurrency } from '@const/currency.tsx'
import { CellText } from '@components/rocket/NewUI/RocketTables/RocketTable.tsx'

type MoneyCellProps = FlexProps & {
  value: string | number,
  currency?: string,
  imageProps?: ImageProps,
}

const MoneyCell = ({value, currency, imageProps, ...rest}: MoneyCellProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      gap="5px"
      {...rest}
    >
      {currency && (
        <Image
          src={getSymbolFromCurrency(currency)}
          width="12px"
          minWidth="12px"
          height="12px"
          aspectRatio="1/1"
          borderRadius="50%"
          {...imageProps}
        />
      )}
      <CellText>
        {value}
      </CellText>
    </Flex>
  )
}

export default MoneyCell;
