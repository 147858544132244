import { Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'

type RocketInputProps = InputProps & {
  ref?: React.LegacyRef<HTMLInputElement> | undefined,
}

const RocketInput = ({...rest}: RocketInputProps) => {
  return (
    <Input
      height="31px"
      fontSize="26px"
      {...NotoSansFontFamily.semiBold}
      fontStyle="italic"
      lineHeight="1"
      border="none"
      outline="none !important"
      boxShadow="none !important"
      backgroundColor="transparent !important"
      position="relative"
      padding="0"
      color="#ffd200"
      borderRadius="0"
      paddingLeft="5.3px"
      transform="translateY(-6%)"
      _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
      {...rest}
    />
  )
}

export default RocketInput;
