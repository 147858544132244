export const maxTickets = 100
export const minTickets = 1
export const defaultMultiplier = 10

export const bingoConfig = {
  mining: {
    animationDuration: 400,
    ballFlyDuration: 300,
    balls: 30,
    extraBalls: 10,
  },
  ticket: {
    rows: 3,
    cols: 5,
  },
  round: {
    newRoundAnimationDuration: 1000,
    winningAnimationDuration: 3000,
    winningTicketDuration: 3000,
    timingBetweenCongrats: 100,
    delayBeforeCongrats: 1000,
    ballExtraLockBefore: 1000,
    bingoWarningDuration: 2000,
  },
}
