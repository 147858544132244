import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import React from 'react';
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import { f } from 'fintech-number'

type Rank = 1 | 2 | 3

type RankingSpotProps = BoxProps & {
  rank: Rank,
  playerName?: string,
  bonus?: number,
  currency?: string,
}

type MainFlagProps = BoxProps & {
  rank: Rank,
}

const assetsByRank: Record<number, any> = {
  1: {
    mainFlag: '/bingo/images/endgame/no-1-flag.webp',
    backFlag: '/bingo/images/endgame/no-1-flag-back.webp',
    cup: {
      src: '/bingo/images/endgame/no-1-cup.webp',
      top: '18px',
    },
    cupBg: {
      src: '/bingo/images/endgame/no-1-cup-bg.webp',
      ratio: '270/233',
      width: '100px',
    },
    back: {
      src: '/bingo/images/endgame/no-1-back.webp',
      width: '10px',
      top: '-3px',
      ratio: '25/26',
    },
    wingLeft: {
      src: '/bingo/images/endgame/no-1-wing-left.webp',
      left: '-5px',
      top: '22px',
      width: '33px',
      ratio: '81/125',
    },
    wingRight: {
      src: '/bingo/images/endgame/no-1-wing-right.webp',
      right: '-5px',
      top: '22px',
      width: '33px',
      ratio: '81/125',
    },
    icon: '/bingo/images/endgame/no-1-icon.webp',
  },
  2: {
    mainFlag: '/bingo/images/endgame/no-2-flag.webp',
    backFlag: '/bingo/images/endgame/no-2-flag-back.webp',
    cup: {
      src: '/bingo/images/endgame/no-2-cup.webp',
      top: '22px',
    },
    cupBg: {
      src: '/bingo/images/endgame/no-2-cup-bg.webp',
      ratio: '180/209',
      width: '70px',
    },
    back: {
      src: '/bingo/images/endgame/no-2-back.webp',
      width: '78px',
      top: '16px',
      ratio: '199/195',
    },
    wingLeft: {
      src: '/bingo/images/endgame/no-2-wing-left.webp',
      left: '-26px',
      top: '11px',
      width: '50px',
      ratio: '134/176',
    },
    wingRight: {
      src: '/bingo/images/endgame/no-2-wing-right.webp',
      right: '-26px',
      top: '11px',
      width: '50px',
      ratio: '134/176',
    },
    icon: '/bingo/images/endgame/no-2-icon.webp',
  },
  3: {
    mainFlag: '/bingo/images/endgame/no-3-flag.webp',
    backFlag: '/bingo/images/endgame/no-3-flag-back.webp',
    cup: {
      src: '/bingo/images/endgame/no-3-cup.webp',
      top: '12px',
    },
    cupBg: {
      src: '/bingo/images/endgame/no-3-cup-bg.webp',
      ratio: '183/184',
      width: '70px',
    },
    back: {
      src: '/bingo/images/endgame/no-3-back.webp',
      width: '30px',
      top: '-10px',
      ratio: '71/38',
    },
    wingLeft: {
      src: '/bingo/images/endgame/no-3-wing-left.webp',
      left: '-18px',
      top: '-1px',
      width: '37px',
      ratio: '99/173',
    },
    wingRight: {
      src: '/bingo/images/endgame/no-3-wing-right.webp',
      right: '-18px',
      top: '-1px',
      width: '37px',
      ratio: '99/173',
    },
    icon: '/bingo/images/endgame/no-3-icon.webp',
  },
}

const playerInfoStyleByRank: Record<number, Record<string, string>> = {
  1: {
    top: '106px',
  },
  2: {
    top: '116px',
  },
  3: {
    top: '99px',
  },
}

const Flag = ({rank, ...rest}: MainFlagProps) => {
  return (
    <Box
      position="relative"
      zIndex="0"
      {...rest}
    >
      <Box
        // main flag
        background={`url('${assetsByRank[rank].mainFlag}') no-repeat top center`}
        backgroundSize="contain"
        width="100%"
        aspectRatio="214/330"
        position="relative"
        zIndex="1"
      />
      <Box
        // back flag
        background={`url('${assetsByRank[rank].backFlag}') no-repeat bottom center`}
        backgroundSize="cover"
        width="70%"
        aspectRatio="158/242"
        position="absolute"
        left="50%"
        bottom="-5px"
        transform="translateX(-50%)"
        zIndex="0"
      />
    </Box>

  )
}

const Medal = ({rank, ...rest}: BoxProps & {rank: Rank}) => {
  return (
    <Box
      position="absolute"
      top="24px"
      left="50%"
      transform="translateX(-50%)"
      zIndex="1"
      {...rest}
    >
      <Box
        // cup bg
        background={`url('${assetsByRank[rank].cupBg.src}') no-repeat top center`}
        backgroundSize="cover"
        width={assetsByRank[rank].cupBg.width}
        aspectRatio={assetsByRank[rank].cupBg.ratio}
        position="relative"
        zIndex="1"
      />
      <Box
        // back
        background={`url('${assetsByRank[rank].back.src}') no-repeat top center`}
        backgroundSize="cover"
        width={assetsByRank[rank].back.width}
        aspectRatio={assetsByRank[rank].back.ratio}
        position="absolute"
        top={assetsByRank[rank].back.top}
        left="50%"
        transform="translateX(-50%)"
        zIndex="0"
      />
      <Box
        // cup
        background={`url('${assetsByRank[rank].cup.src}') no-repeat top center`}
        backgroundSize="cover"
        width="45px"
        aspectRatio="84/84"
        position="absolute"
        top={assetsByRank[rank].cup.top}
        left="50%"
        transform="translateX(-50%)"
        zIndex="2"
      />
      <Box
        // wing left
        background={`url('${assetsByRank[rank].wingLeft.src}') no-repeat top center`}
        backgroundSize="cover"
        width={assetsByRank[rank].wingLeft.width}
        aspectRatio={assetsByRank[rank].wingLeft.ratio}
        position="absolute"
        left={assetsByRank[rank].wingLeft.left}
        top={assetsByRank[rank].wingLeft.top}
      />
      <Box
        // wing right
        background={`url('${assetsByRank[rank].wingRight.src}') no-repeat top center`}
        backgroundSize="cover"
        width={assetsByRank[rank].wingRight.width}
        aspectRatio={assetsByRank[rank].wingRight.ratio}
        position="absolute"
        right={assetsByRank[rank].wingRight.right}
        top={assetsByRank[rank].wingLeft.top}
      />
    </Box>
  )
}

const RankingSpot = ({rank, playerName, bonus, currency, ...rest}: RankingSpotProps) => {
  return (
    <Box
      position="relative"
      width="140px"
      marginTop={rank !== 1 ? '50px' : '0'}
      order={rank === 2 ? '0' : '1'}
      {...rest}
    >
      <Flag rank={rank} />
      <Medal rank={rank} />
      <Box
        // icon
        background={`url('${assetsByRank[rank].icon}') no-repeat top center`}
        backgroundSize="cover"
        width="30px"
        aspectRatio="58/73"
        position="absolute"
        top="-10px"
        right="5px"
      />
      <Box
        position="absolute"
        left="10px"
        right="10px"
        textAlign="center"
        {...playerInfoStyleByRank[rank]}
      >
        <Text
          // player name
          color="#003900"
        >
          {playerName ?? 'Reserved spot'}
        </Text>
        {bonus && (
          <Flex
            // bonus
            borderRadius="50px"
            background="rgba(0, 0, 0, 0.5)"
            color="#ffffff"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            padding="3px 5px"
          >
            <BingoCurrencyIcon currency={currency} width="20px" />
            <Text
              fontWeight="700"
            >
              {f(bonus, {decimal: 2})}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default RankingSpot;