import { useEffect } from 'react'
import { zoomUI } from '@const/helpers.ts'

type UseZoomUIProps = {
  containersClassNames?: string[]
  desiredWidth?: number
}

const useZoomUI = ({ containersClassNames, desiredWidth }: UseZoomUIProps) => {
  useEffect(() => {
    if (!containersClassNames || containersClassNames.length === 0) return

    let zoomUITimer: ReturnType<typeof setTimeout> | undefined = undefined

    zoomUITimer = setTimeout(() => {
      zoomUI({
        desiredWidth,
      })
    }, 1000)

    const handleResize = () => {
      zoomUI({
        desiredWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(zoomUITimer)
    }
  }, [containersClassNames, desiredWidth])
}

export default useZoomUI