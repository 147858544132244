import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { trendsToBoard } from '../../../utils/trends'
import { TimeResponse } from '../entities/common.entity'
import { BoardConfig, GameConfig, LastGameDetail, WinnerItemnew } from '../entities/game.entity'
import { BetType } from '../entities/order.entity'
import { BaseGetManyResponse } from '../entities/response.entity'
import { GameService } from '../services/game.service'
import { createThunk } from './common'

export interface GameState {
  gameId: string
  config: GameConfig
  lastGame?: LastGameDetail
  diffTime: number
  trends: BetType[]
  winners: WinnerItemnew[]
  boardConfig: BoardConfig
}

const initialState: GameState = {
  gameId: 'H_BS',
  config: {
    winRate: 1.98,
    amountStep: 1,
    maxAmount: 200000,
    minAmount: 1,
    roundTime: 30,
    betAmounts: '10,50,100,200,500,1000',
  },
  trends: [],
  diffTime: 0,
  winners: [
    {
      gameId: 'H_BS',
      topWinners: [],
    },
    {
      gameId: 'H_OE',
      topWinners: [],
    },
    {
      gameId: 'H_BP',
      topWinners: [],
    },
    {
      gameId: 'H_LUCKY',
      topWinners: [],
    },
    {
      gameId: 'H_NIUNIU',
      topWinners: [],
    },
  ],
  boardConfig: {
    columns: 14,
    rows: 6,
  },
}

export const getTrends = createThunk<BaseGetManyResponse<BetType>, void>('game/getTrends', async (_, store) => {
  const gameId = selectGameId(store.getState() as RootState)
  return GameService.getTrends(gameId)
})

export const getGameConfigs = createThunk<GameConfig, void>('game/getGameConfigs', async (_, store) => {
  const gameId = selectGameId(store.getState() as RootState)
  const response = await GameService.getConfig(gameId)
  return response
})

export const getServerTime = createThunk<TimeResponse, void>('game/getServerTime', async (_, store) => {
  return GameService.getServerTime()
})

declare type SyncServerTimeResp = Awaited<ReturnType<typeof GameService.getServerTime>>

export const syncServerTime = createThunk('orders/syncServerTime', async (_: void, thunkApi) => {
  let result = await GameService.getServerTime()
  thunkApi.dispatch(gameSlice.actions.diffServerTimeUpdated(result))

  for (let i = 0; i < 3; i++) {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const res = await GameService.getServerTime()
    if (res.latency < result.latency) result = res
  }
  return result
})

interface LastGameUpdated extends LastGameDetail {
  isRetain?: boolean
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameId: (state, action: PayloadAction<string>) => {
      state.gameId = action.payload
    },
    lastGameUpdated: (state, action: PayloadAction<LastGameUpdated>) => {
      state.lastGame = action.payload
      const lastResult = action.payload.last.result
      const isRetain = action.payload.isRetain
      if (lastResult && !isRetain) state.trends.push(lastResult)
    },
    // topWinnerUpdated: (state, action: PayloadAction<any>) => {
    //   const roundTime = state.config.roundTime // time game
    //   const defaultWinner = {
    //     amount: '0',
    //     winAmount: '0',
    //     total: 1,
    //     userId: '50bce9ae-31d9-420b-8908-c32afa7690fb',
    //     bet: 'SMALL',
    //     gameID: '',
    //   }

    //   const newWinners = action.payload.winners.map((winner) => ({
    //     ...winner,
    //     bet: action.payload.game.result,
    //     gameID: action.payload.game.gameId,
    //   }))

    //   // Gộp các winner mới vào state hiện tại
    //   const existingWinners = state.winners || []

    //   // Gộp winner mới với cũ mà không bị trùng lặp
    //   const updatedWinners = [...existingWinners, ...newWinners].reduce((acc, current) => {
    //     const x = acc.find((item) => item.userId === current.userId)
    //     if (!x) {
    //       return acc.concat([current])
    //     } else if (Number(current.winAmount) > Number(x.winAmount)) {
    //       // Nếu có trùng userId và winAmount lớn hơn, thay thế
    //       return acc.map((item) => (item.userId === x.userId ? current : item))
    //     } else {
    //       return acc // Giữ lại winner cũ
    //     }
    //   }, [])

    //   // Sắp xếp danh sách winners theo winAmount giảm dần
    //   state.winners = updatedWinners.sort((a, b) => Number(b.winAmount) - Number(a.winAmount))
    // },

    topWinnerUpdated: (state, action: PayloadAction<any>) => {
      const { game, winners } = action.payload
      const { gameId, result } = game

      // Cập nhật state.winners
      const updatedWinners = state.winners.map((winner) => {
        // Nếu gameId trùng với gameId trong payload, cập nhật topWinners
        if (winner.gameId === gameId) {
          return {
            ...winner,
            topWinners: winners.map((w) => ({
              userId: w.userId,
              amount: w.amount,
              winAmount: w.winAmount,
              total: w.total,
              bet: result,
              gameId: gameId,
              currency: w.currency,
            })),
          }
        }
        // Nếu không trùng gameId, giữ lại dữ liệu cũ
        return winner
      })

      // Tìm kiếm phần tử với gameId trùng và tách nó ra
      const updatedWinner = updatedWinners.find((winner) => winner.gameId === gameId)

      // Nếu tìm thấy, loại bỏ phần tử đó khỏi mảng
      const filteredWinners = updatedWinners.filter((winner) => winner.gameId !== gameId)

      // Đưa phần tử đã cập nhật lên đầu mảng
      state.winners = [updatedWinner, ...filteredWinners]
    },

    updateConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload
    },
    setBoardConfig: (state, action: PayloadAction<BoardConfig>) => {
      state.boardConfig = action.payload
    },
    diffServerTimeUpdated: (state, action: PayloadAction<SyncServerTimeResp>) => {
      state.diffTime = action.payload.diff
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrends.fulfilled, (state, action) => {
        state.trends = action.payload.data
      })
      .addCase(getServerTime.fulfilled, (state, action) => {
        const { diff } = action.payload
        state.diffTime = diff || 0
      })
      .addCase(getGameConfigs.fulfilled, (state, action) => {
        state.config = action.payload
      })
      .addCase(getGameConfigs.rejected, (state, action) => {
        console.error('Failed to fetch game configs:', action)
      })
  },
})
export const selectCurrentLotteryResultHash = (state: RootState) => state.game.lastGame?.current.lotteryResultHash
export const selectLastLotteryHash = (state: RootState) => state.game.lastGame?.last.lotteryHash
export const selectLastResult = (state: RootState) => state.game.lastGame?.last.result
export const selectAmountResultAround = (state: RootState) => state.game.lastGame?.current.win_amount
export const selectLastGame = (state: RootState) => state.game.lastGame?.last
export const selectGameEndTime = (state: RootState) => state.game.lastGame?.current.endTime
export const selectGameStartTime = (state: RootState) => state.game.lastGame?.current.startTime
export const selectDiffTime = (state: RootState) => state.game.diffTime
export const selectGameId = (state: RootState) => state.game.gameId
export const selectTotalGameTime = (state: RootState) => {
  const last = state.game.lastGame?.last
  if (!last) return 30
  return Math.round((last.endTime - last.startTime) / 1000)
}
export const selectGameTrends = (state: RootState) => state.game.trends
export const selectGameConfigs = (state: RootState) => state.game.config
export const selectTopWinner = (state: RootState) => state.game.winners
export const selectBoardConfig = (state: RootState) => state.game.boardConfig

const _selectTrendsBoard = createSelector([selectBoardConfig, selectGameTrends], (config, trends) => {
  const { columns, rows } = config
  return trendsToBoard(trends, columns, rows)
})
export function selectTrendsBoard(state: RootState) {
  return _selectTrendsBoard(state)
}

export const gameActions = {
  ...gameSlice.actions,
  getTrends,
  getServerTime,
  getGameConfigs,
}

export default gameSlice
