import React from 'react'
import { Image, ImageProps } from '@chakra-ui/react'
import { getSymbolFromCurrency } from '@/const/currency'
import { useAppSelector } from '@/redux/store'
import { selectWallet } from '@/redux/store/modules/wallet.slice'

type RocketIconCurrencyProps = ImageProps

const RocketIconCurrency = ({ ...rest }: RocketIconCurrencyProps) => {
  const wallet = useAppSelector(selectWallet)
  return (
    // <Image
    //   src="/images/rockets/icon-dollar-coin.png"
    //   width="17.5px"
    //   minWidth="17.5px"
    //   aspectRatio="1/1"
    //   alignSelf="center"
    //   {...rest}
    // />
    <Image
      src={getSymbolFromCurrency(wallet.currency)}
      width="17.5px"
      minWidth="17.5px"
      aspectRatio="1/1"
      alignSelf="center"
      {...rest}
    />
  )
}

export default RocketIconCurrency
