// Function to shuffle and pick random numbers
import { bingoGroups, bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { BingoGroup } from '@/bingo/types/bingo.ts'
import { bingoConfig } from './bingoConfigs'

const getRandomNumbers = (array: number[], count: number): number[] => {
  const shuffled = [...array].sort(() => Math.random() - 0.5) // Shuffle the array
  return shuffled.slice(0, count) // Pick the first `count` numbers
}

// Function to generate a flattened bingo card
export const generateBingoCard = (): number[] => {
  const bingoCard: number[] = []
  bingoGroups.forEach((col, j) => {
    const randomNumbers = getRandomNumbers(bingoNumbers[col], bingoConfig.ticket.rows) // Get 3 random numbers for the column
    randomNumbers.forEach((val, i) => {
      bingoCard[i * bingoConfig.ticket.cols + j] = val
    })
  })

  return bingoCard
}

export const convertToRowWiseCard = (input?: number[]): number[] => {
  if (!input || input.length === 0) return []

  const numRows = bingoConfig.ticket.rows
  const numCols = bingoConfig.ticket.cols

  const rowWiseCard: number[] = []

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      rowWiseCard.push(input[col * numRows + row])
    }
  }

  return rowWiseCard
}

export function getTicketCol(ticket: number[], col: number) {
  const result: number[] = []
  for (let i = 0; i < bingoConfig.ticket.rows; i++) {
    result.push(ticket[i * bingoConfig.ticket.cols + col])
  }
  return result
}

export const getBingoGroupByNumber = (value?: number): BingoGroup => {
  if (!value || value <= 0) return 'B'

  switch (true) {
    case value <= 12:
      return 'B'
    case value <= 24:
      return 'I'
    case value <= 36:
      return 'N'
    case value <= 48:
      return 'G'
    case value <= 60:
      return 'O'
    default:
      return 'B'
  }
}
