import { Button, Input, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useState } from 'react'
import Decimal from 'decimal.js'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { NormalParagraph } from '@/components/hash-game/rule-game/RuleGameContainer'

const STAKE_E = 4000000000
const text = `<pre style="font-size: 12px; background: #2c2d30; padding: 8px; overflow: auto;">
package main

import (
    "encoding/hex"
    "fmt"
    "math"

    "github.com/shopspring/decimal"
)

func main() {
    fmt.Println("result: ", ConvertRandomValueToLotteryResult("Hash Value"))
}

func ConvertRandomValueToLotteryResult(randomValue string) decimal.Decimal {
    randomness, err := hex.DecodeString(randomValue)
    if err != nil {
        return decimal.Zero
    }
    randomInt := uint64(0)
    for i := 0; i < 8; i++ {
        randomInt |= uint64(randomness[i]) << (8 * i)
    }
    x := float64(randomInt) / float64(math.MaxUint64)
    outcome := math.Min(1e6, math.Max(1.0, math.Floor(100.0*(1.0-0.05)/x)/100.0))
    return decimal.NewFromFloat(outcome)
}
</pre>
`
export default function Fairness({ randomValue, isMenu = false }: { randomValue: string; isMenu?: boolean }) {
  const [val, setVal] = useState<string>(randomValue)
  const [result, setResult] = useState<string>('')
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  const handleClickBtn = () => {
    const result = convertRandomValueToLotteryResult(val)
    setResult(result)
  }

  // Custom hex-to-Uint8Array converter
  function hexToBytes(hex: string): Uint8Array {
    if (hex.length % 2 !== 0) {
      throw new Error('Invalid hex string')
    }
    const bytes = new Uint8Array(hex.length / 2)
    for (let i = 0; i < hex.length; i += 2) {
      bytes[i / 2] = parseInt(hex.substr(i, 2), 16)
    }
    return bytes
  }

  function convertRandomValueToLotteryResult(randomValue: string): string {
    // Decode the hex string into a buffer
    const randomness = hexToBytes(randomValue)

    if (randomness.length < 8) {
      console.error('Error decoding hex: input too short')
      return new Decimal(0).toString()
    }

    // Convert the first 8 bytes to a uint64 value
    let randomInt = BigInt(0)
    for (let i = 0; i < 8; i++) {
      randomInt |= BigInt(randomness[i]) << (BigInt(8) * BigInt(i))
    }

    // Calculate x as a float
    const maxUint64 = BigInt('0xFFFFFFFFFFFFFFFF')
    const x = Number(randomInt) / Number(maxUint64)

    // Calculate the outcome
    const outcome = Math.min(1e6, Math.max(1.0, Math.floor((100.0 * (1.0 - 0.05)) / x) / 100.0))

    // Return the outcome as a Decimal
    return new Decimal(outcome).toString()
  }

  // function convertRandomValueToLotteryResult(randomValue: string): string {
  //   const value = new Decimal(parseInt(randomValue.slice(0, 8), 16))
  //   if (value.isNaN()) {
  //     return new Decimal(0).toString()
  //   }
  //   if (value.modulo(100).equals(0)) {
  //     return new Decimal(1.0).toString()
  //   }
  //   const bigValue = value.plus(1)

  //   // const stakeE = new Decimal(STAKE_E);
  //   const stakeE = new Decimal(STAKE_E)
  //   const percentage = new Decimal(0.99)
  //   const result = stakeE.times(percentage).dividedBy(bigValue).toDecimalPlaces(2, Decimal.ROUND_DOWN)

  //   if (result.lessThan(1.0)) {
  //     return new Decimal(1.0).toString()
  //   }

  //   return Decimal.min(result, new Decimal(1000.0)).toString()
  // }

  return (
    <Stack w="100%" p={4} color="#fff" mb={4} overflowY="auto" className="newgame-container">
      {!isMenu && (
        <>
          <Text>{t('rocket.fairness.title1')}</Text>
          <Input
            value={val}
            placeholder="Please enter the game hash value"
            onChange={(e) => {
              setVal(e.target.value)
              setResult('')
            }}
          ></Input>
          <Button
            aria-label="buttonVerifyHash"
            aria-labelledby="buttonVerifyHash"
            w="100%"
            backgroundColor="#c7fb14"
            borderRadius="8px"
            mt="8px"
            py="6px"
            onClick={handleClickBtn}
            isDisabled={!val || val.length !== 64}
          >
            {'Verify'}
          </Button>
          <Table
            borderRadius="6px"
            overflow="hidden"
            // border="1px solid #000"
            mt={2}
            sx={{
              th: {
                background: '#c7fb14',
                // color: '#508290',
                fontSize: '12px',
              },
              'th, td': {
                border: '0.5px solid #57575745',
                padding: '5px 10px',
                textAlign: 'center',
              },
              td: {
                // background: '#272f3f66',
                // color: '#98acd0',
                fontSize: '14px',
              },
            }}
          >
            <Thead>
              <Tr>
                <Th>{t('rocket.fairness.gameHash')}</Th>
                <Th>{t('result')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td wordBreak="break-all">{val}</Td>
                <Td>{result}</Td>
              </Tr>
            </Tbody>
          </Table>
        </>
      )}
      <Text fontSize={18} fontWeight={500}>
        {t('rocket.fairness.title')}
      </Text>
      <NormalParagraph>
        {t(gameId)} {t('rocket.fairness.paragraph1')}
      </NormalParagraph>
      <NormalParagraph>
        {' '}
        {t('rocket.fairness.paragraph2', {
          gameId: t(gameId),
        })}
      </NormalParagraph>
      <ul style={{ listStyleType: 'disc', marginLeft: '32px', fontSize: 14 }}>
        <li> {t('rocket.fairness.paragraph3')}</li>
        <li>{t('rocket.fairness.paragraph4')}</li>
        <li>{t('rocket.fairness.paragraph5')}</li>
      </ul>
      <NormalParagraph>{t('rocket.fairness.paragraph6')}</NormalParagraph>
      <NormalParagraph>{t('rocket.fairness.paragraph7')}</NormalParagraph>
      <NormalParagraph>{t('rocket.fairness.paragraph8')}</NormalParagraph>
      <NormalParagraph>{t('rocket.fairness.paragraph9')}</NormalParagraph>
      <div dangerouslySetInnerHTML={{ __html: text }} className="pb-8"></div>
    </Stack>
  )
}
