import { Flex, Stack } from '@chakra-ui/react'
import React from 'react';
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx';
import TicketList from '@/bingo/components/FormSection/OrderConfirmation/TicketList.tsx'
import { useAppSelector } from '@store'
import { selectBingoTicketsGenerated } from '@/bingo/redux/bingo.slice.ts'
import ResultRow from '@/bingo/components/FormSection/Mining/ResultRow.tsx'

const Mining = () => {
  const generatedTickets = useAppSelector(selectBingoTicketsGenerated)

  return (
    <FormContainer
      backgroundProps={{
        background: 'none',
        border: 'none',
      }}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        rowGap="10px"
        flexWrap="wrap"
        padding="0 22px"
      >
        <ResultRow
          value="2,594.00"
          isPossible
        />
        <ResultRow
          value="22.00"
          balls={[4, 5, 16, 34, 55]}
        />
        <ResultRow
          value="40.00"
          balls={[16, 27, 29, 60]}
        />
        <ResultRow
          value="60.00"
          balls={[4, 5, 9, 60]}
        />
        <ResultRow
          value="60.00"
          balls={[26]}
        />
      </Flex>

      <Stack
        padding="15px"
        position="relative"
        flex="1"
        gap="0"
        overflow="hidden"
      >
        <TicketList
          data={generatedTickets}
        />
      </Stack>
    </FormContainer>
  )
}

export default Mining;
