import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

type BallNumberProps = {
  value: string | number
}

const BallNumber = ({ value, ...rest }: BallNumberProps & TextProps) => {
  return (
    <Text
      fontSize="20px"
      lineHeight="1"
      fontWeight="600"
      textAlign="center"
      margin="0"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      userSelect="none"
      {...rest}
    >
      {value}
    </Text>
  )
}

export default BallNumber
