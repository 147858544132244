import AuthGame from '@/bingo/components/AuthGame'
import FormSection from '@/bingo/components/FormSection'
import GameplayStage from '@/bingo/components/GameplayStage'
import BallSubscription from '@/bingo/components/Subcription/BallsSubscription'
import ConfigRoundSubscription from '@/bingo/components/Subcription/ConfigRoundSubscription'
import EndRoundSubscription from '@/bingo/components/Subcription/EndRoundSubscription'
import GameRoundSubscription from '@/bingo/components/Subcription/GameRoundSubscription'
import NewRoundSubscription from '@/bingo/components/Subcription/NewRoundSubscription'
import PlayingOrderSubscription from '@/bingo/components/Subcription/PlayingOrderSubscription'
import Breadcrumb from '@/components/hash-game/Breadcrumb'
// import useMobileViewport from '@/hooks/useMobileViewport'
import { BingoSounds } from '@/bingo/components/BingoSounds'
import PopupLastRound from '@/bingo/components/Common/PopupLastRound.tsx'
import LastOrderSubscription from '@/bingo/components/Subcription/LastOrderSubscription.tsx'
import { useSyncServerTime } from '@/hooks/useSyncServerTime'
import { Box, Stack } from '@chakra-ui/react'
import useZoomUI from '@hooks/useZoomUI.ts'
import React from 'react'

export default function MainGameBingo() {
  // useMobileViewport()
  useZoomUI({
    containersClassNames: ['zoom-container'],
  })

  useSyncServerTime()

  return (
    <AuthGame>
      <GameRoundSubscription />
      <NewRoundSubscription />
      <ConfigRoundSubscription />
      <LastOrderSubscription />
      <PlayingOrderSubscription />
      <Stack
        className="zoom-container"
        minHeight="100dvh"
        gap="0"
        sx={{
          '*': {
            fontSynthesis: 'weight style small-caps !important',
          },
        }}
      >
        <BallSubscription />
        <EndRoundSubscription />
        <Breadcrumb />
        <Box height={3} />
        <GameplayStage />
        <FormSection />
        <BingoSounds />
        <PopupLastRound />
      </Stack>
    </AuthGame>
  )
}
