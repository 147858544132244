import React from 'react'
import RocketTable, {
  CellNull,
  CellText,
  renderPlayerCell,
  RocketTableCell,
  RocketTableData,
  RocketTableRow,
} from '@components/rocket/NewUI/RocketTables/RocketTable.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { useAppSelector } from '@store'
import { selectListPasts } from '@store/modules/rocketTshow.ts'
import { Flex, Image } from '@chakra-ui/react'
import PointChip from '@components/rocket/GamePlay/Stats/PointChip.tsx'
import { CopyBtn } from '@components/common/CopyBtn.tsx'
import { f } from 'fintech-number'

const renderExplosionAltitudeCell = (value?: string | number): RocketTableCell => {
  if (!value) return {
    value: <CellNull />,
  }

  return {
    value: (
      <PointChip
        showBorder
        padding="2px 6px"
        point={Number(value)}
        showBg={false}
      />
    ),
    padding: '6px',
  }
}

const renderEscapeCell = (t: (key: string, options?: any) => string, value?: string | number): RocketTableCell => {
  const valueNumbered = Number(value)

  if (valueNumbered >= 1) return {
    value: <CellText>{valueNumbered}x</CellText>,
    className: 'isGreen',
  }

  if (valueNumbered === 0) return {
    value: <CellText>{t('rocket.tables.notEscaped')}</CellText>,
    className: 'isRed',
  }

  return {
    value: <CellNull />,
  }
}

const renderHashCell = (value?: string): RocketTableCell => {
  if (!value) return {
    value: <CellNull />,
  }

  return {
    value: (
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        gap="5px"
      >
        <CellText>...{value.substring(value.length - 8)}</CellText>
        <CopyBtn
          text={value}
          customIcon={(
            <Image
              src="/images/rockets/icon-copy.webp"
              width="15px"
              minWidth="15px"
              height="15px"
            />
          )}
          checkedIconProps={{
            size: '11px',
          }}
        />
      </Flex>
    ),
  }
}

const renderAverageRow = (listPasts: any[], t: (key: string, options?: any) => string): RocketTableRow => {
  if (!listPasts.length) return {
    cells: []
  }

  const escapeData = listPasts
    .filter((record) => !isNaN(record?.extreme_multiplier) && record?.extreme_multiplier !== '0')
    .map(record => Number(record?.extreme_multiplier))

  const average = escapeData
    ?.reduce((sum, num) => sum + num, 0) / escapeData?.length

  // const closest = escapeData
  //   ?.reduce((prev, curr) =>
  //       Math.abs(curr - average) < Math.abs(prev - average) ? curr : prev,
  //     0,
  //   )

  return {
    rowProps: {
      position: 'sticky',
      bottom: '0',
      zIndex: '5',
      background: 'rgba(26, 33, 42, 0.7)',
      backdropFilter: 'blur(10px)',
    },
    cells: [
      {
        value: <CellText whiteSpace="pre-line" lineHeight="1.2">{t('rocket.tables.average20')}：</CellText>,
        colSpan: 2,
        textAlign: 'left',
        width: '59px',
        maxWidth: '59px'
      },
      { value: average ? <CellText>{f(average, { decimal: 2 })}x</CellText> : <CellNull /> },
      { value: '' },
    ]
  }
}

const TableBreakingPoints = () => {
  const { t } = useCustomTranslation()
  const listPasts = useAppSelector(selectListPasts)

  const listPastsNotNull = [...listPasts].filter(Boolean)
  const listPastsUnique = [...new Map(
    listPastsNotNull.map((item) => [item.random_value, item])
  ).values()]

  const breakingPointsRows = listPastsUnique?.map((item): RocketTableRow => {
    return {
      cells: [
        renderExplosionAltitudeCell(item?.multiplier),
        renderPlayerCell(item?.ultimate_player),
        renderEscapeCell(t, item?.extreme_multiplier),
        renderHashCell(item?.random_value),
      ]
    }
  })

  const averageRow = renderAverageRow(listPastsUnique, t)

  if (breakingPointsRows?.length > 0) {
    breakingPointsRows.push(averageRow)
  }

  const breakingPointData: RocketTableData = {
    columns: ['rocket.tables.explosionAltitude', 'rocket.tables.escapePlayer', 'rocket.tables.escapeHeight', 'rocket.tables.explosionPointHash'],
    rows: breakingPointsRows,
  }

  return (
    <RocketTable
      columns={breakingPointData.columns}
      rows={breakingPointData.rows}
      tableContainerProps={{
        maxHeight: '498px',
        overflowY: 'auto',
        position: 'relative',
      }}
      theadProps={{
        position: 'sticky',
        top: '0',
        zIndex: 1,
      }}
      tdProps={{
        padding: '13px 16px',
      }}
    />
  )
}

export default TableBreakingPoints
