import { preventBingoBall } from '@/bingo/hooks/useBingoGamePlay'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { Topics } from '@/redux/store/common/topic'
import { BingoBall } from '@/redux/store/entities/game.entity'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

let currentIndex = 0
export default function BallSubscription() {
  const game_id = useGameId()

  const _message = useSubscription(Topics.bingoBalls(game_id))
  const message = _message?.message

  useEffect(() => {
    if (!message?.message) return
    const retain = _message.message?.config?.retain
    try {
      const data = JSON.parse(message.message.toString() || '') as BingoBall
      if (data.index == currentIndex) return
      currentIndex = data.index
      const numbers = data.numbers

      if (!isEmpty(numbers)) {
        preventBingoBall(numbers, retain)
      }
    } catch (error) {
      console.warn('ConfigRoundSubscription error: ', error)
    }
  }, [message])

  return <></>
}
