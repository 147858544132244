import React, { useEffect } from 'react'
import { GameStateEnum } from './hooks/useRocketGamePlay'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Flex, Image } from '@chakra-ui/react'
import { rocketTshowActions, selectMultiplier } from '@/redux/store/modules/rocketTshow'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

export const MultiplierDisplay = ({
  multiplier,
  gameState,
}: {
  multiplier: string
  gameState: GameStateEnum
  isHidden?: boolean
}) => {
  const { t } = useCustomTranslation()
  const dispatch = useAppDispatch()

  const roundMultiplier = useAppSelector(selectMultiplier)
  const _multiplier = roundMultiplier.m ?? multiplier
  const multiplierDisplay = _multiplier ? parseFloat(_multiplier).toFixed(2) : ''

  useEffect(() => {
    if (gameState === 'REWARD') {
      dispatch(rocketTshowActions.updateListCurrentLives([]))
    }
  }, [gameState])

  return (
    <Box
      className="absolute left-[48%] transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center zoom-container z-10 pointer-events-none"
      top={gameState === 'REWARD' ? '212px' : '278px'}
    >
      <Box
        className={`relative flex flex-col items-center justify-center ${
          gameState === 'REWARD' ? 'multiplier-box' : ''
        }`}
        // className="relative flex flex-col items-center justify-center multiplier-box"
      >
        <Image
          src="/images/rockets/rocket-end.webp"
          aspectRatio="604/373"
          position="absolute"
          top="96px"
          right="-87px"
          width="382px"
          zIndex="1"
          opacity={gameState !== 'REWARD' ? 0 : 1}
        />
        <Flex
          className="text-[62px] italic tracking-wider multiplier-text select-none"
          marginTop="2px"
          alignItems="flex-end"
          lineHeight="1"
          position="relative"
          zIndex="3"
          sx={{
            // '-webkit-text-stroke': '0.7px #000000',
            textShadow: '1px 3px 8px black, -0.5px -0.5px 1px black, 0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px  0.5px 1px black, -0.5px  0px 1px black, 0.5px  0px 1px black, 0px -0.5px 1px black, 0px  0.5px 1px black, -0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px -0.5px 1px black, 0.5px  0.5px 1px black, -0.5px -0.5px 1px black, 0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px  0.5px 1px black',
          }}
        >
          <RocketText {...NotoSansFontFamily.bold}>{multiplierDisplay}</RocketText>
          <RocketText fontSize="49px" {...NotoSansFontFamily.bold}>x</RocketText>
        </Flex>
        <Box
          className="text-[26px] italic tracking-wide mt-[-2px] multiplier-label select-none"
          position="relative"
          zIndex="3"
          {...NotoSansFontFamily.medium}
        >
          {gameState === 'PLAYING' ? (
            <RocketText color="#a2afc8" fontSize="19px" marginTop="4px">
              {t('rocket.flightAltitude')}
            </RocketText>
          ) : (
            <RocketText
              {...NotoSansFontFamily.bold}
              color="#fff000"
              sx={{
                // '-webkit-text-stroke': '0.7px #000000',
                textShadow: '1px 3px 8px black, -0.5px -0.5px 1px black, 0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px  0.5px 1px black, -0.5px  0px 1px black, 0.5px  0px 1px black, 0px -0.5px 1px black, 0px  0.5px 1px black, -0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px -0.5px 1px black, 0.5px  0.5px 1px black, -0.5px -0.5px 1px black, 0.5px -0.5px 1px black, -0.5px  0.5px 1px black, 0.5px  0.5px 1px black',
              }}
            >
              {t('rocket.explosionAltitude')}
            </RocketText>
          )}
        </Box>
      </Box>
    </Box>
  )
}
