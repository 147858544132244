import { useAppDispatch } from '@/redux/store'
import { syncServerTime } from '@/redux/store/modules/game.slice'
import { useEffect } from 'react'

export function useSyncServerTime() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(syncServerTime())
  }, [])
}
