export const gameplayEn = `
<h1>Introduce:</h1>
<p style="font-size: 16px">Bingo is a popular and enduring lottery game around the world.</p>
<p style="font-size: 16px">Players can purchase lottery tickets in two ways: random or self-selected. Each lottery ticket contains 3 rows and 5 columns with a total of 15 numbered balls.</p>
<p style="font-size: 16px">In each draw, the first 30 prize ball numbers drawn are ordinary balls. If the number on the ticket matches the winning number, it will be marked on the ticket. When the pattern formed by the marked numbered balls on the lottery ticket is consistent with the winning pattern, the prize is won. When each lottery ticket matches multiple winning patterns, only the one with the highest bonus will be calculated.</p>
<br/>
<p style="font-size: 16px">After the normal balls are played, 10 additional balls will be played. If your lottery ticket has a chance to hit a higher prize or is about to win, you can choose whether to purchase additional balls. Each additional ball requires an additional purchase. The purchase amount will vary depending on factors such as your betting amount, betting multiplier, expected winning bonus, etc.</p>
<br/>
<p style="font-size: 16px">The winning patterns include the following 12 types:</p>
<img src="/bingo/images/gameplay/gameplay1.webp" alt="">
<h1 style="margin-top: 30px">How to play:</h1>
<p style="font-size: 16px">Players can first choose the lottery tickets to be purchased through two methods: randomly generated by the system or by their own choice:</p>
<img src="/bingo/images/gameplay/gameplay2.webp" alt="">
<p style="font-size: 16px">For lottery tickets that you are more optimistic about, you can add them to your favorites by collecting them. You can also create new lottery tickets in your favorites by creating them yourself:</p>
<img src="/bingo/images/gameplay/gameplay3.webp" alt="">
<p style="font-size: 16px">After confirming the number of lottery tickets purchased, you can adjust the betting multiple at the order confirmation (when calculating the bonus, the original bonus will be multiplied by the betting multiple). You can adjust the multiples of all lottery tickets uniformly at the bottom; you can also click on the lottery ticket to double a single lottery ticket.</p>
<br/>
<p style="font-size: 16px">Finally, click the Bet button to complete your bet:</p>
<img src="/bingo/images/gameplay/gameplay4.webp" alt="">
<p style="font-size: 16px">During the lottery drawing process, a reminder bar will be displayed on the lottery ticket. The tip bar displays the expected prize numbers and the corresponding bonuses that can be obtained. You can decide whether to purchase additional balls based on the bonuses you can receive:</p>
<img src="/bingo/images/gameplay/gameplay5.webp" alt="">
<h1 style="margin-top: 30px">Description:</h1>
<p style="font-size: 16px">During each game, we will publish the result hash before the player makes a judgment. Leverage hash fairness to ensure game fairness.</p>
<h1 style="margin-top: 30px">What is the lottery result hash?</h1>
<img src="/bingo/images/gameplay/gameplay6.webp" alt="">
<h1 style="margin-top: 30px">What is hash fairness?</h1>
<p style="font-size: 16px">Because <tag>SHA256</tag> Characteristics of the encryption algorithm: There is a strict one-to-one correspondence between the encryption result (lottery result hash) and the encrypted data (lottery result). For each lottery result hash, the corresponding pre-encrypted data is unique. There is no situation where two contents can get the same result hash, and if any character sequence is changed for the same content, the resulting hash will be completely different.</p>
<br/>
<p style="font-size: 16px">Therefore, it is equivalent to the lottery result being determined before the game starts and cannot be tampered with during the game, ensuring the fairness of the game.</p>
<h1 style="margin-top: 30px">Fairness:</h1>
<p style="font-size: 16px">1. Copy verification information</p>
<p style="font-size: 16px">Click on 'Verify Fairness' within the bet details and copy the verification information in the fairness verification pop-up.</p>
<br/>
<p style="font-size: 16px">2. Complete the SHA256 calculation on third-party platform</p>
<p style="font-size: 16px">You can search for 'SHA256 online encryption' on Google and choose any result. Alternatively, you can use the following link: <a href="https://emn178.github.io/online-tools/sha256.html">SHA256</a></p>
`
