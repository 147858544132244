import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
// import useMobileViewport from '@hooks/useMobileViewport.ts'

type RocketGameBackgroundProps = BoxProps

const RocketGameBackground = ({ children, ...rest }: RocketGameBackgroundProps) => {
  // useMobileViewport()

  return (
    <Box
      backgroundColor="#11151b"
      paddingBottom="42px"
      id="rocket-game-container"
      fontFamily="MiSans-normal, system-ui, sans-serif"
      sx={{
        userSelect: 'none',
        ...{
          '*': {
            fontSynthesis: 'weight style small-caps !important',
          },
        },
        ...rest?.sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default RocketGameBackground
