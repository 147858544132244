import React, { useState } from 'react'
import { Box, Flex, FlexProps, Stack, StackProps, Image } from '@chakra-ui/react'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { CrashOrder, TrenBallType } from '@/@generated/gql/graphql-hash'
import { getSymbolFromCurrency } from '@/const/currency'
import { f } from 'fintech-number'
import { useAppSelector } from '@/redux/store'
import { selectConfigRound, selectListActiveTrenBallOrders } from '@/redux/store/modules/rocketTshow'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type TrenballButtonVariant =
  | TrenBallType.None
  | TrenBallType.Blue
  | TrenBallType.Green
  | TrenBallType.Red
  | TrenBallType.Yellow

type TrenballButtonProps = StackProps & {
  title: string | React.ReactNode
  variant: string
  betAmount: number
  multiplier: number
  isSelected?: boolean
  isDisabled?: boolean
  currency?: string | null
}

type TrenballTabsProps = FlexProps & {
  setValue: any
  tren_ball_type: any
  isFormDisabled?: boolean
}

const colorByVariant: Record<TrenballButtonVariant, string> = {
  yellow: '#ff9600',
  red: '#d52b4f',
  green: '#27a052',
  blue: '#0a79df',
}

const TrenballButton = ({
  title,
  variant,
  betAmount,
  multiplier,
  isSelected,
  currency,
  isDisabled,
  ...rest
}: TrenballButtonProps) => {
  const { t } = useCustomTranslation()

  return (
    <Stack
      overflow="hidden"
      lineHeight="1"
      textAlign="center"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      color="#fefefe"
      boxShadow="0 6px 11px 0 rgba(19,24,31,0.48)"
      borderRadius="7.3px"
      width="calc(25% - 2.15px)"
      minWidth="calc(25% - 2.15px)"
      userSelect="none"
      gap="0"
      opacity={isDisabled ? 0.65 : 1}
      {...rest}
    >
      <Box
        padding="11px 8px 10px"
        borderWidth="2px"
        borderStyle="solid"
        borderColor={isSelected ? colorByVariant[variant] : 'transparent'}
        borderBottom="none"
        backgroundColor={isSelected ? '#212935' : '#323e51'}
        backgroundImage="url('/images/rockets/btn-trenball-inset.webp')"
        backgroundRepeat="no-repeat"
        backgroundSize="113px"
        backgroundPosition="-4px -2px"
        borderTopRadius="7.3px"
      >
        <RocketText {...NotoSansFontFamily.semiBold} fontStyle="italic" fontSize="22px" marginBottom="7px">
          {title}
        </RocketText>
        <Flex gap={1} justifyContent="center" alignItems="center">
          <RocketText color={isSelected ? '#bdf303' : '#fefefe'} {...NotoSansFontFamily.normal} fontSize="15px">
            {t('rocket.tabs.betAmountYuan', {
              amount: f(betAmount, {
                decimal: 2,
              }),
            })}
          </RocketText>
          {currency && <Image src={getSymbolFromCurrency(currency)} className="w-[14px] h-[14px] rounded-lg" />}
        </Flex>
      </Box>
      <Box background={colorByVariant[variant]} padding="3.3px 5.3px" fontSize="14px" flex="1">
        <RocketText as="span" {...NotoSansFontFamily.normal}>
          {t('rocket.tabs.payout')}
        </RocketText>
        <RocketText as="span" {...NotoSansFontFamily.semiBold} fontSize="16px" marginLeft="2px">
          {multiplier}
        </RocketText>
        <RocketText as="span" {...NotoSansFontFamily.normal}>
          x
        </RocketText>
      </Box>
    </Stack>
  )
}

const TrenballTabs = ({ isFormDisabled, setValue, tren_ball_type, ...rest }: TrenballTabsProps) => {
  const [selectedTrenball, setSelectedTrenball] = useState<TrenballButtonVariant>(tren_ball_type)
  const { t } = useCustomTranslation()
  const listActiveOrder = useAppSelector(selectListActiveTrenBallOrders)
  const config = useAppSelector(selectConfigRound)

  const handleTrenballButtonClick = (variant: TrenballButtonVariant) => {
    if (isFormDisabled) return
    setSelectedTrenball(variant)
    setValue('tren_ball_type', variant)
  }

  const getTrellBallItem = (list: CrashOrder[], type: any) => {
    const result = list?.filter((item: CrashOrder) => item.tren_ball_type === type)?.[0]
    if (result) return result
    return null
  }
  return (
    <Flex alignItems="stretch" gap="4.3px" {...rest}>
      <TrenballButton
        variant="yellow"
        title="Crash"
        currency={getTrellBallItem(listActiveOrder, TrenBallType.Yellow)?.currency}
        betAmount={getTrellBallItem(listActiveOrder, TrenBallType.Yellow)?.amount ?? 0}
        multiplier={config?.trenBall?.yellow ?? 15}
        isSelected={selectedTrenball === TrenBallType.Yellow}
        onClick={() => handleTrenballButtonClick(TrenBallType.Yellow)}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="red"
        title={t('rocket.tabs.red')}
        currency={getTrellBallItem(listActiveOrder, TrenBallType.Red)?.currency}
        betAmount={getTrellBallItem(listActiveOrder, TrenBallType.Red)?.amount ?? 0}
        multiplier={config?.trenBall?.red ?? 1.96}
        isSelected={selectedTrenball === TrenBallType.Red}
        onClick={() => handleTrenballButtonClick(TrenBallType.Red)}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="green"
        currency={getTrellBallItem(listActiveOrder, TrenBallType.Green)?.currency}
        betAmount={getTrellBallItem(listActiveOrder, TrenBallType.Green)?.amount ?? 0}
        title={t('rocket.tabs.green')}
        multiplier={config?.trenBall?.green ?? 2.0}
        isSelected={selectedTrenball === TrenBallType.Green}
        onClick={() => handleTrenballButtonClick(TrenBallType.Green)}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="blue"
        currency={getTrellBallItem(listActiveOrder, TrenBallType.Blue)?.currency}
        betAmount={getTrellBallItem(listActiveOrder, TrenBallType.Blue)?.amount ?? 0}
        title="Moon"
        multiplier={config?.trenBall?.blue ?? 10}
        isSelected={selectedTrenball === TrenBallType.Blue}
        onClick={() => handleTrenballButtonClick(TrenBallType.Blue)}
        isDisabled={isFormDisabled}
      />
    </Flex>
  )
}

export default TrenballTabs
