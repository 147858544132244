import React, { useRef, useState } from 'react'
import { Box, useDisclosure } from '@chakra-ui/react'
import RocketTable, {
  CellNull,
  CellText,
  renderBetAmountCell,
  renderRewardCell,
  RocketTableCell,
  RocketTableData,
  RocketTableRow,
} from '@components/rocket/NewUI/RocketTables/RocketTable.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { useAppSelector } from '@store'
import { useNavigate } from 'react-router-dom'
import { ROUND_STATE, selectGameRound, selectMyOrders } from '@store/modules/rocketTshow.ts'
import ListBetting from '@components/rocket/MyBets/ListBetting.tsx'
import { AppDrawer } from '@components/common/AppDrawer.tsx'
import dayjs from 'dayjs'
import { CrashOrder, TrenBallType } from '@/@generated/gql/graphql-hash.ts'
import { RocketBetDetailInfo } from '@components/rocket/MyBets/BetInfo.tsx'
import { f } from 'fintech-number'
import { GameRound } from '@/redux/store/entities/game.entity'
import MoneyCell from './MoneyCell'

const renderCreatedAtCell = (value?: string): RocketTableCell => {
  const isValidDate = dayjs(value).isValid()
  if (!value || !isValidDate)
    return {
      value: <CellNull />,
    }

  return {
    value: <CellText>{dayjs(value).format('MM/DD HH:mm')}</CellText>,
  }
}

const renderEscapeCell = (
  order: CrashOrder,
  gameRound: GameRound,
  t: (key: string, options?: any) => string,
): RocketTableCell => {
  if (order.tren_ball_type === TrenBallType.None) {
    const valueNumbered = f(order?.cash_out !== '0' ? order?.cash_out : order?.auto_cash_out, {
      decimal: 2,
    })

    const isRoundRunning = order.round_id === gameRound.round_id && gameRound.state !== ROUND_STATE.END
    const isWin = +order?.reward > 0

    if (isRoundRunning) {
      if (isWin)
        return {
          value: <CellText>{valueNumbered}x</CellText>,
          className: 'isGreen',
        }
      return {
        value: <CellNull />,
      }
    }

    if (isWin)
      return {
        value: <CellText>{valueNumbered}x</CellText>,
        className: 'isGreen',
      }
    else
      return {
        value: <CellText>{t('rocket.tables.notEscaped')}</CellText>,
        className: 'isRed',
      }
  } else {
    return {
      value: (
        <CellText>
          {order.tren_ball_type === TrenBallType.Yellow && 'Crash'}
          {order.tren_ball_type === TrenBallType.Green && t('rocket.tabs.green')}
          {order.tren_ball_type === TrenBallType.Red && t('rocket.tabs.red')}
          {order.tren_ball_type === TrenBallType.Blue && 'Moon'}
        </CellText>
      ),
      // className: order.tren_ball_type,
      className:
        +order.reward > 0
          ? 'isGreen'
          : gameRound?.state !== ROUND_STATE.END && gameRound.round_id === order.round_id
          ? ''
          : 'isRed',
    }
  }
}

export const renderRewardWinningCell = (order: any, gameRound: GameRound): RocketTableCell => {
  const defaultCell = {
    value: <CellNull />,
  }

  if (
    !order?.reward ||
    (order?.tren_ball_type !== TrenBallType.None &&
      gameRound.state !== ROUND_STATE.END &&
      gameRound.round_id === order?.round_id)
  )
    return defaultCell

  const valueNumbered = Number(order?.reward)

  if (valueNumbered > 0) {
    return {
      value: <MoneyCell value={f(valueNumbered, { decimal: 2 })} currency={order?.currency} />,
      className: 'isGreen',
    }
  }

  return {
    value: <MoneyCell value="0.00" currency={order?.currency} />,
  }
}

const TableMyBets = () => {
  const { t } = useCustomTranslation()
  const [popup, setPopup] = useState('')
  const selectedOrderRef = useRef<CrashOrder | null>(null)
  const navigate = useNavigate()
  const myOrders = useAppSelector(selectMyOrders)
  const gameRound = useAppSelector(selectGameRound)
  const drawerBetDetail = useDisclosure()

  const myOrdersRows: RocketTableRow[] = myOrders.map(
    (order): RocketTableRow => {
      const preventClick = order?.multiplier === '0' || !order?.multiplier
      return {
        rowProps: {
          onClick: () => {
            // if (isRoundRunning) return
            if(preventClick) return
            selectedOrderRef.current = order
            drawerBetDetail.onOpen()
          },
          cursor: preventClick ? 'not-allowed' : 'pointer',
        },
        cells: [
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          renderCreatedAtCell(order?.created_at || order?.CreatedAt),
          renderBetAmountCell(order?.amount, order?.currency),
          renderEscapeCell(order, gameRound, t),
          renderRewardWinningCell(order, gameRound),
        ],
      }
    },
  )

  const myBetsData: RocketTableData = {
    columns: [
      'rocket.tables.time',
      'rocket.tables.betAmount',
      'rocket.tables.escapeHeight',
      'rocket.tables.winningAmount',
    ],
    rows: myOrdersRows,
  }

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }

  const handleClickViewMore = () => {
    setPopup('BettingRecordRocket')
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('param', 'BettingRecordRocket')
    navigate(`?${searchParams.toString()}`, { replace: true })
  }

  // const getListOrder = () => {
  //   dispatch(
  //     getMyOrders({
  //       game_id: gameId,
  //       page: 0,
  //       limit: 10,
  //     }),
  //   )
  // }

  // useEffect(() => {
  //   getListOrder()
  // }, [])

  // useEffect(() => {
  //   if (gameRound?.state === ROUND_STATE.END) getListOrder()
  // }, [gameRound])

  return (
    <>
      <RocketTable
        columns={myBetsData.columns}
        rows={myBetsData.rows}
        tableContainerProps={{
          height: '455px',
          overflowY: 'auto',
          position: 'relative',
        }}
      />
      <RocketTable
        tbodyProps={{
          display: 'none',
        }}
        tableCaptionProps={{
          padding: '26.3px 21.3px',
          fontSize: '16px',
        }}
        tableCaption={
          myOrdersRows?.length > 0 && (
            <Box
              cursor="pointer"
              display="inline-block"
              userSelect="none"
              textDecoration="underline"
              onClick={handleClickViewMore}
              position="relative"
              top="-7px"
            >
              {t('rocket.tables.viewMoreBets')} {'>'}
            </Box>
          )
        }
      />
      {'BettingRecordRocket' === popup && (
        <AppDrawer
          title={t(`menu.bettingListing`)}
          isOpen={'BettingRecordRocket' === popup}
          onClose={closePopup}
          overlay={true}
        >
          <Box p={4}>
            <ListBetting noPaging={false} />
          </Box>
        </AppDrawer>
      )}
      {drawerBetDetail.isOpen && selectedOrderRef.current && (
        <AppDrawer
          title={t('titleDetailOrder')}
          isOpen={drawerBetDetail.isOpen}
          onClose={drawerBetDetail.onClose}
          overlay={true}
        >
          <RocketBetDetailInfo order={selectedOrderRef.current} />
        </AppDrawer>
      )}
    </>
  )
}

export default TableMyBets
