import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex, Link, Menu, MenuButton, MenuItem, MenuList, Stack, Text, Image, useDisclosure } from '@chakra-ui/react'
import React, { ReactNode, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Fairness from './Fairness'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

interface Option {
  value: string
  label: string
  content: ReactNode
  headingPopup?: string
  image: string
}

export default function SelectMenuPopupRocket({ options, icon }: { options: Option[]; icon: any }) {
  const { t } = useCustomTranslation()
  const [popup, setPopup] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [content, setContent] = useState<ReactNode>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [params] = useSearchParams()
  const lang = (params.get('lang') || 'en').toLowerCase()

  const openPopup = (popupName: string) => {
    setPopup(popupName)
  }

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }
  return (
    <Stack>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              role="button"
              aria-expanded="false"
              aria-haspopup="menu"
              aria-controls="menu-list-:rc:"
              mt={2}
              mr={1}
              background="#273038"
              as={Link}
              defaultValue="option1"
              alignItems="center"
              gap={0}
              className="flex items-center justify-center !w-[40px] !h-[24px] rounded-full"
            >
              {/* {icon} */}
              <Stack flexDirection="row" gap="5px" ml="6px">
                <div className="w-[6px] h-[6px] rounded bg-[#fff]"></div>
                <div className="w-[6px] h-[6px] rounded bg-[#fff]"></div>
                <div className="w-[6px] h-[6px] rounded bg-[#fff]"></div>
              </Stack>
            </MenuButton>
            <MenuList
              minWidth="10%"
              background="#1f2836"
              py={0}
              borderRadius="12px"
              overflow="hidden"
              borderWidth="0"
              // left="-50px"
              // transform="translateX(-50%)"
              mt="4px"
            >
              {options.map((option: Option, index: number) => (
                <MenuItem
                  fontSize={14}
                  py="20px"
                  pl="18px"
                  w={lang === 'en' ? '180px' : '178px'}
                  background="#1f2836"
                  key={option.value}
                  onClick={() => {
                    // if (option.value === 'GamePlay') {
                    //   onOpen()
                    //   return
                    // }
                    openPopup(option.value)
                    setContent(option.content)
                    const searchParams = new URLSearchParams(location.search)
                    searchParams.set('param', option.value)
                    navigate(`?${searchParams.toString()}`, { replace: true })
                  }}
                  borderBottom={index < options?.length - 1 ? '1px solid #2d3849' : ''}
                >
                  <Flex alignItems="center" gap={2}>
                    <Image src={option.image} w="22px" h={index === 0 ? '22px' : 'auto'} alt="" />
                    <RocketText color="#bacfeb" fontSize={16} {...NotoSansFontFamily.semiBold}>
                      {t(`menu.${option.label}`)}
                    </RocketText>
                  </Flex>
                </MenuItem>
              ))}
            </MenuList>
            {isOpen && (
              <Stack
                transform="rotate(45deg)"
                background="#1f2836"
                position="absolute"
                bottom="-22px"
                right="25px"
                w="22px"
                height="22px"
                borderRadius="3px"
                animation="fade-in 250ms ease-out"
              ></Stack>
            )}
          </>
        )}
      </Menu>
      <Stack>
        {options.map((option: Option) => (
          <AppDrawer
            key={option.value}
            title={option.headingPopup ? t(`menu.${option.headingPopup}`) : undefined}
            isOpen={option.value === popup}
            onClose={closePopup}
            overlay={true}
          >
            {content}
          </AppDrawer>
        ))}
      </Stack>
      {isOpen && (
        <AppDrawer title={t('menu.fairness')} isOpen={isOpen} onClose={onClose} overlay={true}>
          <Fairness isMenu={true} randomValue={''} />
        </AppDrawer>
      )}
    </Stack>
  )
}
