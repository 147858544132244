import { Center, Flex, Image, ListItem, Stack, Tag, TagProps, Text, TextProps, UnorderedList } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { MdHexagon } from 'react-icons/md'

export const HexagonHeading = ({ heading }: { heading: string }) => {
  return (
    <Flex>
      <MdHexagon
        color="c3fb04"
        style={{
          rotate: '20deg',
          display: 'block',
          float: 'left',
          marginTop: '7px',
          marginRight: 3,
          minWidth: '16px',
          minHeight: '16px',
          width: '16px',
          height: '16px',
        }}
      />
      <Text color="#fff" fontSize={20} fontWeight={700} pl={1}>
        {heading}
      </Text>
    </Flex>
  )
}

export const NormalParagraph = (props: TextProps) => {
  const { children } = props

  return (
    <Text color="#fff" fontSize={14} {...props}>
      {children}
    </Text>
  )
}

export const UnorderedTextList = ({ items }: { items: (string | ReactElement)[] }) => {
  return (
    <UnorderedList color="#fff" fontSize={14}>
      {items.map((item) => (
        <ListItem>{item}</ListItem>
      ))}
    </UnorderedList>
  )
}

export const NormalTag = (props: TagProps) => {
  const { children } = props

  return (
    <Tag size="md" variant="outline" color="#fff" {...props}>
      {children}
    </Tag>
  )
}

type RuleGameContainerProps = {
  title: string | React.ReactNode
  children: React.ReactNode
}

const RuleGameContainer = ({ title, children }: RuleGameContainerProps) => {
  return (
    <Stack height="80vh" gap={0}>
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        p={4}
        borderTopRadius={'12px'}
      >
        <Flex color="white" justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} fontSize={28}>
              {title}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" w={100} h={100} />
        </Flex>
      </Stack>
      <Stack padding={4} lineHeight={1.8} flex={1} overflow="auto">
        {children}
      </Stack>
    </Stack>
  )
}

export default RuleGameContainer
