import { HiloRound, RoundStatus } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import { CopyBtn } from '@/components/common/CopyBtn'
import { textGrayDrawer, textGrayWhite } from '@/const/color'
import { currency, currencyMap, getSymbolFromCurrency } from '@/const/currency'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Text, Flex, Stack, useDisclosure, Image } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { f } from 'fintech-number'
import React from 'react'
import CopyAll from '@/components/common/CopyAll'
import useGameId from '@/hooks/useGameId'
import { FlipItem } from '../gamePlay/FlipList'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { IoTimerOutline } from 'react-icons/io5'

type Props = {
  order: HiloRound
}

export default function BetInfo(props: Props) {
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const gameId = useGameId()
  const { order } = props
  const isWin = +order?.reward > 0
  const currencySupported = currencyMap[order?.currency]
  return (
    <Stack p={0}>
      <Box
        onClick={() => {
          if (order?.round_status !== RoundStatus.Running) {
            onOpen()
          }
        }}
        padding="10px 6px 3px 6px"
        backgroundColor="#232327"
        borderRadius={12}
      >
        <Stack>
          <Flex justifyContent="space-between" alignItems="center" px="5px">
            <Flex alignItems="center" gap={3}>
              {/* <RenderIconBet bet={order.bet} color={color} indexColor={indexColor} /> */}
              <Stack>
                <Text letterSpacing={1} fontSize={12} textColor={'#fff'} fontWeight={500}>
                  {t(gameId)}
                </Text>
                <Flex alignItems="flex-end" mt={-2} fontSize={12}>
                  <Flex gap={1} alignItems="flex-end" lineHeight={1}>
                    <Text fontWeight={300} color={textGrayDrawer}>
                      {t('betting')}:
                    </Text>
                    <Text color="white" fontSize="14px" mb="-1px">
                      {f(+order?.amount, {
                        decimal: currencySupported.decimal,
                      })}
                    </Text>
                    <Text fontSize={10} color={textGrayWhite} lineHeight={1}>
                      ({order?.currency})
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>
            <Stack alignItems="center">
              {order.round_status !== RoundStatus.Running ? (
                <>
                  <Flex fontWeight={600} alignItems="center" gap={1}>
                    <Text color={isWin ? '#B0FC00' : '#ff5959'} fontSize="18px">
                      {isWin ? '+' : '-'}
                      {isWin
                        ? f(order?.reward, {
                            decimal: currencySupported.decimal,
                          })
                        : f(order?.amount, {
                            decimal: currencySupported.decimal,
                          })}
                    </Text>
                    <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
                      {`(${order?.currency})`}
                    </Text>
                  </Flex>
                  <Text mt={-3} fontSize={12} color={textGrayDrawer}>
                    {isWin ? t(`win`) : t(`lose`)}
                  </Text>
                </>
              ) : (
                <>
                  {' '}
                  <IoTimerOutline fontSize={28} color={'#55555a'} />
                </>
              )}
            </Stack>
          </Flex>
          <hr style={{ borderColor: '#2d2d32' }} />
          <Flex fontSize="0.9rem" justifyContent="space-between" alignItems="center" mb="3px" px="5px">
            <Text fontSize={12} color={'#75757f'} fontWeight={400}>
              {dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
            {order?.round_status === RoundStatus.Win && (
              <Text fontSize={13} color={'#DFBB49'} fontWeight={400}>
                Odds:{' '}
                {f(order?.odds, {
                  decimal: 2,
                })}
                x
              </Text>
            )}
            {order?.round_status === RoundStatus.Lose && (
              <Text fontSize={13} color={'#DFBB49'} fontWeight={400}>
                Guessed wrong
              </Text>
            )}
          </Flex>
        </Stack>
      </Box>

      {isOpen && (
        <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
          <BetDetailInfo order={order} />
        </AppDrawer>
      )}
    </Stack>
  )
}

const BetDetailInfo = React.memo(
  ({ order }: { order: HiloRound }) => {
    const { t } = useCustomTranslation()
    const isWin = +order?.reward > 0
    const isRoundEnd = !!order?.random_value
    const currencySupported = currencyMap[order.currency]
    const gameId = useGameId()
    const formatOrderId = (orderId?: string) => {
      if (!orderId) return ''

      const formatOrderIdRegex = /^(.{8}).*(.{8})$/

      return orderId.replace(formatOrderIdRegex, '$1...$2')
      // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
    }

    return (
      <>
        <Box backgroundColor="#2f2f31">
          <Stack p={4} backgroundColor="#2f2f31" color={'#fff'} gap={0}>
            <Text fontWeight={600} fontSize="22px" mt={2}>
              {t(gameId)}
            </Text>
            <Flex gap={2} alignItems="center" lineHeight={1.2}>
              <Text fontSize={32} fontWeight={600} color={isWin ? '#B0FC00' : '#ff5959'}>
                {isWin ? (
                  <>{`+${f(+order?.reward, {
                    decimal: currencySupported.decimal,
                  })}`}</>
                ) : (
                  <>
                    {f(+order?.amount, {
                      decimal: currencySupported.decimal,
                    })}
                  </>
                )}
              </Text>
              <Image src={getSymbolFromCurrency(order?.currency)} className="w-6 h-6 rounded-lg" />
            </Flex>
            <Stack flexDirection="row" justifyContent="space-between" color={isWin ? '#B0FC00' : '#ff5959'}>
              <Text>{isWin ? 'Win' : 'Lose'}</Text>
              {isRoundEnd && <Text>{t('statusPayment')}</Text>}
            </Stack>
          </Stack>
          <Stack p={4} color={'#fff'} borderRadius="14px 14px 0 0" backgroundColor="#363639" gap="10px">
            <Stack>
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('game')}</Text>
                <Text fontWeight={400}>{t(gameId)}</Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betAmount')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>
                    {f(+order?.amount, {
                      decimal: currencySupported.decimal,
                    })}{' '}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>Odds</Text>
                <Text fontWeight={400} color="#ffffff">
                  {order?.odds}x
                  {/* {f(order.cash_out !== '0' ? order.cash_out : order.auto_cash_out, {
                  decimal: currencySupported.decimal,
                })} */}
                </Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" flexDirection="column" py={1}>
                <Text color={textGrayDrawer}>Guessing card history</Text>
                <Stack w="100%" h="112px" mt="8px" position="relative" background="#0b0d1d" borderRadius="24px">
                  <Image
                    src="/images/bg-flip-list.webp"
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="170px"
                  />
                  <Swiper
                    slidesPerView={7}
                    className={`h-full w-full !px-2 !py-2`}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                  >
                    {order?.result?.slice(0, order?.max_index).map((item: any, index: number) => (
                      <SwiperSlide key={index} className={`relative`}>
                        <FlipItem gameRound={order} item={item} index={index} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Stack>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}> Pay for Switch</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>
                    {f(order?.pay_for_switch, {
                      decimal: 2,
                    })}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>Treasure reward</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>
                    {order.treasures?.length! > 0
                      ? order.treasures?.reduce((accumulator, currentValue) => accumulator + currentValue)
                      : 0}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>Total reward</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400} color={isWin ? '#B0FC00' : '#ff5959'}>
                    {isWin ? (
                      <>{`+${f(+order?.reward, {
                        decimal: currencySupported.decimal,
                      })}`}</>
                    ) : (
                      <>
                        {f(+order?.amount, {
                          decimal: currencySupported.decimal,
                        })}
                      </>
                    )}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betTime')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}</Text>
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('orderId')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{formatOrderId(order?.id)}</Text>
                  <CopyBtn text={order?.id} />
                </Flex>
              </Flex>
            </Stack>
            {isRoundEnd && (
              <>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('hashValue')}</Text>
                      <CopyBtn text={order?.random_value} />
                    </Flex>
                    <Text fontWeight={400}>{order?.random_value}</Text>
                  </Stack>
                </Stack>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('serverSeed')}</Text>
                      <CopyBtn text={order?.server_seed} />
                    </Flex>
                    <Text fontWeight={400}>{order?.server_seed}</Text>
                  </Stack>
                </Stack>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('clientSeed')}</Text>
                      <CopyBtn text={order?.client_seed} />
                    </Flex>
                    <Text fontWeight={400}>{order?.client_seed}</Text>
                  </Stack>
                </Stack>
                <CopyAll
                  text="Copy verify information to SHA256"
                  cpyTxt={`${order?.random_value}${order?.server_seed}${order?.client_seed}`}
                  handeClick={() => {
                    window.open(
                      `https://emn178.github.io/online-tools/sha256.html?input=${order?.random_value}${order?.server_seed}${order?.client_seed}`,
                      '_blank',
                    )
                  }}
                />
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('proof')}</Text>
                      <CopyBtn text={order?.proof} />
                    </Flex>
                    <Text fontWeight={400}>{order?.proof}</Text>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Box>
      </>
    )
  },
  () => true,
)
