import { CrashOrder } from '@/@generated/gql/graphql-hash'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { getMyOrdersQuery, selectMyOrders, selectTotalOrdersRK } from '@/redux/store/modules/rocketTshow'
import { Box, Flex, Image, Spinner, Stack } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { useState } from 'react'
import BetInfo from './BetInfo'
import { useQuery } from '@apollo/client'

export default function ListBetting({ noPaging = false }: { noPaging: boolean }) {
  const { t } = useCustomTranslation()
  const limit = 10
  const [page, setPage] = useState(1)
  const game_id = useGameId()
  const totals = useAppSelector(selectTotalOrdersRK)
  const myOrdersHomePage = useAppSelector(selectMyOrders)
  const { data, loading } = useQuery(getMyOrdersQuery, {
    variables: {
      input: { game_id: game_id, page: page, limit: limit },
    },
    skip: page === 1
  })
  const myOrders = data?.crashOrdersHistory?.data

  const myOrdersRender = page === 1 ? myOrdersHomePage : myOrders

  const changePage = (page: number) => {
    setPage(page)
  }

  return (
    <>
      <Stack
        sx={{
          opacity: loading ? '0.3' : '1',
        }}
        minHeight="70vh"
        maxHeight="70vh"
      >
        {myOrdersRender?.length > 0 ? (
          myOrdersRender.map((order: CrashOrder, index: number) => <BetInfo order={order} key={index} />)
        ) : loading ? (
          <Spinner />
        ) : (
          <Flex
            w="100%"
            // height="200px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#3d3d43"
            gap={'11px'}
          >
            <Box>
              <Image w={'40px'} src="/images/norecord.webp" />
            </Box>
            <Box color={'#c1c1de'} fontSize={'13px'}>
              {t('notification.youHavenorecordsYet')}
            </Box>
          </Flex>
        )}
        {noPaging === false && (
          <Stack alignItems="center">
            {myOrdersRender?.length > 0 && (
              <Flex direction="row" align="center" justify="center" mt={4} pb={6}>
                <Pagination className="paging" onChange={changePage} current={page} total={totals} pageSize={limit} />
              </Flex>
            )}
          </Stack>
        )}
      </Stack>
    </>
  )
}
