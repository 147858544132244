import GroupColumn from '@/bingo/components/FormSection/EditTicket/CurrentTicket/GroupColumn.tsx'
import NumberCell from '@/bingo/components/FormSection/EditTicket/NumbersSelect/NumberCell.tsx'
import { bingoConfig } from '@/bingo/const/bingoConfigs'
import { bingoGroups, bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { BingoGroup } from '@/bingo/types/bingo.ts'
import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { keyBy } from 'lodash'
import React from 'react'

type NumbersSelectProps = BoxProps & {
  currentGroup: BingoGroup
  numbers: number[]
  onNumberChange: (numbers: number[]) => void
}

const mapGroup = {
  B: 0,
  I: 1,
  N: 2,
  G: 3,
  O: 4,
}

const NumbersSelect = ({ onNumberChange, currentGroup, numbers }: NumbersSelectProps) => {
  const check = keyBy(numbers)
  function onClick(value: number) {
    const active = !!check[value]
    if (active) {
      const newNumbers = numbers.map((x) => (x == value ? 0 : x))
      onNumberChange(newNumbers)
      return
    }
    const col = mapGroup[currentGroup]
    const newNumbers = [...numbers]
    for (let row = 0; row < bingoConfig.ticket.rows; row++) {
      const index = row * bingoConfig.ticket.cols + col
      if (!newNumbers[index]) {
        newNumbers[index] = value
        onNumberChange(newNumbers)
        return
      }
    }
  }

  return (
    <Box borderRadius="12px" overflow="hidden" width="250px">
      <Flex>
        {bingoGroups.map((group) => (
          <GroupColumn group={group} key={group} isActive={currentGroup === group} showData={false} />
        ))}
      </Flex>
      <Flex flexWrap="wrap" gap="2px">
        {bingoNumbers[currentGroup].map((bingoNumber) => (
          <NumberCell active={!!check[bingoNumber]} value={bingoNumber} onClick={() => onClick(bingoNumber)} />
        ))}
      </Flex>
    </Box>
  )
}

export default NumbersSelect
