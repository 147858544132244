const OLD_GAME_IDS = ['H_BS', 'H_BP', 'H_LUCKY', 'H_NIUNIU', 'H_OE']

export function updateViewport() {
  const path = window.location.pathname
  const gameIdMatch = path.split('/')
  const gameId = gameIdMatch ? gameIdMatch[1] : ''
  const viewportMeta = document.querySelector('meta[name="viewport"]')
  if (!viewportMeta) return

  if (!gameId || OLD_GAME_IDS.includes(gameId)) {
    viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no')
  } else {
    viewportMeta.setAttribute('content', 'width=515px, user-scalable=no')
  }
}
