import { BingoOrder } from '@/@generated/gql/graphql-hash'
import Decimal from 'decimal.js'
import { flatMap } from 'lodash'
import { bingoConfig } from '../const/bingoConfigs'
import { PatternBoardRate } from '../types/bingo'

interface Pattern {
  index: bigint
  multiplier: number
  pattern: PatternBoardRate
}

function flatPatterns(patterns: PatternBoardRate[], reverse = true): Pattern[] {
  const newPatterns = flatMap(patterns, (item) => {
    const { ex, pattern, rate } = item
    return (
      ex?.map((pattern) => ({
        index: patternToBigint(pattern, reverse),
        multiplier: rate,
        pattern: item,
      })) || [
        {
          index: patternToBigint(pattern, reverse),
          multiplier: rate,
          pattern: {
            ...item,
            pattern,
          },
        },
      ]
    )
  })

  return newPatterns
}

function patternToBigint(pattern: string, reverse: boolean) {
  let text = (pattern || '').replace(/o/g, '0').replace(/x/g, '1').replace(/[^01]/g, '')
  if (reverse) {
    text = text.split('').reverse().join('')
  }

  return BigInt(`0b${text}`)
}

interface ExtraBallPosition {
  pattern: PatternBoardRate
  ball: number
  index: number
}

export function getBingoExtraBallPosition(ticket: number[], balls: number[], _patterns: PatternBoardRate[]) {
  const patterns = flatPatterns(_patterns, false)

  const lotteryLookup = makeLookupForArr(balls)
  const numbers = reverseNewArray(ticket)
  const bitMark = toBitMask(numbers, lotteryLookup)

  const result: ExtraBallPosition[] = []

  for (let i = patterns.length - 1; i >= 0; i--) {
    const pattern = patterns[i]
    const tempBitMark = bitMark & pattern.index

    if (tempBitMark === pattern.index) {
      break
    }

    const diffs = countDifferentBits(tempBitMark, pattern.index)

    if (diffs.length == 1) {
      const i = bingoConfig.ticket.rows * bingoConfig.ticket.cols - diffs[0] - 1

      result.push({
        pattern: pattern.pattern,
        index: i,
        ball: ticket[i],
      })
    }
  }
  return result
}

export function getBingoExtraBallAmount(
  index: number,
  bet: BingoOrder,
  ticketUnitPrice: number,
  balls: number[],
  _patterns: PatternBoardRate[],
): Decimal {
  const patterns = flatPatterns(_patterns)
  let possible = new Decimal(0)

  for (const { numbers, star } of bet.bingo_bet_tickets) {
    let multiplier = new Decimal(0)

    const lotteryLookup = makeLookupForArr(balls)
    const bitMark = toBitMask(numbers, lotteryLookup)

    for (let i = patterns.length - 1; i >= 0; i--) {
      const pattern = patterns[i]
      const tempBitMark = bitMark & pattern.index

      if (tempBitMark === pattern.index) {
        break
      }

      const numDiffNumber = countDifferentBits(tempBitMark, pattern.index).length
      if (numDiffNumber === 1) {
        multiplier = new Decimal(pattern.multiplier)
        break
      }
    }

    if (multiplier.gt(0)) {
      possible = possible.add(multiplier.mul(new Decimal(star)))
    }
  }

  const ratio = new Decimal(0.036).plus(new Decimal(index - 30).mul(0.001))

  return possible.mul(ticketUnitPrice).mul(ratio).toDecimalPlaces(2)
}

export function getTicketMatchPattern(ticket: number[], balls: number[], _patterns: PatternBoardRate[]) {
  const patterns = flatPatterns(_patterns, false)
  const lotteryLookup = makeLookupForArr(balls)
  const numbers = reverseNewArray(ticket)
  const bitMark = toBitMask(numbers, lotteryLookup)

  for (let i = patterns.length - 1; i >= 0; i--) {
    const pattern = patterns[i]
    const tempBitMark = bitMark & pattern.index

    if (tempBitMark === pattern.index) {
      return pattern
    }
  }
  return null
}

function reverseNewArray(arr: number[]): number[] {
  return [...arr].reverse()
}

function toBitMask(ticket: number[], target: Record<number, boolean>) {
  let mask = 0
  for (let i = 0; i < ticket.length; i++) {
    if (target[ticket[i]] === true) {
      mask |= 1 << i
    }
  }
  return BigInt(mask)
}

function makeLookupForArr(arr: number[]): Record<number, boolean> {
  const lookup: Record<number, boolean> = {}
  for (const num of arr) {
    lookup[num] = true
  }
  return lookup
}

function countDifferentBits(sample: bigint, pattern: bigint) {
  let xor = sample ^ pattern
  const diffs: number[] = []
  let index = 0
  while (xor !== 0n) {
    if (xor & 1n) {
      diffs.push(index)
    }
    xor >>= 1n
    index++
  }

  return diffs
}

export function genTicketId(numbers?: number[]): string {
  return numbers?.join('|') || ''
}
export function convertBEPattern(bePattern: string) {
  const length = bingoConfig.ticket.rows * bingoConfig.ticket.cols
  const pattern = '0'.repeat(length - bePattern.length) + bePattern
  return (
    pattern
      .replace(/0/g, 'o')
      .replace(/1/g, 'x')
      .match(/.{1,5}/g)
      ?.join('|') || ''
  )
}
