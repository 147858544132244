import useCanPlaySound from '@/hooks/useCanPlaySound'
import { useCustomSound } from '@/hooks/useCustomSound'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { HookOptions } from 'use-sound/dist/types'

export const bgMusic = '/sound/bingo/bingoBg.mp3'
export const bingoMiningSound = '/sound/bingo/mining.mp3'

const sounds: {
  src: string
  options?: HookOptions
}[] = [{ src: bgMusic, options: { loop: true } }, { src: bingoMiningSound }]

const soundRefs: Record<string, SoundPlayerRef> = {}
export function playBingoSound(audio: string) {
  const sound = soundRefs[audio]
  if (sound) {
    sound.play()
  }
}

export function pauseBingoSound(audio: string) {
  const sound = soundRefs[audio]
  if (sound) {
    sound.pause()
  }
}

export function BingoSounds() {
  const canPlay = useCanPlaySound()
  useEffect(() => {
    if (canPlay) {
      playBingoSound(bgMusic)
      return () => pauseBingoSound(bgMusic)
    }
  }, [canPlay])

  return (
    <>
      {sounds.map((sound) => (
        <SoundPlayer
          ref={(ref) => {
            if (ref) {
              soundRefs[sound.src] = ref
            }
          }}
          sound={sound.src}
          options={sound.options}
        />
      ))}
    </>
  )
}

interface SoundPlayerRef {
  play: () => void
  pause: () => void
}

interface SoundPlayerProps {
  sound: string
  options?: HookOptions
}

const SoundPlayer = forwardRef<SoundPlayerRef, SoundPlayerProps>(({ sound, options }, ref) => {
  const [_play, { pause: _pause }] = useCustomSound(sound, options)
  const canPlay = useCanPlaySound()

  const play = () => {
    if (!canPlay) return
    _play()
  }
  const pause = () => {
    _pause()
  }

  useImperativeHandle(ref, () => ({
    play,
    pause,
  }))

  return <></>
})
