import useGameId from '@/hooks/useGameId.ts'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice.ts'
import { Box, Flex, IconButton, Stack, Text, Image } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { getWalletById, selectWallet } from '@store/modules/wallet.slice.ts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft } from 'react-icons/fa'
import { RxUpdate } from 'react-icons/rx'
import { currencyMap } from '../../const/currency'
import {
  ArabicIcon,
  ChineseIcon,
  EnglishIcon,
  EspanolIcon,
  HindiIcon,
  KoreanIcon,
  PortugueseIcon,
} from '../../const/icons'
import { getInitialLang } from '../../i18n'
import { IframeCommunicationEvent, iframeCon, isLoadedByIframe } from '../../utils/iframe.ts'
import SelectLanguage from '../UI/SelectLanguage'
import SelectWallet from '../UI/SelectWallet.tsx'
import { NumberAnim } from '../common/NumberAnim.tsx'

export default function Breadcrumb() {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const languageDefault = getInitialLang()
  const gameID = useGameId()
  const wallet = useAppSelector(selectWallet)
  const isRefetchWallet = useAppSelector(selectIsLoadingAction(getWalletById.pending))
  const languageOptions = [
    { value: 'en', label: 'English', icon: <EnglishIcon /> },
    { value: 'zh', label: 'Chinese', icon: <ChineseIcon /> },
    { value: 'hi', label: 'Hindi', icon: <HindiIcon /> },
    { value: 'pt', label: 'Portuguese', icon: <PortugueseIcon /> },
    { value: 'ko', label: 'Korean', icon: <KoreanIcon /> },
    { value: 'ar', label: 'Arabic', icon: <ArabicIcon /> },
    { value: 'es', label: 'Espanol', icon: <EspanolIcon /> },
  ]

  const handleWalletUpdate = () => {
    if (wallet.id) {
      dispatch(getWalletById(wallet.id))
    }
  }

  const handleSelectLanguage = (option: any) => {
    i18n.changeLanguage(option.value)
  }

  const handleReturn = () => {
    if (isLoadedByIframe()) {
      iframeCon.sendToParent({ event: IframeCommunicationEvent.GO_BACK, params: {} })
      return
    }

    window.close()
  }
  const currency = currencyMap[wallet.currency]

  // useEffect(() => {
  //   const endTime = new Date();
  //   const startTime = window.startTime ?? new Date();
  //   const timeRendered = endTime.getTime() - startTime.getTime();
  //   //disable loading when rendered component LCP
  //   //timeout = timeRendered + bufffer (renderd canvas)
  //   const id = setTimeout(() => {
  //     const pageLoadingElement = document.getElementById('pageLoading');
  //     if (pageLoadingElement) {
  //       pageLoadingElement.remove();
  //     }
  //     document.body.removeAttribute('style');
  //   }, timeRendered / 8 + 500);
  //
  //   return () => {
  //     clearTimeout(id);
  //   };
  // }, []);
  
  return (
    <Stack w="100%" pt="5px">
      <Flex justifyContent="space-between" gap={4}>
        <Flex alignItems="center" gap={2} cursor="pointer" onClick={handleReturn}>
          <FaChevronLeft />
          <Text textStyle="semibold" fontSize={'1.1rem'}>
            {t(gameID)}
          </Text>
        </Flex>
        <Flex gap={2} alignItems="center" position="relative" zIndex="11">
          <SelectLanguage defaultValue={languageDefault} options={languageOptions} onSelect={handleSelectLanguage} />
          <Flex p="4px" backgroundColor="#18181b" gap={2} alignItems="center" borderRadius={8}>
            <SelectWallet />
            <NumberAnim to={wallet.amount ?? 0} decimal={currency?.decimal ?? 0} dataId={"user-wallet-balance"} />

            <IconButton
              data-id="user-wallet-currency"
              data-value={wallet.currency}
              top="-1px"
              paddingInline={1.5}
              size="xs"
              background="#c3fb04"
              boxShadow="0px 3px 0px  #80a500"
              _hover={{ bg: '#c3fb04' }}
              _active={{
                transform: 'translateY(2px)',
                boxShadow: '0px 0px 0px  #80a500',
              }}
              icon={
                <Box className={`${isRefetchWallet ? `animate-spin` : ``}`}>
                  <RxUpdate fontSize={12} />
                </Box>
              }
              aria-label="Update Wallet"
              onClick={handleWalletUpdate}
              w="20px"
              h="20px"
            />
          </Flex>
        </Flex>
      </Flex>
    </Stack>
  )
}
