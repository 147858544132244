import { Box } from '@chakra-ui/react'
import React from 'react'

const BingoNewGamePopup = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      backgroundColor="rgba(0, 0, 0, 0.3)"
      backdropFilter="blur(2px)"
      zIndex="2"
    >
      <Box
        width="370px"
        aspectRatio="750/690"
        background="url('/bingo/images/bingo-new-game.webp') no-repeat center center"
        backgroundSize="cover"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
    </Box>
  )
}

export default BingoNewGamePopup
