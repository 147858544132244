import { useAppSelector } from '@/redux/store'
import { selectIsMuted } from '@/redux/store/modules/audio.slice'
import { useEffect, useState } from 'react'

function useCanPlaySound() {
  const [canPlay, setCanPlay] = useState(false)
  const muted = useAppSelector(selectIsMuted)

  useEffect(() => {
    const checkAudioContext = () => {
      setCanPlay(true)
    }

    const handleUserInteraction = () => {
      checkAudioContext()
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('keydown', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
    }

    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)
    document.addEventListener('touchstart', handleUserInteraction)

    return () => {
      document.removeEventListener('click', handleUserInteraction)
      document.removeEventListener('keydown', handleUserInteraction)
      document.removeEventListener('touchstart', handleUserInteraction)
    }
  }, [])

  return canPlay && !muted
}

export default useCanPlaySound
