import useGameId from '@/hooks/useGameId.ts'
import { selectOrderDetail } from '@/redux/store/modules/order.slice.ts'
import { Box, Flex, Image, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { RootState, useAppSelector } from '@store'
import { BetType, OrderDetail } from '@store/entities/order.entity'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { IoTimerOutline } from 'react-icons/io5'
import { itemHash1, textGrayDrawer, textGrayWhite } from '../../../const/color'
import { currency, getSymbolFromCurrency } from '../../../const/currency'
import { gameType, GameUrlMap } from '../../../const/game-type.ts'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import { prettyPrintWallet } from '../../../utils/number.ts'
import { TrendItem } from '../../../utils/trends.ts'
import { LotteryHashHBP } from '../../banker-player/LotteryHash.tsx'
import { AppDrawer } from '../../common/AppDrawer.tsx'
import Chess from '../../common/Chess.tsx'
import CopyAll from '../../common/CopyAll'
import { CopyBtn } from '../../common/CopyBtn'
import { LotteryHashHLUCKY } from '../../lucky/LotteryHash.tsx'
import { LotteryHashHNIUNIU } from '../../niuniu/LotteryHash.tsx'
import { LotteryHash } from '../LotteryHash.tsx'
interface BetDetailProps {
  id: string
}

function RenderAmountItem({ order, t, balance, gameID }: { order: OrderDetail; t: any; balance: any; gameID: string }) {
  let result = order.result == order.bet ? 'win' : 'lose' || '--'

  if (gameID === GameUrlMap.H_NIUNIU) {
    let amountResult = ''
    if (order.result === 'TIE') {
      result = 'tie'
      amountResult = (Number(order.amount) * 10).toString()
    } else {
      amountResult =
        result === 'win'
          ? (Number(order.win_amount) + Number(order.amount) * 9).toString()
          : Number(order.amount) * 10 - Number(order.return_amount)
    }
    return (
      <Stack alignItems="center">
        <Text>
          <Flex color={itemHash1} fontWeight={600} alignItems="center" gap={1}>
            <Text color={result === 'win' || result === 'tie' ? '#B0FC00' : '#ff5959'} fontSize="18px">
              {result === 'win' || result === 'tie' ? '+' : '-'}
              {amountResult ? prettyPrintWallet(+amountResult) : ''}
            </Text>
            <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
              {`(${order.currency})`}
            </Text>
          </Flex>
        </Text>
        <Text mt={-3} fontSize={12} color={textGrayDrawer}>
          {result ? t(`${result}`) : ''}
        </Text>
      </Stack>
    )
  }
  if (gameID === GameUrlMap.H_BP) {
    let amountResult = ''
    if (order.result === 'TIE') {
      amountResult = order.result === order.bet ? order?.win_amount?.toString() : order.amount
    } else {
      amountResult = result === 'win' ? order?.win_amount?.toString() : order.amount
    }
    return (
      <Stack alignItems="center">
        <Text>
          <Flex color={itemHash1} fontWeight={600} alignItems="center" gap={1}>
            <Text color={result === 'win' ? '#B0FC00' : '#ff5959'} fontSize="18px">
              {result === 'win' ? '+' : '-'}
              {amountResult ? prettyPrintWallet(+amountResult) : ''}
            </Text>
            <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
              {`(${order.currency})`}
            </Text>
          </Flex>
        </Text>
        <Text mt={-3} fontSize={12} color={textGrayDrawer}>
          {result ? t(`${result}`) : ''}
        </Text>
      </Stack>
    )
  }

  return (
    <Stack alignItems="center">
      <Text>
        <Flex color={itemHash1} fontWeight={600} alignItems="center" gap={1}>
          <Text color={result === 'win' ? '#B0FC00' : '#ff5959'} fontSize="18px">
            {result === 'win' ? '+' : '-'}
            {result === 'win' ? prettyPrintWallet(+order.win_amount) : prettyPrintWallet(+order.amount)}
          </Text>
          <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
            {`(${order.currency})`}
          </Text>
        </Flex>
      </Text>
      <Text mt={-3} fontSize={12} color={textGrayDrawer}>
        {result ? t(`${result}`) : ''}
      </Text>
    </Stack>
  )
}

function RenderHashShort({ gameID, order, t }: { gameID: string; order: OrderDetail; t: any }) {
  if (gameID === GameUrlMap.H_LUCKY) {
    return (
      <Flex color={textGrayWhite} fontSize={12} letterSpacing="1px">
        <LotteryHashHLUCKY text={order.game?.lotteryHash} type={'middleEllipsis'} prefixLength={0} suffixLength={8} />
        <Text color="#d5fe47" as="span">
          ({order.result ? t(`${order.result}`) : '--'})
        </Text>
      </Flex>
    )
  }
  if (gameID === GameUrlMap.H_BP) {
    return (
      <Flex color={textGrayWhite} fontSize={12} letterSpacing="1px">
        <LotteryHashHBP hash={order.game?.lotteryHash} showEllipsis={false} />
        <Text color="#d5fe47" as="span">
          ({order.result ? t(`${order.result}`) : '--'})
        </Text>
      </Flex>
    )
  }
  if (gameID === GameUrlMap.H_NIUNIU) {
    return (
      <Flex color={textGrayWhite} fontSize={12} letterSpacing="1px">
        <LotteryHashHNIUNIU hash={order.game?.lotteryHash} showEllipsis={false} />
        <Text color="#d5fe47" as="span">
          ({order.result ? t(`${order.result}`) : '--'})
        </Text>
      </Flex>
    )
  }

  return (
    <Flex color={textGrayWhite} fontSize={12} letterSpacing="1px">
      <LotteryHash type="suffixOnly" suffixLength={10} text={order.game?.lotteryHash ?? ''} />
      &nbsp;
      <Text color="#B7FE00">({order.result ? t(`${order.result}`) : '--'})</Text>
    </Flex>
  )
}

function RenderIconBet({ bet }: { bet: BetType | TrendItem | string }) {
  const gameID = useGameId()
  if (gameID === GameUrlMap.H_LUCKY) {
    return <Image src="/images/logo-lucky.webp" width={8} height={8} />
  }
  return <Chess type={bet} width="28px" height="28px" />
}

export default function BetDetail({ id }: BetDetailProps) {
  const order = useAppSelector(selectOrderDetail(id))
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const balance = useAppSelector((state: RootState) => state.balance)
  const gameID = useGameId()

  const color: string[] = useMemo(() => {
    const game = gameType.find((item) => item.id === gameID)?.color
    return Array.isArray(game) ? game : []
  }, [gameID, gameType])

  const indexColor: number = useMemo(() => {
    const game = gameType.find((item) => item.id === gameID)?.type
    if (game) {
      return game.indexOf(order.bet)
    }
    return -1
  }, [gameID, order.bet, gameType])

  return (
    <Stack p={0}>
      <Box
        onClick={() => {
          onOpen()
        }}
        padding="10px 6px 3px 6px"
        backgroundColor="#232327"
        borderRadius={12}
        data-id={`item-order-${order.id}`}
      >
        <Stack>
          <Flex justifyContent="space-between" alignItems="center" px="5px">
            <Flex alignItems="center" gap={3}>
              <RenderIconBet bet={order.bet} color={color} indexColor={indexColor} />

              <Stack>
                <Text letterSpacing={1} fontSize={12} textColor={textGrayWhite} fontWeight={500}>
                  {t(gameID)}
                </Text>
                <Flex alignItems="flex-end" mt={-2} fontSize={12}>
                  <Flex gap={1} alignItems="flex-end" lineHeight={1}>
                    <Text fontWeight={300} color={textGrayDrawer}>
                      {t('betting')}:
                    </Text>
                    <Text color="white" fontSize="14px" mb="-1px">
                      {gameID !== 'H_LUCKY' && t(order.bet) + ' • '}
                      {prettyPrintWallet(+order.amount)}
                    </Text>
                    <Text fontSize={10} color={textGrayWhite} lineHeight={1}>
                      ({order.currency})
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>
            {order.result ? (
              <RenderAmountItem order={order} t={t} balance={balance} gameID={gameID} />
            ) : (
              <Stack color={'#55555a'} mr={6}>
                <IoTimerOutline fontSize={28} />
              </Stack>
            )}
          </Flex>
          <hr style={{ borderColor: '#2d2d32' }} />
          <Flex fontSize="0.9rem" justifyContent="space-between" alignItems="center" mb="3px" px="5px">
            <Text fontSize={12} color={'#75757f'} fontWeight={400}>
              {dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
            {order.result ? (
              <RenderHashShort gameID={gameID} order={order} t={t} />
            ) : (
              <Text color={textGrayDrawer} textTransform="uppercase">
                {t('playing')}
              </Text>
            )}
          </Flex>
        </Stack>
      </Box>

      <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
        <BetDetailInfo order={order} gameID={gameID} currencyUsed={order.currency} />
      </AppDrawer>
    </Stack>
  )
}

function RenderAmountRound({ order, gameID }: { order: OrderDetail; gameID: string }) {
  let result = order.result ? (order.result == order.bet ? 'win' : 'lose') : '--'
  if (gameID === GameUrlMap.H_NIUNIU) {
    let amountResult = ''
    if (order.result === 'TIE') {
      result = 'win'
      amountResult = (Number(order.amount) * 10).toString()
    } else {
      result = order.result == order.bet ? 'win' : 'lose'
      amountResult =
        order.result == order.bet
          ? (order.win_amount + Number(order.return_amount)).toString()
          : Number(order.amount) * 10 - Number(order.return_amount)
    }
    return (
      <Text fontSize={32} fontWeight={600} color={result === 'win' ? '#B0FC00' : '#ff5959'}>
        {result === 'win' ? '+' : '-'}
        {amountResult ? prettyPrintWallet(+amountResult) : ''}
      </Text>
    )
  }
  if (gameID === GameUrlMap.H_BP) {
    let amountResult = ''
    if (order.result === 'TIE') {
      amountResult = order.result === order.bet ? order.win_amount?.toString() : order.amount
    } else {
      amountResult = result === 'win' ? order?.win_amount?.toString() : order.amount
    }
    return (
      <Text fontSize={32} fontWeight={600} color={result === 'win' ? '#B0FC00' : '#ff5959'}>
        {result === 'win' ? '+' : '-'}
        {amountResult ? prettyPrintWallet(+amountResult) : ''}
      </Text>
    )
  }
  return (
    <>
      {order.result ? (
        <Text fontSize={32} fontWeight={600} color={result === 'win' ? '#B0FC00' : '#ff5959'} data-id="bet-amount">
          {result === 'win' ? `+${prettyPrintWallet(order.win_amount)}` : `-${prettyPrintWallet(+order.amount)}`}
        </Text>
      ) : (
        <Text fontSize={32} fontWeight={600} color="#B0FC00">
          __
        </Text>
      )}
    </>
  )
}

function RenderStatusGame({ gameId, t, order }: { gameId: string; t: any; order: OrderDetail }) {
  const is_win = order.bet === order.result
  if (gameId === GameUrlMap.H_NIUNIU) {
    if (order.result === 'TIE') {
      return (
        <Flex color={'#B0FC00'} justifyContent="space-between" mt={-3}>
          <Text data-id="bet-result">{t(`${'tiestatus'}`)}</Text>
          <Text data-id="bet-status-payment">{t('statusPayment')}</Text>
        </Flex>
      )
    } else {
      return (
        <Flex color={is_win ? '#B0FC00' : '#ff5959'} justifyContent="space-between" mt={-3}>
          <Text data-id="bet-result">{is_win ? t(`${'win'}`) : t(`${'lose'}`)}</Text>
          <Text data-id="bet-status-payment">{t('statusPayment')}</Text>
        </Flex>
      )
    }
  }
  return (
    <Flex color={is_win ? '#B0FC00' : '#ff5959'} justifyContent="space-between" mt={-3}>
      <Text data-id="bet-result">{is_win ? t(`${'win'}`) : t(`${'lose'}`)}</Text>
      <Text data-id="bet-status-payment">{t('statusPayment')}</Text>
    </Flex>
  )
}

const GameOrderResultDisplay = ({ gameID, order, textGrayWhite, t }: any) => {
  if (gameID === GameUrlMap.H_LUCKY) {
    return (
      <LotteryHashHLUCKY text={order.game?.lotteryHash}>
        &nbsp; <span style={{ color: '#c7fb14' }}>{`(${!order.result ? '' : t(`${order.result}`)})`}</span>
      </LotteryHashHLUCKY>
    )
  }
  if (gameID === GameUrlMap.H_BP) {
    return (
      <Flex color={textGrayWhite}>
        <LotteryHashHBP
          hash={order.game?.lotteryHash}
          showEllipsis={true}
          result={
            <span style={{ color: '#c7fb14' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; ({order.result ? t(`${order.result}`) : '--'})
            </span>
          }
        />
      </Flex>
    )
  }

  if (gameID === GameUrlMap.H_NIUNIU) {
    return (
      <Flex color={textGrayWhite}>
        <LotteryHashHNIUNIU
          hash={order.game?.lotteryHash}
          showEllipsis={true}
          result={
            <span style={{ color: '#c7fb14' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; ({order.result ? t(`${order.result}`) : '--'})
            </span>
          }
        />
      </Flex>
    )
  }

  return (
    <LotteryHash text={order.game?.lotteryHash || ''}>
      &nbsp; <span style={{ color: '#c7fb14' }}>{`(${!order.result ? '' : t(`${order.result}`)})`}</span>
    </LotteryHash>
  )
}

export function BetDetailInfo({
  order,
  gameID,
  currencyUsed,
}: {
  order: OrderDetail
  gameID: string
  currencyUsed?: string
}) {
  const { t } = useCustomTranslation()
  const CurrencyIcon = () => {
    return React.cloneElement(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      currency.find((i) => i.name === currencyUsed)?.icon,
      {
        style: {
          width: '24px',
          height: '24px',
          minWidth: '24px',
          minHeight: '24px',
        },
      },
    )
  }
  return (
    <Box backgroundColor="#2f2f31">
      <Stack p={4} backgroundColor="#2f2f31" color={'#fff'} gap={0}>
        <Text fontWeight={600} fontSize="18px" mt={2}>
          {t(`${gameType.find((item) => item.id === gameID)?.id}`)}
        </Text>
        <Flex gap={2} alignItems="center">
          {order.result ? (
            <RenderAmountRound order={order} gameID={gameID} />
          ) : (
            <Text fontSize={32} fontWeight={600} color="#B0FC00">
              __
            </Text>
          )}
          <Image src={getSymbolFromCurrency(order.currency)} className="w-6 h-6 rounded-lg" />
          {/* <CurrencyIcon /> */}
        </Flex>
        {order.result && <RenderStatusGame gameId={gameID} order={order} t={t} />}
      </Stack>
      <Stack p={4} color={'#fff'} borderRadius="14px 14px 0 0" backgroundColor="#363639" gap="10px">
        <Stack>
          <Flex justifyContent="space-between" py={1}>
            <Text color={textGrayDrawer}>{t('game')}</Text>
            <Text fontWeight={400}>{t(`${gameType.find((item) => item.id === gameID)?.id}`)}</Text>
          </Flex>
        </Stack>
        <Stack>
          <hr style={{ borderColor: '#434343' }} />
          <Flex justifyContent="space-between" py={1}>
            <Text color={textGrayDrawer}>{t('typeBetting')}</Text>
            <Text fontWeight={400}>{t(`${order.bet}`)}</Text>
          </Flex>
        </Stack>
        <Stack>
          <hr style={{ borderColor: '#434343' }} />
          <Flex justifyContent="space-between" py={1}>
            <Text color={textGrayDrawer}>{t('betAmount')}</Text>
            <Flex gap={1} alignItems="center">
              <Text fontWeight={400}>{prettyPrintWallet(+order.amount)} </Text>
              {currency.find((i) => i.name === currencyUsed)?.icon}
            </Flex>
          </Flex>
        </Stack>
        {gameID === GameUrlMap.H_NIUNIU && (
          <>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('freeze')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{prettyPrintWallet(+order.amount * 9)} </Text>
                  {currency.find((i) => i.name === currencyUsed)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('return')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{order.return_amount ? prettyPrintWallet(+order.return_amount) : ''}</Text>
                  {currency.find((i) => i.name === currencyUsed)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('valid-bet')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{order.valid_bet ? prettyPrintWallet(+order.valid_bet) : ''}</Text>
                  {currency.find((i) => i.name === currencyUsed)?.icon}
                </Flex>
              </Flex>
            </Stack>
          </>
        )}
        {order.win_amount && (
          <>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1} alignItems="center">
                <Text color={textGrayDrawer}>{t('bonusAmount')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{prettyPrintWallet(+order.win_amount)}</Text>
                  {currency.find((i) => i.name === currencyUsed)?.icon}
                </Flex>
              </Flex>
            </Stack>
          </>
        )}
        <Stack>
          <hr style={{ borderColor: '#434343' }} />
          <Flex justifyContent="space-between" py={1}>
            <Text color={textGrayDrawer}>{t('betTime')}</Text>
            <Text fontWeight={400}>
              {order.createdAt ? dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss') : '__'}
            </Text>
          </Flex>
        </Stack>
        <Stack>
          <hr style={{ borderColor: '#434343' }} />
          <Flex justifyContent="space-between" py={1}>
            <Text color={textGrayDrawer}>{t('timePayment')}</Text>
            <Text fontWeight={400}>{order.game ? dayjs(order.game?.endTime).format('YYYY-MM-DD HH:mm:ss') : '__'}</Text>
          </Flex>
        </Stack>
        <Stack pb={2}>
          <hr style={{ borderColor: '#434343' }} />
          <Stack gap="0">
            <Flex alignItems="center" justifyContent="space-between" py={1}>
              <Text color={textGrayDrawer}>{t('lotteryHash')}</Text>
              <CopyBtn text={order.game?.lotteryHash || ''} />
            </Flex>
            <GameOrderResultDisplay gameID={gameID} order={order} textGrayWhite={textGrayWhite} t={t} />
          </Stack>
          <Stack>
            <hr style={{ borderColor: '#434343' }} />
            <Stack gap="0">
              <Flex alignItems="center" justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('serverSeed')}</Text>
                <CopyBtn text={order.game?.serverSeed || ''} />
              </Flex>
              <Text fontWeight={400}>{order.game?.serverSeed}</Text>
            </Stack>
          </Stack>
          <Stack>
            <hr style={{ borderColor: '#434343' }} />
            <Stack>
              <Stack gap="0">
                <Flex alignItems="center" justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{t('publicChainHash')}</Text>
                  <CopyBtn text={order.game?.publicChainHash || ''} />
                </Flex>
                <Text fontWeight={400}>{order.game?.publicChainHash}</Text>
              </Stack>
            </Stack>
          </Stack>
          <CopyAll cpyTxt={`${order.game?.lotteryHash}\n${order.game?.serverSeed}\n${order.game?.publicChainHash}`} />
        </Stack>
        <Stack>
          <hr style={{ borderColor: '#434343' }} />
          <Stack gap="0">
            <Flex alignItems="center" justifyContent="space-between" py={1}>
              <Text color={textGrayDrawer}>{t('resultLotteryHash')}</Text>
              <CopyBtn text={order.game?.lotteryResultHash || ''} />
            </Flex>
            <Text fontWeight={400}>{order.game?.lotteryResultHash}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
