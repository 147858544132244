import LotteryPattern from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern'
import { selectBingoPatterns } from '@/bingo/redux/bingo.slice'
import { useAppSelector } from '@/redux/store'
import { Flex } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'

const TopRow = () => {
  const patterns = useAppSelector(selectBingoPatterns)
  if (isEmpty(patterns)) return null

  const [_1, _2, _3, _4] = patterns
  return (
    <Flex flex="1" alignItems="center" justifyContent="space-between">
      <Flex justifyContent="space-between" gap="20.5px">
        <LotteryPattern pattern={_1} />
        <LotteryPattern pattern={_2} />
      </Flex>
      <Flex justifyContent="space-between" gap="20px">
        <LotteryPattern pattern={_3} />
        <LotteryPattern pattern={_4} />
      </Flex>
    </Flex>
  )
}

export default TopRow
