import React from 'react';
import { Image, ImageProps } from '@chakra-ui/react'
import { getSymbolFromCurrency } from '@const/currency.tsx'

type BingoCurrencyIconProps = ImageProps & {
  currency?: string
}

const BingoCurrencyIcon = ({currency, width = '20px', ...rest}: BingoCurrencyIconProps) => {
  return (
    <Image
      src={getSymbolFromCurrency(currency)}
      width={width}
      height={width}
      minWidth={width}
      minHeight={width}
      borderRadius="50%"
      {...rest}
    />
  )
}

export default BingoCurrencyIcon;
