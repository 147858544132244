import { BallAnimated } from '@/bingo/components/GameplayStage/MiningArea/Ball'
import { Box } from '@chakra-ui/react'
import React from 'react'
import { bingoActions } from '@/bingo/redux/bingo.slice.ts'
import { useAppDispatch } from '@store'

interface ExploitedBallsProps {
  balls: number[]
  animated: boolean
  ballRef?: React.MutableRefObject<any>
}

const ExploitedBalls = ({ ballRef, balls, animated }: ExploitedBallsProps) => {
  const dispatch = useAppDispatch()

  const handleExploitedBallsClick = () => {
    dispatch(bingoActions.showPopupLastRound(true))
  }

  return (
    <Box
      background="url('/bingo/images/bg-balls-list.webp') no-repeat left center"
      backgroundSize="cover"
      backgroundPosition="4px"
      width="288px"
      height="82px"
      position="relative"
      padding="11.5px 2px 0 24px"
      marginRight="5.5px"
      marginTop="15px"
      cursor="pointer"
      onClick={handleExploitedBallsClick}
    >
      <Box
        overflowX="auto"
        borderLeftRadius="50px"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box display="flex" gap="5.5px" width="330px">
          <div
            style={{
              top: 12,
              left: 24,
              zIndex: 0,
            }}
            className="absolute w-12 h-12"
            ref={ballRef}
          />
          {balls.map((ball, index) => (
            <BallAnimated value={ball} key={ball} animated={!index && animated} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ExploitedBalls
