import { BingoRound } from '@/@generated/gql/graphql-hash'
import CopyAll from '@/components/common/CopyAll'
import { CopyBtn } from '@/components/common/CopyBtn'
import HtmlRender from '@/components/HtmlRender'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { getInitialLang } from '@/i18n'
// import { fairnessZh } from '@/pages/plinko/components/BetActions/fairness.zh'
import { getMyOrdersBingoQuery } from '@/redux/store/services/queries'
import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { gameplayEn } from '@/bingo/const/gameplay.en.ts'

const fairnessContent: Record<string, string> = {
  en: gameplayEn,
  // zh: fairnessZh,
}

declare type PopupType = 'Gameplay' | 'Verification'
interface FairnessPopupProps {
  popup?: PopupType
  bingo_round?: BingoRound
}

const mapPopupWithTab: Record<PopupType, number> = {
  Gameplay: 0,
  Verification: 1,
}

export function MenuBingo({ popup, bingo_round }: FairnessPopupProps) {
  const { t } = useCustomTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramValue = queryParams.get('param')
  const defaultTab = mapPopupWithTab[popup || (paramValue as PopupType)] || 0

  return (
    <Stack height="80vh" overflow="hidden" borderRadius="12px 12px 0 0" className="newgame-container">
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={2}
        px={5}
        pb={7}
        borderRadius="12px 12px 0 0"
      >
        <Flex color="white" pr={4} justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} fontSize={21} mt={2} lineHeight={1.2}>
              {t('hashLottery')}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" className="w-[74px] h-[68px] mt-[2px]" />
        </Flex>
      </Stack>
      <Stack></Stack>
      <Tabs defaultIndex={defaultTab}>
        <Center mt={-10}>
          <TabList
            backgroundColor="#4a4a4e"
            border="none"
            borderRadius="30px"
            className="w-full ml-5 mr-4 p-1 pb-[4px] h-[42px]"
          >
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0 !py-0"
            >
              <Text marginTop="4px">{t('gameplay')}</Text>
            </Tab>
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0"
            >
              <Text marginTop="4px">{t('verify')}</Text>
            </Tab>
          </TabList>
        </Center>
        <TabPanels height="calc(80vh - 132px)" overflowY="auto">
          <TabPanel>
            <GameplayTab />
          </TabPanel>
          <TabPanel>
            <FairnessTab bingo_round={bingo_round} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

function GameplayTab() {
  const lang = getInitialLang()
  const content = fairnessContent[lang] || fairnessContent['en']
  return <HtmlRender className="text-[white]" html={content} />
}

function FairnessTab({ bingo_round }: { bingo_round?: BingoRound }) {
  const { t } = useCustomTranslation()
  const game_id = useGameId()
  const limit = 1
  const page = 0
  const { data } = useQuery(getMyOrdersBingoQuery, {
    variables: {
      input: { game_id: game_id, page: page, limit: limit },
    },
    skip: !!bingo_round,
  })
  const { proof, server_seed, client_seed, random_value } = bingo_round
    ? bingo_round
    : data?.bingoOrderHistory?.data?.[0]?.bingo_round || {}

  return (
    <Stack gap={4}>
      <Box position="relative">
        <Image width="100%" src={'/images/Panel-light.svg'} alt="" />
        <Text position="absolute" top={'50%'} left={4} transform="translateY(-50%)" fontSize="14px" color="#fff">
          {t('lastRoundHash')}
        </Text>
      </Box>

      <Stack backgroundColor="#252527" p={4} mt={-4} borderRadius={8} borderTopRadius={0} fontSize={13}>
        <Stack color="white" mt={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('randomness')}
            </Text>
            <CopyBtn text={random_value} />
          </Flex>
          <Text>{random_value}</Text>
        </Stack>

        <Stack color="white" mt={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('gamePlay.serverSeed')}
            </Text>
            <CopyBtn text={server_seed} />
          </Flex>
          <Text>{server_seed}</Text>
        </Stack>
        <Stack color="white" mt={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('clientSeed')}
            </Text>
            <Link href={`https://etherscan.io/tx/${client_seed}`} isExternal>
              <Stack direction={'row'} alignItems={'center'} color={'#ccc'}>
                <Text>{t('gamePlay.etherscan')}</Text>
                <FiExternalLink size={14} style={{ display: 'inline' }} />
              </Stack>
            </Link>
            <CopyBtn text={client_seed} />
          </Flex>
          <Text>{client_seed}</Text>
        </Stack>

        <CopyAll cpyTxt={`${random_value}${server_seed}${client_seed}`} />
      </Stack>

      <Stack color="white" backgroundColor="#252527" p={4} borderRadius={8} fontSize={13}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="#c7fb14" fontWeight={500}>
            {t('hashResult')}
          </Text>
          <CopyBtn text={proof} />
        </Flex>
        <Text>{proof}</Text>
      </Stack>
      <Button
        aria-label="buttonVerifyWallet"
        aria-labelledby="buttonVerifyWallet"
        w="100%"
        backgroundColor="#c7fb14"
        borderRadius="8px"
      >
        <Stack direction="row">
          <Link
            href={`https://emn178.github.io/online-tools/sha256.html?input=${random_value}${server_seed}${client_seed}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
            isExternal
          >
            {t('buttonVerifyWallet')} <FiExternalLink style={{ display: 'inline' }} />
          </Link>
        </Stack>
      </Button>
    </Stack>
  )
}
