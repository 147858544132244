// export const MiSansFontFamily = {
//   normal: 'MiSans-normal, system-ui, sans-serif',
//   medium: 'MiSans-medium, system-ui, sans-serif',
//   semiBold: 'MiSans-semi-bold, system-ui, sans-serif',
//   demiBold: 'MiSans-demi-bold, system-ui, sans-serif',
//   bold: 'MiSans-bold, system-ui, sans-serif',
// }

export const NotoSansFontFamily = {
  normal: {
    fontFamily: '"Noto Sans SC", system-ui, sans-serif',
    fontWeight: '400',
    fontDisplay: 'swap',
  },
  medium: {
    fontFamily: '"Noto Sans SC", system-ui, sans-serif',
    fontWeight: '500',
    fontDisplay: 'swap',
  },
  semiBold: {
    fontFamily: '"Noto Sans SC", system-ui, sans-serif',
    fontWeight: '600',
    fontDisplay: 'swap',
  },
  bold: {
    fontFamily: '"Noto Sans SC", system-ui, sans-serif',
    fontWeight: '700',
    fontDisplay: 'swap',
  },
}