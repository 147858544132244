import { Box, Button, Checkbox, Flex, FormLabel, Stack, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import QuickFormInput from '@/components/UI/quick-form/QuickFormInput'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { currencyMap, getSymbolFromCurrency } from '@/const/currency'
import { f } from 'fintech-number'
import ButtonBet from '@/components/rocket/QuickForm/ButtonBet'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import {
  rocketTshowActions,
  createNewOrder,
  ROUND_STATE,
  selectConfigRound,
  selectGameRound,
  selectListActiveOrders,
  selectTemporaryOrders,
  selectAmountRocketTShow,
} from '@/redux/store/modules/rocketTshow'
import { CreateCrashOrderInput } from '@/@generated/gql/graphql-hash'
import { selectWallet } from '@/redux/store/modules/wallet.slice'
import { selectAgencyId, selectPlayerName } from '@/redux/store/modules/auth.slice'
import eventBus from '@/utils/eventBus'
import { EVENT_MESSAGE_ERROR } from '@/components/common/ErrorHandlerWrapper'
import useGameId from '@/hooks/useGameId'
import useCustomToast from '@/hooks/useCustomToast'
import { ImCheckmark } from 'react-icons/im'
import ButtonCashOutAll from './ButtonCashOutAll'
import { CopyBtn } from '@/components/common/CopyBtn'

type InputProps = {
  autoEnscape: boolean
  enscape: string | null
  amount: string | null
  isRepeat: boolean
}
const QuickForm = () => {
  const dispatch = useAppDispatch()
  const { t } = useCustomTranslation()
  const wallet = useAppSelector(selectWallet)
  const agencyId = useAppSelector(selectAgencyId)
  const playerName = useAppSelector(selectPlayerName)
  const gameRound = useAppSelector(selectGameRound)
  const temporaryOrders = useAppSelector(selectTemporaryOrders)
  const configRound = useAppSelector(selectConfigRound)
  const listActiveOrders = useAppSelector(selectListActiveOrders)
  const amount = useAppSelector(selectAmountRocketTShow)
  const [loading, setLoading] = useState<boolean>(false)
  const gameId = useGameId()
  const { showToastThrottle } = useCustomToast()

  const { setValue, getValues, register, handleSubmit, watch } = useForm<InputProps>({
    defaultValues: {
      autoEnscape: false,
      enscape: '1.5',
      amount: amount ?? configRound?.minBetAmount,
      isRepeat: false,
    },
  })
  const name = watch("amount")
  useEffect(() => {
    if(name) {
      dispatch(rocketTshowActions.updateAmount(name))
    }
  }, [name])
  
  function smallestSameDigitCount(number: number) {
    const numStr = number.toString()
    const digitCount = numStr.length
    const smallestNumber = Math.pow(10, digitCount - 1)
    return smallestNumber
  }

  const handlePlus = (name: 'enscape' | 'amount') => {
    if (!watch('autoEnscape') && name === 'enscape') return
    const inputMaxAmount = configRound?.maxBetAmount
    const prevVal = getValues(name) ? getValues(name).replace(/,/g, '') : 0
    const intPart = Number(prevVal.toString().split('.')[0])
    const increment = smallestSameDigitCount(intPart)
    let newValue = intPart + increment
    if (newValue < 10) {
      newValue = 10
    }
    const result = inputMaxAmount ? (newValue <= inputMaxAmount ? newValue : inputMaxAmount) : newValue
    setValue(name, f(result))
  }

  const handleMinus = (name: 'enscape' | 'amount') => {
    if (!watch('autoEnscape') && name === 'enscape') return
    const inputMinAmount = +configRound?.minBetAmount
    const currentValue = getValues(name) ? getValues(name).replace(/,/g, '') : 0
    const digitCount = currentValue.toString().split('.')[0].length
    const smallestNumber = 10 ** (digitCount - 1)
    const minus = 10 ** (digitCount - 1) - 10 ** (digitCount - 2)
    const largestNumberWithOneDigitLess = minus < inputMinAmount ? inputMinAmount : minus
    const newValue = Math.max(smallestNumber - 10 ** (digitCount - 1), currentValue - smallestNumber)
    const resultValue = newValue < largestNumberWithOneDigitLess ? largestNumberWithOneDigitLess : newValue

    if (resultValue < 1) {
      return 1
    }

    setValue(name, f(resultValue))
  }

  const handleChange = (e: any) => {
    const inputMaxAmount = +configRound?.maxBetAmount
    let value = e.target.value
    if (value === '') {
      return
    }
    value = e.target.value.replace(/[^0-9.]/g, '') // Remove anything that isn't a number or period
    let formatedValue = formatInputValue(value)
    let valueNumbered = Number(formatedValue.replace(/,/g, ''))

    if (valueNumbered > inputMaxAmount) {
      formatedValue = formatInputValue(inputMaxAmount)
      valueNumbered = inputMaxAmount
    }
    e.target.value = formatedValue
  }

  const formatInputValue = (value: string | number) => {
    // Format number with commas
    if (value.toString().includes('.')) {
      const [intPart, decimalPart] = value.toString().split('.')
      return f(Number(intPart)) + '.' + decimalPart
    } else {
      return f(Number(value)) // Format with commas
    }
  }

  const handleErrorOnSubmit = (data: any) => {
    const amount = data.amount?.replace(/,/g, '')

    if (!amount) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'OUT_OF_BEING_LIMIT',
          code: 'OUT_OF_BEING_LIMIT',
        },
      })
      return false
    }

    if (+amount > +wallet.amount) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'INSUFFICIENT_AMOUNT',
          code: 'INSUFFICIENT_AMOUNT',
        },
      })
      return false
    }
    return true
  }

  const onSubmit: SubmitHandler<InputProps> = (data) => {
    if (!handleErrorOnSubmit(data)) return

    if (gameRound?.state !== ROUND_STATE.WAITING) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'ORDER__GAME_ENDED',
          code: 'ORDER__GAME_ENDED',
        },
      })
      return
    }
    const param: CreateCrashOrderInput = {
      amount: data.amount?.replace(/,/g, ''),
      agency_id: agencyId!,
      auto_cash_out: data?.autoEnscape ? data?.enscape?.replace(/,/g, '') : '0',
      currency: wallet.currency,
      wallet_id: wallet.id,
      round_id: gameRound?.round_id,
      game_id: gameId,
      player_name: playerName!,
    }
    handleCreateNewOrder(param)
  }

  const handleCreateNewOrder = (param: CreateCrashOrderInput) => {
    setLoading(true)
    dispatch(createNewOrder(param))
      .then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: t('notification.successfully'),
            status: 'success',
            isClosable: false,
            duration: 1500,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
          dispatch(rocketTshowActions.updateTemporaryOrders([...temporaryOrders, param]))
        }
      })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (gameRound?.state === ROUND_STATE.END) {
      dispatch(
        rocketTshowActions.updateTemporaryOrders(
          temporaryOrders.filter((item) => item.round_id === gameRound.round_id),
        ),
      )
    }
    if (gameRound?.state === ROUND_STATE.WAITING && getValues('isRepeat')) {
      for (let i = 0; i < temporaryOrders?.length; i++) {
        const timeout = setTimeout(function () {
          const order = temporaryOrders?.[i]
          const param: CreateCrashOrderInput = {
            amount: order.amount?.replace(/,/g, ''),
            agency_id: agencyId!,
            auto_cash_out: order?.auto_cash_out ? order?.auto_cash_out : '0',
            currency: wallet.currency,
            wallet_id: wallet.id,
            round_id: gameRound?.round_id,
            game_id: gameId,
            player_name: playerName!,
          }

          dispatch(createNewOrder(param)).then((res) => {
            if (i === temporaryOrders.length - 1 && res?.meta?.requestStatus === 'fulfilled') {
              showToastThrottle({
                title: t('notification.successfully'),
                status: 'success',
                isClosable: false,
                duration: 1500,
                icon: <ImCheckmark fontSize={32} />,
                containerStyle: {
                  width: '60px',
                  paddingBlock: '0px',
                  pointerEvents: 'none',
                },
              })
            }
          })
        }, i * 150)

        // return () => {
        //   clearTimeout(timeout)
        // }
      }
      // temporaryOrders.forEach((order, index) => {
      //   timeout = setTimeout(() => {
      //     const param: CreateCrashOrderInput = {
      //       amount: order.amount?.replace(/,/g, ''),
      //       agency_id: agencyId!,
      //       auto_cash_out: order?.auto_cash_out ? order?.auto_cash_out : '0',
      //       currency: wallet.currency,
      //       wallet_id: wallet.id,
      //       round_id: gameRound?.round_id,
      //       game_id: gameId,
      //       player_name: playerName!,
      //     }

      //     dispatch(createNewOrder(param)).then((res) => {
      //       if (index === temporaryOrders.length - 1 && res?.meta?.requestStatus === 'fulfilled') {
      //         showToastThrottle({
      //           title: t('notification.successfully'),
      //           status: 'success',
      //           isClosable: false,
      //           duration: 1500,
      //           icon: <ImCheckmark fontSize={32} />,
      //           containerStyle: {
      //             width: '60px',
      //             paddingBlock: '0px',
      //             pointerEvents: 'none',
      //           },
      //         })
      //       }
      //     })
      //   }, 500)
      // })
      const list = temporaryOrders.map((item) => {
        return {
          ...item,
          round_id: gameRound.round_id,
        }
      })
      dispatch(rocketTshowActions.updateTemporaryOrders(list))
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [gameRound?.state])

  return (
    <form className="px-[10px] py-[14px] gap-0 mb-[5px] bg-[#25242B]" onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={3}>
        <Box width="50%">
          <Stack gap="2px" position="relative">
            <FormLabel htmlFor="enscape">{t('autoCashout')}</FormLabel>
            <QuickFormInput
              leftIcon={<Checkbox w="16px" {...register('autoEnscape')} />}
              inputProps={{
                ...register('enscape'),
                placeholder: 'Enscape',
                type: 'text',
                autoComplete: 'off',
                id: 'enscape',
                disabled: !watch('autoEnscape'),
                onChange: handleChange,
              }}
              minusCallback={() => handleMinus('enscape')}
              plusCallback={() => handlePlus('enscape')}
            />
          </Stack>
        </Box>
        <Box width="50%">
          <Stack gap="2px" position="relative">
            <Box className="flex justify-between items-center mb-2">
              <FormLabel htmlFor="amountInput" className="!mb-0">
                {t('home.bottomButton.betAmount')}
              </FormLabel>
              <Button
                fontSize={{
                  base: '11px',
                }}
                width="62px"
                height="24px"
                _hover={{ backgroundColor: '#d5fe47', color: '#242429' }}
                fontWeight={600}
                textTransform="uppercase"
                letterSpacing={1}
                backgroundColor="rgb(23, 23, 24)"
                color="white"
                transition="0.5s"
                onClick={() => {
                  const amountInput = f(
                    +wallet.amount <= +configRound?.maxBetAmount ? wallet.amount : configRound?.maxBetAmount,
                    {
                      decimal: currencyMap?.[wallet.currency]?.decimal ?? 0,
                      round: 'down',
                    },
                  )
                  setValue('amount', amountInput)
                }}
              >
                {t('maxBet')}
              </Button>
            </Box>
            <QuickFormInput
              leftIcon={<Image w="16px" src={getSymbolFromCurrency(wallet.currency)} alt="" />}
              inputProps={{
                ...register('amount'),
                placeholder: 'Amount',
                type: 'text',
                id: 'amount',
                onChange: handleChange,
              }}
              containerProps={{
                id: 'amountTooltip',
              }}
              minusCallback={() => handleMinus('amount')}
              plusCallback={() => handlePlus('amount')}
            />
          </Stack>
        </Box>
      </Flex>
      <Box position="relative" className="flex items-center gap-4">
        <Box>
          <Box borderRadius={6} backgroundColor="#171718" py={2} px={2} height="fit-content">
            <Stack backgroundColor="transparent" width="fit-content" borderRadius={4}>
              <Checkbox fontSize={11} {...register('isRepeat')}>
                <Text lineHeight={1} fontWeight={500}>
                  {t('repeat')}
                </Text>
              </Checkbox>
            </Stack>
            {/* {gameRound.proof && (
              <Flex
                gap={1}
                alignItems="center"
                // position="absolute"
                fontSize={14}
                lineHeight={1}
                mt={2}
              >
                <Text fontSize={14} lineHeight={1} fontWeight={600}>
                  {t('proof')}: ...{gameRound?.proof.substring(gameRound?.proof.length - 8)}{' '}
                </Text>
                <CopyBtn text={gameRound?.proof} />
              </Flex>
            )} */}
          </Box>
          {gameRound.proof && (
            <Flex
              gap={1}
              alignItems="center"
              // position="absolute"
              fontSize={14}
              lineHeight={1}
              mt={2}
            >
              <Text fontSize={14} lineHeight={1} fontWeight={600}>
                {t('proof')}: ...{gameRound?.proof.substring(gameRound?.proof.length - 8)}{' '}
              </Text>
              <CopyBtn text={gameRound?.proof} />
            </Flex>
          )}
        </Box>

        {listActiveOrders?.length > 0 && gameRound.state === ROUND_STATE.RUNNING ? (
          <ButtonCashOutAll></ButtonCashOutAll>
        ) : (
          <ButtonBet
            disabled={gameRound?.state !== ROUND_STATE.WAITING}
            inputProps={{
              isDisabled: gameRound?.state !== ROUND_STATE.WAITING,
            }}
            isLoading={loading}
          ></ButtonBet>
        )}
        {/* <Stack
          // textAlign="right"
          // mt={2}
          backgroundColor="transparent"
          // backgroundColor="#171718"
          width="fit-content"
          borderRadius={4}
          position="absolute"
          right={0}
          top="28%"
          maxW="25%"
          transform="translateY(-28%)"
        >
          <Checkbox px={1} py={1} fontSize={11} fontWeight={600} {...register('isRepeat')}>
            Repeat
          </Checkbox>
        </Stack> */}
      </Box>
    </form>
  )
}

export default QuickForm
