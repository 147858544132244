// import { useScale } from '@/bingo/hooks/useScale'
import { bingoActions, selectBingoTicketsCollected } from '@/bingo/redux/bingo.slice'
import { LocalBingoTicket } from '@/bingo/types/bingo'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Button, ButtonProps, Center, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { keyBy } from 'lodash'
import React, { useRef, useState } from 'react'
import { MdAddCircle, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import BingoDrawer from '../../Common/BingoDrawer'
import Ticket, { TicketData } from '../../Common/Ticket'
import EditTicket from '../EditTicket'
import FormFooter from '../FormFooter'
import BingoConfirm from '@/bingo/components/Common/BingoConfirm.tsx'
import { genTicketId } from '@/bingo/utils/BingoEngine.ts'

function AddTicketButton() {
  const editTicketDrawer = useDisclosure()

  // const scale = useScale()
  // const height = 186 * scale
  const { t } = useCustomTranslation()

  return (
    <>
      <Center
        flexDirection="column"
        borderRadius="5px"
        width={`calc(50% - 10px)`}
        // height={`${height}px`}
        aspectRatio="233.5/185.89"
        background="linear-gradient(180deg, rgba(255, 248, 241, 0.8) 0%, rgba(255, 234, 197, 0.8) 100%);"
        border="1px solid #fff"
        cursor="pointer"
        onClick={() => editTicketDrawer.onOpen()}
      >
        <MdAddCircle color="red" size={40} />
        <Text color="#7a7a7a" fontWeight="500" marginTop={1}>
          {t('bingo.addTicket')}
        </Text>
      </Center>
      <BingoDrawer isOpen={editTicketDrawer.isOpen} onClose={editTicketDrawer.onClose} title="Edit Ticket">
        <EditTicket onClose={editTicketDrawer.onClose} />
      </BingoDrawer>
    </>
  )
}

export function MyTicketCollection() {
  const confirmDeleteTicket = useDisclosure()
  const deletingTicket = useRef<TicketData | undefined>(undefined)
  const collections = useAppSelector(selectBingoTicketsCollected) || []
  const [selected, setSelected] = useState<string[]>([])

  function onClickTicket(ticket: LocalBingoTicket) {
    setSelected((prev) => {
      if (prev.includes(ticket.id)) {
        return prev.filter((id) => id != ticket.id)
      }
      return [...prev, ticket.id]
    })
  }
  const isSelectedAll = selected.length > 0 && selected.length == collections.length
  function selectAll() {
    if (isSelectedAll) {
      setSelected([])
    } else {
      setSelected(collections.map((item) => item.id))
    }
  }

  const dispatch = useAppDispatch()
  function selectTicket() {
    const details = keyBy(collections, 'id')

    const tickets = selected.map((id) => details[id])
    dispatch(bingoActions.updateTicketsGenerated(tickets))
    dispatch(bingoActions.naviate({ step: 'orderConfirmation' }))
  }
  const { t } = useCustomTranslation()

  const handleDeleteTicketClick = (ticket?: TicketData) => {
    if (!ticket) return

    deletingTicket.current = ticket
    confirmDeleteTicket.onOpen()
  }

  const deleteTicket = (ticket?: TicketData) => {
    if (!ticket) return

    const action = bingoActions.removeTicketCollection
    const localTicket = { ...ticket, id: genTicketId(ticket?.numbers) }
    dispatch(action(localTicket))
  }

  return (
    <>
      <Flex flex={1} flexDirection="column" userSelect="none" justifyContent="space-between">
        <Flex flexWrap="wrap" gap="20px" overflowY="auto" padding="10px 10px 20px">
          <AddTicketButton />
          {collections.map((ticket) => (
            <Ticket
              data={ticket}
              key={ticket.id}
              hideCollection
              selected={selected.includes(ticket.id)}
              onClickTicket={() => onClickTicket(ticket)}
              onClickDelete={handleDeleteTicketClick}
              canEdit
              canDelete
            />
          ))}
        </Flex>
        <FormFooter display="flex" flexDirection="row" color="red">
          <Center
            color={isSelectedAll ? 'red' : '#d3d3d3'}
            cursor="pointer"
            onClick={selectAll}
            userSelect="none"
            fontSize="lg"
            fontWeight="500"
          >
            {isSelectedAll ? <MdOutlineCheckBox fontSize={24} /> : <MdOutlineCheckBoxOutlineBlank fontSize={24} />}
            <Text marginLeft={2}>{t('bingo.selectAll')}</Text>
          </Center>
          <Flex flex={1} />
          {selected.length > 0 ? (
            <FooterButton onClick={selectTicket}>Selected {selected.length}</FooterButton>
          ) : (
            <Text fontSize="xl" fontWeight="500">
              {t('bingo.myCollection')} {collections.length}/60
            </Text>
          )}
        </FormFooter>
      </Flex>
      <BingoConfirm
        isOpen={confirmDeleteTicket.isOpen}
        onClose={confirmDeleteTicket.onClose}
        onOk={() => deleteTicket(deletingTicket.current)}
        title="Hint"
        content="Cancel collect this ticket?"
      />
    </>
  )
}

type FooterButtonProps = ButtonProps

const FooterButton = ({ children, ...rest }: FooterButtonProps) => {
  return (
    <Button
      background="linear-gradient(180deg, #fe543c 0%, #e84731 100%)"
      padding="8px 15px"
      textAlign="center"
      lineHeight="1"
      color="white"
      fontWeight="500"
      fontSize="18px"
      borderRadius="5px"
      minWidth="150px"
      height="40px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}
