import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import { selectBingoNumbersOfTickets, selectBingoTicketUnitPrice } from '@/bingo/redux/bingo.slice.ts'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import React from 'react'
import { selectWallet } from '@store/modules/wallet.slice.ts'

const SubTotal = () => {
  const walletSelected = useAppSelector(selectWallet)
  const numbersOfTickets = useAppSelector(selectBingoNumbersOfTickets)
  const ticketUnitPrice = useAppSelector(selectBingoTicketUnitPrice)
  const subTotal = numbersOfTickets * ticketUnitPrice
  const { t } = useCustomTranslation()

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderTop="2px solid white"
      padding="10px 0 0"
      marginTop="auto"
    >
      <SubTitle>{t('bingo.subtotal')}</SubTitle>
      <Flex alignItems="center" justifyContent="center" gap="5px">
        <BingoCurrencyIcon currency={walletSelected?.currency} />
        <SubTitle>{subTotal}</SubTitle>
      </Flex>
    </Flex>
  )
}

export default SubTotal
