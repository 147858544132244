import { BingoBetExtraBall, BingoBetTickets, BingoOrder } from '@/@generated/gql/graphql-hash'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import {} from '@/redux/store'
import { f } from 'fintech-number'
import { getColorBall } from './TabResults'
import { CopyBtn } from '@/components/common/CopyBtn'
import { RiArrowRightDoubleFill } from 'react-icons/ri'
import { AppDrawer } from '@/components/common/AppDrawer'
import { TicketDetail } from './TicketDetail'
type Props = {
  order: BingoOrder
}

const TabExtraBalls = (props: Props) => {
  const { t } = useCustomTranslation()
  const { order } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [extraBallSelected, setExtraBallSelected] = useState<BingoBetExtraBall>()
  const { bingo_bet_extra_balls } = order
  const total =
    bingo_bet_extra_balls?.length > 0
      ? bingo_bet_extra_balls?.reduce(
          (result: number, currentVal: BingoBetExtraBall) => result + +currentVal?.amount,
          0,
        )
      : 0

  const onClickOpenDetailExtraBalls = (e: any, item: BingoBetExtraBall) => {
    e.preventDefault()
    setExtraBallSelected(item)
    onOpen()
  }

  const formatOrderId = (orderId?: string) => {
    if (!orderId) return ''

    const formatOrderIdRegex = /^(.{6}).*(.{6})$/

    return orderId.replace(formatOrderIdRegex, '$1...$2')
    // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
  }
  return (
    <Box textAlign="center">
      <Text>
        {bingo_bet_extra_balls?.length} additional balls total, {f(total)} in total
      </Text>
      <Flex p={4} background="#fff" borderRadius={12} flexDirection="column" mt={2} gap={4}>
        {bingo_bet_extra_balls?.length > 0 ? (
          [...bingo_bet_extra_balls]?.sort((a: BingoBetExtraBall, b: BingoBetExtraBall) => a?.index - b?.index).map((item: BingoBetExtraBall, index: number) => {
            const ballIndex = item?.index - 30
            return (
              <Flex
                key={index}
                gap={2}
                alignItems="center"
                cursor="pointer"
                onClick={(e) => {
                  onClickOpenDetailExtraBalls(e, item)
                }}
              >
                <Text color="#ff4218" fontWeight={500} w="40px">
                  {ballIndex + 1} / 10:
                </Text>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  border={`1px solid ${getColorBall(item?.number)}`}
                  borderRadius="50%"
                  p={2}
                  w="32px"
                  h="32px"
                >
                  <Text fontWeight={700} color={getColorBall(item?.number)}>
                    {item?.number}
                  </Text>
                </Flex>
                <Box mt={1} flex={1}>
                  <Text textAlign="center" display="inline-block">
                    {formatOrderId(item?.id)}
                    <Box display="inline-block" ml="2px" mb="-2px">
                      <CopyBtn text={item?.id} colorClassName="#000" />
                    </Box>
                  </Text>
                </Box>
                <Flex ml="auto" alignItems="center" justifyContent="flex-end">
                  <Text color="#ff4218" fontWeight={500}>
                    {f(item?.amount)}
                  </Text>
                  <RiArrowRightDoubleFill />
                </Flex>
              </Flex>
            )
          })
        ) : (
          <Box>No records</Box>
        )}
      </Flex>
      {isOpen && (
        <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
          <TicketDetail order={order} ticket={{} as BingoBetTickets} extraBallIdx={extraBallSelected} />
        </AppDrawer>
      )}
    </Box>
  )
}

export default TabExtraBalls
