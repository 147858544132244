import { BingoBetTickets, BingoOrder } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import { CopyBtn } from '@/components/common/CopyBtn'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import {} from '@/redux/store'
import { Box, Flex, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React, { useState } from 'react'
import Ticket from '../Common/Ticket'
import { TicketDetail } from './TicketDetail'

type Props = {
  order: BingoOrder
}

export const convertWinningPattern = (input: string) => {
  // Step 1: Replace '1' with 'x' and '0' with 'o'
  const converted = input.split('').reverse().join('').replace(/1/g, 'x').replace(/0/g, 'o')

  // Step 2: Add a pipe '|' after every 5 characters
  const chunkSize = 5
  let result = ''

  for (let i = 0; i < converted.length; i += chunkSize) {
    result += converted.substring(i, i + chunkSize)
    if (i + chunkSize < converted.length) {
      result += '|'
    }
  }
  return result
}
const TabTickets = (props: Props) => {
  const { t } = useCustomTranslation()
  const { order } = props
  const [ticketSelected, setTicketSelected] = useState<BingoBetTickets>()
  const listTickets = order?.bingo_bet_tickets
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onClickOpenDetailTicket = (e: any, item: BingoBetTickets) => {
    e.preventDefault()
    setTicketSelected(item)
    onOpen()
  }

  const formatOrderId = (orderId?: string) => {
    if (!orderId) return ''

    const formatOrderIdRegex = /^(.{8}).*(.{8})$/

    return orderId.replace(formatOrderIdRegex, '$1...$2')
    // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const resultBalls = [...order?.bingo_round?.result.slice(0, 30), ...order?.bingo_bet_extra_balls?.map(item => item?.number)]
  return (
    <Box>
      <Text textAlign="center">
        Total tickets: {listTickets?.length}, total amount: {f(order?.amount)}
      </Text>
      {listTickets?.length > 0 ? (
        <Grid className="grid-cols-2" gap={4} mt={4}>
          {listTickets?.map((item: BingoBetTickets, index: number) => {
            return (
              <Box
                background="#fff"
                borderRadius={8}
                onClick={(e) => onClickOpenDetailTicket(e, item)}
                cursor="pointer"
                key={index}
              >
                <Ticket
                  data={item}
                  ticketUnitPrice={order?.ticket_unit_price}
                  resultBalls={resultBalls}
                  winningPattern={item?.pattern ? convertWinningPattern(item?.pattern) : ''}
                  // expectedBalls={false}
                  isBetSuccess
                  w="100%"
                  h="100%"
                  patternBoardProps={{
                    fontSize: '14px'
                  }}
                >
                  <Box textAlign="center" paddingBottom="10px">
                    <Text textAlign="center" display="inline-block">
                      {formatOrderId(item?.id)}
                      <Box display="inline-block" ml="4px" mb="-2px">
                        <CopyBtn text={item?.id} colorClassName="#000" />
                      </Box>
                    </Text>
                  </Box>
                </Ticket>
              </Box>
            )
          })}
        </Grid>
      ) : (
        <Flex p={4} background="#fff" borderRadius={12} w="100%" mt={2} justifyContent="center" alignItems="center">
          <Text>No records</Text>
        </Flex>
      )}
      {isOpen && (
        <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
          <TicketDetail order={order} ticket={ticketSelected!} />
        </AppDrawer>
      )}
    </Box>
  )
}

export default TabTickets
