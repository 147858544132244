import { Box, BoxProps, Stack } from '@chakra-ui/react'
import React from 'react'

type TabsBackgroundProps = BoxProps & {
  children: React.ReactNode
}

const FormBackground = ({ children, ...rest }: TabsBackgroundProps) => {
  return (
    <Box
      position="relative"
      zIndex="0"
      _before={{
        content: '""',
        display: 'block',
        aspectRatio: '748/748',
        background: 'url("/bingo/images/bg-tabs-section-top.webp") no-repeat top center',
        backgroundSize: 'cover',
        position: 'relative',
        zIndex: '1',
      }}
      _after={{
        content: '""',
        display: 'block',
        background: 'url("/bingo/images/bg-tabs-section-repeat.webp") repeat-y left center',
        backgroundSize: 'contain',
        position: 'absolute',
        left: '0',
        right: '0',
        top: '95px',
        bottom: '0',
      }}
      {...rest}
    >
      <Stack
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="2"
        justifyContent="flex-start"
        height="100%"
      >
        {children}
      </Stack>
    </Box>
  )
}

export default FormBackground
