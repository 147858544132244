import { BoxProps, Flex } from '@chakra-ui/react'
import React from 'react';
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type PointChipProps = BoxProps & {
  point: number,
  isNew?: boolean,
  showBg?: boolean,
  showBorder?: boolean,
}

export const colorByPoint = (point: number): string => {
  switch (true) {
    case point >= 10:
      return '#f5b638'
    case point >= 6 && point < 10:
      return '#9685ff'
    case point >= 2 && point < 6:
      return '#30dca8'
    default:
      return '#319fdd'
  }
}

export const bgColorByPoint = (point: number): string => {
  switch (true) {
    case point >= 10:
      return '#2a2610'
    case point >= 6 && point < 10:
      return '#353347'
    case point >= 2 && point < 6:
      return '#0c2b23'
    default:
      return '#002e39'
  }
}

const PointChip = ({point, isNew, showBg = true, showBorder = false, ...rest}: PointChipProps) => {
  return (
    <Flex
      display="inline-flex"
      border={showBorder ? `1px solid ${colorByPoint(point)}` : 'none'}
      borderRadius="4px"
      alignItems="center"
      justifyContent="center"
      padding="3px 6px"
      lineHeight="1"
      font
      color={colorByPoint(point)}
      backgroundColor={showBg ? bgColorByPoint(point) : ''}
      minWidth="40px"
      position="relative"
      {...NotoSansFontFamily.medium}
      fontSize="18px"
      {...rest}
    >
      <RocketText>
        {Number(point).toFixed(2)}
      </RocketText>
      {isNew && (
        <Flex
          width="16px"
          height="16px"
          position="absolute"
          top="-8px"
          right="-7px"
          borderRadius="50px"
          color="white"
          backgroundColor="red"
          alignItems="center"
          justifyContent="center"
          lineHeight="1"
          fontSize="7px"
        >
          New
        </Flex>
      )}
    </Flex>
  )
}

export default PointChip;
