import { BingoTicketInput } from '@/@generated/gql/graphql-hash'
import { bingoActions, selectBingoIsCollectedTicket } from '@/bingo/redux/bingo.slice'
import { genTicketId } from '@/bingo/utils/BingoEngine'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'
import { IoStarOutline, IoStarSharp } from 'react-icons/io5'

type TicketCollectProps = FlexProps & {
  ticket?: BingoTicketInput
}

const TicketCollect = ({ ticket }: TicketCollectProps) => {
  const isCollected = useAppSelector(selectBingoIsCollectedTicket(genTicketId(ticket?.numbers)))
  const dispatch = useAppDispatch()

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (!ticket) return
    const action = isCollected ? bingoActions.removeTicketCollection : bingoActions.addTicketCollection
    const localTicket = { ...ticket, id: genTicketId(ticket?.numbers) }
    dispatch(action(localTicket))
  }

  return (
    <Flex
      position="absolute"
      top="-5px"
      left="-5px"
      width="20px"
      height="20px"
      borderRadius="50%"
      backgroundColor={isCollected ? '#fa533a' : 'white'}
      padding={1}
      color={isCollected ? 'white' : 'gray'}
      alignItems="center"
      justifyContent="center"
      fontSize="14px"
      cursor="pointer"
      zIndex="1"
      onClick={(e) => handleClick(e)}
    >
      {isCollected ? <IoStarSharp /> : <IoStarOutline />}
    </Flex>
  )
}

export default TicketCollect
