import React, { useEffect, useRef } from 'react'
import { useRocketGamePlay } from './hooks/useRocketGamePlay'
import ListCurrentBet from '../ListCurrentBet'
import TopPlayers from './TopPlayers'
import './styles/rocket.scss'
import { MultiplierDisplay } from './StageComponent'
import RocketBackground from '../BgAnimation/RocketBackground'
import ListPastsOnScreen from './on-screen/ListPastsOnScreen'
import WinningAnimation from './WinningAnimation'
import ListPlayerBettingInRound from './on-screen/ListPlayerBettingInRound'
import { Box, Image, Stack, useDisclosure } from '@chakra-ui/react'
import Stats from '@components/rocket/GamePlay/Stats'
import { useRocketSound } from './hooks/useRocketSound'
import useGameId from '@/hooks/useGameId'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getCrashResultHistory, getMyOrders, ROUND_STATE, selectConfigRound } from '@/redux/store/modules/rocketTshow'
import { RocketCountdown } from './CountDown'
import BreadcrumbRocket from '../Breadcrumb'

const Rocket = () => {
  const container = useRef<HTMLDivElement>(null)
  const rocketRef = useRef<HTMLDivElement>(null)
  const rocketElId = 'rocket-box'
  const statsDrawer = useDisclosure()

  const {
    pathRef,
    containerSize,
    gameState,
    gameRound,
    countdown,
    topCashoutOrder,
    listActiveOrders,
    rewardInfo,
    showWinAnim,
  } = useRocketGamePlay({
    rocketRef,
    containerRef: container,
  })
  useRocketSound(gameState, countdown)

  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const roundConfig = useAppSelector(selectConfigRound)

  const getListOrder = () => {
    dispatch(
      getMyOrders({
        game_id: gameId,
        page: 0,
        limit: 10,
      }),
    )
  }

  useEffect(() => {
    getListOrder()
  }, [])

  useEffect(() => {
    if (gameRound?.state === ROUND_STATE.END) getListOrder()
  }, [gameRound])

  useEffect(() => {
    if (gameState === 'ENDED') {
      dispatch(
        getCrashResultHistory({
          game_id: gameId,
        }),
      )
    }
  }, [gameId, gameState, dispatch])

  return (
    <div ref={container} id="rocket-container" className="relative overflow-hidden aspect-[1125/1331] select-none">
      <RocketBackground animationDuration={3.5} isAnimating={gameState === 'PLAYING'} gameState={gameState} />
      <svg
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        viewBox={`0 0 ${containerSize.width} ${containerSize.height}`}
        preserveAspectRatio="none"
      >
        <path
          ref={pathRef}
          stroke="#fff000"
          strokeWidth="3"
          fill="none"
          strokeDasharray="3,5"
          style={{ vectorEffect: 'non-scaling-stroke' }}
        />
      </svg>
      {/* <RocketCountdown countdown={10} duration={15} /> */}
      {gameState === 'WAITING' ? (
        <RocketCountdown countdown={countdown} duration={(roundConfig?.waitingTime ?? 9) - 2} />
      ) : undefined}
      {gameState !== 'WAITING' && <MultiplierDisplay multiplier={gameRound.multiplier} gameState={gameState} />}
      <div id={rocketElId} ref={rocketRef} className="rocket-sprite pointer-events-none">
        <div className="relative w-[110px] aspect-[277/397] object-contain">
          {gameState === 'PLAYING' ? (
            <>
              <Image src="/images/rockets/img_rocket.webp" alt="rocket" position="relative" zIndex={1} />
              <Box
                sx={{
                  width: '65px',
                  height: '115px',
                  background: 'url(/images/rockets/rocket-firing-sprite.webp) no-repeat center',
                  backgroundSize: '565px',
                  backgroundPosition: '-13px 0px',
                  position: 'absolute',
                  left: '20px',
                  zIndex: 1,
                  transform: 'translateY(-28%)',
                  animation: 'firing 0.5s infinite 0s step-end',
                }}
              />
            </>
          ) : undefined}

          {gameState === 'ENDED' ? (
            <Box
              sx={{
                width: '150px',
                height: '150px',
                background: 'url(/images/rockets/img_sprites_explode.webp) no-repeat',
                backgroundSize: 'cover',
                transform:
                  Number(gameRound.multiplier ?? '1') <= 1 ? 'translate(-10px, -40px)' : 'translate(-40px, -40px)',
                animation: 'rocket-explode 1.2s steps(10) forwards',
              }}
            />
          ) : undefined}
        </div>
      </div>
      {gameState === 'REWARD' && <TopPlayers players={topCashoutOrder} />}
      {listActiveOrders?.length > 0 && <ListCurrentBet />}
      {gameState === 'ENDED' && showWinAnim && rewardInfo && <WinningAnimation reward={rewardInfo} />}
      <Stack className="absolute right-0 left-0 z-[11] zoom-container" mt="6px">
        <BreadcrumbRocket />
      </Stack>
      <ListPlayerBettingInRound />
      <ListPastsOnScreen openStats={statsDrawer.onOpen} />
      <Stats isOpen={statsDrawer.isOpen} closeStats={statsDrawer.onClose} />
    </div>
  )
}

export default Rocket
