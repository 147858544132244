export let preventBingoBall = (balls: number[], ignoreAnimate?: boolean) => Promise.resolve(balls)

export function setPreventBingoBall(handler: typeof preventBingoBall) {
  preventBingoBall = handler
}

export function useBingoGamePlay() {
  return {
    prevent: preventBingoBall,
  }
}
