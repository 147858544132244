import BottomRow from '@/bingo/components/GameplayStage/LotteryPatternsArea/BottomRow'
import GuideButton from '@/bingo/components/GameplayStage/LotteryPatternsArea/GuideButton.tsx'
import GuideButtonsContainer from '@/bingo/components/GameplayStage/LotteryPatternsArea/GuideButtonsContainer.tsx'
import TopRow from '@/bingo/components/GameplayStage/LotteryPatternsArea/TopRow'
import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Stack, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MenuBingo } from '../../Menu'

const LotteryPatternsArea = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [popup, setPopup] = useState<'Gameplay' | 'Verification'>('Gameplay')
  const { t } = useCustomTranslation()

  const onClickOpenMenu = (str: string) => {
    setPopup(str)
    onOpen()
  }

  return (
    <>
      <Stack
        height="40%"
        gap="5px"
        justifyContent="center"
        flexWrap="wrap"
        padding="10px 30px 5px 30px"
        position="relative"
      >
        <GuideButtonsContainer>
          <GuideButton
            onClick={() => {
              onClickOpenMenu('Gameplay')
            }}
          >
            {t('bingo.description')}
          </GuideButton>
          <GuideButton
            onClick={() => {
              onClickOpenMenu('Verification')
            }}
          >
            {t('bingo.fairness')}
          </GuideButton>
        </GuideButtonsContainer>
        <TopRow />
        <BottomRow />
      </Stack>
      {isOpen && (
        <AppDrawer isOpen={isOpen} onClose={onClose} overlay>
          <MenuBingo popup={popup} />
        </AppDrawer>
      )}
    </>
  )
}

export default LotteryPatternsArea
