import { bingoActions } from '@/bingo/redux/bingo.slice'
import { useAppDispatch } from '@/redux/store'
import React, { useEffect } from 'react'

export default function LastOrderSubscription() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(bingoActions.getBingoPlayingOrder())
  }, [])

  return <></>
}
