import BallNumber from '@/bingo/components/GameplayStage/MiningArea/Ball/BallNumber.tsx'
import InnerBall from '@/bingo/components/GameplayStage/MiningArea/Ball/InnerBall.tsx'
import { bingoConfig } from '@/bingo/const/bingoConfigs'
import { getBingoGroupByNumber } from '@/bingo/const/bingoHelpers.ts'
import { Box, BoxProps, Center } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useState } from 'react'

type BallProps = {
  value: number
  size?: number
  animated?: boolean
}

const colorsByVariant: Record<string, Record<string, string>> = {
  B: {
    bgBall: 'linear-gradient(161deg, #69a5ff 9.32%, #448bf7 50.52%, #448bf7 86.17%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #f9fbff 0%, #bad5ff 100%)',
    bgText: 'linear-gradient(180deg, #448bf7 0%, #2173ef 100%)',
    color: '#448bf7',
  },
  I: {
    bgBall: 'linear-gradient(180deg, #ff4a5f 0%, #f13d52 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff5f6 0%, #ffd2d7 100%)',
    bgText: 'linear-gradient(180deg, #e25565 0%, #d23547 100%)',
    color: '#e25565',
  },
  N: {
    bgBall: 'linear-gradient(180deg, #7e34e4 0%, #4518a8 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #beb1d7 100%)',
    bgText: 'linear-gradient(180deg, #9658cc 0%, #7541a2 100%)',
    color: '#9658cc',
  },
  G: {
    bgBall: 'linear-gradient(180deg, #8ada76 0%, #3d822d 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #bedaba 100%)',
    bgText: 'linear-gradient(180deg, #5eae47 0%, #4a8b37 100%)',
    color: '#5eae47',
  },
  O: {
    bgBall: 'linear-gradient(180deg, #c9983e 0%, #7c5d20 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #efe3c2 100%)',
    bgText: 'linear-gradient(180deg, #d19d41 0%, #a87e34 100%)',
    color: '#d19d41',
  },
}

const MotionBox = motion(Box)
const ballSize = 48

const Ball = ({ value, ...rest }: BoxProps & BallProps) => {
  const variant = getBingoGroupByNumber(value)

  return (
    <Box
      padding="3.5px"
      borderRadius="50px"
      background={colorsByVariant[variant].bgBall}
      boxShadow="0 -1px 0.078rem 0 rgba(255,255,255,0.2) inset"
      w={`${ballSize}px`}
      h={`${ballSize}px`}
      position="relative"
      {...rest}
    >
      <InnerBall bg={colorsByVariant[variant].bgInner} />
      <BallNumber value={value} color={colorsByVariant[variant].color} />
    </Box>
  )
}
export const SmallBall = ({ value, ...rest }: BoxProps & BallProps) => {
  const variant = getBingoGroupByNumber(value)

  return (
    <Box
      padding="1.5px"
      borderRadius="50px"
      background={colorsByVariant[variant].bgBall}
      boxShadow="0 -1px 0.078rem 0 rgba(255,255,255,0.2) inset"
      w={`28px`}
      h={`28px`}
      position="relative"
      {...rest}
    >
      <InnerBall bg={colorsByVariant[variant].bgInner} />
      <BallNumber value={value} color={colorsByVariant[variant].color} fontWeight="400" fontSize="16px" />
    </Box>
  )
}
export const BallAnimated = ({ value, animated }: BallProps) => {
  const variant = getBingoGroupByNumber(value)
  const [isComplete, setIsComplete] = useState(!animated)

  return (
    <Center>
      <MotionBox
        padding="3.5px"
        borderRadius="50px"
        background={colorsByVariant[variant].bgBall}
        boxShadow="0 -1px 0.078rem 0 rgba(255,255,255,0.2) inset"
        opacity={isComplete ? 1 : 0}
        w={animated ? 0 : `${ballSize}px`}
        h={animated ? 0 : `${ballSize}px`}
        animate={{ width: ballSize, height: ballSize }}
        transition={{ duration: bingoConfig.mining.ballFlyDuration / 1000 }}
        position="relative"
        onUpdate={(v) => {
          const completed = Number(v.width) > 0.99 * ballSize
          setIsComplete(completed)
        }}
      >
        <InnerBall bg={colorsByVariant[variant].bgInner} />
        <BallNumber value={value} color={colorsByVariant[variant].color} />
      </MotionBox>
    </Center>
  )
}

export default Ball
