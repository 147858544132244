import BallsCounter from '@/bingo/components/GameplayStage/MiningArea/BallsCounter.tsx'
import ExploitedBalls from '@/bingo/components/GameplayStage/MiningArea/ExploitedBalls.tsx'
import RoundId from '@/bingo/components/GameplayStage/MiningArea/RoundId.tsx'
import SoundController from '@/bingo/components/GameplayStage/MiningArea/SoundController.tsx'
import { bingoConfig } from '@/bingo/const/bingoConfigs'
import { useBingo } from '@/bingo/hooks/useBingo'
import { useBingoExtraBallWarning } from '@/bingo/hooks/useBingoExtraBall'
import { setPreventBingoBall } from '@/bingo/hooks/useBingoGamePlay'
import { bingoActions, selectBingoGameRoundId, selectBingoIsMinningExtraBall } from '@/bingo/redux/bingo.slice'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Box, Center, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Ball from './Ball'
import { Mining } from './Miner'

const duration = bingoConfig.mining.ballFlyDuration / 1000

const MotionBox = motion(Box)

interface FlyingElementProps {
  startRef: React.MutableRefObject<HTMLElement | null>
  endRef: React.MutableRefObject<HTMLElement | null>
  children: any
}

const FlyingElement = ({ startRef, endRef, children }: FlyingElementProps) => {
  const [start, setStart] = useState<DOMRect>()
  const [end, setEnd] = useState<DOMRect>()
  const [completed, setCompleted] = useState(!children)

  useEffect(() => {
    setCompleted(!children)
  }, [children])

  useEffect(() => {
    if (startRef.current && endRef.current) {
      const startRect = startRef.current.getBoundingClientRect()
      const endRect = endRef.current.getBoundingClientRect()

      setStart(startRect)
      setEnd(endRect)
    }
  }, [startRef, endRef])

  if (!start || !end) return null

  if (completed) return null

  return (
    <MotionBox
      style={{
        position: 'fixed',
        transformOrigin: 'left top',
        willChange: 'transform, opacity',
      }}
      initial="step1"
      animate={['step2', 'step3']}
      variants={{
        step1: {
          left: start.x,
          top: start.y,
          scale: 0,
          opacity: 1,
        },
        step2: {
          left: end.x,
          top: end.y,
          scale: 1,
          opacity: 1,
          transition: { duration, ease: 'easeInOut' },
        },
        step3: {
          opacity: 0,
          transition: { delay: duration, duration: 0.2, ease: 'easeInOut' },
        },
      }}
      onAnimationComplete={() => setCompleted(true)}
    >
      {children}
    </MotionBox>
  )
}

const MiningArea = () => {
  const [isMoved, setIsMoved] = useState(false)
  const [balls, setBalls] = useState<number[]>([])
  const [totalBalls, setTotalBalls] = useState<number>(balls.length)
  const [newBall, setNewBall] = useState<number>()
  const [ballPrevented, setBallPrevented] = useState<number>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isMoved) return
    const timeout = setTimeout(() => {
      setIsMoved(false)
    }, bingoConfig.mining.ballFlyDuration)
    return () => clearTimeout(timeout)
  }, [isMoved])

  const [displayBalls, setDisplayBalls] = useState<number[]>([])

  function onComplete() {
    if (isEmpty(balls)) return

    setTotalBalls(balls.length)
    setDisplayBalls(balls.slice(-6).reverse())
    setIsMoved(true)
    setBallPrevented(newBall)
    setNewBall(undefined)
    setTimeout(() => {
      if (isEmpty(balls)) return
      dispatch(bingoActions.roundBallsUpdated(balls))
    }, bingoConfig.mining.ballFlyDuration)
  }

  async function prevent(balls: number[], ignoreAnimate?: boolean) {
    if (ignoreAnimate) {
      setTotalBalls(balls.length)
      setDisplayBalls(balls.slice(-6).reverse())
      return []
    }
    if (!balls?.length) return []

    setBalls(balls)
    setNewBall(balls[balls.length - 1])
    return balls
  }
  setPreventBingoBall(prevent)
  const { balls: ballCount, extraBalls: extraBallCount } = bingoConfig.mining
  const currentBallCount = totalBalls >= ballCount ? ballCount : totalBalls
  const _currentExtraBallCount = totalBalls - ballCount
  const currentExtraBallCount = _currentExtraBallCount > 0 ? _currentExtraBallCount : 0
  const isExtraBall = useAppSelector(selectBingoIsMinningExtraBall)
  const roundId = useAppSelector(selectBingoGameRoundId)
  const ball = newBall || ballPrevented

  const fromRef = useRef<HTMLElement | null>(null)
  const toRef = useRef<HTMLElement | null>(null)

  return (
    <Box position="relative" display="flex" justifyContent="space-between" alignItems="center" height="60%">
      <RoundId value={roundId} />
      <SoundController />
      <Mining repeat={isExtraBall ? 3 : 1} onComplete={onComplete} ballRef={fromRef} stoped={!newBall} />
      <ExploitedBalls balls={displayBalls} ballRef={toRef} animated={isMoved} />
      {ball && isMoved && (
        <FlyingElement startRef={fromRef} endRef={toRef}>
          <Ball value={ball} />
        </FlyingElement>
      )}
      <BallsCounter
        currentNormalBalls={currentBallCount}
        totalNormalBalls={ballCount}
        currentExtraBalls={currentExtraBallCount}
        totalExtraBalls={extraBallCount}
      />
      <BingoWarning />
      <BingoExtraBallWarning />
    </Box>
  )
}
const MotionImage = motion(Image)

function BingoWarning() {
  const src = '/bingo/images/mining/ordinary-ball.c113482.webp'

  const hasBingo = useBingo()
  const [isDisplay, setDisplay] = useState(true)

  if (!isDisplay || !hasBingo) return null

  return (
    <Center position="absolute" top={0} left={0} right={0} bottom={-2} borderRadius={16}>
      <MotionImage
        initial="step1"
        animate={['step2', 'step3']}
        variants={{
          step1: {
            opacity: 1,
            scale: 0.5,
          },
          step2: {
            scale: 1,
            opacity: 1,
            transition: { duration: 0.3, ease: 'easeInOut' },
          },
          step3: {
            opacity: 0,
            transition: { delay: 1.5, duration: 0.5, ease: 'easeInOut' },
          },
        }}
        src={src}
        onAnimationComplete={() => setDisplay(false)}
      />
    </Center>
  )
}
function BingoExtraBallWarning() {
  const src = '/bingo/images/mining/extra-ball-en.522c902.webp'

  const hasBingo = useBingoExtraBallWarning()
  const [isDisplay, setDisplay] = useState(true)

  if (!isDisplay || !hasBingo) return null

  return (
    <Center position="absolute" top={0} left={0} right={0} bottom={-2} borderRadius={16}>
      <MotionImage
        initial="step1"
        animate={['step2', 'step3']}
        variants={{
          step1: {
            opacity: 1,
            scale: 0.5,
          },
          step2: {
            scale: 1,
            opacity: 1,
            transition: { duration: 0.3, ease: 'easeInOut' },
          },
          step3: {
            opacity: 0,
            transition: { delay: 1.5, duration: 0.5, ease: 'easeInOut' },
          },
        }}
        src={src}
        onAnimationComplete={() => setDisplay(false)}
      />
    </Center>
  )
}

export default MiningArea
