import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppSelector } from '@/redux/store'
import { selectConfigRound } from '@/redux/store/modules/rocketTshow'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React from 'react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'

export default function RoundConfig() {
  const roundConfig = useAppSelector(selectConfigRound)
  const { t } = useCustomTranslation()

  return (
    <Flex
      width="100%"
      gap={1}
      justifyContent="space-between"
      {...NotoSansFontFamily.normal}
      color="#9cb4d9"
      fontSize="12px"
      lineHeight="1"
      fontStyle="italic"
      background="#26303e"
      padding="12px 16px 12px"
    >
      <Stack gap={1.5}>
        <Text>{t('betLimit')}:</Text>
        <Text>
          {f(+roundConfig?.minBetAmount, {
            decimal: 2,
          })}{' '}
          -{' '}
          {f(+roundConfig?.maxBetAmount, {
            decimal: 2,
          })}
        </Text>
      </Stack>
      <Stack gap={1.5}>
        <Text>{t('maxRewardAltitude')}:</Text>
        <Text>
          {f(+roundConfig?.maxMultiple, {
            decimal: 2,
          })}
          x
        </Text>
      </Stack>
      <Stack gap={1.5}>
        <Text>{t('maxProfit')}:</Text>
        <Text>
          {f(+roundConfig?.maxBetAmount, {
            decimal: 2,
          })}
        </Text>
      </Stack>
    </Flex>
  )
}
