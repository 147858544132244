import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { GameStateEnum } from './hooks/useTShowGamePlay'

type BackgroundProps = {
  gameState: GameStateEnum
  isAnimating: boolean
}

const TShowBackground = ({ isAnimating, gameState }: BackgroundProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      if (isAnimating) {
        videoRef.current.play()
      } else {
        videoRef.current.pause()
      }
    }
  }, [isAnimating])

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 0,
      }}
    >
      {gameState === 'PLAYING' || gameState === 'ENDED' ? (
        <video
          ref={videoRef}
          data-v-450f549d=""
          preload="auto"
          loop
          playsInline
          tabIndex={-1}
          muted
          poster="/images/tshows/img_bg.jpg"
          autoPlay
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        >
          <source src="/images/tshows/tshow-bg.mp4" type="video/mp4" />
        </video>
      ) : (
        <Box
          as="img"
          src="/images/tshows/img_bg_blue.webp"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}
    </Box>
  )
}

export default TShowBackground
