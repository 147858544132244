import { BingoOrder } from '@/@generated/gql/graphql-hash'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Text, Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import {} from '@/redux/store'
import { AppDrawer } from '@/components/common/AppDrawer'
import { MenuBingo } from '../Menu'
import { getBingoGroupByNumber } from '@/bingo/const/bingoHelpers.ts'
import { ballsColorByGroup } from '@/bingo/components/FormSection/Mining/ResultRow.tsx'

type Props = {
  order: BingoOrder
}
export const getColorBall = (num: number) => {
  const group = getBingoGroupByNumber(num)
  return ballsColorByGroup[group]
}
const TabResults = (props: Props) => {
  const { t } = useCustomTranslation()
  const { order } = props

  const listData = order?.bingo_round?.result
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box className="drawerBingoContainer">
      {listData?.length > 0 ? (
        <>
          <Flex
            p={3}
            mt={4}
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(180deg, #fff 0%, #ffffffcc 100%)"
            borderRadius={8}
            flexDirection="column"
          >
            <Text fontSize={18} fontWeight={500}>
              Ordinary balls
            </Text>
            <Flex flexWrap="wrap" gap={2} mt={2}>
              {listData.slice(0, 30).map((item: number, index: number) => (
                <Flex
                  key={index}
                  border={`1px solid ${getColorBall(item)}`}
                  borderRadius="50%"
                  p={3}
                  w="34px"
                  h="34px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontWeight={700} color={getColorBall(item)}>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Flex
            p={3}
            mt={4}
            alignItems="center"
            justifyContent="center"
            background="linear-gradient(180deg, #fff 0%, #ffffffcc 100%)"
            borderRadius={8}
            flexDirection="column"
          >
            <Text fontSize={18} fontWeight={500}>
              Extra balls
            </Text>
            <Flex flexWrap="wrap" gap={2} mt={2}>
              {listData.slice(30, listData?.length).map((item: number, index: number) => (
                <Flex
                  key={index}
                  border={`1px solid ${getColorBall(item)}`}
                  borderRadius="50%"
                  p={3}
                  w="34px"
                  h="34px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontWeight={700} color={getColorBall(item)}>
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Text
            textAlign="center"
            color="#059aff"
            mt={8}
            textDecoration="underline"
            cursor="pointer"
            onClick={() => onOpen()}
          >
            Verify the fairness of the results &#xbb;
          </Text>
        </>
      ) : (
        <Text>Please try again</Text>
      )}

      {isOpen && (
        <AppDrawer isOpen={isOpen} onClose={onClose} overlay>
          <MenuBingo popup={'Verification'} />
        </AppDrawer>
      )}
    </Box>
  )
}

export default TabResults
