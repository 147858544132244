import { Box, Stack, StackProps } from '@chakra-ui/react'
import React from 'react'

type FormContainerProps = {
  children?: React.ReactNode
  title?: string | React.ReactNode
  backgroundProps?: StackProps
}

const FormContainer = ({ backgroundProps, children }: FormContainerProps) => {
  return (
    <Box height="100%">
      <Stack height="100%" gap="0" {...backgroundProps}>
        {children}
      </Stack>
    </Box>
  )
}

export default FormContainer
