import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type GuideButtonProps = ButtonProps & {
  children?: React.ReactNode
}

const GuideButton = ({children, ...rest}: GuideButtonProps) => {
  return (
    <Button
      background="url('/bingo/images/bg-guide-button.webp') no-repeat top center"
      backgroundSize="contain"
      width="80px"
      height="auto"
      aspectRatio="130/44"
      fontSize="15px"
      lineHeight="1"
      borderRadius="0"
      color="#fff0d3"
      fontWeight="400"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default GuideButton;
