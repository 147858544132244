import { BingoTicketInput } from '@/@generated/gql/graphql-hash'
import TicketButton from '@/bingo/components/Common/Ticket/TicketButton.tsx'
import TicketCollect from '@/bingo/components/Common/Ticket/TicketCollect.tsx'
import TicketMultiplier from '@/bingo/components/Common/Ticket/TicketMultiplier.tsx'
import TicketPatternBoard, { TicketPatternBoardProps } from '@/bingo/components/Common/Ticket/TicketPatternBoard.tsx'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
// import { useScale } from '@/bingo/hooks/useScale'
import { bingoActions } from '@/bingo/redux/bingo.slice.ts'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { genTicketId } from '@/bingo/utils/BingoEngine'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, BoxProps, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { useAppDispatch } from '@store'
import React from 'react'
import EditTicket from '../../FormSection/EditTicket'
import BingoDrawer from '../BingoDrawer'

export type TicketData = BingoTicketInput & {
  multiplier?: string
}

type TicketProps = BoxProps & {
  data: TicketData
  hideCollection?: boolean
  selected?: boolean
  isBetSuccess?: boolean
  winningPattern?: string
  onClickTicket?: (ticket: LocalBingoTicket) => void
  onClickDelete?: (ticket: TicketData) => any
  resultBalls?: number[]
  ticketUnitPrice?: string
  expectedBalls?: boolean
  patternBoardProps?: Partial<TicketPatternBoardProps>
  canEdit?: boolean
  canSwitch?: boolean
  canDelete?: boolean
  children?: React.ReactNode
}

const Ticket = ({
  data,
  isBetSuccess,
  hideCollection,
  selected,
  onClickTicket,
  onClickDelete,
  winningPattern,
  resultBalls,
  ticketUnitPrice,
  expectedBalls,
  patternBoardProps,
  canEdit,
  canSwitch,
  canDelete,
  children,
  ...rest
}: TicketProps) => {
  const editTicketDrawer = useDisclosure()
  // const scale = useScale()
  // const height = 186 * scale
  const { multiplier, star } = data || {}

  const _multiplier = Number(multiplier)
  const unitPrice = Number(ticketUnitPrice)
  const bonus = _multiplier && star && unitPrice ? _multiplier * star * unitPrice : 0

  const dispatch = useAppDispatch()

  const handleSwitchTicket = () => {
    const numbers = generateBingoCard()
    const newTicket: LocalBingoTicket = {
      ...(data || {}),
      id: genTicketId(data?.numbers),
      numbers,
    }

    dispatch(bingoActions.ticketDetailUpdated(newTicket))
  }

  const handleEditTicket = () => {
    editTicketDrawer.onOpen()
  }

  const handleDeleteTicket = () => {
    if (!onClickDelete) return

    onClickDelete(data)
  }

  const { t } = useCustomTranslation()

  return (
    <>
      <Box
        backgroundColor="white"
        borderRadius="5px"
        width={`calc(50% - 10px)`}
        // height={`${height}px`}
        position="relative"
        {...rest}
      >
        {!hideCollection && <TicketCollect ticket={data} />}
        <TicketMultiplier value={data?.star} />
        <TicketPatternBoard
          backgroundColor={selected ? 'red' : undefined}
          borderTopLeftRadius={5}
          borderTopRightRadius={5}
          overflow="hidden"
          winningPattern={winningPattern}
          numbers={data?.numbers}
          expectedBalls={expectedBalls}
          balls={isBetSuccess ? resultBalls || [] : []}
          onClick={() =>
            onClickTicket
              ? onClickTicket({
                  ...data,
                  id: genTicketId(data.numbers),
                })
              : null
          }
          {...patternBoardProps}
        />
        {!isBetSuccess && (
          <Flex alignItems="center" justifyContent="center" padding="5px 10px 8px" gap="10px">
            {canSwitch && (
              <TicketButton onClick={handleSwitchTicket}>{t('bingo.switch')}</TicketButton>
            )}
            {canEdit && (
              <TicketButton onClick={handleEditTicket} isDisabled={selected}>{t('bingo.edit')}</TicketButton>
            )}
            {canDelete && (
              <TicketButton onClick={handleDeleteTicket} isDisabled={selected}>{t('bingo.delete')}</TicketButton>
            )}
          </Flex>
        )}
        {isBetSuccess && (
          <Text color="#ff4218" textAlign="center" fontSize="20px" fontWeight="500" lineHeight="1" padding="10px">
            {t('prize.win')}: {bonus}
          </Text>
        )}
        {children}
      </Box>
      <BingoDrawer isOpen={editTicketDrawer.isOpen} onClose={editTicketDrawer.onClose} title="Edit Ticket">
        <EditTicket ticket={data} onClose={editTicketDrawer.onClose} />
      </BingoDrawer>
    </>
  )
}

export default Ticket
