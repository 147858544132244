import { bingoActions } from '@/bingo/redux/bingo.slice'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { BingoRound } from '@/redux/store/entities/game.entity'
import React, { useEffect } from 'react'

export default function GameRoundSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()

  const _message = useSubscription(Topics.gameRound(game_id), {
    qos: 1,
  })
  const message = _message?.message

  useEffect(() => {
    if (!message?.message) return
    try {
      const data = JSON.parse(message.message.toString() || '') as BingoRound
      data.isRetain = message.config?.retain || false
      if (data.status == 'End') {
        return
      }

      console.log('round: ', data)
      if (data.status == 'Waiting' && !data.isRetain) {
        setTimeout(() => {
          dispatch(bingoActions.updateGameRound(data))
        }, 2000)
      } else {
        dispatch(bingoActions.updateGameRound(data))
      }
    } catch (error) {
      console.warn('GameRoundSubscription error: ', error)
    }
  }, [message])

  return <></>
}
