import { Box, Checkbox, CheckboxProps, Flex, InputProps, Stack, Text } from '@chakra-ui/react'
import React, { HTMLProps } from 'react'
import RocketInputTitle from '@components/rocket/NewUI/RocketTabs/RocketInputTitle.tsx'
// import RocketInputSubTitle from '@components/rocket/NewUI/RocketTabs/RocketInputSubTitle.tsx'
import AutoEscapeInput from '@components/rocket/NewUI/Common/AutoEscapeInput.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import RocketInputSubTitle from '@components/rocket/NewUI/RocketTabs/RocketInputSubTitle.tsx'

type ClassicTabProps = {
  inputProps?: HTMLProps<HTMLInputElement> & InputProps,
  repeatCheckboxProps?: HTMLProps<HTMLInputElement> & CheckboxProps,
  autoEscapeCheckboxProps?: HTMLProps<HTMLInputElement> & CheckboxProps,
  plusCallback?: (params?: any) => any,
  minusCallback?: (params?: any) => any,
  isFormDisabled?: boolean,
}

const ClassicTab = ({inputProps, repeatCheckboxProps, autoEscapeCheckboxProps, plusCallback, minusCallback, isFormDisabled}: ClassicTabProps) => {
  const { t } = useCustomTranslation()

  return (
    <Box
      marginBottom="8px"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        lineHeight="1"
        marginBottom="4.9px"
      >
        <RocketInputTitle paddingLeft="11px">{t('rocket.tabs.autoEscapePoint')}</RocketInputTitle>
        <Stack
          backgroundColor="#1a212a"
          borderRadius="8px"
          border="1px solid #2a3444"
          padding="5.3px"
        >
          <Checkbox
            size="md"
            sx={{
              '.chakra-checkbox__control': {
                border: '1px solid #2a3444 !important',
                color: '#636d7d',
                boxShadow: 'none',
              }
            }}
            {...repeatCheckboxProps}
          >
            <RocketInputSubTitle fontStyle="normal">{t('rocket.tabs.repeat')}</RocketInputSubTitle>
          </Checkbox>
        </Stack>
        {/*<RocketInputSubTitle>预估机会0.99%</RocketInputSubTitle>*/}
      </Flex>
      <AutoEscapeInput
        inputProps={inputProps}
        autoEscapeCheckboxProps={autoEscapeCheckboxProps}
        plusCallback={plusCallback}
        minusCallback={minusCallback}
        isFormDisabled={isFormDisabled}
      />
    </Box>
  )
}

export default ClassicTab;
