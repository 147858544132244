import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

const Speedometer = ({ current, totalSeconds }: { current: number; totalSeconds: number }) => {
  const canvasRef = useRef(null)
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= 768,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= 768,
      })
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const canvas: any = canvasRef.current
    if (!canvas) return

    const context: any = canvas.getContext('2d')
    const scale = window.devicePixelRatio || 3
    canvas.width = 90 * scale
    canvas.height = 90 * scale
    context.scale(scale, scale)

    canvas.style.width = '90px'
    canvas.style.height = '90px'
    const width = canvas.width / scale
    const height = canvas.height / scale
    const centerX = width / 2
    const centerY = height / 2
    const radius = 22
    const pointerColor = '#c5ff00'
    const backgroundArcColor = '#480014'
    const centerCircleColor = '#c3fb04'

    const drawSpeedometer = (currentSecond: number) => {
      context.clearRect(0, 0, width, height)

      // Vẽ từng cái vạch sọc
      for (let i = 0; i <= 180; i += 20) {
        const angle = (i - 180) * (Math.PI / 180)
        const x1 = centerX + (radius - 3) * Math.cos(angle)
        const y1 = centerY + (radius - 3) * Math.sin(angle)
        const x2 = centerX + radius * Math.cos(angle)
        const y2 = centerY + radius * Math.sin(angle)

        context.beginPath()
        context.moveTo(x1, y1)
        context.lineTo(x2, y2)
        context.strokeStyle = '#c90039'
        context.lineWidth = 1
        context.stroke()
      }

      // Vòng tròn bên xanh bên ngoài
      context.beginPath()
      context.arc(centerX, centerY, radius + 5, Math.PI, 2 * Math.PI)

      context.strokeStyle = backgroundArcColor
      context.lineWidth = 7
      context.stroke()

      //vẽ 2 tròn nhỏ ở chân
      context.beginPath()
      context.arc(centerX, centerY, radius + 5, Math.PI, 2 * Math.PI)
      context.strokeStyle = backgroundArcColor
      context.lineWidth = 7
      context.stroke()

      // Vẽ vòng tròn nhỏ tại điểm Math.PI
      const x1 = centerX + (radius + 5) * Math.cos(Math.PI)
      const y1 = centerY + (radius + 5) * Math.sin(Math.PI)

      // Tạo vòng tròn nhỏ tại (x1, y1)
      context.beginPath()
      context.arc(x1, y1, 3.4, 0, 2 * Math.PI) // 10 là bán kính của vòng tròn nhỏ
      context.fillStyle = backgroundArcColor // Màu của vòng tròn nhỏ
      context.fill()

      // Vẽ vòng tròn nhỏ tại điểm 2 * Math.PI
      const x2 = centerX + (radius + 5) * Math.cos(2 * Math.PI)
      const y2 = centerY + (radius + 5) * Math.sin(2 * Math.PI)

      // Tạo vòng tròn nhỏ tại (x2, y2)
      context.beginPath()
      context.arc(x2, y2, 3.4, 0, 2 * Math.PI) // 10 là bán kính của vòng tròn nhỏ
      context.fillStyle = backgroundArcColor // Màu của vòng tròn nhỏ
      context.fill()

      // Vòng tròn tối bên ngoài biểu thị tốc độ
      const endAngle = ((currentSecond * 180) / totalSeconds) * (Math.PI / 180)
      context.beginPath()
      context.arc(centerX, centerY, radius + 5, Math.PI, Math.PI + endAngle)
      context.strokeStyle = pointerColor
      context.lineWidth = 3
      context.stroke()

      // Kim
      const speedAngle = ((currentSecond * 180) / totalSeconds) * (Math.PI / 180)
      const pointerLength = radius + 42
      // Giảm chiều dài của kim
      const pointerWidth = 6 // Giảm chiều rộng của kim

      context.beginPath()
      context.moveTo(centerX, centerY)
      context.lineTo(
        centerX + pointerLength * Math.cos(speedAngle - Math.PI / 2),
        centerY + pointerLength * Math.sin(speedAngle - Math.PI / 2),
      )
      context.lineTo(
        centerX + (pointerWidth / 2) * Math.cos(speedAngle + Math.PI / 2),
        centerY + (pointerWidth / 2) * Math.sin(speedAngle + Math.PI / 2),
      )
      context.lineTo(
        centerX - (pointerLength / 3) * Math.cos(speedAngle),
        centerY - (pointerLength / 3) * Math.sin(speedAngle),
      )
      context.lineTo(
        centerX + (pointerWidth / 2) * Math.cos(speedAngle - Math.PI / 2),
        centerY + (pointerWidth / 2) * Math.sin(speedAngle - Math.PI / 2),
      )
      context.closePath()
      context.fillStyle = pointerColor
      context.fill()

      // Cái vòng tròn tâm của kim
      context.beginPath()
      context.arc(centerX, centerY, 5, 0, 2 * Math.PI)
      context.fillStyle = centerCircleColor
      context.fill()
      context.strokeStyle = '#4a0015'
      context.lineWidth = 2
      context.stroke()
    }

    drawSpeedometer(parseInt(current))
  }, [current, totalSeconds])

  // useEffect(() => {
  //   const endTime = new Date()
  //   const startTime = window.startTime ?? new Date()
  //   const timeRendered = endTime.getTime() - startTime.getTime()
  //   console.log('{timeRendered} timeRendered, window.startTime, endTime: ', timeRendered, startTime, endTime)
  //   //disable loading when rendered component LCP
  //   //timeout = timeRendered + bufffer (renderd canvas)
  //   const id = setTimeout(() => {
  //     console.log('{} canvas stop load: ')
  //     const pageLoadingElement = document.getElementById('pageLoading')
  //     if (pageLoadingElement) {
  //       pageLoadingElement.remove()
  //     }
  //     document.body.removeAttribute('style')
  //   }, timeRendered/8 + 500)
  //
  //   return () => {
  //     clearTimeout(id)
  //   }
  // }, [])
  return <canvas ref={canvasRef} width={90} height={90} />
}

export default Speedometer
