import { bingoActions, selectBingoCurrentPage } from '@/bingo/redux/bingo.slice'
import { CustomTheme } from '@/const/custom-theme'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { ChakraProvider, extendTheme, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import RandomTicket from '../RandomTicket'
import { MyTicketCollection } from './MyTicketCollection'

export function GenTicket() {
  const theme = extendTheme(CustomTheme, {
    components: {
      Tabs: {
        variants: {
          custom: {
            tab: {
              bg: 'linear-gradient(180deg, #ffe4ad 0%, #fff1e6 100%);',
              borderTopRadius: 20,
              color: '#b68943',
              fontWeight: '500',
              height: 12,
              _selected: {
                bg: 'linear-gradient(180deg, #f9b8bc 0%, #fdcdb7 100%)',
                color: '#e82013',
                fontWeight: '600',
              },
              _hover: {},
            },
          },
        },
      },
    },
  })
  const { args } = useAppSelector(selectBingoCurrentPage)
  const tabIndex = args?.tabIndex
  const dispatch = useAppDispatch()
  function handleTabChange(tabIndex: number) {
    dispatch(bingoActions.setParams({ tabIndex }))
  }
  const { t } = useCustomTranslation()

  return (
    <ChakraProvider theme={theme}>
      <Flex height="100%" display="flex" flexDirection="column">
        <Tabs
          flex={1}
          display="flex"
          flexDirection="column"
          borderTopLeftRadius={28}
          borderTopRightRadius={28}
          overflow="hidden"
          variant="custom"
          index={tabIndex}
          onChange={handleTabChange}
        >
          <TabList display="flex" borderBottom="1px solid rgb(252, 226, 227)">
            <Tab flex={1} textTransform="capitalize">
              {t('bingo.randomTicket')}
            </Tab>
            <Tab flex={1} textTransform="capitalize">
              {t('bingo.myCollection')}
            </Tab>
          </TabList>

          <TabPanels flex="1" backgroundColor="#fde8e8" display="flex" flexDirection="column" overflow="hidden">
            <TabPanel flex={1} height="100%" padding={0}>
              <RandomTicket />
            </TabPanel>
            <TabPanel flex={1} height="100%" padding={0} display="flex">
              <MyTicketCollection />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </ChakraProvider>
  )
}
