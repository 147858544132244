import { bingoConfig } from '@/bingo/const/bingoConfigs'
import { useIsDisplayMinerCountdown } from '@/bingo/hooks/useIsDisplayCountdown'
import { useRoundCountdown } from '@/bingo/hooks/useRoundCountdown'
import { selectBingoGameRoundStartTime, selectBingoRoundConfig } from '@/bingo/redux/bingo.slice'
import { useAppSelector } from '@/redux/store'
import { selectDiffTime } from '@/redux/store/modules/game.slice'
import { Box, Center, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { bingoMiningSound, playBingoSound } from '../../BingoSounds'

const miningFrames = [...Array(17)].map((_, i) => `/bingo/images/mining/img_${i}.webp`)
const frameDuration = bingoConfig.mining.animationDuration / miningFrames.length

interface MiningProps {
  stoped?: boolean
  repeat: number
  onComplete: () => void
  ballRef?: React.MutableRefObject<any>
}

export function Mining({ stoped, onComplete, ballRef, repeat }: MiningProps) {
  const [frame, setFrame] = useState(0)
  const [count, setCount] = useState(0)
  const isCountdown = useIsDisplayMinerCountdown()

  useEffect(() => {
    if (stoped) return

    const interval = setTimeout(() => {
      if (stoped) {
        if (frame != 0) {
          setFrame(0)
        }
        clearInterval(interval)
        return
      }
      setFrame((prev) => (prev + 1) % miningFrames.length)
      if (frame == miningFrames.length - 1) {
        playBingoSound(bingoMiningSound)
        if (count >= repeat - 1) {
          clearInterval(interval)
          onComplete()
          if (count != 0) {
            setCount(0)
          }
        } else {
          setCount((prev) => prev + 1)
        }
      }
    }, frameDuration)
    return () => clearInterval(interval)
  }, [frame, stoped, onComplete, count, repeat])

  const currentFrame = stoped ? 0 : frame

  return (
    <div className="relative">
      <div
        style={{
          bottom: 24,
          right: 24,
        }}
        ref={ballRef}
        className="absolute w-1 h-1"
      />
      {miningFrames.map((src, i) => (
        <Image
          display={i != currentFrame ? 'none' : undefined}
          src={src}
          userSelect="none"
          minWidth="138px"
          width="138px"
          height="138px"
          padding={2}
          opacity={isCountdown ? 0 : 1}
          key={i}
        />
      ))}

      {isCountdown && <CountdownRunning />}
    </div>
  )
}

function CountdownRunning() {
  const startTime = useAppSelector(selectBingoGameRoundStartTime)
  const { waitingTime } = useAppSelector(selectBingoRoundConfig)
  const { seconds } = useRoundCountdown()

  return (
    <Center position="absolute" top={0} left={0} right={0} bottom={0} p={2} flexDirection="column">
      <Center width="50%" height="50%">
        <Countdown endTime={new Date(startTime).getTime()} totalTime={waitingTime * 1000} />
      </Center>
      <Text color="#FFD674" fontWeight="bold" fontSize="xl">
        {seconds <= waitingTime ? seconds : 0}s
      </Text>
    </Center>
  )
}

interface CountdownProps {
  endTime: number
  totalTime: number
}
function Countdown({ endTime, totalTime }: CountdownProps) {
  const diffTime = useAppSelector(selectDiffTime)
  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    diffTimeRef.current = diffTime
  }, [diffTime])

  const [diff, setDiff] = useState(endTime - Date.now() - diffTimeRef.current)

  useEffect(() => {
    if (diff <= 0) return

    const timer = setInterval(() => {
      setDiff(endTime - Date.now() - diffTimeRef.current)
    }, 100)

    return () => clearInterval(timer)
  }, [diff, endTime])

  const percent = diff / totalTime
  const _angle = percent * 270
  const needleRotation = 135 - _angle
  const angle = 270 - _angle

  const radius = 90
  const size = 200

  return (
    <Box position="relative" width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <Arc size={size} angle={270} radius={radius} color="#411F37" />
        <Arc size={size} angle={angle} radius={radius} color="#FFD674" />
        <path
          d="M 100 40
             A 5 5 0 0 1 105 45
             L 110 115
             A 5 5 0 0 1 90 115
             L 95 45
             A 5 5 0 0 1 100 40 Z"
          fill="#FFD674"
          transform={`rotate(${needleRotation}, 100, 100)`}
        />
      </svg>
    </Box>
  )
}

interface ArcProps {
  radius: number
  angle: number
  size: number
  color: string
}

function Arc({ radius, size, angle, color }: ArcProps) {
  const startAngle = -angle
  const endAngle = 0
  const startX = size / 2 + radius * Math.cos(toRadians(startAngle))
  const startY = size / 2 - radius * Math.sin(toRadians(startAngle))
  const endX = size / 2 + radius * Math.cos(toRadians(endAngle))
  const endY = size / 2 - radius * Math.sin(toRadians(endAngle))
  const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0
  return (
    <path
      d={`M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${endX} ${endY}`}
      fill="none"
      stroke={color}
      strokeWidth="20"
      strokeLinecap="round"
      transform="rotate(135, 100, 100)"
    />
  )
}
const toRadians = (deg: number) => (deg * Math.PI) / 180
