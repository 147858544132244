import React, { useEffect, useRef } from 'react'
import { Box, Image } from '@chakra-ui/react'
import { GameStateEnum } from '@components/rocket/GamePlay/hooks/useRocketGamePlay.ts'
import './gameplay.scss'
import { useAppSelector } from '@/redux/store'
import { ROUND_STATE, selectGameRound } from '@/redux/store/modules/rocketTshow'
import Rules from './Rules'

type BackgroundProps = {
  animationDuration?: number
  isAnimating: boolean
  currentMultiplier?: number
  gameState?: GameStateEnum
}

const RocketBackground = ({ animationDuration = 3.5, isAnimating, gameState }: BackgroundProps) => {
  const bgRef = useRef<any>()
  const gameRound = useAppSelector(selectGameRound)
  useEffect(() => {
    if (bgRef) {
      if (gameRound.state === ROUND_STATE.RUNNING) {
        createStars(15, 3, 3)
        setTimeout(() => {
          createStars(15, 3, 2)
        }, 4000)
        setTimeout(() => {
          createStars(20, 1, 1.5)
        }, 8000)
      } else {
        const starDiv = document.querySelectorAll('.stars')
        if (starDiv) {
          starDiv.forEach((div) => {
            div.remove()
          })
        }
      }
    }
  }, [gameRound?.state])

  const createStars = (numStars: number, speed: number, speed_1: number) => {
    for (let i = 0; i < numStars; i++) {
      const star = document.createElement('div')
      star.className = 'stars'
      star.style.top = (Math.random() * window.screen.width) / 2 + 'px'
      star.style.left = Math.random() * bgRef.current.offsetHeight + 'px'
      const s = Math.random() * 2 + 1
      star.style.animationDuration = s + 's, ' + (Math.random() * speed + speed_1) + 's' // Random speeds
      star.style.animationDelay = Math.random() * 5 + 's' // Random start delay
      // star.style.animation = 'twinkle 2s infinite ease-in-out alternate, moveDown 4s cubic-bezier(0.25, 0.1, 0.25, 1) infinite' // Random start delay
      // star.style.animation = 'twinkle 2s infinite ease-in-out alternate, moveDown 4s cubic-bezier(0.25, 0.1, 0.25, 1) infinite' // Random start delay
      bgRef.current.appendChild(star)

      // setTimeout(() => {
      //   // star.style.animation = 'twinkle 2s infinite ease-in-out alternate, moveDown 4s linear infinite' // Random start delay
      //   star.style.animationDuration = s + 's, ' + 4.8 + 's' // Random speeds
      // }, 3000)
    }
  }
  return (
    <Box
      className="zoom-container pointer-events-none"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 0,
      }}
      ref={bgRef}
    >
      <Image src="/images/rockets/img_bg.jpg" width="100%" />
      <Image src="/images/rockets/img_bg.jpg" width="100%" />
      <Image
        src="/images/rockets/img_earth.png"
        width="100%"
        opacity={gameState === 'WAITING' ? '1' : '0.9'}
        sx={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '100%',
          // height: '100%',
          zIndex: 20,
        }}
      />
      {/* <Rules /> */}
    </Box>
  )
}

export default RocketBackground
