import useGameId from '@/hooks/useGameId.ts'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice.ts'
import { Flex, Stack, Image } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { getWalletById, selectWallet } from '@store/modules/wallet.slice.ts'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft } from 'react-icons/fa'
import { currencyMap } from '../../const/currency'
import {
  ArabicIcon,
  ChineseIcon,
  EnglishIcon,
  EspanolIcon,
  HindiIcon,
  KoreanIcon,
  PortugueseIcon,
} from '../../const/icons'
import { getInitialLang } from '../../i18n'
import { IframeCommunicationEvent, iframeCon, isLoadedByIframe } from '../../utils/iframe.ts'
import SelectLanguage from '../UI/SelectLanguage'
import SelectWallet from '../UI/SelectWallet.tsx'
import { NumberAnim } from '../common/NumberAnim.tsx'
import ListMenu from './Menu/index.tsx'
import { useSearchParams } from 'react-router-dom'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

const BreadcrumbRocket = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const languageDefault = getInitialLang()
  const gameID = useGameId()
  const wallet = useAppSelector(selectWallet)
  const isRefetchWallet = useAppSelector(selectIsLoadingAction(getWalletById.pending))
  const languageOptions = [
    { value: 'en', label: 'English', icon: <EnglishIcon /> },
    { value: 'zh', label: 'Chinese', icon: <ChineseIcon /> },
    { value: 'hi', label: 'Hindi', icon: <HindiIcon /> },
    { value: 'pt', label: 'Portuguese', icon: <PortugueseIcon /> },
    { value: 'ko', label: 'Korean', icon: <KoreanIcon /> },
    { value: 'ar', label: 'Arabic', icon: <ArabicIcon /> },
    { value: 'es', label: 'Espanol', icon: <EspanolIcon /> },
  ]

  const [params] = useSearchParams()
  const switch_wallet = params.get('switch_wallet')

  const canSwitchWallet = switch_wallet == '1'

  const switch_lang = params.get('switch_lang')

  const canSwitchLang = switch_lang == '1'

  const handleWalletUpdate = () => {
    if (wallet.id) {
      dispatch(getWalletById(wallet.id))
    }
  }

  const handleSelectLanguage = (option: any) => {
    i18n.changeLanguage(option.value)
  }

  const handleReturn = () => {
    if (isLoadedByIframe()) {
      iframeCon.sendToParent({ event: IframeCommunicationEvent.GO_BACK, params: {} })
      return
    }

    window.close()
  }
  const currency = currencyMap[wallet.currency]

  return (
    <Stack w="100%" px="10px">
      <Flex gap={4}>
        <Flex alignItems="center" gap={2} cursor="pointer" onClick={handleReturn}>
          <FaChevronLeft fontSize={20} />
          <RocketText transform="translateY(-7%)" ml="7px" {...NotoSansFontFamily.normal} fontSize={24}>
            {t(gameID)}
          </RocketText>
        </Flex>
        <Flex ml="14px" gap={2} alignItems="center">
          {canSwitchLang && (
            <SelectLanguage defaultValue={languageDefault} options={languageOptions} onSelect={handleSelectLanguage} />
          )}
          <Flex
            pr="8px"
            py="7px"
            pl="0"
            backgroundColor="#323b45"
            gap={2}
            alignItems="center"
            borderRadius="100px"
            position="relative"
          >
            <Stack position="absolute" top="50%" transform="translateY(-50%)" left="2px" w="auto" h="auto">
              <SelectWallet />
            </Stack>
            <NumberAnim
              to={wallet.amount ?? 0}
              decimal={currency?.decimal ?? 0}
              dataId={'user-wallet-balance'}
              className={`!text-[18px] ${canSwitchWallet ? 'ml-[52px]' : 'ml-[36px]'}`}
              {...NotoSansFontFamily.normal}
              transform="translateY(-10%)"
            />
            <Image
              src="/images/rockets/ic-reload.webp"
              width="22px"
              height="auto"
              onClick={handleWalletUpdate}
              cursor="pointer"
              alt=""
              className={isRefetchWallet ? 'animate-spin' : ''}
            />
          </Flex>
        </Flex>
        <ListMenu />
      </Flex>
    </Stack>
  )
}

export default React.memo(BreadcrumbRocket)
