import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

type WinningAnimationProps = BoxProps & {
  winningPattern?: string,
}

type DrawLinesProps = {
  patternName?: string,
}

const getPatternName = (pattern?: string) => {
  switch (pattern) {
    case 'xxxxx|ooooo|ooooo':
      return 'one-line-1'

    case 'ooooo|xxxxx|ooooo':
      return 'one-line-2'

    case 'ooooo|ooooo|xxxxx':
      return 'one-line-3'

    case 'ooxoo|oxoxo|xooox':
      return 'pattern-1'

    case 'xooox|xooox|xooox':
      return 'pattern-2'

    case 'ooxoo|oxoxo|xxxxx':
      return 'pattern-3'

    case 'xoxox|oxoxo|xoxox':
      return 'pattern-4'

    case 'xooox|xxxxx|ooxoo':
      return 'pattern-5'

    case 'xxxxx|xxxxx|ooooo':
      return 'two-line-1'

    case 'xxxxx|ooooo|xxxxx':
      return 'two-line-2'

    case 'ooooo|xxxxx|xxxxx':
      return 'two-line-3'

    case 'xxxxx|oxoxo|oxoxo':
      return 'pattern-6'

    case 'xxxxx|oxoxo|oxxxo':
      return 'pattern-7'

    case 'xoxox|xxxxx|xoxox':
      return 'pattern-8'

    case 'xxxxx|xooox|xxxxx':
      return 'pattern-9'

    case 'xxxxx|xxxxx|xxxxx':
      return 'pattern-10'

    default:
      return ''
  }
}

const DrawLines = ({patternName}: DrawLinesProps) => {
  if (!patternName) return <></>

  switch (patternName) {
    case 'pattern-1':
    case 'pattern-2':
    case 'two-line-1':
    case 'two-line-2':
    case 'two-line-3':
      return new Array(2).fill(1).map(() => <Box className="pattern-line" />)

    case 'pattern-3':
    case 'pattern-6':
      return new Array(3).fill(1).map(() => <Box className="pattern-line" />)

    case 'pattern-4':
    case 'pattern-5':
    case 'pattern-7':
    case 'pattern-8':
    case 'pattern-9':
      return new Array(4).fill(1).map(() => <Box className="pattern-line" />)

    case 'pattern-10':
      return new Array(12).fill(1).map(() => <Box className="pattern-line" />)

    default:
      return (
        <Box
          className="pattern-line"
        />
      )
  }
}

const WinningLines = ({winningPattern, ...rest}: WinningAnimationProps) => {
  const patternName = getPatternName(winningPattern)
  const combinedClassName = `winning-ticket-lines ${patternName} ${rest?.className}`

  if (!winningPattern) return <></>

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      {...rest}
      className={combinedClassName}
    >
      <DrawLines patternName={patternName} />
    </Box>
  )
}

export default WinningLines;