import CopyAll from '@/components/common/CopyAll'
import { CopyBtn } from '@/components/common/CopyBtn'
import HtmlRender from '@/components/HtmlRender'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { getInitialLang } from '@/i18n'
import { plinkoActions, selectPlinkoLastOrder } from '@/redux/store/modules/plinko.slice'
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import * as CryptoJS from 'crypto-js'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import { convertHashToLR } from '../../utils/game'
import { fairnessEn } from './fairness.en'
import { fairnessZh } from './fairness.zh'

const fairnessContent: Record<string, string> = {
  en: fairnessEn,
  zh: fairnessZh,
}

declare type PopupType = 'Gameplay' | 'Verification'
interface FairnessPopupProps {
  popup?: PopupType
}

const mapPopupWithTab: Record<PopupType, number> = {
  Gameplay: 0,
  Verification: 1,
}

export function FairnessPopup({ popup }: FairnessPopupProps) {
  const { t } = useCustomTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramValue = queryParams.get('param')
  const defaultTab = mapPopupWithTab[popup || (paramValue as PopupType)] || 0

  return (
    <Stack height="80vh" overflow="hidden" borderRadius="12px 12px 0 0">
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={2}
        px={5}
        pb={7}
        borderRadius="12px 12px 0 0"
      >
        <Flex color="white" pr={4} justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} fontSize={21} mt={2} lineHeight={1.2}>
              {t('hashLottery')}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" className="w-[74px] h-[68px] mt-[2px]" />
        </Flex>
      </Stack>
      <Stack></Stack>
      <Tabs defaultIndex={defaultTab}>
        <Center mt={-10}>
          <TabList
            backgroundColor="#4a4a4e"
            border="none"
            borderRadius="30px"
            className="w-full ml-5 mr-4 p-1 pb-[4px] h-[42px]"
          >
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0 !py-0"
            >
              <Text marginTop="4px">{t('gameplay')}</Text>
            </Tab>
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0"
            >
              <Text marginTop="4px">{t('verify')}</Text>
            </Tab>
          </TabList>
        </Center>
        <TabPanels height="calc(80vh - 132px)" overflowY="auto">
          <TabPanel>
            <GameplayTab />
          </TabPanel>
          <TabPanel>
            <FairnessTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

function GameplayTab() {
  const lang = getInitialLang()
  const content = fairnessContent[lang] || fairnessContent['en']
  return <HtmlRender className="text-[white]" html={content} />
}

function FairnessTab() {
  const lastOrder = useAppSelector(selectPlinkoLastOrder)
  const dispatch = useAppDispatch()
  const { t } = useCustomTranslation()
  const { proof, server_seed, client_seed, random_value, rows = 16, balls = 0, ball_paths } = lastOrder || {}

  useEffect(() => {
    if (isEmpty(lastOrder)) {
      dispatch(plinkoActions.getPlinkoLastOrder())
    }
  }, [])

  return (
    <Stack gap={4}>
      <Box position="relative">
        <Image width="100%" src={'/images/Panel-light.svg'} alt="" />
        <Text
          position="absolute"
          top={'50%'}
          left={4}
          transform="translateY(-50%)"
          // left="0"
          // padding={'4px 14px 2px 14px'}
          fontSize="14px"
          color="#fff"
        >
          {t('lastRoundHash')}
        </Text>
      </Box>

      <Stack backgroundColor="#252527" p={4} mt={-4} borderRadius={8} borderTopRadius={0} fontSize={13}>
        <Stack color="white" mt={2}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('randomness')}
            </Text>
            <CopyBtn text={random_value} />
          </Flex>
          <Text>{random_value}</Text>
        </Stack>

        <Stack color="white" mt={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('gamePlay.serverSeed')}
            </Text>
            <CopyBtn text={server_seed} />
          </Flex>
          <Text>{server_seed}</Text>
        </Stack>
        <Stack color="white" mt={3}>
          <Flex justifyContent="space-between" alignItems="center">
            <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
              {t('clientSeed')}
            </Text>
            <Link href={`https://etherscan.io/tx/${client_seed}`} isExternal>
              <Stack direction={'row'} alignItems={'center'} color={'#ccc'}>
                <Text>{t('gamePlay.etherscan')}</Text>
                <FiExternalLink size={14} style={{ display: 'inline' }} />
              </Stack>
            </Link>
            <CopyBtn text={client_seed} />
          </Flex>
          <Text>{client_seed}</Text>
        </Stack>

        <CopyAll cpyTxt={`${random_value}${server_seed}${client_seed}`} />
      </Stack>

      <Stack color="white" backgroundColor="#252527" p={4} borderRadius={8} fontSize={13}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="#c7fb14" fontWeight={500}>
            {t('hashResult')}
          </Text>
          <CopyBtn text={proof} />
        </Flex>
        <Text>{proof}</Text>
      </Stack>
      <Button
        aria-label="buttonVerifyWallet"
        aria-labelledby="buttonVerifyWallet"
        w="100%"
        backgroundColor="#c7fb14"
        borderRadius="8px"
      >
        <Stack direction="row">
          <Link
            href={`https://emn178.github.io/online-tools/sha256.html?input=${random_value}${server_seed}${client_seed}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
            isExternal
          >
            {t('buttonVerifyWallet')} <FiExternalLink style={{ display: 'inline' }} />
          </Link>
        </Stack>
      </Button>
      <Stack color="white" backgroundColor="#252527" p={4} pb={0} borderRadius={8} fontSize={13}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="#c7fb14" fontWeight={500}>
            {t('plinko.ballPaths')}
          </Text>
        </Flex>
        <Flex fontWeight="bold" textTransform="capitalize">
          <Text flex={1}>{t('plinko.pathHash')}</Text>
          <Text px={3} flex={1}>
            {t('plinko.pathBit')}
          </Text>
          <Text flex={1}>{t('plinko.ballPath')}</Text>
        </Flex>
        <Stack
          className="scrollable"
          css={{
            '::after': {
              background: 'linear-gradient(to bottom, #25252700, #252527)',
            },
          }}
        >
          <Box overflow={balls > 6 ? 'auto' : undefined} maxHeight={170}>
            {(ball_paths || []).map((path, i) => {
              const input = (random_value || '') + i
              const hash = CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex)
              const { bits } = convertHashToLR(hash)

              return (
                <Flex color="#fff">
                  <Flex flex={1} overflow="hidden">
                    <Text minWidth={5}>{i}.</Text>
                    <Text isTruncated>{hash}</Text>
                    <Link
                      href={`https://emn178.github.io/online-tools/sha256.html?input=${input}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
                      isExternal
                    >
                      <FiExternalLink style={{ display: 'inline' }} />
                    </Link>
                  </Flex>
                  <Flex flex={1} overflow="hidden" px={3}>
                    <Text isTruncated>{bits}</Text>
                    <Link href={`https://www.rapidtables.com/convert/number/hex-to-binary.html?x=${hash}`} isExternal>
                      <FiExternalLink style={{ display: 'inline' }} />
                    </Link>
                  </Flex>
                  <Text flex={1} overflow="hidden" isTruncated>
                    {path.substring(0, rows)}
                  </Text>
                </Flex>
              )
            })}
            <div className="h-4" />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
