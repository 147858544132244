import TicketPatternCell from '@/bingo/components/Common/Ticket/TicketPatternCell.tsx'
import WinningLines from '@/bingo/components/Common/Ticket/WinningLines.tsx'
import { bingoConfig } from '@/bingo/const/bingoConfigs'
import { selectBingoPatterns } from '@/bingo/redux/bingo.slice'
import { getBingoExtraBallPosition } from '@/bingo/utils/BingoEngine'
import { useAppSelector } from '@/redux/store'
import { Flex, FlexProps } from '@chakra-ui/react'
import { keyBy } from 'lodash'
import React from 'react'

export type TicketPatternBoardProps = FlexProps & {
  numbers: number[]
  balls: number[]
  winningPattern?: string
  expectedBalls?: boolean
}

const NumbersRenderer = ({ numbers, balls, expectedBalls = true }: TicketPatternBoardProps) => {
  const patterns = useAppSelector(selectBingoPatterns)

  if (!numbers || numbers.length === 0) {
    return (
      <>
        {new Array(15).fill(0).map((_, index) => (
          <TicketPatternCell key={index} />
        ))}
      </>
    )
  }

  const extraBallPositions = getBingoExtraBallPosition(numbers, balls, patterns)

  const checkExtraBallPosition = keyBy(extraBallPositions, 'ball')

  return (
    <>
      {numbers?.map((num, index) => (
        <TicketPatternCell
          value={num}
          key={num}
          borderTopLeftRadius={index ? 0 : 4}
          borderTopRightRadius={index == bingoConfig.ticket.cols - 1 ? 4 : 0}
          isSelected={balls.includes(num)}
          isExpected={expectedBalls ? !!checkExtraBallPosition[num] : false}
        />
      ))}
    </>
  )
}

const TicketPatternBoard = ({ numbers, balls, winningPattern, expectedBalls, ...rest }: TicketPatternBoardProps) => {
  return (
    <Flex
      flexWrap="wrap"
      padding="2px"
      gap="2px"
      position="relative"
      fontSize="20px"
      fontWeight="500"
      lineHeight="1"
      {...rest}
    >
      {winningPattern && <WinningLines winningPattern={winningPattern} />}
      <NumbersRenderer numbers={numbers} balls={balls} expectedBalls={expectedBalls}/>
    </Flex>
  )
}

export default TicketPatternBoard
