import BingoInput from '@/bingo/components/Common/BingoInput.tsx'
import { maxTickets, minTickets } from '@/bingo/const/bingoConfigs.ts'
import { bingoActions, selectBingoNumbersOfTickets } from '@/bingo/redux/bingo.slice.ts'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, BoxProps, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React from 'react'

type MarkProps = BoxProps & {
  value: number
  setSliderValue?: (value: number) => void
  dotProps?: BoxProps
}

const CustomSliderMark = ({ value, setSliderValue, dotProps, ...rest }: MarkProps) => {
  if (!value) return <></>

  const handleMarkClick = () => {
    if (!setSliderValue) return

    setSliderValue(value)
  }

  return (
    <Box cursor="pointer" marginTop="10px" position="relative" userSelect="none" color="#000000" onClick={handleMarkClick} {...rest}>
      <Box
        width="10px"
        height="10px"
        backgroundColor="#ef5f24"
        borderRadius="50%"
        position="absolute"
        top="-15px"
        left="50%"
        transform="translateX(-50%)"
        pointerEvents="none"
        {...dotProps}
      />
      x{value}
    </Box>
  )
}

const TicketSlider = () => {
  const numbersOfTickets = useAppSelector(selectBingoNumbersOfTickets)
  const dispatch = useAppDispatch()

  const setNumbersOfTickets = (value: number) => {
    if (!numbersOfTickets) return

    dispatch(bingoActions.updateNumberOfTickets(value))
  }

  const handlePlus = () => {
    if (numbersOfTickets >= maxTickets) return

    setNumbersOfTickets(numbersOfTickets + 1)
  }

  const handleMinus = () => {
    if (numbersOfTickets <= minTickets) return

    setNumbersOfTickets(numbersOfTickets - 1)
  }

  const handleSliderChange = (value: number) => {
    setNumbersOfTickets(value)
  }
  const { t } = useCustomTranslation()

  return (
    <Box backgroundColor="#ffffff" borderBottomRadius="10px" borderTop="2px dashed #ffbda3" padding="15px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="20px" color="#333">
          {t('bingo.numberOfTickets')}
        </Text>
        <BingoInput
          handlePlus={handlePlus}
          handleMinus={handleMinus}
          isMinusDisabled={numbersOfTickets === minTickets}
          isPlusDisabled={numbersOfTickets === maxTickets}
          inputProps={{
            min: minTickets,
            max: maxTickets,
            value: numbersOfTickets,
            isReadOnly: true,
            userSelect: 'none',
          }}
        />
      </Flex>
      <Box>
        <Slider aria-label="slider-ex-6" onChange={handleSliderChange} value={numbersOfTickets} min={minTickets}>
          <SliderTrack backgroundColor="rgba(239, 95, 36, 0.5)">
            <SliderFilledTrack
              backgroundColor="rgb(239, 95, 36)"
              // transition=".05s"
            />
          </SliderTrack>
          <SliderThumb
            width="5px"
            height="15px"
            backgroundColor="rgb(239, 95, 36)"
            boxShadow="none !important"
            outline="none !important"
            // transition=".05s"
          />
        </Slider>
        <Flex alignItems="center" justifyContent="space-between" paddingLeft="15px" marginTop="-14px">
          <CustomSliderMark value={5} setSliderValue={setNumbersOfTickets} transform="translateX(-4.5px)" />
          <CustomSliderMark value={25} setSliderValue={setNumbersOfTickets} transform="translateX(-13px)" />
          <CustomSliderMark value={50} setSliderValue={setNumbersOfTickets} transform="translateX(-3.5px)" />
          <CustomSliderMark value={75} setSliderValue={setNumbersOfTickets} transform="translateX(6px)" />
          <CustomSliderMark
            value={100}
            setSliderValue={setNumbersOfTickets}
            dotProps={{
              transform: 'none',
              left: 'unset',
              right: '-5px',
            }}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default TicketSlider
