import React from 'react'
import { Box, Image } from '@chakra-ui/react'
import './styles/launching.scss'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type CountdownProps = {
  countdown: number
  duration: number
}

export const RocketCountdown = ({ countdown, duration }: CountdownProps) => {
  const { t } = useCustomTranslation()

  //   if (countdown < 0) return null

  const countdownDisplay = countdown.toString().padStart(2, '0')
  const radius = 100 // Radius of the semi-circle
  const circumference = Math.PI * radius // Circumference of the semi-circle
  const progress = (duration - countdown) / duration
  const maxOffset = circumference - 80
  const animFrom = circumference - maxOffset
  const offset = maxOffset * progress
  const dashOffset = animFrom + offset

  return (
    <>
      <Box
        className="absolute top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 zoom-container pointer-events-none"
        zIndex="3"
      >
        <Box opacity={countdown < 0 ? '0' : '1'} className="relative w-[270px]" marginBottom="-27px">
          <Image
            src="/images/rockets/img_bg_countdown.webp"
            alt=""
            position="relative"
            bottom="-35px"
            left="10.5px"
            width="265px"
          />

          {/* semi circle progress */}
          <Box position="absolute" top="44px" left="52.5%" transform="translateX(-50%)">
            <svg
              width="200"
              height="133"
              viewBox="0 0 200 120"
              style={{ marginTop: '44px' }}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* semi-circle */}
              <path
                d="M 25 75 A 60 60 0 1 1 175 75"
                fill="none"
                stroke="#222d46"
                strokeWidth="12"
                strokeLinecap="round"
              />
              {/* progress bar */}
              <path
                d="M 25 75 A 60 60 0 1 1 175 75"
                fill="none"
                stroke="#bdf303"
                strokeWidth="12"
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={dashOffset}
                style={{
                  transition: 'stroke-dashoffset 0.5s linear',
                }}
              />
            </svg>
          </Box>

          {/* countdown text */}
          <Box className="absolute top-[132px] w-[200px] left-1/2 transform -translate-x-1/2">
            <Box
              className="text-center text-[45px] font-bold text-[#fff000] drop-shadow-[0_1px_3px_rgba(0,0,0,0.8)] select-none italic"
              marginTop="-16px"
            >
              <RocketText as="span" {...NotoSansFontFamily.bold}>
                {countdownDisplay}
              </RocketText>
              <RocketText as="span" {...NotoSansFontFamily.semiBold} fontSize="28px">
                s
              </RocketText>
            </Box>
            <RocketText
              className="text-center text-[29px] italic" color="#ffffff" lineHeight="1"
              {...NotoSansFontFamily.bold}
            >
              {t('rocket.countdownTitleTop')}
            </RocketText>
            <RocketText
              className="text-center text-[16px] italic"
              color="#ffffff"
              lineHeight="1"
              marginTop="7px"
              {...NotoSansFontFamily.medium}
            >
              {t('rocket.countdownTitleBottom')}
            </RocketText>
          </Box>
        </Box>

        {/* Rocket positioned on the moon */}
        <CountdownRocket countdown={countdown} />
      </Box>
    </>
  )
}

const CountdownRocket = ({ countdown }: Pick<CountdownProps, 'countdown'>) => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      marginBottom="-92px"
      marginTop="3px"
      className={countdown < 0 ? 'rocket-up' : ''}
    >
      <Box className="relative">
        <Image
          src="/images/rockets/img_rocket.webp"
          alt="rocket"
          className="w-[127px] aspect-[277/397] object-contain"
          position="relative"
        />
        {countdown > 0 && (
          <Image
            id="small_rocket"
            src="/images/rockets/img_small_fire.png"
            alt="rocket"
            w="100%"
            className="absolute bottom-[-19px] left-[0px]"
          />
        )}
        {countdown <= 0 && (
          <Box
            sx={{
              width: '65px',
              height: '115px',
              background: 'url(/images/rockets/rocket-firing-sprite.webp) no-repeat center',
              backgroundSize: '565px',
              backgroundPosition: '-13px 0px',
              transform: 'translateY(20%)',
              zIndex: -1,
              animation: 'firing 1s infinite 0s step-end',
            }}
            className="absolute bottom-[-50px] left-[29px]"
          />
        )}
      </Box>
    </Box>
  )
}
