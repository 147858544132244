import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import useMobileViewport from '@hooks/useMobileViewport.ts'

type TShowGameBackgroundProps = BoxProps

const TShowGameBackground = ({ children, ...rest }: TShowGameBackgroundProps) => {
  useMobileViewport()

  return (
    <Box
      backgroundColor="#11151b"
      paddingBottom="42px"
      id="tshow-game-container"
      sx={{
        userSelect: 'none',
        ...{
          '*': {
            fontSynthesis: 'weight style small-caps !important',
          },
        },
        ...rest?.sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default TShowGameBackground
