import React from 'react'
import RocketTable, {
  CellNull,
  CellText,
  renderBetAmountCell,
  renderPlayerCell,
  renderRewardCell,
  RocketTableCell,
  RocketTableData,
  RocketTableRow,
} from '@components/rocket/NewUI/RocketTables/RocketTable.tsx'
import { useAppSelector } from '@store'
import { ROUND_STATE, selectGameRound, selectListCurrentLives } from '@store/modules/rocketTshow.ts'
import { f } from 'fintech-number'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { TrenBallType } from '@/@generated/gql/graphql-hash'
import { GameRound } from '@/redux/store/entities/game.entity'

const renderEscapeCell = (
  t: (key: string, options?: any) => string,
  gameRound: GameRound,
  record?: any,
): RocketTableCell => {
  if (record.tren_ball_type === TrenBallType.None || record.tren_ball_type === '') {
    if (record?.esc_multiplier === t('rocket.tables.notEscaped')) {
      return {
        value: <CellText>{t('rocket.tables.notEscaped')}</CellText>,
        className: 'isRed',
      }
    }

    const valueNumbered = Number(record?.esc_multiplier)
    if (valueNumbered >= 1) {
      return {
        value: <CellText>{f(valueNumbered, { decimal: 2 })}x</CellText>,
        className: 'isGreen',
      }
    }

    return {
      value: <CellNull />,
    }
  } else {
    return {
      value: (
        <CellText>
          {' '}
          {record.tren_ball_type === TrenBallType.Yellow && 'Crash'}
          {record.tren_ball_type === TrenBallType.Green && t('rocket.tabs.green')}
          {record.tren_ball_type === TrenBallType.Red && t('rocket.tabs.red')}
          {record.tren_ball_type === TrenBallType.Blue && 'Moon'}
        </CellText>
      ),
      className: +record?.reward > 0 ? 'isGreen' : gameRound?.state === ROUND_STATE.END ? 'isRed' : '',
    }
  }
}

const renderAverageRow = (listPlayers: any[], t: (key: string, options?: any) => string): RocketTableRow => {
  if (!listPlayers.length)
    return {
      cells: [],
    }

  const escapeData = listPlayers
    .filter((player) => !isNaN(player?.esc_multiplier))
    .map((player) => Number(player?.esc_multiplier))

  const average = escapeData?.reduce((sum, num) => sum + num, 0) / escapeData?.length

  // const closest = escapeData
  //   ?.reduce((prev, curr) =>
  //       Math.abs(curr - average) < Math.abs(prev - average) ? curr : prev,
  //   0
  // );

  return {
    rowProps: {
      position: 'sticky',
      bottom: '0',
      zIndex: '5',
      background: 'rgba(26, 33, 42, 0.7)',
      backdropFilter: 'blur(10px)',
    },
    cells: [
      {
        value: <CellText>{t('rocket.tables.averageEscapeHeight')}：</CellText>,
        colSpan: 2,
        textAlign: 'left',
        width: '59px',
        maxWidth: '59px',
      },
      { value: average ? <CellText>{f(average, { decimal: 2 })}x</CellText> : <CellNull /> },
      { value: <></> },
    ],
  }
}

const TableCurrentLive = () => {
  const { t } = useCustomTranslation()
  const listPlayers = useAppSelector(selectListCurrentLives)
  const gameRound = useAppSelector(selectGameRound)

  const playerRows =
    listPlayers?.length > 0
      ? listPlayers.map(
          (record): RocketTableRow => {
            return {
              cells: [
                renderPlayerCell(record?.player_name),
                renderBetAmountCell(record?.amount, record?.currency),
                renderEscapeCell(t, gameRound, record),
                renderRewardCell(
                  record?.reward,
                  record?.currency,
                  record.tren_ball_type === TrenBallType.None || record.tren_ball_type === ''
                    ? Number(record?.esc_multiplier) < 1
                    : gameRound.state !== ROUND_STATE.END,
                ),
              ],
            }
          },
        )
      : []

  const averageRow = renderAverageRow(listPlayers, t)

  if (playerRows?.length > 0) {
    playerRows.push(averageRow)
  }

  const currentLiveData: RocketTableData = {
    columns: [
      'rocket.tables.player',
      'rocket.tables.betAmount',
      'rocket.tables.escapeHeight',
      'rocket.tables.payoutAmount',
    ],
    rows: playerRows,
  }

  return (
    <RocketTable
      columns={currentLiveData.columns}
      rows={currentLiveData.rows}
      tableContainerProps={{
        maxHeight: '440px',
        overflowY: 'auto',
        position: 'relative',
      }}
      theadProps={{
        position: 'sticky',
        top: '0',
        zIndex: 1,
      }}
      tbodyProps={{
        paddingBottom: '37.19px',
      }}
    />
  )
}

export default TableCurrentLive
