import React, { useRef } from 'react'
import { Box, BoxProps, Stack } from '@chakra-ui/react'
import Confetti from 'react-confetti'
import { useCheerSound } from './hooks/useCheerSound'
import { RoundReward } from './hooks/useRocketGamePlay'
import { formatNumber } from '@/components/banker-player/Betting'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { useAppSelector } from '@/redux/store'
import { selectGameRound } from '@/redux/store/modules/rocketTshow'
import { TrenBallType } from '@/@generated/gql/graphql-hash'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type WinningAnimationProps = BoxProps & {
  rocketProps?: BoxProps
  reward?: RoundReward | null
}

const WinningAnimation = ({ reward, rocketProps, ...rest }: WinningAnimationProps) => {
  const { t } = useCustomTranslation()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const gameRound = useAppSelector(selectGameRound)

  useCheerSound()

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      ref={containerRef}
      zIndex="1"
      className="zoom-container pointer-events-none"
      {...rest}
    >
      <Confetti
        width={containerRef.current?.offsetWidth}
        height={containerRef.current?.offsetHeight}
        style={{
          zIndex: 1,
        }}
      />
      {/*<Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="2" {...rocketProps}>*/}
      {/*  <Box animation="rocket-shake 1s infinite" position="relative">*/}
      {/*    <Image*/}
      {/*      src="/images/rockets/winningAstronaut.png"*/}
      {/*      width="130px"*/}
      {/*      aspectRatio="250/299"*/}
      {/*      position="relative"*/}
      {/*      zIndex="1"*/}
      {/*    />*/}
      {/*    <Box*/}
      {/*      sx={{*/}
      {/*        width: '65px',*/}
      {/*        height: '115px',*/}
      {/*        background: 'url(/images/rockets/rocket-firing-sprite.webp) no-repeat center',*/}
      {/*        backgroundSize: '565px',*/}
      {/*        backgroundPosition: '-13px 0px',*/}
      {/*        position: 'absolute',*/}
      {/*        top: '55px',*/}
      {/*        left: '-44px',*/}
      {/*        transform: 'rotate(65deg)',*/}
      {/*        animation: 'firing 0.5s infinite 0s step-end',*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <Stack
        position="absolute"
        left="0"
        right="0"
        bottom="100px"
        alignItems="center"
        className="text-2xl md:text-3xl font-semibold tracking-wide mt-1"
        gap="24px"
        fontStyle="italic"
        px="12px"
        {...NotoSansFontFamily.medium}
      >
        <RocketText textShadow="0px 1px 3px rgba(0, 0, 0, 0.5)" textAlign="center" className="select-none">
          {t('rocket.winning.yourExtreme')}
          <Box as="span" className="multiplier-label select-none">
            {reward?.tren_ball_type === TrenBallType.None ? ( reward?.maxMultiplier ? ` ${reward?.maxMultiplier}x` : '') : ` ${gameRound?.multiplier}x`}
          </Box>
        </RocketText>
        <RocketText className="multiplier-label select-none" textAlign="center">
          {t('rocket.winning.winning')}{' '}
          {reward?.rewards?.map((reward) => `+${formatNumber(reward?.amount)} ${reward?.currency}`).join(' ')}
        </RocketText>
      </Stack>
    </Box>
  )
}

export default WinningAnimation
