import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import BingoInput from '@/bingo/components/Common/BingoInput.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx'
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import FormGoBack from '@/bingo/components/FormSection/FormGoBack.tsx'
import TicketList from '@/bingo/components/FormSection/OrderConfirmation/TicketList.tsx'
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import { bingoActions, selectBingoTicketsGenerated, selectBingoTotalOrderAmount } from '@/bingo/redux/bingo.slice.ts'
import useCustomToast from '@/hooks/useCustomToast'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React, { useState } from 'react'
import { ImCheckmark } from 'react-icons/im'
import { selectWallet } from '@store/modules/wallet.slice.ts'

const OrderConfirmation = () => {
  const walletSelected = useAppSelector(selectWallet)
  const generatedTickets = useAppSelector(selectBingoTicketsGenerated)
  const dispatch = useAppDispatch()
  const { showToastThrottle } = useCustomToast()

  const handleGoBack = () => {
    dispatch(bingoActions.goBack())
  }

  const handleBetOn = () => {
    dispatch(bingoActions.createBingoOrder()).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        showToastThrottle({
          title: t('notification.successfully'),
          status: 'success',
          isClosable: false,
          duration: 1500,
          icon: <ImCheckmark fontSize={32} />,
          containerStyle: {
            width: '60px',
            paddingBlock: '0px',
            pointerEvents: 'none',
          },
        })
      }
    })
  }
  const totalAmount = useAppSelector(selectBingoTotalOrderAmount)

  function handleAmountChanged(value: number) {
    if (value <= 0) return
    if (value == star) return
    setStar(value)
    const tickets = generatedTickets.map((ticket) => ({
      ...ticket,
      star: value,
    }))
    dispatch(bingoActions.updateTicketsGenerated(tickets))
  }
  const [star, setStar] = useState(10)
  const { t } = useCustomTranslation()

  return (
    <>
      <FormContainer title="Order Confirmation">
        <Stack padding="15px" position="relative" flex="1" gap="0" overflow="hidden">
          <SubTitle marginBottom="10px">
            {t('bingo.maximumBonus', { maxBonus: '500,000' })},{' '}
            {t('bingo.selectedTicketCount', { ticketCount: generatedTickets.length })}
          </SubTitle>
          <TicketList data={generatedTickets} canSwitch />
        </Stack>
        <FormFooter justifyContent="space-between">
          <FormGoBack onClick={handleGoBack} />
          <BingoInput
            handleMinus={() => handleAmountChanged(star - 1)}
            handlePlus={() => handleAmountChanged(star + 1)}
            inputProps={{
              value: star,
              onChange: (event) => handleAmountChanged(parseInt(event.target.value)),
            }}
          />
          <FormButtonBig
            width="130px"
            minHeight="70px"
            height="auto"
            padding="7px 10px"
            display="flex"
            flexDirection="column"
            gap="5px"
            fontSize="18px"
            onClick={handleBetOn}
          >
            {t('bingo.betOnTheNextPeriod')}
            <Flex
              background="rgba(255,255,255,0.8)"
              borderRadius="50px"
              padding="3px 6px"
              alignItems="center"
              justifyContent="center"
              gap="5px"
            >
              <BingoCurrencyIcon currency={walletSelected?.currency} width="15px" />
              <Text color="#ff4218" fontSize="16px">
                {isNaN(totalAmount) ? '--' : totalAmount}
              </Text>
            </Flex>
          </FormButtonBig>
        </FormFooter>
      </FormContainer>
    </>
  )
}

export default OrderConfirmation
