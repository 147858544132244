import { PlinkoMultiplier, PlinkoOrder, PlinkoOrderHistory, PrePublicPlinkoRound } from '@/@generated/gql/graphql-hash'
import { gql } from '@apollo/client'

export interface GetPlinkoMultiplierQueryResp {
  plinkoMultiplier: PlinkoMultiplier[]
}
export const getPlinkoMultiplierQuery = gql`
  query GetPlinkoMultiplier {
    plinkoMultiplier {
      name
      rows
      risk
      multipliers
    }
  }
`

export interface GetPlinkoOrderHistoryQueryResp {
  plinkoOrderHistory: PlinkoOrderHistory
}

export const getPlinkoOrderHistoryQuery = gql`
  query GetPlinkoOrderHistory($input: FilterInput) {
    plinkoOrderHistory(input: $input) {
      total
      data {
        id
        game_id
        server_seed
        client_seed
        random_value
        proof
        player_id
        agency_id
        wallet_id
        amount
        currency
        risk_level
        player_name
        rows
        balls
        reward
        created_at
        ball_paths
        multipliers
      }
    }
  }
`

export interface CreatePlinkoOrderQueryResp {
  createPlinkoOrder: PlinkoOrder
}

export const createPlinkoOrderQuery = gql`
  mutation CreatePlinkoOrder($input: PlinkoBetInput!) {
    createPlinkoOrder(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      proof
      player_id
      agency_id
      wallet_id
      amount
      currency
      risk_level
      player_name
      rows
      balls
      reward
      created_at
      ball_paths
      multipliers
    }
  }
`

export interface CreatePlinkoRoundQueryResp {
  createPlinkoRound: PrePublicPlinkoRound
}

export const createPlinkoRoundQuery = gql`
  mutation CreatePlinkoRound {
    createPlinkoRound {
      id
      game_id
      proof
      player_id
    }
  }
`

export const getMyOrdersBingoQuery = gql`
  query BingoOrderHistory($input: FilterInput!) {
    bingoOrderHistory(input: $input) {
      data {
        id
        round_id
        player_id
        agency_id
        wallet_id
        player_name
        currency
        amount
        ticket_unit_price
        reward
        created_at
        bingo_bet_tickets {
          id
          bingo_bet_id
          numbers
          multiplier
          star
          pattern
        }
        bingo_bet_extra_balls {
          id
          bingo_bet_id
          number
          amount
          index
        }
        bingo_round {
          id
          game_id
          server_seed
          client_seed
          random_value
          result
          proof
          start_time
        }
      }
      limit
      page
      total
    }
  }
`
