import { CrashRound } from '@/@generated/gql/graphql-hash'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getListPasts, rocketTshowActions, selectListPasts } from '@/redux/store/modules/rocketTshow'
import { Flex, Button, Box, Image, BoxProps } from '@chakra-ui/react'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import PointChip from '@components/rocket/GamePlay/Stats/PointChip.tsx'
import useGameId from '@hooks/useGameId.ts'
import useSubscription from '@hooks/useSubscription.tsx'
import { Topics } from '@store/common/topic.ts'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type ListPastsOnScreenProps = {
  sx?: BoxProps
  openStats?: () => void
}

const ListPastsOnScreen = ({ sx, openStats }: ListPastsOnScreenProps) => {
  const gameId = useGameId()
  const listPasts = useAppSelector(selectListPasts)
  const dispatch = useAppDispatch()
  const _message = useSubscription(Topics.lastRound(gameId))
  const message = _message?.message?.message

  const handleOpenStats = () => {
    if (!openStats) return

    openStats()
  }

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      dispatch(rocketTshowActions.updateListPasts(data))
    } catch (error) {
      console.warn('LastRoundSubscription error: ', error)
    }
  }, [message])

  useEffect(() => {
    dispatch(
      getListPasts({
        game_id: gameId,
        page: 0,
        limit: 20,
      }),
    )
  }, [])

  return (
    <Flex
      position="absolute"
      top="56px"
      left="10px"
      right="10px"
      zIndex="2"
      background="rgba(46, 51, 60, 0.43)"
      borderRadius="5.3px"
      gap="0"
      justifyContent="center"
      alignItems="center"
      padding="6px 6px 6px 8px"
      className="zoom-container"
      {...sx}
    >
      <RocketText fontSize={16} mr="auto" color="#606a7a" {...NotoSansFontFamily.medium}>
        {t('rocket.listPastsTitle')}
      </RocketText>
      <Flex alignItems="center" justifyContent="center" gap="5px" marginRight="10px">
        {listPasts
          .slice(0, 5)
          .reverse()
          .map((item: CrashRound, index: number) => {
            return <PointChip point={item.multiplier} key={index} />
          })}
        {/* <Text fontSize={14} lineHeight={1}>
          'abc1231'
        </Text>
        <Text fontSize={14} lineHeight={1}>
          1.23
        </Text> */}
      </Flex>
      <Box width="1px" height="auto" background="rgba(89, 101, 122, 0.45)" alignSelf="stretch" marginRight="10px" />
      <Button
        width="auto"
        height="auto"
        fontSize="14px"
        background="linear-gradient(133deg, #46546c 0%, #343e4e 100%)"
        color="#c6d2e3"
        borderRadius="2.6px"
        padding="2.3px 3.3px"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        gap="4px"
        {...NotoSansFontFamily.medium}
        onClick={handleOpenStats}
        _hover={{}}
        _active={{}}
      >
        <Image src="/images/rockets/icon-pie.webp" width="14px" minWidth="14px" aspectRatio="1" />
        {t('rocket.listPastsStats')}
      </Button>
    </Flex>
  )
}

export default React.memo(ListPastsOnScreen)
