import { Flex, Text } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { LuCheckCheck, LuCopy } from 'react-icons/lu'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import { IconBaseProps } from 'react-icons'

type CopyBtnProps = {
  text?: string;
  colorClassName?: string;
  className?: string;
  customIcon?: React.ReactNode;
  checkedIconProps?: IconBaseProps;
}

export function CopyBtn(props: CopyBtnProps) {
  const { t } = useCustomTranslation()
  const { text, colorClassName, className, customIcon, checkedIconProps, ...rest } = props
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const copy = useCallback((e: any) => {
    // if (Notification.permission === 'default') {
    //   Notification.requestPermission().then((permission) => {
    //     alert(permission)
    //   })
    // }
    e.stopPropagation()
    navigator.clipboard
      .writeText(text || '')
      .then(() => {
        setChecked(true)
        setError(null)
        setTimeout(() => {
          setChecked(false)
        }, 1000)
      })
      .catch((err) => {
        console.warn(err)
        // setError('Failed to copy text')
      })
  }, [text])

  return (
    <span
      {...rest}
      onClick={(e) => copy(e)}
      className={`cursor-pointer ${checked ? 'text-lime-500' : (colorClassName ?? 'text-slate-50')} ${className ?? ''}`}
    >
      {checked ? <LuCheckCheck {...checkedIconProps} /> : customIcon ? customIcon : <LuCopy />}
      {/* {error && <span className="text-red-500 ml-2">{error}</span>} */}
      {checked && (
        <Flex className="fixed text-[12px] text-white top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#333] p-1 items-center gap-1 rounded-md z-10">
          <FaCheck />
          <Text>{t('notification.copySuccessful')}</Text>
        </Flex>
      )}
    </span>
  )
}
