import { LastRound } from '@/bingo/types/bingo'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { Topics } from '@/redux/store/common/topic'
import React, { useEffect } from 'react'
import { useAppDispatch } from '@store'
import { bingoActions } from '@/bingo/redux/bingo.slice.ts'
import { BingoLastRound, BingoTopWinner } from '@store/entities/game.entity.ts'

export default function LastOrderSubscription() {
  const game_id = useGameId()
  const dispatch = useAppDispatch()
  const _message = useSubscription(Topics.lastRound(game_id))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '') as LastRound

      const topWinners: BingoTopWinner[] | undefined = data?.bets?.map(item => {
        return {
          playerName: item.player_name,
          winAmount: item.reward,
          currency: item?.currency
        }
      })
      const lastRoundData: BingoLastRound = {
        id: data?.id,
        topWinners,
        balls: data?.result,
      }
      dispatch(bingoActions.updateLastRound(lastRoundData))
    } catch (error) {
      console.warn('LastOrderSubscription error: ', error)
    }
  }, [message])

  return <></>
}
