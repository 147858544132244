import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import React from 'react';
import { useRef } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { btnClickEffect } from './btnClickEffect';
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type BtnQuickChangeProps = ButtonProps & {
  onBtnClick?: (params?: any) => any;
  changeType: 'plus' | 'minus' | 'multiply' | 'divide';
};

const contentByType: Record<string, string | React.ReactNode> = {
  plus: <FaPlus />,
  minus: <FaMinus />,
  multiply: (
    <Flex
      display="inline-flex"
      alignItems="flex-end"
    >
      <RocketText
        {...NotoSansFontFamily.normal}
      >
        x
      </RocketText>
      <RocketText
        marginLeft="1px"
        {...NotoSansFontFamily.semiBold}
      >
        2
      </RocketText>
    </Flex>
  ),
  divide: (
    <Flex
      display="inline-flex"
      alignItems="flex-end"
    >
      <RocketText
        {...NotoSansFontFamily.normal}
      >
        /
      </RocketText>
      <RocketText
        marginLeft="1px"
        {...NotoSansFontFamily.semiBold}
      >
        2
      </RocketText>
    </Flex>
  ),
};

const BtnQuickChange = (props: BtnQuickChangeProps) => {
  const { onBtnClick, changeType, ...rest } = props;
  const audioRef: any = useRef<HTMLAudioElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    btnClickEffect(e);
    if (audioRef.current) {
      audioRef.current.play();
    }
    if (onBtnClick) {
      onBtnClick();
    }
  };

  return (
    <>
      <audio ref={audioRef} src="/sound/click.mp3" />
      <Button
        aria-label={changeType}
        aria-labelledby={changeType}
        onClick={handleClick}
        minWidth="57.5px"
        width="57.5px"
        height="25px"
        padding="6.3px"
        backgroundColor="#232c39"
        color="#90a7c9"
        borderRadius="4px"
        _hover={{}}
        _active={{}}
        className="btn-hover"
        {...rest}
      >
        {contentByType[changeType]}
      </Button>
    </>
  );
};

export default BtnQuickChange;
