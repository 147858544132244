import ButtonCircle from '@/bingo/components/Common/ButtonCircle.tsx'
// import { useScale } from '@/bingo/hooks/useScale'
import { selectBingoGameRound } from '@/bingo/redux/bingo.slice'
import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppSelector } from '@/redux/store'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { MenuBingo } from '../../Menu'
import { MdOutlineContentCopy } from 'react-icons/md'

const ResultHash = (props: BoxProps) => {
  // const scale = useScale()
  // const transform = `scale(${scale})`
  // const top = 40 * scale
  // const height = 110 * scale
  const round = useAppSelector(selectBingoGameRound)
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenDialog, onOpen: onOpenDiaLog, onClose: onCloseDialog } = useDisclosure()
  const [popup, setPopup] = useState<'Gameplay' | 'Verification'>('Gameplay')

  return (
    <Box
      // height={`${height}px`}
      overflow="hidden"
      zIndex={1}
      {...props}
    >
      <Box
        background="url('/bingo/images/bg-bingo-ribbon.webp') no-repeat center"
        backgroundSize="contain"
        aspectRatio="703/259"
        position="relative"
        // marginTop={`${-top}px`}
        pointerEvents="none"
        width="500px"
        height="185px"
        // transform={transform}
        transformOrigin="top left"
      >
        <Flex
          position="absolute"
          left="70px"
          right="70px"
          bottom="60px"
          alignItems="center"
          justifyContent="space-between"
          gap="2px"
        >
          <ButtonCircle
            onClick={() => {
              setPopup('Gameplay')
              onOpen()
            }}
          >
            ?
          </ButtonCircle>
          <Box color="#c31" fontSize="13px" lineHeight="1.05" wordBreak="break-word" whiteSpace="normal">
            <Text>{t('bingo.verifyResultHash')}:</Text>
            <Text>{round?.proof || ''}</Text>
          </Box>

          <ButtonCircle
            onClick={() => {
              navigator.clipboard.writeText(round?.proof || '')
              onOpenDiaLog()
            }}
          >
            <MdOutlineContentCopy fontSize="12px" />
          </ButtonCircle>
        </Flex>
      </Box>
      {isOpen && (
        <AppDrawer isOpen={isOpen} onClose={onClose} overlay>
          <MenuBingo popup={popup} />
        </AppDrawer>
      )}
      {isOpenDialog && (
        // <AppDrawer isOpen={isOpen} onClose={onClose} overlay>
        //   <MenuBingo popup={'Gameplay'} />
        // </AppDrawer>
        <Modal isCentered isOpen={isOpenDialog} onClose={onCloseDialog}>
          <ModalOverlay />
          <ModalContent background="#ffeded">
            <ModalHeader className="flex items-center !justify-center" fontSize={22} color="#560f40">
              Copied
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody background="#ffeded">
              <Text>VerifyResultHash copied.</Text>
              <Text>You can save it and then verify it after the results are published.</Text>
              <Text>Please click the view button for specific verification methods.</Text>
            </ModalBody>

            <ModalFooter
              className="flex items-center !justify-center gap-2"
              borderRadius="0 0 12px 12px"
              background="#ffeded"
            >
              <Button
                color="#d92727"
                w="128px"
                h="38px"
                borderRadius="8px"
                _hover={{
                  background: 'linear-gradient(180deg, #fec6c0 0%, #feb6ad 100%)',
                }}
                background="linear-gradient(180deg, #fec6c0 0%, #feb6ad 100%)"
                onClick={onCloseDialog}
              >
                Close
              </Button>
              <Button
                color="#ffffff"
                w="128px"
                h="38px"
                borderRadius="8px"
                background="linear-gradient(180deg, #fd563e 0%, #ed4b34 100%)"
                _hover={{
                  background: 'linear-gradient(180deg, #fd563e 0%, #ed4b34 100%)',
                }}
                onClick={() => {
                  setPopup('Verification')
                  onOpen()
                  onCloseDialog()
                }}
              >
                Check
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  )
}

export default ResultHash
