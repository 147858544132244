import { useLayoutEffect } from 'react'
import { isMobile } from 'react-device-detect'

const useMobileViewport = (desiredWidth: number = 515) => {
  useLayoutEffect(() => {
    if (!isMobile) return

    const metaViewport = document.querySelector('meta[name=viewport]');
    if (!metaViewport) return

    // const windowWidth = window.innerWidth
    // const scaleValue = (windowWidth / desiredWidth).toFixed(2)
    // console.log({scaleValue})

    const metaViewportContent = `width=${desiredWidth}px`

    // if (windowWidth !== desiredWidth) {
    //   metaViewportContent += `, initial-scale=${scaleValue}, maximum-scale=${scaleValue}`
    // }
    // console.log({metaViewportContent})
    metaViewport.setAttribute('content', metaViewportContent)

    const loadingElement = document.getElementById('pageLoading')
    if (loadingElement) {
      loadingElement.style.opacity = '0'
    }
  }, [desiredWidth])
}

export default useMobileViewport;