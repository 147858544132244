import React, { useEffect, useRef } from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import gsap from 'gsap'
import './styles/topPlayers.scss'
import { getAvatar } from '@/utils/helper'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

interface TopPlayersProps {
  players: Array<{
    id: string | number
    multiplier: number
    name: string
  }>
}

const TopPlayers: React.FC<TopPlayersProps> = ({ players }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
      )
    }
  }, [])

  return (
    <Flex
      ref={containerRef}
      position="absolute"
      bottom="20px"
      left="50%"
      transform="translate(-50%, 0)"
      alignItems="center"
      className="zoom-container pointer-events-none"
      zIndex="3"
    >
      {[2, 1, 3].map((position) => {
        const player = players[position - 1]
        return (
          <Box
            key={position}
            width={position === 1 ? '151px' : '145px'}
            marginBottom={position === 1 ? '-12px' : ' -19px'}
            zIndex={position === 1 ? 2 : 1}
            className="relative"
          >
            <Image
              src={`/images/rockets/img-bg-rank${position}.webp`}
              transform={position === 1 ? 'scale(1.1, 1.1)' : ''}
              marginBottom={position === 1 ? '40px' : '0'}
            />
            <Image
              src={getAvatar(player.id.toString())}
              alt={`Player avatar ${position}`}
              position="absolute"
              width={position === 1 ? '58px' : '51px'}
              height={position === 1 ? '58px' : '51px'}
              top={position === 1 ? '8px' : '18.5px'}
              left="50.3%"
              transform="translate(-49%, 0)"
              borderRadius="50%"
            />
            {/* // player name */}
            <Box position="absolute" top={position === 1 ? '78px' : '77px'} left="10px" right="10px" lineHeight="1">
              <RocketText
                fontSize="17px"
                textAlign="center"
                color="black"
                {...NotoSansFontFamily.semiBold}
                marginBottom={position === 1 ? '4px' : '2px'}
              >
                {player?.name}
              </RocketText>
              <RocketText fontSize="20px" textAlign="center" color="black" {...NotoSansFontFamily.medium}>
                {player?.multiplier ? `${player.multiplier}x` : ''}
              </RocketText>
            </Box>
          </Box>
        )
      })}
    </Flex>
  )
}

export default TopPlayers
