import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

type FormFooterProps = FlexProps

const FormFooter = ({ children, ...rest }: FormFooterProps) => {
  return (
    <Flex
      backgroundColor="white"
      padding="10px 20px"
      alignItems="center"
      justifyContent="center"
      position="relative"
      minHeight="99px"
      borderTopRadius={20}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default FormFooter
