import React, { useEffect } from 'react'
import { Box, Tab, TabList, TabPanel, TabPanelProps, TabPanels, TabProps, Tabs } from '@chakra-ui/react'
import TableCurrentLive from '@components/rocket/NewUI/RocketTables/TableCurrentLive.tsx'
import TableMyBets from '@components/rocket/NewUI/RocketTables/TableMyBets.tsx'
import TableBreakingPoints from '@components/rocket/NewUI/RocketTables/TableBreakingPoints.tsx'
import { useAppDispatch } from '@/redux/store'
import { getListCurrentLives, getMyOrders } from '@/redux/store/modules/rocketTshow'
import useGameId from '@/hooks/useGameId'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type RocketTablesTabProps = TabProps
type RocketTableTabPanelProps = TabPanelProps

const RocketTablesTab = ({ children, ...rest }: RocketTablesTabProps) => {
  return (
    <Tab
      background="none"
      borderRadius="10px"
      padding="15px 7px"
      color="#546175"
      width="calc(100% / 3)"
      lineHeight="1"
      {...NotoSansFontFamily.normal}
      fontSize="19px"
      _selected={{
        background: 'rgba(38, 48, 62, 0.7)',
        color: '#eef4fc',
        ...{...NotoSansFontFamily.semiBold},
      }}
      {...rest}
    >
      {children}
    </Tab>
  )
}

const RocketTablesTabPanel = ({ children, ...rest }: RocketTableTabPanelProps) => {
  return (
    <TabPanel padding="0" {...rest}>
      {children}
    </TabPanel>
  )
}

const RocketTables = () => {
  const { t, i18n } = useCustomTranslation()
  const dispatch = useAppDispatch()
  const gameId = useGameId()
  const currentLang = i18n.language

  useEffect(() => {
    dispatch(
      getMyOrders({
        game_id: gameId,
        page: 0,
        limit: 10,
      }),
    )
  }, [])

  useEffect(() => {
    dispatch(
      getListCurrentLives({
        game_id: gameId,
      }),
    )
  }, [])

  return (
    <Box background="rgba(26, 33, 42, 0.7)" borderRadius="13.3px" overflow="hidden" marginTop="8.3px">
      <Tabs variant="unstyled" isLazy>
        <Box padding="18.3px 6.3px 20.3px">
          <TabList background="rgba(17, 21, 27, 0.7)" borderRadius="12px">
            <RocketTablesTab>
              <RocketText>
                {t('rocket.tables.currentLive')}
              </RocketText>
            </RocketTablesTab>
            <RocketTablesTab>
              <RocketText>
                {t('rocket.tables.myBets')}
              </RocketText>
            </RocketTablesTab>
            <RocketTablesTab>
              <RocketText
                letterSpacing={currentLang === 'en' ? '-0.5px' : ''}
              >
                {t('rocket.tables.breakingPoints')}
              </RocketText>
            </RocketTablesTab>
          </TabList>
        </Box>
        <TabPanels>
          <RocketTablesTabPanel>
            <TableCurrentLive />
          </RocketTablesTabPanel>
          <RocketTablesTabPanel>
            <TableMyBets />
          </RocketTablesTabPanel>
          <RocketTablesTabPanel>
            <TableBreakingPoints />
          </RocketTablesTabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default RocketTables
