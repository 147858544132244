import { useAppSelector } from '@/redux/store';
import endpoint from '@const/endpoint.ts'
import { fetchFn } from '@libs/apiHubFetcher.ts'
import { selectToken } from '@store/modules/auth.slice.ts'
import { useEffect, useState } from 'react'

const fetchPlayerAgency = async (token: string) => {
  if (!token) {
    throw new Error('No token found');
  }
  const data = await fetchFn('GET', `${endpoint.PLAYER_AGENCY}`);
  return data;
};

export const usePlayerAgencyInfo = () => {
  const token = useAppSelector(selectToken);
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (!token) return

    fetchPlayerAgency(token)
      .then(res => {
        setData(res?.data?.data)
      })
      .catch((error) => {
        console.error('usePlayerAgencyInfo error:', error)
      })
  }, [token])

  return { agencyInfo: data }
};

export default usePlayerAgencyInfo;
