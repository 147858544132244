import { Center, Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

type NumberCellProps = FlexProps & {
  value: number
  active: boolean
}

const NumberCell = ({ value, active, ...rest }: NumberCellProps) => {
  const style = active
    ? {
        background: '#ff4218',
        color: '#fff',
      }
    : {}

  return (
    <Flex
      width="calc(25% - 1.5px)"
      minWidth="calc(25% - 1.5px)"
      color="#ff4218"
      fontSize="26px"
      fontWeight="500"
      lineHeight="1"
      aspectRatio="1/1"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      userSelect="none"
      background="linear-gradient(180deg, #ffefdc 0%, #fff5ef 100%)"
      {...rest}
    >
      <Center width="80%" height="80%" borderRadius="50%" {...style}>
        {value}
      </Center>
    </Flex>
  )
}

export default NumberCell
