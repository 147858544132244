import { useAppSelector } from '@/redux/store'
import { selectDiffTime } from '@/redux/store/modules/game.slice'
import { useEffect, useRef, useState } from 'react'
import {
  selectBingoGameRoundStartTime,
  selectBingoHasPlayingOrderInRound,
  selectBingoIsRunning,
  selectBingoRoundConfig,
} from '../redux/bingo.slice'

export function useRoundCountdown() {
  const start_time = useAppSelector(selectBingoGameRoundStartTime)
  const { roundTime } = useAppSelector(selectBingoRoundConfig) || {}
  const hasPlayingOrder = useAppSelector(selectBingoHasPlayingOrderInRound)
  const isRunning = useAppSelector(selectBingoIsRunning)
  const ignoreCountdown = hasPlayingOrder && isRunning

  const totalRoundTime = roundTime * 1000
  const [seconds, setSeconds] = useState(0)
  const diffTime = useAppSelector(selectDiffTime)
  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    diffTimeRef.current = diffTime
  }, [diffTime])

  useEffect(() => {
    if (!start_time) return
    if (ignoreCountdown) return

    const interval = setInterval(() => {
      const startTime = new Date(start_time).getTime()
      const _diff = startTime - Date.now() - diffTimeRef.current
      const diff = _diff > 0 ? _diff : _diff + totalRoundTime

      const seconds = Math.round(diff / 1000)

      setSeconds(seconds)
    }, 100)
    return () => clearInterval(interval)
  }, [start_time, totalRoundTime, ignoreCountdown])
  return { seconds: ignoreCountdown ? -1 : seconds }
}
