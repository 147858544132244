import React, { useEffect, useRef } from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import gsap from 'gsap'
import './styles/topPlayers.scss'
import StarBlinking from './StarBlinking'

interface TopPlayersProps {
  players: Array<{
    id: string | number
    multiplier: number
    name: string
  }>
}

const playerTextColorsByPosition: Record<string, string> = {
  1: '#ffc300',
  2: '#b7c9d6',
  3: '#fe9a41',
}

const TopPlayers: React.FC<TopPlayersProps> = ({ players }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
      )
    }
  }, [])

  const getSpotStyles = (position: number) => {
    const baseStyles = 'flex flex-col items-center gap-2'
    const podiumHeights = {
      1: 'h-[50px]',
      2: 'h-[35px]',
      3: 'h-[30px]',
    }
    const podiumColors = {
      1: 'bg-[#FFD700]',
      2: 'bg-[#808080]',
      3: 'bg-[#CD7F32]',
    }
    const podiumWidths = {
      1: 'w-[120px]',
      2: 'w-[100px]',
      3: 'w-[100px]',
    }

    return {
      container: baseStyles,
      podium: `${podiumHeights[position as keyof typeof podiumHeights]} ${
        podiumColors[position as keyof typeof podiumColors]
      } ${podiumWidths[position as keyof typeof podiumWidths]} flex items-center justify-center rounded-t-lg`,
      player: `w-[80px] h-[80px] flex items-center justify-center relative ${
        position === 1 ? 'top-[-7px]' : 'top-[8px]'
      }`,
      glowEffect:
        position === 1
          ? 'w-[100px] h-[100px] absolute top-[-18px] left-[-10px] bg-[radial-gradient(circle,rgba(255,255,255,0.8)_0%,rgba(255,255,255,0)_70%)] z-[-1]'
          : 'w-[85px] h-[85px] absolute top-[-8px] left-[-2px] bg-[radial-gradient(circle,rgba(255,255,255,0.8)_0%,rgba(255,255,255,0)_70%)] z-[-1]',
      position: `text-${position === 1 ? '2xl' : 'xl'} font-bold text-[white] select-none`,
    }
  }

  return (
    <>
      <Flex
        ref={containerRef}
        position="absolute"
        bottom="33px"
        left="50%"
        transform="translate(-50%, 0)"
        alignItems="flex-end"
      >
        {[2, 1, 3].map((position) => {
          const player = players[position - 1]
          const styles = getSpotStyles(position)
          const starsByPosition: Record<number, number> = {
            1: 3,
            2: 2,
            3: 1,
          }

          return (
            <Box key={position} className={styles.container} justifyContent="flex-end" gap="0">
              <Box className={styles.player}>
                <Image
                  src="/images/tshows/end-game/players-back.webp"
                  position="absolute"
                  zIndex="-1"
                  minWidth="90px"
                  top={position === 1 ? '-20px' : '-13px'}
                />
                <Box className={styles.glowEffect} />
                <Box width="130px" height="130px" position="absolute" top="-34px" zIndex="-1">
                  <Box
                    width="130px"
                    height="130px"
                    opacity="0"
                    className="halo-scale-animation"
                    id={`halo-scale-animation-${position}`}
                    onAnimationEnd={async (event) => {
                      const animationTarget = document.getElementById(`halo-scale-animation-${position}`)
                      if (!animationTarget) return

                      const animationName = event.animationName
                      if (animationName === 'halo-scale-out') {
                        animationTarget.classList.remove('halo-scale-animation')
                        await new Promise((resolve) => setTimeout(resolve, 1))
                        animationTarget.classList.add('halo-scale-animation')
                      }
                    }}
                  >
                    <Image
                      src={`/images/tshows/end-game/halo${position}.png`}
                      width="100%"
                      height="100%"
                      className="halo-spin-animation"
                    />
                  </Box>
                  {new Array(starsByPosition[position]).fill(1).map((_, index) => {
                    return <StarBlinking key={`star-${index}`} rank={position} index={index + 1} />
                  })}
                </Box>
                <Image
                  src={`/images/tshows/img_rank${position}.webp`}
                  alt={`Player ${position}`}
                  w={position < 3 ? '60px' : '50px'}
                />
              </Box>
              <Box
                textAlign="center"
                color={playerTextColorsByPosition[position]}
                lineHeight="1"
                padding="10px 0"
                fontWeight="500"
              >
                <Box fontSize="30px" fontStyle="italic">
                  {player?.multiplier > 0 ? `${player.multiplier.toFixed(2)}x` : ''}
                </Box>
                <Box fontSize="20px" className="select-none">
                  {player?.name}
                </Box>
              </Box>
              <Box className={styles.podium}>
                <Box className={styles.position}>{position}</Box>
              </Box>
            </Box>
          )
        })}
      </Flex>
      <Box
        background="linear-gradient(transparent, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) ,#000000)"
        height="40px"
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        opacity="0.7"
      />
    </>
  )
}

export default TopPlayers
