import React from 'react';
import { Flex, FlexProps, Image, ImageProps } from '@chakra-ui/react'
import usePlayerAgencyInfo from '@hooks/useAgencyInfo.ts'

type AgencyLogoProps = FlexProps & {
  imageProps?: ImageProps,
}

const RocketAgencyLogo = ({imageProps, ...rest}: AgencyLogoProps) => {
  const { agencyInfo } = usePlayerAgencyInfo()

  if (!agencyInfo?.logo_url) {
    return <></>
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      marginTop="28px"
      {...rest}
    >
      <Image
        src={agencyInfo?.logo_url}
        width="auto"
        maxWidth="135px"
        {...imageProps}
      />
    </Flex>
  )
}

export default RocketAgencyLogo;
