import RuleGame from '@/components/hash-game/RuleGame'
import SoundSetting from '@/components/hash-game/settings/SoundSetting'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { TfiMenuAlt } from 'react-icons/tfi'
import SelectMenuPopupRocket from './SelectMenuPopup'
import GamePlayAndVerification from './GamePlayAndVerification'

interface Option {
  value: string
  label: string
  headingPopup?: string
  content: ReactNode
  image: string
}

export default function ListMenu() {
  const { t } = useCustomTranslation()
  const options1: Option[] = [
    // {
    //   value: 'BettingRecord',
    //   label: 'bettingRecord',
    //   headingPopup: 'bettingListing',
    //   content: (
    //     <Box p={4}>
    //       <ListResult noPaging={false} />
    //     </Box>
    //   ),
    // },
    {
      value: 'RuleGame',
      label: 'ruleGame',
      content: <RuleGame />,
      image: '/images/rockets/ic-note.webp',
    },
    {
      value: 'GamePlay',
      label: 'gamePlay',
      // content: <Fairness isMenu={true} randomValue={""}/>,
      content: <GamePlayAndVerification />,
      image: '/images/rockets/ic-info.webp',
    },
    { value: 'SoundSetting', label: 'soundSetting', content: <SoundSetting />, image: '/images/rockets/ic-sound.webp' },
  ]

  return (
    <Flex gap={1} alignItems="center" justifyContent="end" ml="auto">
      <SelectMenuPopupRocket options={options1} icon={<TfiMenuAlt color="#c3fb04" fontSize={22} />} />
    </Flex>
  )
}
