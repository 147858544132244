import React from 'react'
import EndGameAnimation from '@/bingo/components/Common/EndGameAnimation'
import { useAppSelector } from '@store'
import { selectBingoOpenPopupLastRound } from '@/bingo/redux/bingo.slice.ts'

const PopupLastRound = () => {
  const openPopupLastRound = useAppSelector(selectBingoOpenPopupLastRound)

  if (!openPopupLastRound) return <></>

  return (
    <EndGameAnimation />
  )
}

export default PopupLastRound