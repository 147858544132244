import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { genTicketId } from '../utils/BingoEngine'
import bingoSlice from './bingo.slice'

export type BingoPreferenceState = {
  ticketUnitPrice: number
  numberOfTickets: number
  ticketsCollected: string[]
  ticketDetails: Record<string, LocalBingoTicket>
}

const initialState: BingoPreferenceState = {
  numberOfTickets: 1,
  ticketsCollected: [],
  ticketDetails: {},
  ticketUnitPrice: 2,
}

export const bingoPreferenceSlice = createSlice({
  name: 'bingoPreference',
  initialState,
  reducers: {
    updateNumberOfTickets: (state, action: PayloadAction<number>) => {
      state.numberOfTickets = action.payload
    },
    addTicketCollection: (state, action: PayloadAction<LocalBingoTicket>) => {
      state.ticketsCollected = [...state.ticketsCollected, action.payload.id]
      state.ticketDetails = {
        ...state.ticketDetails,
        [action.payload.id]: action.payload,
      }
    },
    removeTicketCollection: (state, action: PayloadAction<LocalBingoTicket>) => {
      state.ticketsCollected = state.ticketsCollected.filter((id) => id != action.payload.id)
    },
    ticketDetailUpdated: (state, action: PayloadAction<LocalBingoTicket>) => {
      const id = genTicketId(action.payload.numbers)

      state.ticketDetails[id] = {
        ...action.payload,
        id,
      }
      if (id != action.payload.id) {
        state.ticketsCollected = state.ticketsCollected.map((item) => (item == action.payload.id ? id : item))
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(bingoSlice.actions.updateTicketsGenerated, (state, action) => {
      state.ticketDetails = {
        ...state.ticketDetails,
        ...keyBy(action.payload, 'id'),
      }
    })
  },
})

export default bingoPreferenceSlice
