import PatternCell from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternCell.tsx'
import { Flex } from '@chakra-ui/react'
import React from 'react'

interface PatternBoardProps {
  pattern: string
}

const blankPattern = 'ooooo|ooooo|ooooo'

const PatternBoard = ({ pattern }: PatternBoardProps) => {
  const boardPattern = ['1L', '2L'].includes(pattern) ? blankPattern : pattern
  return (
    <Flex flexWrap="wrap" gap="1.2px" borderRadius="1px" overflow="hidden">
      {boardPattern.split('').map((cell, index) => {
        const variant = cell == 'o' ? 'dark' : cell == 'x' ? 'light' : null
        if (!variant) return null
        return <PatternCell variant={variant} key={index} />
      })}
    </Flex>
  )
}

export default PatternBoard
