import Ticket from '@/bingo/components/Common/Ticket'
import { selectBingoCurrentStep, selectBingoTicketUnitPrice } from '@/bingo/redux/bingo.slice.ts'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { Flex, FlexProps } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import { useVirtualizer } from '@tanstack/react-virtual'
import React, { useRef } from 'react'

type TicketListProps = FlexProps & {
  data?: LocalBingoTicket[]
  resultBalls?: number[]
  expectedBalls?: boolean
  canEdit?: boolean
  canSwitch?: boolean
  canDelete?: boolean
  // onEdit?: () => void,
}

const TicketList = ({ data, resultBalls, expectedBalls, canEdit, canSwitch, canDelete }: TicketListProps) => {
  const currentStep = useAppSelector(selectBingoCurrentStep)
  const ticketUnitPrice = useAppSelector(selectBingoTicketUnitPrice)
  const parentRef = useRef<HTMLDivElement>(null)

  const items = data || []
  const itemPerRow = 2
  const rowCount = Math.ceil(items.length / itemPerRow)
  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 190,
  })

  return (
    <Flex
      ref={parentRef}
      flexWrap="nowrap"
      flexDirection="column"
      overflowY="auto"
      height="100%"
      padding="10px 10px 20px"
      alignItems="flex-start"
      marginBottom="auto"
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          position: 'relative',
          width: '100%',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const index = virtualRow.index * itemPerRow
          return (
            <Flex
              key={virtualRow.key}
              justifyContent="space-between"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              <Ticket
                ticketUnitPrice={ticketUnitPrice + ''}
                data={items[index]}
                resultBalls={resultBalls}
                isBetSuccess={currentStep === 'betSuccess'}
                expectedBalls={expectedBalls}
                canEdit={canEdit}
                canSwitch={canSwitch}
                canDelete={canDelete}
              />
              {index + 1 < items.length && (
                <Ticket
                  ticketUnitPrice={ticketUnitPrice + ''}
                  data={items[index + 1]}
                  resultBalls={resultBalls}
                  isBetSuccess={currentStep === 'betSuccess'}
                  expectedBalls={expectedBalls}
                  canEdit={canEdit}
                  canSwitch={canSwitch}
                  canDelete={canDelete}
                />
              )}
            </Flex>
          )
        })}
      </div>
    </Flex>
  )
}

export default TicketList
