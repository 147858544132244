import FormBackground from '@/bingo/components/FormSection/FormBackground.tsx'
import Mining from '@/bingo/components/FormSection/Mining'
import OrderConfirmation from '@/bingo/components/FormSection/OrderConfirmation'
import ResultHash from '@/bingo/components/FormSection/ResultHash'
import YourBet from '@/bingo/components/FormSection/YourBet'
// import { useScale } from '@/bingo/hooks/useScale'
import { selectBingoCurrentStep } from '@/bingo/redux/bingo.slice.ts'
import '@/bingo/styles/bingo.scss'
import { Box, Stack } from '@chakra-ui/react'
import { useAppSelector } from '@store'
import React from 'react'
import { BingoCountDown } from './BingoCountDown'
import { GenTicket } from './GenTicket'

const FormSection = () => {
  const currentStep = useAppSelector(selectBingoCurrentStep)
  // const scale = useScale()
  // const top = -36 * scale

  return (
    <Stack flex="1" gap="0" position="relative" width="100%" marginTop="-45px">
      <FormBackground marginTop="120px" flex="1">
        <ResultHash marginTop="-115px" />
        <BingoCountDown marginTop="-46px" />
        <Box overflow="hidden" height="100%" padding={1} paddingBottom={0} position="relative">
          {currentStep === 'randomTicket' && <GenTicket />}
          {currentStep === 'orderConfirmation' && <OrderConfirmation />}
          {currentStep === 'betSuccess' && <YourBet />}
          {currentStep === 'mining' && <Mining />}
        </Box>
      </FormBackground>
    </Stack>
  )
}

export default FormSection
