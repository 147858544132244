import {
  BingoBetTickets,
  BingoExtraBallInput,
  BingoNextRoundOrderInput,
  BingoOrder,
  BingoTicketInput,
} from '@/@generated/gql/graphql-hash'
import { BingoGameStep, LocalBingoTicket, PatternBoardRate, WinningTicketItem } from '@/bingo/types/bingo.ts'
import { Configs } from '@/const/configs'
import { BingoLastRound, BingoRound, ConfigRound } from '@/redux/store/entities/game.entity'
import { selectUserName } from '@/redux/store/modules/auth.slice'
import { createThunk } from '@/redux/store/modules/common'
import { selectActiveWallet } from '@/redux/store/modules/wallet.slice'
import {
  BingoActiveOrderResp,
  BingoNextRoundOrderResp,
  buyExtraBallMutate,
  BuyExtraBallResp,
  createBingoNextRoundOrderMutate,
  CreateBingoNextRoundOrderResp,
  getBingoActiveOrderQuery,
  getBingoNextRoundOrderQuery,
} from '@/redux/store/services/queries.bingo'
import { gqlClient } from '@/utils/apollo-client'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store'
import Decimal from 'decimal.js'
import { isEmpty, map, sum } from 'lodash'
import { bingoConfig } from '../const/bingoConfigs'
import {
  convertBEPattern,
  genTicketId,
  getBingoExtraBallAmount,
  getBingoExtraBallPosition,
  getTicketMatchPattern,
} from '../utils/BingoEngine'
import { bingoPreferenceSlice } from './bingoPreference.slice'

const zero = new Decimal(0)

interface BingoPage {
  step: BingoGameStep
  args?: any
}

export type BingoGameState = {
  pages: BingoPage[]
  ticketsGenerated: string[]
  winningTicket?: LocalBingoTicket
  playingOrder?: BingoOrder
  gameRound: BingoRound
  roundConfig: ConfigRound
  roundBalls: number[]
  patterns: PatternBoardRate[]
  pendingWinningTickets: WinningTicketItem[]
  winningTicketsCongrats: Record<number, BingoBetTickets>
  lastRound?: BingoLastRound
  openPopupLastRound?: boolean
}

const initialState: BingoGameState = {
  pages: [{
    step: 'randomTicket',
    args: {
      tabIndex:
        sessionStorage.getItem('bingoTabIndex')
          ? Number(sessionStorage.getItem('bingoTabIndex'))
          : 0,
    },
  }],
  ticketsGenerated: [],
  gameRound: {} as BingoRound,
  roundConfig: {} as ConfigRound,
  roundBalls: [],
  patterns: [],
  pendingWinningTickets: [],
  winningTicketsCongrats: {},
  lastRound: undefined,
  openPopupLastRound: false,
}

export const getBingoPlayingOrder = createThunk('bingo/getBingoPlayingOrder', async (_: void, thunkApi) => {
  try {
    const [order1, order2] = await Promise.all([
      gqlClient
        .query<BingoActiveOrderResp>({
          query: getBingoActiveOrderQuery,
        })
        .then((resp) => resp.data.bingoActiveOrder)
        .catch(() => null),
      gqlClient
        .query<BingoNextRoundOrderResp>({
          query: getBingoNextRoundOrderQuery,
        })
        .then((resp) => resp.data.bingoNextRoundOrder)
        .catch(() => null),
    ])
    return order1 || order2
  } catch (error) {
    console.info(error)
    return null
  }
})

export const createBingoOrder = createThunk('bingo/createBingoOrder', async (_: void, thunkApi) => {
  const { getState } = thunkApi
  const state = getState() as RootState
  const generatedTickets = selectBingoTicketsGenerated(state)

  const tickets = generatedTickets.map(({ numbers, star }) => ({ numbers, star }))
  const price = selectBingoTicketUnitPrice(state)
  const amount = calTotalOrderAmount(tickets, price) + ''
  const wallet = selectActiveWallet(state) || {}

  const orderRequest: BingoNextRoundOrderInput = {
    amount,
    currency: wallet.currency,
    game_id: Configs.bingoGameId,
    player_name: selectUserName(state) || '',
    tickets,
    wallet_id: wallet.id,
  }
  // try {
  const resp = await gqlClient.mutate<CreateBingoNextRoundOrderResp>({
    mutation: createBingoNextRoundOrderMutate,
    variables: { input: orderRequest },
  })
  const order = resp.data?.createBingoNextRoundOrder!
  return order
  // } catch (error) {
  //   console.log('mylog err: ', error)

  //   const roundId = selectBingoGameRoundId(state)
  //   const request: BingoOrderInput = { ...orderRequest, round_id: roundId }
  //   const resp = await gqlClient.mutate<CreateBingoOrderResp>({
  //     mutation: createBingoOrderMutate,
  //     variables: { input: request },
  //   })
  //   return resp.data?.createBingoOrder!
  // }
})
export const resetNewRound = createThunk('bingo/resetNewRound', async (_: void, thunkApi) => {
  const { getState } = thunkApi
  const state = getState() as RootState
  return state.bingo.playingOrder
})

export const buyExtraBall = createThunk('bingo/buyExtraBall', async (_: void, thunkApi) => {
  const { getState } = thunkApi
  const state = getState() as RootState
  const round_id = selectBingoGameRound(state)?.id
  const amount = selectBingoExtraBallAmount(state)
  const bet_id = selectBingoPlayingOrder(state)?.id || ''

  const roundBalls = selectBingoRoundBalls(state).length
  const index = roundBalls

  const orderRequest: BingoExtraBallInput = {
    amount,
    bet_id,
    index,
    round_id,
  }

  const resp = await gqlClient.mutate<BuyExtraBallResp>({
    mutation: buyExtraBallMutate,
    variables: { input: orderRequest },
  })
  return resp.data?.bingoBuyExtraBall!
})

const bingoSlice = createSlice({
  name: 'bingo',
  initialState,
  reducers: {
    roundBallsUpdated: (state, action: PayloadAction<number[]>) => {
      state.roundBalls = action.payload
      const { roundBalls, playingOrder, patterns, gameRound } = state
      if (
        playingOrder?.round_id &&
        playingOrder?.round_id == gameRound.id &&
        roundBalls.length >= bingoConfig.ticket.cols
      ) {
        const { balls: ballCount } = bingoConfig.mining
        const { bingo_bet_extra_balls } = playingOrder
        const normalBalls = roundBalls.slice(0, ballCount)
        const extraBalls = bingo_bet_extra_balls.map((i) => roundBalls[i.index])
        const balls = [...normalBalls, ...extraBalls]
        state.playingOrder = {
          ...playingOrder,
          bingo_bet_tickets: playingOrder.bingo_bet_tickets.map((ticket) => {
            const pattern = getTicketMatchPattern(ticket.numbers, balls, patterns)

            return {
              ...ticket,
              multiplier: (pattern?.multiplier || 0) + '',
              pattern: pattern?.index.toString(2) || '',
            }
          }),
        }

        const firstItem = state.pendingWinningTickets[0]
        const pendingWinningTickets = state.playingOrder.bingo_bet_tickets
          .map((ticket, index) => ({ ...ticket, index }))
          .filter(({ multiplier, index }) => {
            if (index == firstItem?.index && multiplier == firstItem?.multiplier) return false

            return +multiplier > 0 && multiplier != state.winningTicketsCongrats[index]?.multiplier
          })
        pendingWinningTickets.sort((a, b) => +b.multiplier - +a.multiplier)
        if (firstItem && firstItem.index != pendingWinningTickets[0]?.index) {
          pendingWinningTickets.unshift(firstItem)
        }

        state.pendingWinningTickets = pendingWinningTickets
      }
    },

    congratsTicketSuccess: (state) => {
      const [item, ...items] = state.pendingWinningTickets
      state.pendingWinningTickets = items
      if (item) {
        state.winningTicketsCongrats[item.index] = item
      }
    },

    naviate: (state, action: PayloadAction<BingoPage>) => {
      state.pages = [action.payload, ...state.pages]
    },
    goBack: (state) => {
      if (state.pages.length <= 1) return

      const [_, ...pages] = state.pages
      state.pages = pages
    },
    setParams: (state, action: PayloadAction<any>) => {
      if (action.payload?.tabIndex.toString().length > 0) {
        sessionStorage.setItem('bingoTabIndex', action.payload.tabIndex.toString())
      }
      state.pages[0].args = action.payload
    },
    updateTicketsGenerated: (state, action: PayloadAction<LocalBingoTicket[]>) => {
      state.ticketsGenerated = action.payload.map(({ id }) => id)
    },
    updateGameRound: (state, action: PayloadAction<BingoRound>) => {
      state.gameRound = action.payload
      const { status, id, isRetain } = action.payload
      const playing = !isEmpty(state.playingOrder)

      if (!isRetain && status != 'End' && state.playingOrder?.id && !state.playingOrder.round_id) {
        state.playingOrder.round_id = id
      }

      switch (status) {
        case 'Running':
          if (playing) {
            state.pages = [{ step: 'betSuccess' }]
          }
          state.roundBalls = []
          break

        default:
          break
      }
      // if (state.playingOrder?.round_id && state.playingOrder?.round_id != id) {
      //   state.playingOrder = undefined
      //   if (state.pages[0].step == 'betSuccess') {
      //     state.pages = [{ step: 'randomTicket' }]
      //     state.extraBallIndex = []
      //   }
      // }
    },
    updateRoundConfig: (state, action: PayloadAction<ConfigRound>) => {
      state.roundConfig = action.payload
      state.patterns = parsePattern(action.payload.bingoPattern)
    },
    updateLastRound: (state, action: PayloadAction<BingoLastRound>) => {
      state.lastRound = action.payload
    },
    showPopupLastRound: (state, action: PayloadAction<boolean>) => {
      state.openPopupLastRound = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createBingoOrder.fulfilled, (state, action) => {
        state.pages = [{ step: 'betSuccess' }]
        if (action.payload) {
          state.playingOrder = action.payload
        }
        state.ticketsGenerated = []
      })
      .addCase(resetNewRound.fulfilled, (state) => {
        state.pendingWinningTickets = []
        state.winningTicketsCongrats = []
        if (state.gameRound.id != state.playingOrder?.round_id) {
          state.playingOrder = undefined
          if (state.pages[state.pages.length - 1]?.step == 'betSuccess') {
            state.pages = [{ step: 'randomTicket' }]
          }
        }
      })
      .addCase(bingoActions.ticketDetailUpdated, (state, action) => {
        const id = genTicketId(action.payload.numbers)
        if (id != action.payload.id) {
          state.ticketsGenerated = state.ticketsGenerated.map((item) => (item == action.payload.id ? id : item))
        }
      })
      .addCase(getBingoPlayingOrder.fulfilled, (state, action) => {
        if (action.payload) {
          state.playingOrder = action.payload
          state.pages = [{ step: 'betSuccess' }]
        }
      })
      .addCase(buyExtraBall.fulfilled, (state, action) => {
        state.playingOrder = {
          ...action.payload,
          bingo_bet_tickets: state.playingOrder?.bingo_bet_tickets || action.payload.bingo_bet_tickets,
        }
      })
  },
})

export const _selectBingoPatterns = (state: RootState) => state.bingo.roundConfig.bingoPattern
function parsePattern(raw: { name: string; index: string; multiplier: string }[]) {
  const patterns = (raw || []).map(({ name, multiplier, index }) => {
    const pattern = convertBEPattern(index)
    const r: PatternBoardRate = {
      name,
      rate: +multiplier,
      pattern: pattern,
    }
    return r
  })
  const grouped: Record<string, PatternBoardRate[]> = patterns.reduce((acc, item) => {
    if (!acc[item.name]) {
      acc[item.name] = []
    }
    acc[item.name].push(item)
    return acc
  }, {} as Record<string, PatternBoardRate[]>)

  const result = Object.values(grouped).map((patterns) => {
    if (patterns.length == 1) return patterns[0]
    return {
      name: patterns[0].name,
      rate: patterns[0].rate,
      ex: patterns.map((p) => p.pattern),
      pattern: patterns[0].name,
    }
  })
  result.sort((a, b) => a.rate - b.rate)
  return result
}
export const selectBingoPatterns = (state: RootState) => state.bingo.patterns
export const selectBingoNumbersOfTickets = (state: RootState) => state.bingoPreference.numberOfTickets
export const selectBingoCurrentStep = (state: RootState) => state.bingo.pages[0].step
export const selectBingoCurrentPage = (state: RootState) => state.bingo.pages[0]
const _selectBingoTicketsGenerated = (state: RootState) => state.bingo.ticketsGenerated
const _selectBingoTicketDetails = (state: RootState) => state.bingoPreference.ticketDetails
export const selectBingoTicketsGenerated = createSelector(
  [_selectBingoTicketsGenerated, _selectBingoTicketDetails],
  (ids, details) => {
    return ids.map((id) => details[id]).filter((item) => item)
  },
)
const _selectBingoTicketsCollected = (state: RootState) => state.bingoPreference.ticketsCollected
export const selectBingoTicketsCollected = createSelector(
  [_selectBingoTicketsCollected, _selectBingoTicketDetails],
  (ids, details) => {
    return ids.map((id) => details[id]).filter((item) => item)
  },
)
export const selectBingoTicketUnitPrice = (state: RootState) => state.bingoPreference.ticketUnitPrice
export const selectBingoCanBack = (state: RootState) => state.bingo.pages.length > 1
export const selectBingoPlayingOrder = (state: RootState) => state.bingo.playingOrder
export const selectBingoHasPlayingOrder = (state: RootState) => !isEmpty(state.bingo.playingOrder)
export const selectBingoPlayingOrderRoundId = (state: RootState) => state.bingo.playingOrder?.round_id
export const selectBingoHasPlayingOrderInRound = (state: RootState) =>
  !!state.bingo.playingOrder?.round_id && state.bingo.playingOrder.round_id == state.bingo.gameRound.id
export const selectBingoCanBuyMoreTicket = (state: RootState) =>
  !state.bingo.playingOrder?.round_id ||
  (selectBingoIsWaiting(state) && state.bingo.playingOrder?.round_id == state.bingo.gameRound.id)
export const selectBingoHasWinningTicket = (state: RootState) => state.bingo.pendingWinningTickets.length > 0
export const selectBingoWinningTickets = (state: RootState) => state.bingo.pendingWinningTickets
export const selectBingoWinningTicket = (state: RootState) => state.bingo.pendingWinningTickets[0]
export const selectBingoGameRound = (state: RootState) => state.bingo.gameRound
export const selectBingoGameRoundStartTime = (state: RootState) => state.bingo.gameRound.start_time
export const selectBingoGameRoundId = (state: RootState) => state.bingo.gameRound.id
export const selectBingoRoundStatus = (state: RootState) => selectBingoGameRound(state)?.status
export const selectBingoIsRunning = (state: RootState) =>
  selectBingoHasPlayingOrder(state) && selectBingoRoundStatus(state) == 'Running'
export const selectBingoIsWaiting = (state: RootState) => selectBingoRoundStatus(state) == 'Waiting'
export const selectBingoIsMinningExtraBall = (state: RootState) =>
  selectBingoRoundStatus(state) == 'Running' && selectBingoRoundBalls(state).length >= bingoConfig.mining.balls
export const selectBingoRoundConfig = (state: RootState) => state.bingo.roundConfig
export const selectBingoRoundBalls = (state: RootState) => state.bingo.roundBalls
export const selectBingoRoundBallsCount = (state: RootState) => state.bingo.roundBalls.length
export const selectBingoIsEmptyRoundBalls = (state: RootState) => state.bingo.roundBalls.length == 0
const emptyNumbers: number[] = []
export const selectBingoRoundOrderBalls = createSelector(
  [selectBingoRoundBalls, selectBingoPlayingOrder],
  (balls, order) => {
    if (!order) return emptyNumbers
    const normalBalls = balls.slice(0, bingoConfig.mining.balls)

    const extraBalls = order.bingo_bet_extra_balls.map((ball) => balls[ball.index] || 0)
    return [...normalBalls, ...extraBalls]
  },
)

export const selectBingoTicketDetail = (id: string) => (state: RootState) => _selectBingoTicketDetails(state)[id]

export const selectBingoRoundBallByIndex = (index: number) => (state: RootState) => selectBingoRoundBalls(state)[index]
export const selectBingoBoughtExtraBall = (index: number) =>
  createSelector(selectBingoPlayingOrder, (order) => {
    if (!order) return false
    const { bingo_bet_extra_balls } = order
    const i = bingo_bet_extra_balls?.findIndex((ball) => ball.index == index)
    return i >= 0
  })
export const selectBingoTotalBonus = createSelector(
  [selectBingoPlayingOrder, selectBingoTicketUnitPrice],
  (order, _price) => {
    const price = Number(order?.ticket_unit_price) || _price
    return sum(
      order?.bingo_bet_tickets.map(({ multiplier, star }) => {
        const _m = +multiplier
        return _m ? _m * star * price : 0
      }),
    )
  },
)
export const selectBingoExtraBallAmount = createSelector(
  [
    selectBingoPlayingOrder,
    selectBingoRoundBalls,
    selectBingoTicketUnitPrice,
    selectBingoRoundOrderBalls,
    selectBingoPatterns,
  ],
  (order, balls, price, orderBalls, patterns) => {
    if (!order) return zero

    const { balls: ballCount } = bingoConfig.mining
    const index = balls.length
    if (index < ballCount) return zero

    return getBingoExtraBallAmount(index, order, price, orderBalls, patterns)
  },
)
export const selectBingoIsCollectedTicket = (id?: string) =>
  createSelector([selectBingoTicketsCollected], (collected) => {
    if (!id) return false
    const ticket = collected.findIndex((ticket) => ticket.id == id)
    return ticket >= 0
  })
export const selectBingoTotalOrderAmount = createSelector(
  [selectBingoTicketsGenerated, selectBingoTicketUnitPrice],
  calTotalOrderAmount,
)

function calTotalOrderAmount(tickets: BingoTicketInput[], price: number) {
  return sum(tickets.map((item) => item.star)) * price
}

export const selectBingoPlayingTickets = createSelector([selectBingoPlayingOrder], (order) => {
  const { bingo_bet_tickets } = order || {}
  if (isEmpty(bingo_bet_tickets)) return []

  return bingo_bet_tickets?.map((ticket) => ({
    ...ticket,
    id: genTicketId(ticket.numbers),
  }))
})

export const selectBingoPlaingOrderAccumulatedBetAmount = createSelector([selectBingoPlayingOrder], (order) => {
  if (!order) return 0
  const { amount, bingo_bet_extra_balls } = order
  return +amount + sum(bingo_bet_extra_balls.map(({ amount }) => +amount || 0))
})

export const selectBingoPossibles = createSelector(
  [selectBingoPlayingOrder, selectBingoGameRound, selectBingoRoundOrderBalls, selectBingoPatterns],
  (order, round, balls, patterns) => {
    if (!order?.round_id) return null
    const { round_id, bingo_bet_tickets, ticket_unit_price } = order
    const price = +ticket_unit_price
    if (round.status != 'Running') return null
    if (round_id != round.id) return null
    const tickets = bingo_bet_tickets.map((ticket) => ({
      ticket,
      positions: getBingoExtraBallPosition(ticket.numbers, balls, patterns),
    }))
    const result: Record<number, number[]> = {}
    tickets.forEach(({ positions, ticket }) => {
      if (isEmpty(positions)) return
      const { star } = ticket
      positions.forEach(({ pattern, ball }) => {
        const { rate } = pattern
        const amount = star * rate * price
        result[amount] = result[amount] || []
        if (!result[amount].includes(ball)) {
          result[amount].push(ball)
        }
      })
    })
    const positions = map(result, (balls, amount) => ({ amount: +amount, balls }))
    positions.sort((a, b) => a.amount - b.amount)
    return positions
  },
)

export const selectBingoLastRound = (state: RootState) => state.bingo.lastRound
export const selectBingoOpenPopupLastRound = (state: RootState) => state.bingo.openPopupLastRound

export const bingoActions = {
  ...bingoSlice.actions,
  ...bingoPreferenceSlice.actions,
  createBingoOrder,
  resetNewRound,
  buyExtraBall,
  getBingoPlayingOrder,
}

export default bingoSlice
