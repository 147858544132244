import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CountUp } from 'use-count-up'
import { money } from '../../utils/number.ts'
import { Box, BoxProps } from '@chakra-ui/react'

export function NumberAnim(props: BoxProps & {
  to: number,
  step?: number, // increasing step
  decimal?: number, // count of digits in decimal place
  dataId?: string,
  className?: any,
}) {
  const {
    to,
    step,
    decimal,
    dataId,
    className,
    ...rest
  } = props

  // const toNum = parseFloat(to as any) ?? 0
  const toNum = parseFloat(to as any) ?? 0
  const stepNum = step ?? 1
  const decimalNum = decimal ?? 1
  const prev = useRef<number>(toNum)

  // persist width, avoid unstable width while anim
  const [containerWidth, setContainerWidth] = useState<number | string>('auto')
  const container = useRef<any>()
  const resize = useCallback(() => {
    setContainerWidth('auto')
    return setTimeout(() => setContainerWidth(getElWidth(container.current)), 100)
  }, [])
  useEffect(() => {
    const tid = resize()
    return () => clearTimeout(tid)
  }, [resize])

  return <Box
    as="span"
    ref={container}
    style={{width: containerWidth, minWidth: "fit-content"}}
    className={`overflow-hidden max-h-[1em] leading-none font-semibold text-[18px] ${className}`}
    data-id={dataId}
    data-value={toNum}
    {...rest}
  >
    <CountUp
      isCounting
      decimalPlaces={stepNum}
      start={prev.current}
      end={toNum}
      formatter={v => money(v, decimalNum, decimalNum)}
      duration={1}
      easing="linear"
      // updateInterval={0.1}
      key={toNum} // This will force CountUp to re-render
      onComplete={() => {
        prev.current = toNum
        resize()
      }}
    />
  </Box>
}

function getElWidth(el: any) {
  return el?.getBoundingClientRect().width ?? 100
}
