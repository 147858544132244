import { TextProps } from '@chakra-ui/react'
import React from 'react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type RocketInputTitleProps = TextProps

const RocketInputTitle = ({children, ...rest}: RocketInputTitleProps) => {
  return (
    <RocketText
      color="#9cb4d9"
      fontSize="16px"
      lineHeight="1"
      {...NotoSansFontFamily.medium}
      fontStyle="italic"
      letterSpacing="0.8px"
      {...rest}
    >
      {children}
    </RocketText>
  )
}

export default RocketInputTitle;
