import React from 'react'
import { GameStateEnum } from './hooks/useTShowGamePlay'
import './styles/getReady.scss'
import { Box } from '@chakra-ui/react'
import './styles/launching.scss'
import { selectMultiplier } from '@/redux/store/modules/rocketTshow'
import { useAppSelector } from '@/redux/store'

export const TShowCountdown = ({ countdown }: { countdown: number }) => {
  if (countdown < 0) return null

  const countdownDisplay = `${countdown.toString().padStart(2, '0')}s`

  return (
    <>
      <Box className="absolute top-1/3 w-full" zIndex="1">
        <Box id="get-ready" height="60px" className="relative flex flex-col justify-center">
          <div className="get-ready-bg absolute inset-0"></div>
          <div
            className={`text-[44px] font-bold text-white select-none text-center ${
              countdown === 0 ? 'textSlideOutTop' : 'textSlideInTop'
            }`}
          >
            GET READY
          </div>
          <div
            className={`text-[44px] font-bold text-white select-none text-center ${
              countdown === 0 ? 'textSlideOutBottom' : 'textSlideInBottom'
            }`}
          >
            GET READY
          </div>
        </Box>
      </Box>

      {/* Countdown at bottom */}
      <Box
        className="absolute bottom-[50px] left-1/2 transform -translate-x-1/2 flex items-center justify-center"
        zIndex="3"
        style={{
          width: '197px',
          height: '35px',
          backgroundImage: 'url(/images/tshows/img_bg_countdown.webp)',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="text-[24px] font-bold text-white select-none">{countdownDisplay}</div>
      </Box>
    </>
  )
}

export const MultiplierDisplay = ({ multiplier }: { multiplier: string; gameState: GameStateEnum }) => {
  const roundMultiplier = useAppSelector(selectMultiplier)
  const _multiplier = roundMultiplier.m ?? multiplier
  const multiplierDisplay = _multiplier ? parseFloat(_multiplier).toFixed(2) : ''

  return (
    <div className="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-full">
      <div className="relative flex items-center justify-center w-[min(90vw,320px)] aspect-[440/80] bg-[url('/images/tshows/img_bg_multiplier.webp')] bg-center bg-contain bg-no-repeat">
        <div className="pb-[20px] font-['Bahnschrift'] text-[35px] font-bold tracking-wider text-[#fc67ae] [text-shadow:-1px_-1px_0_#fff,1px_-1px_0_#fff,-1px_1px_0_#fff,1px_1px_0_#fff] select-none ">
          {multiplierDisplay}x
        </div>
      </div>
    </div>
  )
}
