import { Box, Button, Flex, InputProps, Stack } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import React, { HTMLProps, useEffect, useRef, useState } from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation'
import { cashOutOrder, ROUND_STATE, selectGameRound, selectListActiveOrders } from '@/redux/store/modules/rocketTshow'
import useCustomToast from '@/hooks/useCustomToast'
import { CrashOrderCashOutInput } from '@/@generated/gql/graphql-hash'
import useGameId from '@/hooks/useGameId'
import { ImCheckmark } from 'react-icons/im'

type Props = {
  inputProps?: HTMLProps<HTMLInputElement> & InputProps
}

const ButtonCashOutAll = (props: Props) => {
  const { inputProps } = props
  const { t } = useCustomTranslation()
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const listActiveOrders = useAppSelector(selectListActiveOrders)
  const dispatch = useAppDispatch()
  const { showToastThrottle } = useCustomToast()
  const game_id = useGameId()
  const gameRound = useAppSelector(selectGameRound)

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if(gameRound.state === ROUND_STATE.RUNNING) {
      timeout = setTimeout(() => {
        setIsDisable(false)
      }, 1500)
    }
    return (() => {
      clearTimeout(timeout)
    }) 
  }, [gameRound])
  useEffect(() => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isMuted])

  const handleClickBtn = () => {
    const params: CrashOrderCashOutInput = {
      bet_ids: listActiveOrders.map((item) => item.id),
      game_id: game_id,
      round_id: listActiveOrders?.[0].round_id,
    }
    setIsLoading(true)
    dispatch(cashOutOrder(params))
      .then((res) => {
        console.log('{cashoutOrder}: ', res)
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: 'Cash out successful',
            status: 'success',
            isClosable: false,
            duration: 2000,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Box textAlign="center" flex={1}>
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex justifyContent="center" marginBottom="15px" marginTop="27px">
        <Stack
          border={isLoading ? '1px solid #313e06' : '1px solid #25242B'}
          flex={1}
          borderRadius={12}
          position="relative"
        >
          <Button
            aria-label="Bet"
            aria-labelledby="Bet"
            top="-7px"
            borderRadius={12}
            // width="231px"
            height="38px"
            left="-1px"
            overflow="hidden"
            color="black"
            fontSize="1.2rem"
            fontWeight={700}
            position="relative"
            boxShadow="0px 3px 0px  #799c00 !important"
            // transform="scale(1.03)"
            // _hover={{ bg: '#c3fb04' }}
            isDisabled={isDisable}
            _active={{
              transform: isLoading ? '' : 'translateY(5px)',
              // boxShadow: '0px 0px 0px  #80a500',
            }}
            _disabled={{
              opacity: 0.4,
              cursor: 'not-allowed',
            }}
            _after={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              right: 0,
              transform: 'translateX(50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            _before={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              left: 0,
              transform: 'translateX(-50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            // onClick={onBet}
            isLoading={isLoading}
            onClick={handleClickBtn}
            // spinner={}
            sx={{
              background: 'url(/images/btnBet.webp) no-repeat top center !important',
              backgroundSize: 'cover',
            }}
            {...inputProps}
          >
            {/* {disabled ? 'Pending' : `${t('bet')}`} */}
            {/* {t('bet')} */}
            {t('rocket.tabs.cashOutAll')}
          </Button>
        </Stack>
      </Flex>
    </Box>
  )
}

export default ButtonCashOutAll
