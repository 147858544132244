import { GameID } from '@/const/game-type'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import {
  rocketTshowActions,
  ROUND_STATE,
  selectGameRound,
  selectListCurrentLives,
} from '@/redux/store/modules/rocketTshow'
import React, { useEffect } from 'react'

export default function CurrentBetSubcription() {
  const dispatch = useAppDispatch()

  const game_id = useGameId()

  const _message = useSubscription(Topics.getPlayerBettingInRound(game_id), {
    qos: 1,
  })
  const message = _message?.message?.message
  const listPlayers = useAppSelector(selectListCurrentLives)
  const gameRound = useAppSelector(selectGameRound)
  const gameId = useGameId()
  const { t } = useCustomTranslation()

  // const { data } = useQuery(getCurrentLiveQuery, {
  //   variables: {
  //     input: { game_id: gameId },
  //   },
  // })

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      data.forEach((messageItem: any) => {
        const itemToUpdate = listPlayers.find((item) => item.id === messageItem?.id)
        if (!itemToUpdate) {
          dispatch(rocketTshowActions.updateListCurrentLives([messageItem, ...listPlayers]))
        } else {
          dispatch(rocketTshowActions.updateCurrentLives(messageItem))
        }
      })

      const newCashoutOrders = data.filter((item: any) => Number(item?.esc_multiplier))
      if (newCashoutOrders.length) dispatch(rocketTshowActions.addCashoutOrders(newCashoutOrders))
    } catch (error) {
      console.warn('GetPlayerBettingInRoundSubcription error: ', error)
    }
  }, [message])

  useEffect(() => {
    // if (gameRound.state === ROUND_STATE.WAITING) dispatch(rocketTshowActions.updateListCurrentLives([]))
    if (gameRound.state === ROUND_STATE.END) {
      const listUpdated = listPlayers.map((item) => {
        if (item?.esc_multiplier === '0') {
          return {
            ...item,
            esc_multiplier: gameId === GameID.H_ROCKET ? t('rocket.tables.notEscaped') : 'No win', //t('noWin')
            reward: '0.00',
          }
        }
        return item
      })

      dispatch(rocketTshowActions.updateListCurrentLives(listUpdated))
    }

    if (gameRound.state === ROUND_STATE.WAITING) {
      dispatch(rocketTshowActions.updateCashoutOrders([]))
    }
  }, [gameRound.state])

  // useEffect(() => {
  //   if (data && data['crashCurrentRoundOrder']) {
  //     const cashoutOrders = data['crashCurrentRoundOrder'].filter((order) => {
  //       return !!Number(order.esc_multiplier)
  //     })
  //     if (cashoutOrders.length) {
  //       dispatch(rocketTshowActions.updateCashoutOrders(cashoutOrdersRef.current))
  //     }
  //   }
  // }, [data, dispatch])

  return <></>
}
