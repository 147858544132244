import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import PointChip from '@components/rocket/GamePlay/Stats/PointChip.tsx'
import { getCrashResultHistory, selectCrashResultHistory } from '@/redux/store/modules/rocketTshow'
import { useAppDispatch, useAppSelector } from '@store'
import useGameId from '@hooks/useGameId.ts'
import { IoIosClose } from "react-icons/io";
import TrendChart from '@components/rocket/GamePlay/Stats/TrendChart.tsx'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'

type StatsProps = {
  isOpen?: boolean,
  closeStats?: () => void,
}

const explosionAltitudes = [1, 2, 3, 4, 5, 10]

const Stats = ({isOpen, closeStats}: StatsProps) => {
  const { t, i18n } = useCustomTranslation()
  const currentLang = i18n.language
  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const crashResultHistory = useAppSelector(selectCrashResultHistory)

  const crashPoints = crashResultHistory?.result?.filter((_, index) => index < 50)?.map(Number) ?? []
  const last10 = crashResultHistory?.last10 ?? []
  const last20 = crashResultHistory?.last20 ?? []
  const last30 = crashResultHistory?.last30 ?? []
  const last50 = crashResultHistory?.last50 ?? []
  const last100 = crashResultHistory?.last100 ?? []

  const breakingArr = [last10, last20, last30, last50, last100]

  const transformedBreakingArray = breakingArr[0]?.map((_, colIndex) => {
    return {
      explosionAltitude: explosionAltitudes[colIndex],
      numbers: breakingArr?.map(row => row[colIndex])
    }
  });

  const crashResultHistorySplitted = [...crashPoints]?.reverse()?.reduce((resultArray: number[][], item: number, index: number) => {
    const chunkIndex = Math.floor(index/10)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  const handleCloseStats = () => {
    if (!closeStats) return

    closeStats()
  }

  useEffect(() => {
    if (!isOpen) return

    dispatch(
      getCrashResultHistory({
        game_id: gameId,
      }),
    )
  }, [isOpen])

  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      zIndex="11"
      background="rgba(0, 0, 0, 0.8)"
      backdropFilter="blur(5px)"
      paddingTop="10px"
      transform={isOpen ? 'translateX(0)' : 'translateX(-100%)'}
      transition=".2s ease-in-out"
      className="zoom-container"
    >
      <Button
        onClick={handleCloseStats}
        position="absolute"
        top="0"
        right="-14px"
        background="none"
        color="white"
        fontSize="30px"
        _hover={{}}
        _active={{}}
      >
        <IoIosClose />
      </Button>
      <RocketText
        textAlign="center"
        marginBottom="24px"
        mt="24px"
        {...NotoSansFontFamily.semiBold}
        fontSize={16}
      >
        {t('rocket.stats.title')}
      </RocketText>

      {/* CrashResultHistory */}
      <Stack
        position="relative"
        padding="0 7px"
        gap="3px"
        overflowX="hidden"
        marginBottom="18px"
        sx={{
          '&::-webkit-scrollbar': {
            width: '0',
            height: '2px',
          },
        }}
      >
        {crashResultHistorySplitted?.map((row: number[], rowIndex: number) => {
          const rowNumber = crashResultHistorySplitted?.length - rowIndex + '0'

          return (
            <Flex
              alignItems="center"
              key={rowIndex}
            >
              <Box
                minWidth="18px"
                width="18px"
                marginRight="6px"
                textAlign="right"
                letterSpacing="-0.5px"
                fontSize="15px"
              >
                {rowNumber}
              </Box>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flex="1"
                // gap="2px"
              >
                {row?.map((point, pointIndex) => {
                  return (
                    <PointChip
                      padding="3px"
                      fontSize="14px"
                      width="calc(10% - 5px)"
                      minWidth="calc(10% - 5px)"
                      key={point + pointIndex * Math.random()}
                      point={point}
                      isNew={(rowIndex === 4) && (pointIndex === row?.length - 1)}
                    />
                  )
                })}
              </Flex>
            </Flex>
          )
        })}
        <Flex
          justifyContent="space-between"
          textAlign="center"
        >
          <Box
            minWidth="18px"
            width="18px"
            marginRight="6px"
          />
          <Flex
            alignItems="center"
            justifyContent="space-between"
            flex="1"
            gap="5px"
          >
            {new Array(10).fill(1).map((_, index) => {
              const colNumber = ('0' + (10 - index)).slice(-2)

              return (
                <Box
                  minWidth="40px"
                  width="40px"
                  fontSize="15px"
                  key={index}
                >
                  {colNumber}
                </Box>
              )
            })}
          </Flex>
        </Flex>
      </Stack>

      {/* Tabs */}
      <Tabs
        padding="0 10px"
      >
        <TabList
          backgroundColor="#272f3f66"
          display="flex"
          alignItems="stretch"
          borderRadius="20px"
          border="none"
          lineHeight="1"
        >
          <Tab
            borderRadius="20px"
            width="50%"
            padding="3px 10px"
            _selected={{ color: '#b6fdff', bg: 'linear-gradient(180deg, #285b69 0%, #29697b 100%)' }}
            {...NotoSansFontFamily.medium}
          >
            {t('rocket.stats.breakingStatsistics')}
          </Tab>
          <Tab
            borderRadius="20px"
            width="50%"
            padding="3px 10px"
            _selected={{ color: '#b6fdff', bg: 'linear-gradient(180deg, #285b69 0%, #29697b 100%)' }}
            {...NotoSansFontFamily.medium}
          >
            {t('rocket.stats.trend')}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel
            padding="10px 0 0"
          >
            <TableContainer
              padding="0"
            >
              <Table
                borderRadius="13px"
                overflow="hidden"
                sx={{
                  'th': {
                    background: '#252f3e',
                    color: '#508290',
                    fontSize: '12px',
                  },
                  'th, td': {
                    border: '1px solid rgba(0, 0, 0, 0.5)',
                    padding: '5px 10px',
                    textAlign: 'center',
                  },
                  'td': {
                    background: '#272f3f66',
                    color: '#98acd0',
                    fontSize: '14px',
                  }
                }}
              >
                <Thead>
                  <Tr>
                    <Th>
                      {currentLang === 'en' ? (<>Explosion<br />altitude</>) : t('explosionAltitude')}
                    </Th>
                    <Th>{t('rocket.stats.last')} 10</Th>
                    <Th>{t('rocket.stats.last')} 20</Th>
                    <Th>{t('rocket.stats.last')} 30</Th>
                    <Th>{t('rocket.stats.last')} 50</Th>
                    <Th>{t('rocket.stats.last')} 100</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transformedBreakingArray?.map((row, rowIndex) => {
                    return (
                      <Tr key={rowIndex}>
                        <Td>{`>=${row?.explosionAltitude}x`}</Td>
                        {row?.numbers?.map((number, numberIndex) => {
                          return (
                            <Td key={numberIndex}>{number}</Td>
                          )
                        })}
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TrendChart
              crashPoints={crashResultHistory?.result ? crashResultHistory?.result : []}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default React.memo(Stats);
