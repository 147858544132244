import { BingoBetExtraBall, BingoOrder } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Text, Flex, useDisclosure, Tabs, Tab, TabList, TabPanels, TabPanel, Image } from '@chakra-ui/react'
import React from 'react'
import {} from '@/redux/store'
import TabResults from './TabResults'
import TabTickets from './TabTickets'
import TabExtraBalls from './TabExtraBalls'
import { getSymbolFromCurrency } from '@/const/currency'
import { f } from 'fintech-number'

type Props = {
  order: BingoOrder
}

const BetBingoInfo = (props: Props) => {
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { order } = props
  const { bingo_bet_extra_balls } = order
  const total =
    bingo_bet_extra_balls?.length > 0
      ? bingo_bet_extra_balls?.reduce(
          (result: number, currentVal: BingoBetExtraBall) => result + +currentVal?.amount,
          0,
        )
      : 0
  const isWin = Number(order?.reward) > (Number(total) + Number(order?.amount))

  const formatOrderId = (orderId?: string) => {
    if (!orderId) return ''

    const formatOrderIdRegex = /^(.{8}).*(.{8})$/

    return orderId.replace(formatOrderIdRegex, '$1...$2')
    // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
  }

  return (
    <>
      <Box
        background="linear-gradient(180deg, #fff 0%, rgba(255,255,255,0.2) 100%)"
        p="0px 16px 8px"
        borderRadius="8px"
        key={order?.id}
        cursor="pointer"
        onClick={() => {
          onOpen()
        }}
      >
        <Box
          textAlign="center"
          background="linear-gradient(180deg, #ffb7b7 0%, #ffdfdf 100%)"
          fontSize={18}
          px={4}
          fontWeight={600}
          color="#e82013"
          borderRadius="0 0 8px 8px"
          width="fit-content"
          mx="auto"
        >
          {order?.round_id}
        </Box>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap={1}>
            Amount:{' '}
            {f(order?.amount, {
              decimal: 2,
            })}
            <Image src={getSymbolFromCurrency(order?.currency)} className={`w-[14px] h-[14px] rounded-lg`} />
          </Flex>
          <Flex alignItems="center" gap={1}>
            Extra Ball：
            {f(total, {
              decimal: 2,
            })}
            <Image src={getSymbolFromCurrency(order?.currency)} className={`w-[14px] h-[14px] rounded-lg`} />
          </Flex>
        </Flex>
        <Box h="1px" backgroundColor="#fff" mt="4px" mb="4px"></Box>
        <Flex alignItems="center" gap={1}>
          Bonuses:{' '}
          {f(order?.reward, {
            decimal: 2,
          })}
          <Image src={getSymbolFromCurrency(order?.currency)} className={`w-[14px] h-[14px] rounded-lg`} />
          {isWin && (
            <Image
              src="/bingo/images/endgame/medal-win.webp"
              width="35px"
              marginLeft="5px"
            />
          )}
        </Flex>
      </Box>

      {isOpen && (
        <AppDrawer title={`${formatOrderId(order?.round_id)} Detail`} isOpen={isOpen} onClose={onClose} overlay={true}>
          <BetDetailBingoInfo order={order} />
        </AppDrawer>
      )}
    </>
  )
}

export default BetBingoInfo

export const BetDetailBingoInfo = React.memo(({ order }: { order: BingoOrder }) => {
  const { t } = useCustomTranslation()
  return (
    <>
      <Box
        backgroundColor="#fde8e8"
        px={4}
        pt={2}
        pb={4}
        className="newgame-container drawerBingoContainer"
        minHeight="57vh"
        maxHeight="57vh"
      >
        <Tabs>
          <TabList px={12} justifyContent="space-between">
            <Tab
              color="#560f4099"
              fontWeight="500"
              _selected={{
                color: '#e82013',
              }}
            >
              Results
            </Tab>
            <Tab
              color="#560f4099"
              fontWeight="500"
              _selected={{
                color: '#e82013',
              }}
            >
              Tickets
            </Tab>
            <Tab
              color="#560f4099"
              fontWeight="500"
              _selected={{
                color: '#e82013',
              }}
            >
              Extra Balls
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TabResults order={order} />
            </TabPanel>
            <TabPanel>
              <TabTickets order={order} />
            </TabPanel>
            <TabPanel>
              <TabExtraBalls order={order} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  )
})
