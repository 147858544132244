import React, { useEffect, useRef, useState } from 'react'
import { Box, Tab, TabList, TabPanel, TabPanelProps, TabPanels, TabProps, Tabs } from '@chakra-ui/react'
import RocketTabFooter from '@components/rocket/NewUI/RocketTabs/RocketTabFooter.tsx'
import ClassicTab from '@components/rocket/NewUI/RocketTabs/ClassicTab.tsx'
import TrenballTabs from '@components/rocket/NewUI/RocketTabs/TrenballTab.tsx'
import BetAmountForm from '@components/rocket/NewUI/RocketTabs/BetAmountForm.tsx'
import { useAppDispatch, useAppSelector } from '@store'
import useCustomTranslation from '@hooks/useCustomTranslation.tsx'
import { selectWallet } from '@store/modules/wallet.slice.ts'
import { selectAgencyId, selectPlayerName } from '@store/modules/auth.slice.ts'
import {
  createNewOrder,
  rocketTshowActions,
  ROUND_STATE,
  selectAmountRocketTShow,
  selectConfigRound,
  selectGameRound,
  selectListActiveOrders,
  selectTemporaryOrders,
} from '@store/modules/rocketTshow.ts'
import useGameId from '@hooks/useGameId.ts'
import useCustomToast from '@hooks/useCustomToast.ts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { f } from 'fintech-number'
import eventBus from '@/utils/eventBus'
import { EVENT_MESSAGE_ERROR } from '@components/common/ErrorHandlerWrapper.tsx'
import { CreateCrashOrderInput, InputMaybe, TrenBallType } from '@/@generated/gql/graphql-hash.ts'
import { ImCheckmark } from 'react-icons/im'
import RoundConfig from '@components/rocket/RoundConfig.tsx'
import ls from '@/libs/local-storage'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type RocketFormData = {
  autoEscape: boolean
  escape: string | null
  amount: string | null
  isRepeat: boolean
  tren_ball_type: InputMaybe<TrenBallType> | undefined
}

type RocketTabProps = TabProps
type RocketTabPanelProps = TabPanelProps

const maxAutoEscapeValue = 9999.99

const formatInputValue = (value: string | number) => {
  // Format number with commas
  if (value.toString().includes('.')) {
    const [intPart, decimalPart] = value.toString().split('.')
    return f(Number(intPart)) + '.' + decimalPart.slice(0, 2)
  } else {
    return f(Number(value), { decimal: 2 }) // Format with commas
  }
}

const RocketTab = ({ children, ...rest }: RocketTabProps) => {
  return (
    <Tab
      width="50%"
      color="#76869d"
      position="relative"
      transition="none"
      fontSize="20px"
      {...NotoSansFontFamily.normal}
      lineHeight="1"
      padding="12px 16px"
      _after={{
        content: '""',
        position: 'absolute',
        top: '0',
        bottom: '0',
        width: '40px',
        background: 'transparent',
        zIndex: '-1',
      }}
      _selected={{
        color: '#ffffff',
        zIndex: '1',
        ...{...NotoSansFontFamily.semiBold},
        '&::after': {
          background: '#26303e',
        },
        '.tab-btn-bg': {
          background: '#26303e',
        },
      }}
      _first={{
        borderTopLeftRadius: '13.3px',
        '&::after': {
          right: '2px',
          transform: 'skewX(17deg)',
          borderTopRightRadius: '10px',
        },
        '.tab-btn-bg': {
          left: '0',
          right: '20px',
          borderTopLeftRadius: '13.3px',
        },
      }}
      _last={{
        borderTopRightRadius: '13.3px',
        '&::after': {
          left: '2px',
          transform: 'skewX(-17deg)',
          borderTopLeftRadius: '10px',
        },
        '.tab-btn-bg': {
          right: '0',
          left: '20px',
          borderTopRightRadius: '13.3px',
        },
      }}
      {...rest}
    >
      <Box className="tab-btn-bg" position="absolute" top="0" bottom="0" zIndex="-1" />
      {children}
    </Tab>
  )
}

const RocketTabPanel = ({ children, tabIndex, ...rest }: RocketTabPanelProps) => {
  return (
    <TabPanel
      borderTopRightRadius={tabIndex === 0 ? '13.3px' : '0'}
      borderTopLeftRadius={tabIndex === 1 ? '13.3px' : '0'}
      padding="10px 16px 11.3px"
      {...rest}
    >
      {children}
    </TabPanel>
  )
}

const RocketTabs = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useAppDispatch()
  const { t } = useCustomTranslation()
  const wallet = useAppSelector(selectWallet)
  const agencyId = useAppSelector(selectAgencyId)
  const playerName = useAppSelector(selectPlayerName)
  const gameRound = useAppSelector(selectGameRound)
  const temporaryOrders = useAppSelector(selectTemporaryOrders)
  const configRound = useAppSelector(selectConfigRound)
  const listActiveOrders = useAppSelector(selectListActiveOrders)
  const amount = useAppSelector(selectAmountRocketTShow)
  const [loading, setLoading] = useState<boolean>(false)
  const gameId = useGameId()
  const { showToastThrottle } = useCustomToast()
  const amountFsLS = ls.get('H_ROCK_AMOUNT')
  const autoEscapeAppendCharTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const { setValue, getValues, register, watch } = useForm<RocketFormData>({
    defaultValues: {
      autoEscape: false,
      escape: '1.5',
      amount: amountFsLS ? amountFsLS : amount ?? configRound?.minBetAmount,
      isRepeat: false,
      tren_ball_type: TrenBallType.Yellow,
    },
  })

  const name = watch('amount')
  const tren_ball_type = watch('tren_ball_type')
  const isAutoEscape = watch('autoEscape')

  useEffect(() => {
    if (name) {
      dispatch(rocketTshowActions.updateAmount(name))
      ls.set('H_ROCK_AMOUNT', name)
    }
  }, [name])

  useEffect(() => {
    const amountFsLS = ls.get('H_ROCK_AMOUNT')
    if (amountFsLS) setValue('amount', amountFsLS.toString())
  }, [])

  const handleBetAmountChange = (e: any) => {
    const inputMaxAmount = +configRound?.maxBetAmount
    let value = e.target.value

    if (value === '') {
      setValue('amount', '')
      return
    }

    value = e.target.value.replace(/[^0-9.]/g, '') // Remove anything that isn't a number or period
    let formatedValue = formatInputValue(value)
    let valueNumbered = Number(formatedValue.replace(/,/g, ''))

    if (valueNumbered > inputMaxAmount) {
      formatedValue = formatInputValue(inputMaxAmount)
      valueNumbered = inputMaxAmount
    }
    setValue('amount', valueNumbered.toString())
    e.target.value = formatedValue
  }

  const handleAutoEscapeChange = (e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value
    const valueNumeric = inputValue.replace(/[^0-9.]/g, '') // Remove anything that isn't a number or period
    const isOverflow = Number(valueNumeric) >= maxAutoEscapeValue
    const appendedChar = 'x'
    clearTimeout(autoEscapeAppendCharTimerRef.current)

    if (inputValue === '' || inputValue.startsWith('.') || valueNumeric === '') {
      setValue('escape', '0')
      e.currentTarget.value = ''
      return
    }

    if (isOverflow) {
      // setValue('escape', maxAutoEscapeValue.toString())
      setValue(
        'escape',
        f(maxAutoEscapeValue, {
          decimal: 2,
          round: "down"
        }),
      )
    } else {
      setValue(
        'escape',
        f(+valueNumeric, {
          decimal: 2,
          round: "down"
        }),
      )
    }

    const autoEscapeInput: HTMLInputElement | null = document.querySelector('input#auto-escape-input')
    if (autoEscapeInput && !isNaN(Number(valueNumeric.slice(-1)))) {
      autoEscapeAppendCharTimerRef.current = setTimeout(() => {
        if (isOverflow) {
          autoEscapeInput.value = f(maxAutoEscapeValue, {
            decimal: 2,
            round: "down"
          }) + appendedChar
        } else {
          autoEscapeInput.value = f(+valueNumeric, {
            decimal: 2,
            round: "down"
          }) + appendedChar
        }
      }, 500)
    }
  }

  const handlePlus = () => {
    const currentValue = getValues('escape') ? getValues('escape')?.replace(/,/g, '') : 0
    const newValue = Number(currentValue) < 1 ? 1 : Number(currentValue) + 0.1
    const isOverflow = newValue >= maxAutoEscapeValue

    if (isOverflow) {
      setValue('escape', f(maxAutoEscapeValue))
    } else {
      setValue('escape', f(newValue))
    }

    const autoEscapeInput: HTMLInputElement | null = document.querySelector('input#auto-escape-input')
    if (autoEscapeInput) {
      autoEscapeInput.value = f(newValue)
      const appendedChar = 'x'
      clearTimeout(autoEscapeAppendCharTimerRef.current)
      autoEscapeAppendCharTimerRef.current = setTimeout(() => {
        if (isOverflow) {
          autoEscapeInput.value = f(maxAutoEscapeValue) + appendedChar
        } else {
          autoEscapeInput.value = f(newValue) + appendedChar
        }
      }, 500)
    }
  }

  const handleMinus = () => {
    const currentValue = getValues('escape') ? getValues('escape')?.replace(/,/g, '') : 0
    const newValue = Number(currentValue) <= 1 ? 1 : Number(currentValue) - 0.1

    setValue('escape', f(newValue))

    const autoEscapeInput: HTMLInputElement | null = document.querySelector('input#auto-escape-input')
    if (autoEscapeInput) {
      autoEscapeInput.value = f(newValue)
      const appendedChar = 'x'
      clearTimeout(autoEscapeAppendCharTimerRef.current)
      autoEscapeAppendCharTimerRef.current = setTimeout(() => {
        autoEscapeInput.value = f(newValue) + appendedChar
      }, 500)
    }
  }

  const handleMultiply = () => {
    const minBetAmount = +configRound?.minBetAmount
    const maxBetAmount = +configRound?.maxBetAmount
    const currentValue = getValues('amount') ? getValues('amount')?.replace(/,/g, '') : 0
    let newValue = currentValue ? Number(currentValue) * 2 : minBetAmount

    if (newValue >= maxBetAmount) {
      newValue = maxBetAmount
    }

    setValue('amount', f(newValue))

    const amountInput: HTMLInputElement | null = document.querySelector('input#amount-input')
    if (amountInput) {
      amountInput.value = f(newValue).toString()
    }
  }

  const handleDivide = () => {
    const minBetAmount = +configRound?.minBetAmount
    const currentValue = getValues('amount') ? getValues('amount')?.replace(/,/g, '') : 0
    let newValue = currentValue ? Number(currentValue) / 2 : minBetAmount

    if (newValue <= minBetAmount) {
      newValue = minBetAmount
    }

    setValue(
      'amount',
      f(newValue, {
        decimal: 2,
        round: 'auto',
      }),
    )

    const amountInput: HTMLInputElement | null = document.querySelector('input#amount-input')
    if (amountInput) {
      amountInput.value = f(newValue, {
        decimal: 2,
        round: 'auto',
      }).toString()
    }
  }

  const handleQuickBetAmount = (amount: number) => {
    if (!amount) return

    setValue('amount', f(amount, { decimal: 2 }))

    const amountInput: HTMLInputElement | null = document.querySelector('input#amount-input')
    if (amountInput) {
      amountInput.value = f(amount, { decimal: 2 }).toString()
    }
  }

  const handleErrorOnSubmit = (data: any) => {
    const amount = data.amount?.replace(/,/g, '')
    const enscape = data.escape?.replace(/,/g, '')

    if (!amount || +amount < +configRound?.minBetAmount || +amount > +configRound?.maxBetAmount) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'OUT_OF_BEING_LIMIT',
          code: 'OUT_OF_BEING_LIMIT',
        },
      })
      return false
    }

    if (+amount > +wallet.amount) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'INSUFFICIENT_AMOUNT',
          code: 'INSUFFICIENT_AMOUNT',
        },
      })
      return false
    }

    if (enscape !== '0' && +enscape <= 1 && tabIndex === 0) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'ErrAutoCashOutInvalid',
          code: 'ErrAutoCashOutInvalid',
        },
      })
      return false
    }
    return true
  }

  const onSubmit: SubmitHandler<RocketFormData> = (data) => {
    if (!handleErrorOnSubmit(data)) return

    if (gameRound?.state !== ROUND_STATE.WAITING) {
      eventBus.dispatch(EVENT_MESSAGE_ERROR, {
        data: {
          message: 'ORDER__GAME_ENDED',
          code: 'ORDER__GAME_ENDED',
        },
      })
      return
    }

    const param: CreateCrashOrderInput = {
      amount: data.amount?.replace(/,/g, ''),
      agency_id: agencyId!,
      auto_cash_out: tabIndex === 0 ? (data?.autoEscape ? data?.escape?.replace(/,/g, '') : '0') : '0',
      currency: wallet.currency,
      wallet_id: wallet.id,
      round_id: gameRound?.round_id,
      game_id: gameId,
      player_name: playerName!,
      tren_ball_type: tabIndex === 0 ? TrenBallType.None : data.tren_ball_type,
    }

    if (tabIndex === 0) {
      dispatch(rocketTshowActions.updateTemporaryOrders([...temporaryOrders, param]))
    }

    handleCreateNewOrder(param)
  }

  const handleCreateNewOrder = (param: CreateCrashOrderInput) => {
    setLoading(true)
    dispatch(createNewOrder(param))
      .then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: t('notification.successfully'),
            status: 'success',
            isClosable: false,
            duration: 1500,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
        }
      })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (gameRound?.state === ROUND_STATE.END) {
      dispatch(
        rocketTshowActions.updateTemporaryOrders(
          temporaryOrders.filter((item) => item.round_id === gameRound.round_id),
        ),
      )
    }
    if (gameRound?.state === ROUND_STATE.WAITING && getValues('isRepeat')) {
      for (let i = 0; i < temporaryOrders?.length; i++) {
        const timeout = setTimeout(function () {
          const order = temporaryOrders?.[i]
          const param: CreateCrashOrderInput = {
            amount: order.amount?.replace(/,/g, ''),
            agency_id: agencyId!,
            auto_cash_out: order?.auto_cash_out ? order?.auto_cash_out : '0',
            currency: wallet.currency,
            wallet_id: wallet.id,
            round_id: gameRound?.round_id,
            game_id: gameId,
            player_name: playerName!,
          }

          dispatch(createNewOrder(param)).then((res) => {
            if (i === temporaryOrders.length - 1 && res?.meta?.requestStatus === 'fulfilled') {
              showToastThrottle({
                title: t('notification.successfully'),
                status: 'success',
                isClosable: false,
                duration: 1500,
                icon: <ImCheckmark fontSize={32} />,
                containerStyle: {
                  width: '60px',
                  paddingBlock: '0px',
                  pointerEvents: 'none',
                },
              })
            }
          })
        }, i * 150)
      }
      const list = temporaryOrders.map((item) => {
        return {
          ...item,
          round_id: gameRound.round_id,
        }
      })
      dispatch(rocketTshowActions.updateTemporaryOrders(list))
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [gameRound?.state])

  const handleOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = e.key
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
    if ((e.ctrlKey || e.metaKey) && char === 'r') {
      console.log('Ctrl+R or Cmd+R pressed!')
      return true
    }

    if (controlKeys.includes(char)) {
      return true
    }
    const input = e.currentTarget.value
    const [integerPart, decimalPart] = input.split('.')

    if (decimalPart && decimalPart.length >= 2) {
      e.preventDefault()
      return false
    }

    if (char >= '0' && char <= '9') {
      return true
    }

    if (char === '.') {
      return e.currentTarget.value.indexOf('.') === -1
    }

    e.preventDefault()
    return false
  }

  return (
    <Tabs
      variant="unstyled"
      marginTop="-90px"
      borderRadius="13.3px"
      background="rgba(34, 42, 54, 0.7)"
      onChange={(index) => {
        setTabIndex(index)
        dispatch(rocketTshowActions.updateTabIndex(index))
      }}
    >
      <TabList>
        <RocketTab>
          <RocketText>{t('rocket.tabs.classic')}</RocketText>
        </RocketTab>
        <RocketTab>
          <RocketText>{t('rocket.tabs.trenball')}</RocketText>
        </RocketTab>
      </TabList>
      <Box background="#26303e">
        <TabPanels
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            top: '-0.5px',
            left: '0',
            right: '0',
            height: '1px',
            background: '#26303e',
          }}
        >
          <RocketTabPanel tabIndex={tabIndex}>
            <ClassicTab
              inputProps={{
                ...register('escape'),
                type: 'text',
                autoComplete: 'off',
                id: 'auto-escape-input',
                onChange: handleAutoEscapeChange,
                isDisabled: !isAutoEscape,
                defaultValue: '1.5x',
              }}
              isFormDisabled={!isAutoEscape}
              repeatCheckboxProps={{ ...register('isRepeat') }}
              autoEscapeCheckboxProps={{ ...register('autoEscape') }}
              minusCallback={() => handleMinus()}
              plusCallback={() => handlePlus()}
            />
          </RocketTabPanel>
          <RocketTabPanel padding="17px 20px 18.3px 15px" tabIndex={tabIndex}>
            <TrenballTabs setValue={setValue} tren_ball_type={tren_ball_type} />
          </RocketTabPanel>
        </TabPanels>
        <BetAmountForm
          canCashOutAll={listActiveOrders?.length > 0 && gameRound.state === ROUND_STATE.RUNNING && tabIndex === 0}
          afterCashOutAll={
            (listActiveOrders?.length === 0 && gameRound.state === ROUND_STATE.RUNNING && tabIndex === 0) ||
            (gameRound.state === ROUND_STATE.RUNNING && tabIndex === 1)
          }
          inputProps={{
            ...register('amount'),
            type: 'text',
            id: 'amount-input',
            onChange: handleBetAmountChange,
            onKeyDown: handleOnInput,
            defaultValue: name ?? configRound?.minBetAmount,
          }}
          // isFormDisabled={tabIndex === 1}
          isBetButtonDisabled={gameRound?.state !== ROUND_STATE.WAITING}
          multiplyCallback={() => handleMultiply()}
          divideCallback={() => handleDivide()}
          quickBetAmountCallback={handleQuickBetAmount}
          currentBetAmount={Number(getValues('amount'))}
          buttonBetProps={{
            isLoading: loading,
            onClickFn: () => {
              const formData: RocketFormData = {
                autoEscape: getValues('autoEscape'),
                escape: getValues('escape'),
                amount: getValues('amount'),
                isRepeat: getValues('isRepeat'),
                tren_ball_type: getValues('tren_ball_type'),
              }
              onSubmit(formData)
            },
          }}
        />
        <RoundConfig />
      </Box>
      <RocketTabFooter />
    </Tabs>
  )
}

export default RocketTabs
