import { BingoOrder } from '@/@generated/gql/graphql-hash'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { getMyOrdersBingoQuery } from '@/redux/store/services/queries'
import { useQuery } from '@apollo/client'
import { Box, Flex, Image, Spinner, Stack } from '@chakra-ui/react'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { useState } from 'react'
import BetBingoInfo from './BetInfo'
import './index.scss'

const MyBetsBingo = () => {
  const game_id = useGameId()
  const [page, setPage] = useState(1)
  const { t } = useCustomTranslation()
  const limit = 10
  const { data, loading } = useQuery(getMyOrdersBingoQuery, {
    variables: {
      input: { game_id: game_id, page: page, limit: limit },
    },
  })

  const myOrders = data?.bingoOrderHistory?.data ?? []
  const total = data?.bingoOrderHistory?.total ?? 0

  const changePage = (page: number) => {
    setPage(page)
  }

  return (
    <Box
      sx={{
        opacity: loading ? '0.3' : '1',
      }}
      minHeight="57vh"
      maxHeight="57vh"
      className="drawerBingoContainer"
      p={4}
    >
      <Flex flexDirection="column" gap={4}>
        {myOrders?.length > 0 ? (
          myOrders?.map((item: BingoOrder) => <BetBingoInfo order={item} key={item?.id} />)
        ) : loading ? (
          <Spinner />
        ) : (
          <Flex
            w="100%"
            height="400px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#3d3d43"
            gap={'11px'}
          >
            <Box>
              <Image w={'60px'} src="/images/norecord.webp" />
            </Box>
            <Box color={'#c1c1de'} fontSize={'16px'}>
              {t('notification.youHavenorecordsYet')}
            </Box>
          </Flex>
        )}
      </Flex>
      <Stack alignItems="center" pb={6}>
        {myOrders?.length > 0 && (
          <Flex direction="row" align="center" justify="center" mt={4}>
            <Pagination className="paging" onChange={changePage} current={page} total={total} pageSize={limit} />
          </Flex>
        )}
      </Stack>
    </Box>
  )
}

export default MyBetsBingo
