import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

type RocketTextProps = TextProps & {
  negativeY?: string,
}

const RocketText = ({negativeY = '10', children, ...rest}: RocketTextProps) => {
  return (
    <Text
      lineHeight="1"
      transform={`translateY(-${negativeY}%)`}
      {...rest}
    >
      {children}
    </Text>
  )
}

export default RocketText