import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import RankingSpot from '@/bingo/components/Common/EndGameAnimation/RankingSpot.tsx'
import {
  bingoActions,
  selectBingoLastRound,
  selectBingoPlaingOrderAccumulatedBetAmount,
  selectBingoPlayingOrder,
  selectBingoPlayingOrderRoundId,
  selectBingoTotalBonus,
} from '@/bingo/redux/bingo.slice'
import { formatNumber } from '@/components/banker-player/Betting'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Box, Button, ButtonProps, Flex, Image, Stack, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import React from 'react'
import { getColorBall } from '@/bingo/components/MyBets/TabResults.tsx'
import { IoMdClose } from "react-icons/io";

const ButtonClose = ({ ...rest }: ButtonProps) => {
  return (
    <Button
      background="linear-gradient(180deg, #fe563e 0%, #eb4933 100%)"
      borderRadius="50px"
      width="100%"
      color="#ffffff"
      fontSize="25px"
      marginTop="30px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      Close
    </Button>
  )
}

const Win = () => {
  const playingOrder = useAppSelector(selectBingoPlayingOrder)
  const totalBonus = useAppSelector(selectBingoTotalBonus)
  const accumulatedAmount = useAppSelector(selectBingoPlaingOrderAccumulatedBetAmount)

  return (
    <Box
      background="#fde8e8"
      padding="70px 30px 30px"
      position="relative"
      marginLeft="-20px"
      marginRight="-20px"
      _before={{
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-24px',
        left: '0',
        width: '100%',
        aspectRatio: '750/36',
        background: 'url("public/bingo/images/endgame/border-win.webp") no-repeat top center',
        backgroundSize: 'cover',
      }}
    >
      <Image
        src="/bingo/images/endgame/medal-win.webp"
        width="140px"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        top="-68px"
      />
      <Flex
        borderRadius="20px"
        background="#ffffff"
        alignItems="center"
        justifyContent="space-between"
        padding="20px 30px"
        fontWeight="500"
        marginBottom="30px"
      >
        <Text color="#000000">{t('bingo.ticketBonus')}</Text>
        <Flex alignItems="center" gap="5px">
          <Text color="#000000">{formatNumber(totalBonus)}</Text>
          <BingoCurrencyIcon currency={playingOrder?.currency} />
        </Flex>
      </Flex>
      <Flex
        borderRadius="20px"
        background="#ffffff"
        alignItems="center"
        justifyContent="space-between"
        padding="20px 30px"
        fontWeight="500"
      >
        <Text color="#000000">{t('bingo.accumulatedBets')}</Text>
        <Flex alignItems="center" gap="5px">
          <Text color="#000000">{formatNumber(accumulatedAmount)}</Text>
          <BingoCurrencyIcon currency={playingOrder?.currency} />
        </Flex>
      </Flex>
      <ButtonClose />
    </Box>
  )
}

const Lose = () => {
  return (
    <Box padding="0 20px 100px">
      <Image src="/bingo/images/endgame/keep-trying.webp" width="100%" />
      <ButtonClose />
    </Box>
  )
}

type ResultsProps = {
  balls?: number[],
}

const Results = ({balls}: ResultsProps) => {
  const dispatch = useAppDispatch()

  if (!balls || balls?.length === 0) return <></>

  const handleClose = () => {
    dispatch(bingoActions.showPopupLastRound(false))
  }

  const ordinaryBalls = balls?.slice(0, 30)
  const extraBalls = balls?.slice(30, balls?.length)

  return (
    <Box
      borderTopRadius="20px"
      background="#fde8e8"
      padding="30px"
      margin="0 -20px"
      position="relative"
    >
      <Button
        background="none"
        border="none"
        outline="none"
        position="absolute"
        top="5px"
        right="0"
        _hover={{}}
        _active={{}}
        onClick={handleClose}
      >
        <IoMdClose size="23px" />
      </Button>
      <Text
        as="h2"
        color="#000"
        textAlign="center"
        fontSize="20px"
        fontWeight="600"
        lineHeight="1"
        marginBottom="20px"
      >
        Results
      </Text>
      <Box
        borderRadius="20px"
        background="#ffffff"
        padding="15px"
        marginBottom="30px"
      >
        <Text
          textAlign="center"
          color="#000"
          fontWeight="600"
          marginBottom="15px"
        >
          Ordinary balls
        </Text>
        <Flex
          flexWrap="wrap"
          gap="9.4px"
        >
          {ordinaryBalls?.map((number) => (
            <Flex
              key={number}
              border={`1px solid ${getColorBall(number)}`}
              borderRadius="50%"
              p={3}
              w="34px"
              h="34px"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontWeight={700} color={getColorBall(number)}>
                {number}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
      <Box
        borderRadius="20px"
        background="#ffffff"
        padding="15px"
      >
        <Text
          textAlign="center"
          color="#000"
          fontWeight="600"
          marginBottom="15px"
        >
          Extra balls
        </Text>
        <Flex
          flexWrap="wrap"
          gap="9.4px"
        >
          {extraBalls?.map((number) => (
            <Flex
              key={number}
              border={`1px solid ${getColorBall(number)}`}
              borderRadius="50%"
              p={3}
              w="34px"
              h="34px"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontWeight={700} color={getColorBall(number)}>
                {number}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

const EndGameAnimation = () => {
  const totalBonus = useAppSelector(selectBingoTotalBonus)
  const totalAmount = useAppSelector(selectBingoPlaingOrderAccumulatedBetAmount)
  const isWin = totalBonus >= totalAmount
  const roundId = useAppSelector(selectBingoPlayingOrderRoundId) || ''

  const lastRoundData = useAppSelector(selectBingoLastRound)
  const top1 = lastRoundData?.topWinners ? lastRoundData?.topWinners[0] : undefined
  const top2 = lastRoundData?.topWinners ? lastRoundData?.topWinners[1] : undefined
  const top3 = lastRoundData?.topWinners ? lastRoundData?.topWinners[2] : undefined

  return (
    <Stack
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      background="rgba(0, 0, 0, 0.5)"
      zIndex="2"
      justifyContent="space-between"
      padding="0 20px"
      gap="50px"
    >
      <Flex alignItems="flex-start" justifyContent="space-between" padding="70px 0 0" position="relative">
        <RankingSpot
          rank={1}
          playerName={top1?.playerName}
          bonus={top1?.winAmount}
          currency={top1?.currency}
        />
        <RankingSpot
          rank={2}
          playerName={top2?.playerName}
          bonus={top2?.winAmount}
          currency={top2?.currency}
        />
        <RankingSpot
          rank={3}
          playerName={top3?.playerName}
          bonus={top3?.winAmount}
          currency={top3?.currency}
        />
      </Flex>
      {totalAmount > 0 && (
        <Box
          background="linear-gradient(to right, transparent, rgba(0, 0, 0, 0.6), transparent)"
          textAlign="center"
          color="#ffe299"
          padding="10px 0"
          marginTop={isWin ? '-60px' : '0'}
        >
          <Text
            fontSize="20px"
            position="relative"
            _before={{
              content: '""',
              display: 'block',
              width: '30px',
              aspectRatio: '54/54',
              background: 'url("/bingo/images/endgame/star.webp") no-repeat center',
              backgroundSize: 'cover',
              position: 'absolute',
              left: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            _after={{
              content: '""',
              display: 'block',
              width: '30px',
              aspectRatio: '54/54',
              background: 'url("/bingo/images/endgame/star.webp") no-repeat center',
              backgroundSize: 'cover',
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            {t('bingo.congratsWinnerDesc', { roundId })}
          </Text>
          <Text fontSize="40px">{formatNumber(totalBonus)}</Text>
        </Box>
      )}
      {totalAmount > 0 && isWin && <Win />}
      {totalAmount > 0 && !isWin && <Lose />}
      {totalAmount <= 0 && (
        <Results balls={lastRoundData?.balls} />
      )}
    </Stack>
  )
}

export default EndGameAnimation
