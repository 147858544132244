import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react'

type RocketFormContainerProps = BoxProps

const RocketFormContainer = ({children}: RocketFormContainerProps) => {
  return (
    <Box
      padding="10px 14px 0"
      position="relative"
      zIndex="11"
      className="zoom-container"
      _before={{
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        height: '50px',
        background: 'linear-gradient(to top, #11151b, transparent)',
        display: 'block',
        transform: 'translateY(-100%)',
      }}
    >
      {children}
    </Box>
  )
}

export default RocketFormContainer;
