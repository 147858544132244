import { bingoConfig } from '@/bingo/const/bingoConfigs'
import {
  bingoActions,
  selectBingoGameRoundId,
  selectBingoPlayingOrderRoundId,
  selectBingoRoundStatus,
} from '@/bingo/redux/bingo.slice'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import React, { useEffect, useRef, useState } from 'react'
import BingoNewGamePopup from '../Common/BingoNewGamePopup'

const { newRoundAnimationDuration, winningAnimationDuration, delayBeforeCongrats } = bingoConfig.round
function getAnimationDuration(orderRoundId: string, roundId: string) {
  // if (!orderRoundId) return 0
  // if (orderRoundId == roundId) return 0
  return winningAnimationDuration + delayBeforeCongrats
}

export default function NewRoundSubscription() {
  const [isDisplay, setDisplay] = useState(false)
  const playingOrderRoundId = useAppSelector(selectBingoPlayingOrderRoundId)
  const roundId = useAppSelector(selectBingoGameRoundId)
  const animationDuration = getAnimationDuration(playingOrderRoundId || '', roundId)

  const status = useAppSelector(selectBingoRoundStatus)
  const statusRef = useRef(status)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (status == statusRef.current) return

    if (statusRef.current && status == 'Waiting') {
      const timeout = setTimeout(() => {
        statusRef.current = status
        setDisplay(true)
      }, animationDuration + 300)
      return () => clearTimeout(timeout)
    } else {
      statusRef.current = status
    }
  }, [status, statusRef.current, animationDuration])

  useEffect(() => {
    if (!isDisplay) return
    const timeout = setTimeout(() => {
      dispatch(bingoActions.resetNewRound())
      setDisplay(false)
    }, newRoundAnimationDuration)

    return () => clearTimeout(timeout)
  }, [isDisplay])

  return <>{isDisplay && <BingoNewGamePopup />}</>
}
