import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type RocketChipProps = FlexProps & {
  betAmount: number,
  isSelected?: boolean,
  isDisabled?: boolean,
}

const backgroundByBetAmount: Record<number, Record<string, string>> = {
  5: {
    default: 'url("/images/rockets/chip-green.webp")',
    selected: 'url("/images/rockets/chip-green-selected.webp")',
  },
  10: {
    default: 'url("/images/rockets/chip-blue.webp")',
    selected: 'url("/images/rockets/chip-blue-selected.webp")',
  },
  20: {
    default: 'url("/images/rockets/chip-orange.webp")',
    selected: 'url("/images/rockets/chip-orange-selected.webp")',
  },
  50: {
    default: 'url("/images/rockets/chip-purple.webp")',
    selected: 'url("/images/rockets/chip-purple-selected.webp")',
  },
  100: {
    default: 'url("/images/rockets/chip-red.webp")',
    selected: 'url("/images/rockets/chip-red-selected.webp")',
  },
  200: {
    default: 'url("/images/rockets/chip-orange.webp")',
    selected: 'url("/images/rockets/chip-orange-selected.webp")',
  },
  300: {
    default: 'url("/images/rockets/chip-blue.webp")',
    selected: 'url("/images/rockets/chip-blue-selected.webp")',
  },
}

// const chipTextShadows = '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff'

const RocketChip = ({betAmount, isSelected, isDisabled, ...rest}: RocketChipProps) => {
  return (
    <Box
      position="relative"
      width="55px"
      minWidth="55px"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        lineHeight="1"
        width="100%"
        aspectRatio="158/161"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        userSelect="none"
        color="#000000"
        {...NotoSansFontFamily.bold}
        fontSize="23px"
        position="relative"
        opacity={isDisabled ? 0.65 : isSelected ? 1 : 0.65}
        sx={{
          // '-webkit-text-stroke': '0.7px #ffffff',
          textShadow: '-1px -1px 0.5px white, 1px -1px 0.5px white, -1px  1px 0.5px white, 1px  1px 0.5px white, -1px  0px 0.5px white, 1px  0px 0.5px white, 0px -1px 0.5px white, 0px  1px 0.5px white, -1px -1px 0.5px white, -1px  1px 0.5px white, 1px -1px 0.5px white, 1px  1px 0.5px white, -1px -1px 0.5px white, 1px -1px 0.5px white, -1px  1px 0.5px white, 1px  1px 0.5px white',
          '> .chip-bg': {
            position: 'absolute',
            transform: 'scale(1.28455285)',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            aspectRatio: '158/161',
            '&.default': {
              background: backgroundByBetAmount[betAmount].default,
              backgroundSize: 'contain',
            },
            '&.selected': {
              background: backgroundByBetAmount[betAmount].selected,
              backgroundSize: 'contain',
            },

          }
        }}
        _hover={{
          opacity: !isDisabled ? '1' : '',
        }}
        {...rest}
      >
        <Box
          className="chip-bg default"
          opacity={isSelected ? 0 : 1}
        />
        <Box
          className="chip-bg selected"
          opacity={isSelected ? 1 : 0}
        />
        <Box
          marginTop="-2px"
          letterSpacing="-1px"
          position="relative"
        >
          <RocketText>
            {betAmount}
          </RocketText>
        </Box>
      </Flex>
    </Box>
  )
}

export default RocketChip;
