import { CrashOrder, TrenBallType } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import { CopyBtn } from '@/components/common/CopyBtn'
import { textGrayDrawer, textGrayWhite } from '@/const/color'
import { currency, currencyMap, getSymbolFromCurrency } from '@/const/currency'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Text, Flex, Stack, useDisclosure, Image, Button } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { f } from 'fintech-number'
import React from 'react'
import Fairness from '../Menu/Fairness'
import CopyAll from '@/components/common/CopyAll'
import { GameID } from '@/const/game-type'
import useGameId from '@/hooks/useGameId'
import { useAppSelector } from '@/redux/store'
import { ROUND_STATE, selectGameRound } from '@/redux/store/modules/rocketTshow'
import { FiExternalLink } from 'react-icons/fi'

type Props = {
  order: CrashOrder
}

// export default function BetInfo(props: Props) {
const BetInfo = (props: Props) => {
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { order } = props
  const isWin = +order?.reward > 0
  const currencySupported = currencyMap[order.currency]
  const gameRound = useAppSelector(selectGameRound)
  const isRoundRunning = order.round_id === gameRound.round_id && gameRound.state !== ROUND_STATE.END
  const preventClick = order?.multiplier === '0' || !order?.multiplier
  return (
    <Stack p={0}>
      <Box
        onClick={() => {
          if(preventClick) return
          onOpen()
        }}
        padding="10px 6px 3px 6px"
        backgroundColor="#232327"
        borderRadius={12}
        cursor={preventClick ? 'not-allowed' : 'pointer'}
      >
        <Stack>
          <Flex justifyContent="space-between" alignItems="center" px="5px">
            <Flex alignItems="center" gap={3}>
              {/* <RenderIconBet bet={order.bet} color={color} indexColor={indexColor} /> */}
              <Stack>
                <Text letterSpacing={1} fontSize={12} textColor={'#fff'} fontWeight={500}>
                  {t(order?.game_id ?? GameID.H_ROCKET)}
                </Text>
                <Flex alignItems="flex-end" mt={-2} fontSize={12}>
                  <Flex gap={1} alignItems="flex-end" lineHeight={1}>
                    <Text fontWeight={300} color={textGrayDrawer}>
                      {t('betting')}:
                    </Text>
                    <Text color="white" fontSize="14px" mb="-1px">
                      {f(+order?.amount, {
                        decimal: currencySupported?.decimal,
                      })}
                    </Text>
                    <Text fontSize={10} color={textGrayWhite} lineHeight={1}>
                      ({order?.currency})
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>
            <Stack alignItems="center">
              <Flex fontWeight={600} alignItems="center" gap={1}>
                {isRoundRunning ? (
                  isWin ? (
                    <>
                      <Text color="#B0FC00" fontSize={16}>
                        {f(order?.reward, {
                          decimal: currencySupported?.decimal,
                        })}
                      </Text>
                      <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
                        {`(${order?.currency})`}
                      </Text>
                    </>
                  ) : (
                    <Text color="#c5c5cf" fontSize={16}>
                      --
                    </Text>
                  )
                ) : (
                  <>
                    <Text color={isWin ? '#B0FC00' : '#ff5959'} fontSize="18px">
                      {isWin ? '+' : '-'}
                      {isWin
                        ? f(order?.reward, {
                            decimal: currencySupported?.decimal,
                          })
                        : f(order?.amount, {
                            decimal: currencySupported?.decimal,
                          })}
                    </Text>
                    <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
                      {`(${order?.currency})`}
                    </Text>
                  </>
                )}
              </Flex>
              {(!isRoundRunning || (isRoundRunning && isWin)) && (
                <Text mt={-3} fontSize={12} color={textGrayDrawer}>
                  {isWin ? t(`win`) : t(`lose`)}
                </Text>
              )}
            </Stack>
          </Flex>
          <hr style={{ borderColor: '#2d2d32' }} />
          <Flex fontSize="0.9rem" justifyContent="space-between" alignItems="center" mb="3px" px="5px">
            <Text fontSize={12} color={'#75757f'} fontWeight={400}>
              {dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </Text>
            <Text fontSize={12} color={'#ffffff'} fontWeight={400}>
              {order?.tren_ball_type === TrenBallType.None ? t('rocket.tabs.classic') : t('rocket.tabs.trenball')}
            </Text>
          </Flex>
        </Stack>
      </Box>

      {isOpen && (
        <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
          <RocketBetDetailInfo order={order} />
        </AppDrawer>
      )}
    </Stack>
  )
}

export default BetInfo

export const RocketBetDetailInfo = React.memo(
  ({ order }: { order: CrashOrder }) => {
    const { t } = useCustomTranslation()
    const isWin = +order?.reward > 0
    const isRoundEnd = !!order?.random_value

    let gameId = useGameId()
    if (!gameId || gameId.includes('-')) gameId = (order as any).game_type ?? order.game_id

    const currencySupported = currencyMap[order.currency]
    const { isOpen, onOpen, onClose } = useDisclosure()

    const formatOrderId = (orderId?: string) => {
      if (!orderId) return ''

      const formatOrderIdRegex = /^(.{8}).*(.{8})$/

      return orderId.replace(formatOrderIdRegex, '$1...$2')
      // cf05be8a-dc5f-4a48-9424-0d90fae652db => cf05be8a...fae652db
    }

    return (
      <>
        <Box backgroundColor="#2f2f31" className="newgame-container">
          <Stack p={4} backgroundColor="#2f2f31" color={'#fff'} gap={0}>
            <Text fontWeight={600} fontSize="22px" mt={2}>
              {t(gameId)}
            </Text>
            <Flex gap={2} alignItems="center" lineHeight={1.2}>
              <Text fontSize={32} fontWeight={600} color={isWin ? '#B0FC00' : '#ff5959'}>
                {isWin ? (
                  <>{`+${f(+order?.reward, {
                    decimal: currencySupported?.decimal,
                  })}`}</>
                ) : (
                  <>
                    {f(+order?.amount, {
                      decimal: currencySupported?.decimal,
                    })}
                  </>
                )}
              </Text>
              <Image src={getSymbolFromCurrency(order?.currency)} className="w-6 h-6 rounded-lg" />
            </Flex>
            <Stack flexDirection="row" justifyContent="space-between" color={isWin ? '#B0FC00' : '#ff5959'}>
              <Text>{isWin ? t('win') : t('lose')}</Text>
              {isRoundEnd && <Text>{t('statusPayment')}</Text>}
            </Stack>
          </Stack>
          <Stack p={4} color={'#fff'} borderRadius="14px 14px 0 0" backgroundColor="#363639" gap="10px">
            <Stack>
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('game')}</Text>
                <Text fontWeight={400}>{t(gameId)}</Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betAmount')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>
                    {f(+order?.amount, {
                      decimal: currencySupported?.decimal,
                    })}{' '}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            {order?.tren_ball_type === TrenBallType.None ? (
              <Stack>
                <hr style={{ borderColor: '#434343' }} />
                <Flex justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>
                    {gameId === GameID.H_ROCKET && t('skydivingAltitude')}
                    {gameId === GameID.H_TSHOW && t('catwalkDistance')}
                  </Text>
                  {order.cash_out !== '0' || order?.auto_cash_out !== '0' ? (
                    <Text fontWeight={400} color="#ffffff">
                      x
                      {f(order.cash_out !== '0' ? order.cash_out : order.auto_cash_out, {
                        decimal: currencySupported?.decimal,
                      })}
                    </Text>
                  ) : (
                    <Text fontWeight={400} lineHeight={0.5} color="#ffffff">
                      __
                    </Text>
                  )}
                </Flex>
              </Stack>
            ) : (
              <Stack>
                <hr style={{ borderColor: '#434343' }} />
                <Flex justifyContent="space-between" py={1}>
                  <Text color={textGrayDrawer}>{gameId === GameID.H_ROCKET && t('rocket.tabs.trenball')}</Text>
                  <Text
                    fontWeight={400}
                    lineHeight={1}
                    color="#ffffff"
                    // className={classNames('', {
                    //   'text-[#d52b4f]': order?.tren_ball_type === TrenBallType.Red,
                    //   'text-[#ff9600]': order?.tren_ball_type === TrenBallType.Yellow,
                    //   'text-[#27a052]': order?.tren_ball_type === TrenBallType.Green,
                    //   'text-[#0a79df]': order?.tren_ball_type === TrenBallType.Blue,
                    // })}
                  >
                    {order.tren_ball_type === TrenBallType.Yellow && 'Crash'}
                    {order.tren_ball_type === TrenBallType.Green && t('rocket.tabs.green')}
                    {order.tren_ball_type === TrenBallType.Red && t('rocket.tabs.red')}
                    {order.tren_ball_type === TrenBallType.Blue && 'Moon'}
                  </Text>
                </Flex>
              </Stack>
            )}

            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>
                  {gameId === GameID.H_ROCKET && t('explosionAltitude')}
                  {gameId === GameID.H_TSHOW && t('distance')}
                </Text>
                <Text fontWeight={400} color="#ffffff">
                  {isRoundEnd
                    ? `x${f(order.multiplier, {
                        decimal: currencySupported?.decimal,
                      })}`
                    : '--'}
                </Text>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('rebate')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400} color={isWin ? '#B0FC00' : '#ff5959'}>
                    {isWin ? (
                      <>{`+${f(+order?.reward, {
                        decimal: currencySupported?.decimal,
                      })}`}</>
                    ) : (
                      <>
                        {f(+order?.amount, {
                          decimal: currencySupported?.decimal,
                        })}
                      </>
                    )}
                  </Text>
                  {currency.find((i) => i.name === order?.currency)?.icon}
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('betTime')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}</Text>
                </Flex>
              </Flex>
            </Stack>
            <Stack>
              <hr style={{ borderColor: '#434343' }} />
              <Flex justifyContent="space-between" py={1}>
                <Text color={textGrayDrawer}>{t('orderId')}</Text>
                <Flex gap={1} alignItems="center">
                  <Text fontWeight={400}>{formatOrderId(order?.id)}</Text>
                  <CopyBtn text={order?.id} />
                </Flex>
              </Flex>
            </Stack>
            {isRoundEnd && (
              <>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('hashValue')}</Text>
                      <CopyBtn text={order?.random_value} />
                    </Flex>
                    <Text fontWeight={400}>{order?.random_value}</Text>
                  </Stack>
                </Stack>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('serverSeed')}</Text>
                      <CopyBtn text={order?.server_seed} />
                    </Flex>
                    <Text fontWeight={400}>{order?.server_seed}</Text>
                  </Stack>
                </Stack>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('clientSeed')}</Text>
                      <CopyBtn text={order?.client_seed} />
                    </Flex>
                    <Text fontWeight={400}>{order?.client_seed}</Text>
                  </Stack>
                </Stack>
                <Button
                  aria-label="btnVerification"
                  aria-labelledby="btnVerification"
                  w="100%"
                  backgroundColor="#c7fb14"
                  borderRadius="8px"
                  mt="8px"
                  onClick={() => {
                    window.open(
                      `https://emn178.github.io/online-tools/sha256.html?input=${order?.random_value}${order?.server_seed}${order?.client_seed}`,
                      '_blank',
                    )
                  }}
                  height="28px"
                  fontSize={14}
                >
                  {t('copyTextSHA256')}
                  <FiExternalLink style={{ display: 'inline', marginLeft: "4px", marginBottom: '2px' }} />
                </Button>
                <Stack>
                  <hr style={{ borderColor: '#434343' }} />
                  <Stack gap="0">
                    <Flex alignItems="center" justifyContent="space-between" py={1}>
                      <Text color={textGrayDrawer}>{t('proof')}</Text>
                      <CopyBtn text={order?.proof} />
                    </Flex>
                    <Text fontWeight={400}>{order?.proof}</Text>
                  </Stack>
                </Stack>
                <Button
                  aria-label="explosivePointVerification"
                  aria-labelledby="explosivePointVerification"
                  w="100%"
                  backgroundColor="#c7fb14"
                  borderRadius="8px"
                  mt="8px"
                  onClick={() => onOpen()}
                  height="32px"
                >
                  {t('explosivePointVerification')}
                </Button>
              </>
            )}
          </Stack>
        </Box>

        {isOpen && (
          <AppDrawer title={'Fairness'} isOpen={isOpen} onClose={onClose} overlay={true}>
            <Fairness randomValue={order?.random_value} />
          </AppDrawer>
        )}
      </>
    )
  },
  () => true,
)
