import Ticket from '@/bingo/components/Common/Ticket'
import ButtonWinningTicket from '@/bingo/components/FormSection/Mining/ButtonWinningTicket.tsx'
import TotalBonus from '@/bingo/components/FormSection/Mining/TotalBonus.tsx'
import { bingoConfig, defaultMultiplier } from '@/bingo/const/bingoConfigs.ts'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
import { selectBingoRoundOrderBalls, selectBingoTotalBonus } from '@/bingo/redux/bingo.slice'
import { LocalBingoTicket, WinningTicketItem } from '@/bingo/types/bingo.ts'
import { convertBEPattern, genTicketId } from '@/bingo/utils/BingoEngine'
import { useAppSelector } from '@/redux/store'
import { Box, Image, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

const numbers = generateBingoCard()
const mockTicket: LocalBingoTicket = {
  id: genTicketId(numbers),
  numbers,
  star: defaultMultiplier,
}

const BingoWinningAnimation = () => {
  return (
    <Box position="absolute" top="-180px" left="-22px" className="bingo-congrat">
      <Image
        src="/bingo/images/winning-ticket/bingo-light.webp"
        width="300px"
        minWidth="300px"
        aspectRatio="686/630"
        className="winning-light"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-text.webp"
        position="absolute"
        top="130px"
        left="62px"
        width="170px"
        zIndex="5"
        className="bingo-winning-text"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-ball-blue.webp"
        position="absolute"
        top="88px"
        left="108px"
        width="80px"
        zIndex="3"
        className="bingo-winning-ball-blue"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-ball-green.webp"
        position="absolute"
        top="66px"
        left="100px"
        width="40px"
        zIndex="4"
        className="bingo-winning-ball-green"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-ball-pink.webp"
        position="absolute"
        top="112px"
        width="30px"
        left="70px"
        zIndex="6"
        className="bingo-winning-ball-pink"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-ball-purple.webp"
        position="absolute"
        top="79px"
        left="168px"
        width="30px"
        zIndex="2"
        className="bingo-winning-ball-purple"
      />
      <Image
        src="/bingo/images/winning-ticket/bingo-ball-yellow.webp"
        position="absolute"
        top="113px"
        right="76px"
        width="25px"
        zIndex="1"
        className="bingo-winning-ball-yellow"
      />
    </Box>
  )
}

interface WinningTicketProps {
  ticket: WinningTicketItem
  ticketUnitPrice: number
}

const WinningTicket = ({ ticket, ticketUnitPrice }: WinningTicketProps) => {
  const bonus = useAppSelector(selectBingoTotalBonus)
  const _balls = useAppSelector(selectBingoRoundOrderBalls)
  const [balls] = useState(_balls)

  const [isDisplay, setDisplay] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setDisplay(false)
    }, bingoConfig.round.winningTicketDuration)
  }, [])

  if (!isDisplay) return null

  return (
    <>
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0, 0, 0, 0.3)"
        backdropFilter="blur(2px)"
        zIndex="1"
      >
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" width="50%">
          <Box marginBottom="20px" textAlign="right">
            <BingoWinningAnimation />
            {/*<Stack marginRight="-55px" display="inline-flex" className="bingo-winning-ticket">*/}
            {/*  <ButtonWinningTicket>Skip</ButtonWinningTicket>*/}
            {/*  <ButtonWinningTicket>Next</ButtonWinningTicket>*/}
            {/*</Stack>*/}
          </Box>
          <Ticket
            isBetSuccess
            data={ticket}
            ticketUnitPrice={ticketUnitPrice + ''}
            winningPattern={convertBEPattern(ticket.pattern)}
            resultBalls={balls}
            width="100%"
            className="bingo-winning-ticket"
          />
        </Box>
        <TotalBonus value={bonus} />
      </Box>
    </>
  )
}

export default WinningTicket
