import React from 'react'
import Rocket from '@components/rocket/GamePlay/Rocket.tsx'
import AuthGame from '@/components/rocket/AuthGame'
import GameRoundSubscription from '@/components/rocket/Subcription/GameRoundSubscription'
import ConfigRoundSubscription from '@/components/rocket/Subcription/ConfigRoundSubscription'
import MultiplierSubscription from '@/components/rocket/Subcription/MultiplierSubscription'
import OrdersSubscription from '@/components/rocket/Subcription/OrdersSubscription'
import RocketTabs from '@components/rocket/NewUI/RocketTabs'
import RocketFormContainer from '@components/rocket/NewUI/Common/RocketFormContainer.tsx'
import RocketGameBackground from '@components/rocket/NewUI/Common/RocketGameBackground.tsx'
import RocketTables from '@components/rocket/NewUI/RocketTables'
import RocketAgencyLogo from '@components/rocket/NewUI/Common/RocketAgencyLogo.tsx'
import useZoomUI from '@hooks/useZoomUI.ts'
import CurrentBetSubcription from '@/components/rocket/Subcription/CurrentBetSubcription'
// import '@styles/fonts-rocket.css'

export default function MainGameRocket() {
  useZoomUI({
    containersClassNames: ['zoom-container'],
  })

  return (
    <AuthGame>
      <RocketGameBackground>
        <Rocket />
        <GameRoundSubscription />
        <ConfigRoundSubscription />
        <CurrentBetSubcription />
        <MultiplierSubscription />
        <OrdersSubscription />
        <RocketFormContainer>
          <RocketTabs />
          <RocketTables />
          <RocketAgencyLogo />
        </RocketFormContainer>
      </RocketGameBackground>
    </AuthGame>
  )
}
