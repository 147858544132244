import { TextProps } from '@chakra-ui/react'
import React from 'react'
import { NotoSansFontFamily } from '@/utils/rocketFontFamily.ts'
import RocketText from '@components/rocket/RocketText.tsx'

type RocketInputSubTitleProps = TextProps

const RocketInputSubTitle = ({children, ...rest}: RocketInputSubTitleProps) => {
  return (
    <RocketText
      color="#636d7d"
      fontSize="14px"
      lineHeight="1"
      {...NotoSansFontFamily.medium}
      fontStyle="italic"
      {...rest}
    >
      {children}
    </RocketText>
  )
}

export default RocketInputSubTitle;
