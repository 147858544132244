import { bingoConfig } from '@/bingo/const/bingoConfigs'
import {
  // selectBingoGameRoundId,
  // selectBingoPlayingOrderRoundId,
  selectBingoRoundStatus,
} from '@/bingo/redux/bingo.slice'
import { useAppSelector } from '@/redux/store'
import React, { useEffect, useRef, useState } from 'react'
import EndGameAnimation from '../Common/EndGameAnimation'

export default function EndRoundSubscription() {
  const [isDisplay, setDisplay] = useState(false)
  const status = useAppSelector(selectBingoRoundStatus)
  // const orderRoundId = useAppSelector(selectBingoPlayingOrderRoundId)
  // const roundId = useAppSelector(selectBingoGameRoundId)
  const statusRef = useRef(status)
  const { winningAnimationDuration, delayBeforeCongrats } = bingoConfig.round

  // const canDisplayEndRound = !!orderRoundId && orderRoundId != roundId

  useEffect(() => {
    if (status == statusRef.current) return

    if (statusRef.current && status == 'Waiting') {
      const timeout = setTimeout(() => {
        setDisplay(true)
      }, delayBeforeCongrats)
      return () => clearTimeout(timeout)
    }
    statusRef.current = status
  }, [status, statusRef.current])

  useEffect(() => {
    if (!isDisplay) return
    const timeout = setTimeout(() => {
      setDisplay(false)
    }, winningAnimationDuration)

    return () => clearTimeout(timeout)
  }, [isDisplay])

  return <>{isDisplay && <EndGameAnimation />}</>
}
