import { useAppDispatch, useAppSelector } from '@/redux/store'
import {
  mapPlinkoOrderToBetResult,
  plinkoActions,
  PlinkoBetResult,
  selectPlinkoIsPreventing,
  selectPlinkoMuted,
  selectPlinkoNewBalls,
  selectPlinkoRows,
} from '@/redux/store/modules/plinko.slice'
import bgPlinko from '@images/bg-plinko.webp'
import ballAudio from '@sounds/ball.wav'
import { forEach, get, isEmpty, map } from 'lodash'
import { Bodies, Body, Composite, Engine, Mouse, MouseConstraint, Render, Runner, Vector, World } from 'matter-js'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import useSound from 'use-sound'
import { config, plinkoGameConfigs } from '../../config'
import { multiplierSounds } from '../../config/multipliers'
import { LastResult } from '../LastResult'
import WinnerConguration from '../WinnerConguration'
import { Boom } from './components/Boom'
import { MultiplierHighlight } from './components/MultiplierHighlight'
import { useEventHandler } from './hooks/useEventHandler'
import { useFloorWalls } from './hooks/useFloorWalls'
import { useMultipliers } from './hooks/useMultipliers'
import { usePinGap } from './hooks/usePinGap'
import { usePins } from './hooks/usePins'
import { useWalls } from './hooks/useWalls'

declare global {
  interface Window {
    addBalls?: (paths: string[]) => void
  }
}

const streamingBalls: Record<string, boolean> = {}

declare type BoomItem = Vector & { rate: number }

function getPlinkoCollisions(path: string): number[] {
  const lines = path.length + 3
  const pins: number[][] = []
  let index = 0
  for (let i = 0; i < lines; i++) {
    pins[i] = []
    for (let j = 0; j <= i; j++) {
      pins[i][j] = index++
    }
  }
  const result = [0]
  const currentBall = { row: 0, col: 0 }
  for (let i = 0; i < path.length; i++) {
    const dir = path[i]
    currentBall.row++
    if (dir === 'R') currentBall.col++
    const index = get(pins, [currentBall.row, currentBall.col])
    if (index) result.push(index)
    else console.warn('Check getPlinkoCollisions: ', path)
  }
  return result
}

interface PlinkoGameBodyProps {
  width: number
  height?: number
}

export function PlinkoGameBody({ width, height }: PlinkoGameBodyProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  const lines = useAppSelector(selectPlinkoRows)
  const { colors, ball: ballConfig, engine: engineConfig } = config
  const worldWidth = width
  const worldHeight = height || width

  const { pinSize, ballSize, pinYGap, pinXGap } = usePinGap({ worldWidth, worldHeight })
  const boomSize = 20
  const ratio = window.devicePixelRatio || 1
  const timeScale = (plinkoGameConfigs.timing.timeScale * 10) / lines
  const [engine] = useState(
    Engine.create({
      timing: {
        timeScale,
      },
      gravity: engineConfig.gravity,
    }),
  )
  const [runner] = useState(Runner.create())
  const [render, setRender] = useState<Render>()

  const { pins, ref: pinsRef } = usePins({
    worldHeight,
    worldWidth,
    engine,
  })
  const { ref: multipliersRef } = useMultipliers({ engine, worldHeight, worldWidth, render })
  useFloorWalls({ engine, worldWidth, worldHeight })
  useWalls({ engine, worldWidth, worldHeight })
  const ballsRef = useRef<Record<string, Body>>({})

  useEffect(() => {
    engine.gravity = engineConfig.gravity

    const render = Render.create({
      element: containerRef.current!,
      bounds: { max: { y: worldHeight, x: worldWidth }, min: { y: 0, x: 0 } },
      options: {
        background: colors.background,
        hasBounds: true,
        width: worldWidth,
        height: worldHeight,
        pixelRatio: ratio,
        wireframes: false,
      },
      engine,
    })
    setRender(render)

    const mouse = Mouse.create(render.canvas)
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    })
    World.add(engine.world, mouseConstraint)

    const _runner = runner as any

    _runner._running = true
    Runner.run(runner, engine)
    Render.run(render)

    return () => {
      _runner._running = false
      Render.stop(render)
      Runner.stop(runner)
      World.clear(engine.world, true)
      Engine.clear(engine)
      render.canvas.remove()
      render.textures = {}
    }
  }, [engine, runner])

  useEffect(() => {
    engine.timing.timeScale = timeScale
  }, [engine, timeScale])

  const muted = useAppSelector(selectPlinkoMuted)
  const mutedRef = useRef(muted)

  useEffect(() => {
    mutedRef.current = muted
  }, [muted])
  const sounds = [...multiplierSounds, ballAudio]

  const playAudio = (audio: string) => {
    if (mutedRef.current) return
    const sound = soundRefs.current[audio]
    if (sound) {
      sound.play()
    }
  }

  const isPreventing = useAppSelector(selectPlinkoIsPreventing)
  const [preventing, _setPreventing] = useState(isPreventing)
  const preventingRef = useRef(preventing)
  useEffect(() => {
    preventingRef.current = preventing
  }, [preventing])
  const setPreventing = (val: boolean) => {
    if (val == preventingRef.current) return
    if (val) {
      setTimeout(() => {
        setBooms({})
      }, 100)
    }
    _setPreventing(val)
    if (val) {
      dispatch(plinkoActions.preventingUpdated(val))
    } else {
      setTimeout(() => {
        dispatch(plinkoActions.preventingUpdated(val))
      }, plinkoGameConfigs.multipliers.boomTime)
    }
  }

  const incrementBallCount = () => {
    if (!preventing) {
      setPreventing(true)
    }
  }
  const decrementBallCount = () => {
    if (preventingRef.current && map(ballsRef.current).length <= 0) {
      setPreventing(false)
    }
  }

  const [isVisible, setVisible] = useState(true)
  const visibleRef = useRef(isVisible)
  useEffect(() => {
    visibleRef.current = isVisible
  }, [isVisible])

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(document.visibilityState == 'visible')
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      const balls = map(ballsRef.current)
      Composite.remove(engine.world, balls)
      forEach(balls, (ball) => {
        const rate = (ball as any)?.item?.rate

        if (rate) {
          addRate(rate)
        }
        delete ballsRef.current[ball.id]
      })
      Composite.remove(engine.world, balls)
      decrementBallCount()
    }
  }, [isVisible])

  const addRate = (rate: number) => {
    setLastRates((prev) => [rate, ...prev].slice(0, 6))
  }

  const addBall = (item: PlinkoBetResult) => {
    if (!visibleRef.current) {
      addRate(item.rate)
      return
    }
    incrementBallCount()
    playAudio(ballAudio)

    const ballX = worldWidth / 2
    const ballY = 0
    const ballColor = colors.text
    const id = Date.now() + Math.random()
    const ball = Bodies.circle(ballX, ballY, ballSize, {
      ...ballConfig,
      label: `ball-${item.result}`,
      id,
      collisionFilter: { group: -1 },
      render: { fillStyle: ballColor },
      isStatic: false,
    })

    const path = item.result
    const indexs = getPlinkoCollisions(path)
    const r = (pinSize + ballSize) * 0.8
    const points = indexs
      .map((index, i) => {
        const p = pins[index]?.position
        if (!p) return undefined
        const prevDir = path[i - 1]
        const nextDir = path[i]
        const ratio = prevDir == nextDir ? 0.4 : 1
        const delta = nextDir == 'L' ? -r : r
        return {
          x: p.x + delta * ratio,
          y: p.y,
        }
      })
      .filter((item) => item)
    const lastDir = path.charAt(points.length - 1)
    const _lastPoint = points[points.length - 1]
    if (lastDir && _lastPoint) {
      const _dir = lastDir == 'L' ? -1 : 1
      const lastPoint = {
        x: _lastPoint.x + (_dir * pinXGap) / 2,
        y: _lastPoint.y + pinYGap,
      }
      points.push(lastPoint)
    }

    const _ball = ball as any
    _ball.item = item
    _ball.lastDir = path[path.length - 1]
    _ball.points = points
    _ball._path = path
    ballsRef.current[id + ''] = ball
    Composite.add(engine.world, ball)
  }

  useEffect(() => {
    window.addBalls = (paths: string[]) => {
      const multipliers = Array(paths.length).fill(1)
      const balls = mapPlinkoOrderToBetResult({
        ball_paths: paths,
        multipliers,
        id: Math.random() + '',
        rows: paths[0].length,
        amount: '1',
        currency: 'USDT',
        created_at: new Date().toISOString(),
        server_seed: 'sdfsd',
      } as any)
      preventBalls(balls)
    }
  }, [addBall])

  const newBalls = useAppSelector(selectPlinkoNewBalls)
  const dispatch = useAppDispatch()

  async function preventBalls(balls: PlinkoBetResult[]) {
    if (isEmpty(balls)) return

    for (let i = 0; i < balls.length; i++) {
      const ball = balls[i]

      const { id } = ball
      if (streamingBalls[id]) return
      addBall(ball)
      streamingBalls[id] = true
      if (visibleRef.current) await new Promise((resolve) => setTimeout(resolve, 100))
    }
  }

  useEffect(() => {
    if (isEmpty(newBalls)) return
    preventBalls(newBalls)
  }, [newBalls])

  const [booms, setBooms] = useState<Record<string, Vector>>({})
  const [lastRates, setLastRates] = useState<number[]>([])
  function addBoom(pos: Vector & { rate: number }) {
    decrementBallCount()
    const id = Date.now() + Math.random()
    setBooms((prev) => ({
      ...prev,
      [id]: pos,
    }))
    addRate(pos.rate)
    setTimeout(() => {
      setBooms((prev) => {
        const next = { ...prev }
        delete next[id]
        return next
      })
    }, plinkoGameConfigs.multipliers.boomTime)
  }

  const { highlightLabel } = useEventHandler({
    worldHeight,
    worldWidth,
    engine,
    runner,
    render,
    addBoom,
    playAudio,
    multipliersRef,
    ballsRef,
    pinsRef,
  })
  const soundRefs = useRef<Record<string, SoundPlayerRef>>({})

  return (
    <div className="flex flex-1 bg-gradient-to-b from-[#2d1587] to-[#221067] rounded-lg relative">
      <div className="absolute right-0 top-0">
        <LastResult lastRates={lastRates} />
      </div>
      <div
        ref={containerRef}
        id="plinko"
        style={{ backgroundImage: `url(${bgPlinko})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%' }}
        className="flex flex-1 p-2 relative"
      >
        <div
          className="absolute top-0 left-0 right-0"
          style={{
            bottom: plinkoGameConfigs.multipliers.height + 8, // p-2 padding 8px
          }}
        />
      </div>
      {highlightLabel && (
        <div className={`absolute bottom-[40px] left-1/2 transform -translate-x-1/2`}>
          <MultiplierHighlight label={highlightLabel} rows={lines} />
        </div>
      )}

      {sounds.map((sound) => (
        <SoundPlayer
          ref={(ref) => {
            if (ref) {
              soundRefs.current[sound] = ref
            }
          }}
          sound={sound}
        />
      ))}

      {map(booms, ({ x, y }) => (
        <div
          key={`${x}-${y}`}
          style={{
            left: x - boomSize,
            top: y - boomSize,
          }}
          className="absolute"
        >
          <Boom />
        </div>
      ))}
      <WinnerConguration />
    </div>
  )
}

interface SoundPlayerRef {
  play: () => void
}

interface SoundPlayerProps {
  sound: string
}

const SoundPlayer = forwardRef<SoundPlayerRef, SoundPlayerProps>(({ sound }, ref) => {
  const [_play] = useSound(sound)

  const play = () => {
    _play()
  }

  useImperativeHandle(ref, () => ({
    play,
  }))

  return <></>
})
