import PatternBoard from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternBoard.tsx'
import PatternContainer from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternContainer.tsx'
import PatternMultiplier from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternMultiplier.tsx'
import { PatternBoardRate } from '@/bingo/types/bingo'
import { Center, StackProps } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import React from 'react'

type LotteryPatternProps = StackProps & { pattern: PatternBoardRate }

const LotteryPattern = ({ pattern, ...rest }: LotteryPatternProps) => {
  const { ex } = pattern
  return (
    <div className="relative">
      <PatternContainer {...rest}>
        <PatternBoard pattern={pattern.pattern} />
        <PatternMultiplier value={pattern.rate} />
      </PatternContainer>
      {!isEmpty(ex) ? (
        <div className="absolute z-1 top-0 left-0 w-full h-full">
          <Center>{pattern.name}</Center>
        </div>
      ) : null}
    </div>
  )
}

export default LotteryPattern
