import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Box, Button, Flex, Stack, Text, Image } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store/index.ts'
import React, { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import BetInfo from './BetInfo'
import { getMyOrders, ROUND_STATE, selectGameRound, selectMyOrders } from '@/redux/store/modules/rocketTshow'
import { CrashOrder } from '@/@generated/gql/graphql-hash'
import useGameId from '@/hooks/useGameId'
import ListBetting from './ListBetting'

export default function MyBets() {
  const { t } = useCustomTranslation()
  const [popup, setPopup] = useState('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const myOrders = useAppSelector(selectMyOrders)
  const gameId = useGameId()
  const gameRound = useAppSelector(selectGameRound)
  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }

  const getListOrder = () => {
    dispatch(
      getMyOrders({
        game_id: gameId,
        page: 0,
        limit: 10,
      }),
    )
  }

  useEffect(() => {
    getListOrder()
  }, [])

  useEffect(() => {
    if (gameRound?.state === ROUND_STATE.END) getListOrder()
  }, [gameRound])

  const handleClickViewMore = () => {
    setPopup('BettingRecordRocket')
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('param', 'BettingRecordRocket')
    navigate(`?${searchParams.toString()}`, { replace: true })
  }

  return (
    <Stack marginTop={2} gap="14px">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #1a1a1a"
        boxShadow="0px 1px 0px 0px #2b2b2f"
        pb="6px"
      >
        <Text fontSize="15px" color="#E8E8EA" textStyle="semibold">
          {t('bettingRecord')}
        </Text>
        <Button
          aria-label="All"
          aria-labelledby="All"
          _focus={{ bg: 'none' }}
          _hover={{ bg: 'none' }}
          rightIcon={<FaAngleRight style={{ marginBottom: '1px' }} />}
          bg="none"
          color="#56555B"
          onClick={handleClickViewMore}
          p={0}
          minWidth="auto"
          height="auto"
          fontSize="12px"
          // position="relative"
          // top="3px"
        >
          {t('all')}
        </Button>
      </Flex>
      <Stack>
        <Stack gap="9.5px">
          {myOrders?.length > 0 ? (
            myOrders.map((order: CrashOrder, index: number) => <BetInfo order={order} key={index} />)
          ) : (
            <Flex
              w="100%"
              height="200px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              color="#3d3d43"
              gap={'11px'}
            >
              <Box>
                <Image w={'40px'} src="/images/norecord.webp" />
              </Box>
              <Box color={'#4b4b4d'} fontSize={'13px'}>
                {t('notification.youHavenorecordsYet')}
              </Box>
            </Flex>
          )}
        </Stack>
        {myOrders.length && (
          <Stack>
            <Text
              marginBlock={4}
              marginInline={'auto'}
              w={'70px'}
              color="#56555B"
              textAlign={'center'}
              height="auto"
              fontSize="12px"
              cursor={'pointer'}
              onClick={handleClickViewMore}
            >
              {t('viewmore')}
            </Text>
          </Stack>
        )}
      </Stack>

      <AppDrawer
        title={t(`menu.bettingListing`)}
        isOpen={'BettingRecordRocket' === popup}
        onClose={closePopup}
        overlay={true}
        
      >
        <Box p={4}>
          <ListBetting noPaging={false} />
        </Box>
      </AppDrawer>
    </Stack>
  )
}
